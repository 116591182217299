import { Avatar, AvatarGroup, Box, CardHeader, Checkbox, IconButton, Stack, TableCell, TableRow, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { TaskTemplate, User } from "../../../../models";
import './StudentListItem.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import StatusList from "../../../controls/StatusList/StatusList";
import { FilterData } from "../../../filters/FilterStudents";
import ConfidenceList from "../../../controls/StatusList/ConfidenceList";
import RichTextViewer from "../../../controls/RichTextViewer";

const StudentListItem = ({ student, to, graduationYear, columns, isItemSelected, expanded = false, onCheckboxClick, filters }: { student: User, to: string, graduationYear: string|undefined, columns: TaskTemplate[], isItemSelected: boolean, expanded?: boolean, onCheckboxClick: (user: User) => void, filters: FilterData|undefined }) => {  
  const [isExpanded, setExpanded] = useState(expanded);

  const isFilteringAssessments = 
    filters && 
    (
      (filters.prescreens && filters.prescreens !== 'Any') ||
      (filters.auditions_portfolio_required && filters.auditions_portfolio_required !== 'Any') ||
      (filters.early_decision && filters.early_decision !== 'Any') ||
      (filters.early_action && filters.early_action !== 'Any') ||
      (filters.score_optional_enabled && filters.score_optional_enabled !== 'Any') ||
      (filters.common_app && filters.common_app !== 'Any') ||
      (filters.audition_date_start && filters.audition_date_start !== 'Any') ||
      (filters.audition_date_end && filters.audition_date_end !== 'Any') ||
      (filters.college) ||
      (filters.degree) ||
      (filters.applying && filters.applying !== 'Any') ||
      (filters.test_optional && filters.test_optional !== 'Any') ||
      (filters.academic_confidence && filters.academic_confidence !== 'Any') ||
      (filters.artistic_confidence && filters.artistic_confidence !== 'Any') ||
      (filters.unified_auditions && filters.unified_auditions !== 'Any') ||
      (filters.application_status && filters.application_status !== 'Any') ||
      (filters.supplemental_application_status && filters.supplemental_application_status !== 'Any') ||
      (filters.admission_decision && filters.admission_decision !== 'Any')
    )

  const filteredAssessments = student.assessments?.filter(a => {
    let include = true;
    if (a.inactive) include = false;
    if (!a.institution_degree?.institution) include = false;
    if (!a.institution_degree?.degree) include = false;
    if (filters?.prescreens && filters?.prescreens !== 'Any' && a.institution_degree?.pre_screening !== filters.prescreens) include = false;
    if (filters?.auditions_portfolio_required && filters?.auditions_portfolio_required !== 'Any' && a.institution_degree?.required_audition_portfolio !== filters.auditions_portfolio_required) include = false;
    if (filters?.early_decision && filters?.early_decision !== 'Any' && a.institution_degree?.early_decision !== filters.early_decision) include = false;
    if (filters?.early_action && filters?.early_action !== 'Any' && a.institution_degree?.early_action !== filters.early_action) include = false;
    if (filters?.score_optional_enabled && filters?.score_optional_enabled !== 'Any' && a.institution_degree?.score_optional_enabled !== filters.score_optional_enabled) include = false;
    if (filters?.common_app && filters?.common_app !== 'Any' && a.institution_degree?.institution?.common_app !== filters.common_app) include = false;
    if (filters?.audition_date_start && filters?.audition_date_start !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates < filters.audition_date_start) include = false;
    if (filters?.audition_date_end && filters?.audition_date_end !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates > filters.audition_date_end) include = false;
    if (filters?.graduation_year && filters?.graduation_year !== 'Any' && student.high_school_graduation_year?.toString() !== filters.graduation_year.toString()) include = false;
    if (filters?.college && a.institution_degree?.institution_id !== filters.college.id) include = false;
    if (filters?.degree && a.institution_degree?.degree_id !== filters.degree.id) include = false;
    if (filters?.applying && filters?.applying !== 'Any' && a.applying !== filters.applying) include = false;
    if (filters?.test_optional && filters?.test_optional !== 'Any' && a.test_optional !== filters.test_optional) include = false;
    if (filters?.academic_confidence && filters?.academic_confidence !== 'Any' && a.academic_confidence !== filters.academic_confidence) include = false;
    if (filters?.artistic_confidence && filters?.artistic_confidence !== 'Any' && a.artistic_confidence !== filters.artistic_confidence) include = false;
    if (filters?.unified_auditions && filters?.unified_auditions !== 'Any' && a.institution_degree?.unified_auditions !== filters.unified_auditions) include = false;
    if (
      filters?.application_status && filters?.application_status !== 'Any' && 
      (
        (filters?.application_status === 'Not Selected' && a.application_status) || 
        (filters?.application_status !== 'Not Selected' && a.application_status !== filters?.application_status)
      )
    ) include = false;
    if (
      filters?.supplemental_application_status && filters?.supplemental_application_status !== 'Any' && 
      (
        a.institution_degree?.supplemental_application_required !== 'Yes' ||
        !a.institution_degree?.supplemental_application_url ||
        (
          (filters?.supplemental_application_status === 'Not Selected' && a.supplemental_application_status) || 
          (filters?.supplemental_application_status !== 'Not Selected' && a.supplemental_application_status !== filters?.supplemental_application_status)
        )
      )
    ) include = false;
    if (
      filters?.admission_decision && filters?.admission_decision !== 'Any' &&
      (
        a.institution_degree?.pre_screening !== 'Yes' ||
        (
          (filters?.admission_decision === 'Not Selected' && a.admission_decision) || 
          (filters?.admission_decision !== 'Not Selected' && a.admission_decision !== filters?.admission_decision)
        )
      )
    ) include = false;
    return include;
  }) || []

  const sortedAssessments = filteredAssessments.sort((a, b) => {
    let sort = 0;
    // default to institution name
    sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
    return sort;
  });

  if (filters && isFilteringAssessments && filteredAssessments.length === 0) return null;

  return (
    <React.Fragment>
      <TableRow
        hover
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={() => onCheckboxClick(student)}
          />
        </TableCell>
        <TableCell>
          <CardHeader sx={{ p: 0, textDecoration: 'none', color: 'inherit' }}
            avatar={<Avatar alt={`${student.first_name} ${student.last_name}`} src={student.avatar?.url} />}
            title={`${student.first_name} ${student.last_name}`}
            subheader={student.email}
            component={Link}
            to={to}
          />
        </TableCell>
        <TableCell>
          {isExpanded ?
            <Typography variant="body2">{sortedAssessments.length} active applications</Typography>
          :
            <Stack>
              <Typography variant="body2">{sortedAssessments.length} active applications</Typography>
              <Box sx={{display: 'flex'}}>
                <AvatarGroup max={4}>
                  {sortedAssessments.map((assessment, index) =>
                    <Avatar key={assessment.id} alt={`${assessment.institution_degree?.institution?.name} ${assessment.institution_degree?.degree?.name}`} src={assessment.institution_degree?.institution?.institution_avatar?.url} />
                  )}
                </AvatarGroup>
              </Box>
            </Stack>
          }
        </TableCell>      
        {filters?.prescreens &&
          <TableCell></TableCell>
        }      
        {filters?.auditions_portfolio_required &&
          <TableCell></TableCell>
        }
        {filters?.early_decision &&
          <TableCell></TableCell>
        }
        {filters?.early_action &&
          <TableCell></TableCell>
        }
        {filters?.score_optional_enabled &&
          <TableCell></TableCell>
        }
        {filters?.common_app &&
          <TableCell></TableCell>
        }
        {(graduationYear || filters?.graduation_year) &&
          <TableCell>{student.high_school_graduation_year}</TableCell>
        }
        {filters?.test_optional &&
          <TableCell></TableCell>
        }
        {filters?.unified_auditions &&
          <TableCell>Unified Auditions</TableCell>
        }
        {filters?.intended_college_major &&
          <TableCell>{student.intended_college_major}</TableCell>
        }
        {columns.map(taskTemplate =>
          <TableCell key={taskTemplate.id}>{student.tasks?.find(t => t.task_template_id === taskTemplate.id)?.completed_at ? 'Complete' : 'Incomplete'}</TableCell>
        )}
        <TableCell align="right">
          {isExpanded ? (
            <IconButton onClick={() => setExpanded(false)}>
              <ExpandLessIcon />
            </IconButton>
          ) : (
            <IconButton onClick={() => setExpanded(true)}>
              <ExpandMoreIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
      {isExpanded && (
        <>
          {sortedAssessments.map((assessment, index) => 
            <TableRow key={assessment.id}>
              <TableCell padding="checkbox" />
              <TableCell valign="top" sx={{ pl: 9, verticalAlign: 'top' }}>
                <Stack key={assessment.id} direction="row" spacing={2} sx={{ color: 'inherit', textDecoration: 'none' }} component={Link} to={`/assessments/${assessment.id}`}>
                  <img 
                    src={assessment.institution_degree?.institution?.institution_avatar?.url} 
                    alt={`${assessment.institution_degree?.institution?.name} ${assessment.institution_degree?.degree?.name}`} 
                    className="student-list-item__assessment-image"
                  />
                  <Stack>
                    <Typography variant="subtitle2">{assessment.institution_degree?.institution?.name}</Typography>
                    <Typography variant="body2">{assessment.institution_degree?.degree?.name}</Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell valign="top">
                <StatusList assessment={assessment} onChange={() => {}} />
                <ConfidenceList assessment={assessment} isAdmin={true} onChange={() => {}} />
              </TableCell>
              {filters?.prescreens &&
                <TableCell>{assessment.institution_degree.pre_screening}</TableCell>
              }      
              {filters?.auditions_portfolio_required &&
                <TableCell>{assessment.institution_degree.required_audition_portfolio}</TableCell>
              }
              {filters?.early_decision &&
                <TableCell>{assessment.institution_degree.early_decision}</TableCell>
              }
              {filters?.early_action &&
                <TableCell>{assessment.institution_degree.early_action}</TableCell>
              }
              {filters?.score_optional_enabled &&
                <TableCell>
                  <RichTextViewer value={assessment.institution_degree.score_optional_enabled} />
                </TableCell>
              }
              {filters?.common_app &&
                <TableCell>{assessment.institution_degree.institution.common_app}</TableCell>
              }
              {(graduationYear || filters?.graduation_year) &&
                <TableCell>{student.high_school_graduation_year}</TableCell>
              }
              {filters?.test_optional &&
                <TableCell>{assessment.test_optional}</TableCell>
              }
              {filters?.unified_auditions &&
                <TableCell>{assessment.institution_degree.unified_auditions}</TableCell>
              }
              {filters?.intended_college_major &&
                <TableCell>{student.intended_college_major}</TableCell>
              }
              {columns.map(taskTemplate =>
                <TableCell key={taskTemplate.id}>{student.tasks?.find(t => t.task_template_id === taskTemplate.id)?.completed_at ? 'Complete' : 'Incomplete'}</TableCell>
              )}
              <TableCell/>
            </TableRow>
          )}
        </>
      )}
    </React.Fragment>
  )
}

export default StudentListItem;
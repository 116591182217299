import { Avatar, Box, Checkbox, Container, FormControl, FormControlLabel, FormLabel, Grid, Paper, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { User } from "../../../../models";
import PaperDropzone from "../../../controls/PaperDropzone/PaperDropzone";
import PrimaryButton from "../../../controls/PrimaryButton";
import RichTextEditor from "../../../controls/RichTextEditor";
import SecondaryButton from "../../../controls/SecondaryButton";

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = thisYear - 5; i < thisYear + 5; i++) {
  years.push(i);
}

const AdminForm = ({ user, onCancel, onSave }: { user?: User, onCancel: () => void, onSave: (user: User) => void }) => {
  const [firstName, setFirstName] = useState(user?.first_name || '');
  const [lastName, setLastName] = useState(user?.last_name || '');
  const [email, setEmail] = useState(user?.email || '');
  const [biography, setBiography] = useState(user?.biography || '');
  const [file, setFile] = useState<File>();
  const [superadmin, setSuperadmin] = useState(user?.superadmin || false);
  const [notify, setNotify] = useState(user?.notify || false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;
  
  const handleSave = () => {
    const user: User = {
      first_name: firstName,
      last_name: lastName,
      email,
      biography,
      file,
      superadmin,
      notify,
    }
    onSave(user);
  }

  const onFileChange = (file: File) => {
    setFile(file);
  }
  
  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{py: 2}}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Avatar src={user?.avatar?.url} alt={`${user?.first_name} ${user?.last_name}`} />
            <PaperDropzone onChange={onFileChange} sx={{ flexGrow: 1 }} />
          </Stack>
          
          <TextField 
            label="First Name" 
            variant="outlined" 
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required />
          
          <TextField 
            label="Last Name" 
            variant="outlined" 
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required />

          <TextField 
            label="Email" 
            variant="outlined" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required />

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Biography</FormLabel>
            <RichTextEditor value={biography} onChange={setBiography} />
          </FormControl>

          <FormControlLabel control={<Checkbox checked={superadmin} onChange={() => setSuperadmin(!superadmin)} />} label="Superadmin" />
          <FormControlLabel control={<Checkbox checked={notify} onChange={() => setNotify(!notify)} />} label="Notify" />

        </Stack>
      </Container>

      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default AdminForm;
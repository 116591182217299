import { Avatar, Dialog, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { format, parseISO } from "date-fns";
import { DocumentFile, Folder } from "../../models";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import MoveDocument from "./MoveDocument";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditDocument from "./EditDocument";
import ShareDocument from "./ShareDocument";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import useAuth from "../../hooks/useAuth";

const DocumentItem = ({ document, onChange, isAdmin }: { document: DocumentFile, onChange: (document: DocumentFile) => void, isAdmin: boolean }) => {  
  const [anchorElDocument, setAnchorElDocument] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const auth = useAuth();
  
  const deleteDocument = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/${document.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const handleOpenDocumentMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElDocument(event.currentTarget);
  };

  const handleCloseDocumentMenu = () => {
    setAnchorElDocument(null);
  };

  const handleMoveDocument = async (folder?: Folder) => {
    setOpen(false);
    const token = auth.token();

    const body = {
      folder_id: folder?.id,
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/${document.id}/move`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onChange({ ...document, folder_id: folder?.id });
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleChangeDocument = (document: DocumentFile) => {
    setEditOpen(false);
    onChange(document);
  }

  const handleShareDocument = (documents: DocumentFile[]) => {
    setShareOpen(false);
  }

  if (deleted) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1} paddingBottom={1}>
      <InsertDriveFileOutlinedIcon color="primary" />
      <Stack sx={{ flexGrow: 1 }}>
        <Link color="inherit" underline="hover" variant="subtitle1" sx={{ fontWeight: 500, cursor: 'pointer' }} onClick={() => setEditOpen(true)}>{document.name}</Link>
        <Typography variant="body2">
          {`${document.user?.first_name} ${document.user?.last_name}`}
          {' '}
          {document.created_at && format(parseISO(document.created_at), 'MMM d, yyyy')}
        </Typography>
        {document.attachment?.url && (
          <Link variant="body2" href={document.attachment.url}>Download</Link>
        )}
        {document.url && (
          <Link variant="body2" href={document.url}>Link</Link>
        )}
        {document.assessment &&
          <Stack direction="row" spacing={1} sx={{ pt: 1, alignItems: 'center' }}>
            <Avatar sx={{ width: 24, height: 24 }} src={document.assessment?.institution_degree?.institution?.institution_avatar?.url} alt={`${document.assessment?.institution_degree?.institution?.name} ${document.assessment?.institution_degree?.degree?.name}`} />
            <Link variant="body2" color="inherit" underline="hover" href={`/assessments/${document.assessment.id}/documents`}>{`${document.assessment?.institution_degree?.institution?.name} ${document.assessment?.institution_degree?.degree?.name}`}</Link>
          </Stack>
        }
      </Stack>
      <IconButton onClick={handleOpenDocumentMenu} sx={{ p: 0 }}><MoreVertIcon/></IconButton>
      <Menu
        anchorEl={anchorElDocument}
        keepMounted
        open={Boolean(anchorElDocument)}
        onClose={handleCloseDocumentMenu}
      >
        <MenuItem onClick={() => setEditOpen(true)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => setOpen(true)}>
          <ListItemIcon>
            <DriveFolderUploadOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Move to folder...</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => setShareOpen(true)}>
          <ListItemIcon>
            <ContactPageOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Share</ListItemText>
        </MenuItem>

        <MenuItem onClick={() => deleteDocument()}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={open} fullWidth>
        <MoveDocument document={document} onCancel={() => setOpen(false)} onMove={handleMoveDocument} />
      </Dialog>
      <Dialog open={editOpen} fullWidth>
        <EditDocument document={document} onCancel={() => setEditOpen(false)} onSave={handleChangeDocument} showUsers={isAdmin} />
      </Dialog>
      <Dialog open={shareOpen} fullWidth>
        <ShareDocument document={document} onCancel={() => setShareOpen(false)} onShare={handleShareDocument} isAdmin={isAdmin} />
      </Dialog>
    </Stack>
  )
}

export default DocumentItem;
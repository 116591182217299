import { Box, Card, CardContent, CardHeader, Dialog, DialogTitle, Divider, Grid, IconButton, Link, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Assessment, Institution, InstitutionDegree } from "../../../../../../models";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditCollegeInfo from "./EditCollegeInfo";
import { useState } from "react";
import { format, parseISO } from "date-fns";
import EditCollegeDates from "./EditCollegeDates";
import EditableRichNotes from "../../../../../controls/EditableRichNotes";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import RichTextViewer from '../../../../../controls/RichTextViewer';

const CollegeInfo = ({ assessment, isAdmin, onChange }: { assessment: Assessment, isAdmin: boolean, onChange: (assessment: Assessment) => void }) => {
  const collegeInstitutionDegree = assessment.institution_degree

  if (!collegeInstitutionDegree) return null;

  const [open, setOpen] = useState(false);
  const [datesOpen, setDatesOpen] = useState(false);
  // const [collegeInstitutionDegree, setCollegeInstitutionDegree] = useState(institutionDegree);
  
  const city = collegeInstitutionDegree.institution?.city && collegeInstitutionDegree.institution?.city.length > 0 ? collegeInstitutionDegree.institution?.city : null;
  const state = collegeInstitutionDegree.institution?.state && collegeInstitutionDegree.institution?.state.length > 0 ? collegeInstitutionDegree.institution?.state : null;
  const cityState: string[] = [];
  if (city) {
    cityState.push(city);
  }
  if (state) {
    cityState.push(state);
  }
  const cityStateString = cityState.join(", ");

  const country = collegeInstitutionDegree.institution?.country && collegeInstitutionDegree.institution?.country.length > 0 ? collegeInstitutionDegree.institution?.country : null;
  const admissionsOfficePhone = collegeInstitutionDegree.institution?.central_office_phone && collegeInstitutionDegree.institution?.central_office_phone.length > 0 ? collegeInstitutionDegree.institution?.central_office_phone : null;
  const admissionsOfficeEmail = collegeInstitutionDegree.institution?.central_office_email && collegeInstitutionDegree.institution?.central_office_email.length > 0 ? collegeInstitutionDegree.institution?.central_office_email : null;

  const hasContactInfo = cityStateString || country || admissionsOfficePhone || admissionsOfficeEmail;

  const handleSaveInstitution = (updatedInstitution: Institution) => {
    const institution = { institution: updatedInstitution };
    // setCollegeInstitutionDegree({...collegeInstitutionDegree, ...institution});
    setOpen(false);
    onChange({...assessment, institution_degree: {...collegeInstitutionDegree, ...institution}});
  }

  const handleSaveInstitutionDegree = (updatedInstitutionDegree: InstitutionDegree) => {
    // setCollegeInstitutionDegree({...collegeInstitutionDegree, ...updatedInstitutionDegree});
    setDatesOpen(false);
    onChange({...assessment, institution_degree: {...collegeInstitutionDegree, ...updatedInstitutionDegree}});
  }

  if (!collegeInstitutionDegree.institution) {
    return null;
  }

  const action = isAdmin ? (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null;

  const datesAction = isAdmin ? (
    <IconButton onClick={() => setDatesOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null;

  const isValid = isValidPhoneNumber(admissionsOfficePhone || '');
  const phoneNumber = isValid ? parsePhoneNumber(admissionsOfficePhone || '') : null;
  const parsedPhoneNumber = phoneNumber ? phoneNumber.formatInternational() : admissionsOfficePhone

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader 
          title={collegeInstitutionDegree.institution?.name}
          action={action}
          sx={{ pb: 0 }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Contact Information</Typography>
              {hasContactInfo ? (
                <>
                  <Typography variant="body2">{cityStateString}</Typography>
                  <Typography variant="body2">{country}</Typography>
                  <Typography variant="body2">{parsedPhoneNumber}</Typography>
                  <Link variant="body2" href={`mailto:${admissionsOfficeEmail}`}>{admissionsOfficeEmail}</Link>
                </>
              ) : (
                <Typography variant="body2">No Contact Information</Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Common Application Institution:</Typography>
                <Typography variant="body2">{collegeInstitutionDegree.institution.common_app}</Typography>
              </Stack>
              {collegeInstitutionDegree.institution.application_type === 'College-specific App' &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Application:</Typography>
                  <Link variant="body2" href={collegeInstitutionDegree.institution.application_url}>{collegeInstitutionDegree.institution.application_url}</Link>
                </Stack>
              }
            </Grid>

            <Grid item xs={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Coalition Application:</Typography>
                <Typography variant="body2">{collegeInstitutionDegree.institution.coalition_application_institution}</Typography>
              </Stack>
              {collegeInstitutionDegree.institution.coalition_application_institution === 'Yes' &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Coalition Application Website:</Typography>
                  <Link variant="body2" href={collegeInstitutionDegree.institution.coalition_application_institution_url}>{collegeInstitutionDegree.institution.coalition_application_institution_url}</Link>
                </Stack>
              }
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardHeader 
          title="Dates" sx={{ pb: 0 }}
          action={datesAction} 
        />
        <CardContent>
          <Grid container spacing={2}>

            {assessment.applying === 'Regular Decision' && collegeInstitutionDegree.regular_deadline && 
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Regular Admission Deadline:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.regular_deadline), 'MMM d, yyyy')}</Typography>
                </Stack>
                {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.pre_screening_deadline &&
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Regular Decision Prescreen Deadline:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.pre_screening_deadline), 'MMM d, yyyy')}</Typography>
                  </Stack>
                }
              </Grid>
            }

            {assessment.applying === 'Early Action' && collegeInstitutionDegree.early_action === 'Yes' &&
              <Grid item xs={6}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Early Action Date:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.early_action_date), 'MMM d, yyyy')}</Typography>
                </Stack>
                {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_2 &&
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Early Action Prescreen Deadline:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_2), 'MMM d, yyyy')}</Typography>
                  </Stack>
                }
              </Grid>
            }

            {assessment.applying === 'Early Decision' && collegeInstitutionDegree.early_decision === 'Yes' &&
              <Grid item xs={6}>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Early Decision Date:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.early_decision_date), 'MMM d, yyyy')}</Typography>
                </Stack>
                {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_3 &&
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Early Decision Prescreen Deadline:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_3), 'MMM d, yyyy')}</Typography>
                  </Stack>
                }
              </Grid>
            }

            {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision === 'Yes' &&
              <Grid item xs={6}>
                <>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Other Decision Details:</Typography>
                    <Typography variant="body2">{collegeInstitutionDegree.other_decision_details}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Other Decision Date:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.other_decision_date), 'MMM d, yyyy')}</Typography>
                  </Stack>
                  {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_4 &&
                    <Stack direction="row" spacing={1}>
                      <Typography variant="subtitle2">Other Decision Prescreen Deadline:</Typography>
                      <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_4), 'MMM d, yyyy')}</Typography>
                    </Stack>
                  }
                </>
              </Grid>
            }

            {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision_two === 'Yes' &&
              <Grid item xs={6}>
                <>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Other Decision Details 2:</Typography>
                    <Typography variant="body2">{collegeInstitutionDegree.other_decision_details_two}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Other Decision Date 2:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.other_decision_date_two), 'MMM d, yyyy')}</Typography>
                  </Stack>
                  {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_5 &&
                    <Stack direction="row" spacing={1}>
                      <Typography variant="subtitle2">Other Decision 2 Prescreen Deadline:</Typography>
                      <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_5), 'MMM d, yyyy')}</Typography>
                    </Stack>
                  }
                </>
              </Grid>
            }

          </Grid>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="subtitle2">Comments About The College</Typography>
          {isAdmin &&
            <EditableRichNotes 
              endpoint={`institutions/${collegeInstitutionDegree.institution.id}`} 
              field="other" 
              value={collegeInstitutionDegree.institution.other || ''} 
              wrap="institution" 
              onChange={(value) => { onChange({...assessment, institution_degree: { ...collegeInstitutionDegree, institution: { ...collegeInstitutionDegree.institution, other: value } }}) }}
            />
          }
          {!isAdmin &&
            <RichTextViewer value={collegeInstitutionDegree.institution.other || ''} />
          }
        </CardContent>
      </Card>

      {isAdmin &&
        <Card>
          <CardContent>
            <Typography variant="subtitle2">Private College Notes</Typography>
            <EditableRichNotes 
              endpoint={`institutions/${collegeInstitutionDegree.institution.id}`} 
              field="private_institution_notes" 
              value={collegeInstitutionDegree.institution.private_institution_notes || ''} 
              wrap="institution" 
              onChange={(value) => { onChange({...assessment, institution_degree: { ...collegeInstitutionDegree, institution: { ...collegeInstitutionDegree.institution, private_institution_notes: value } }}) }}
            />
          </CardContent>
        </Card>
      }

      <Dialog open={open} fullWidth>
        <DialogTitle>{collegeInstitutionDegree.institution.name}</DialogTitle>
        <EditCollegeInfo institution={collegeInstitutionDegree.institution} onCancel={() => setOpen(false)} onSave={handleSaveInstitution} />
      </Dialog>

      <Dialog open={datesOpen} fullWidth>
        <DialogTitle>{`${collegeInstitutionDegree.institution.name} ${collegeInstitutionDegree.degree?.name}`}</DialogTitle>
        <EditCollegeDates institutionDegree={collegeInstitutionDegree} onCancel={() => setDatesOpen(false)} onSave={handleSaveInstitutionDegree} />
      </Dialog>
    </Stack>
  )
}

export default CollegeInfo;
import { IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { Notification, Delivery } from "../../models";
import useAuth from '../../hooks/useAuth';

const NotificationMenuItem = ({ notification, onDismiss }: { notification: Notification, onDismiss: (notification: Notification) => void }) => {
  const auth = useAuth();
  
  const deliveries = notification.deliveries?.filter(d => d.user_id === auth.user.id)
  const [delivery, setDelivery] = useState<Delivery|undefined>((deliveries?.length || 0) > 0 ? deliveries![0] : undefined);

  const dismissNotification = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const token = auth.token();
    const body = {
      dismissed: true,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/notifications/${notification.id}/deliveries`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setDelivery(json);
      onDismiss(notification);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  if (delivery && delivery.dismissed) {
    return null;
  }
  
  return (
    <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
      <Stack sx={{ flexGrow: 1 }}>
        <Typography sx={{ whiteSpace: 'normal' }} variant="subtitle2">{notification.title}</Typography>
        <Typography sx={{ whiteSpace: 'normal' }} variant="body2">{notification.message}</Typography>
      </Stack>
      <IconButton sx={{ flexGrow: 0, p: 0 }} onClick={dismissNotification}>
        <ClearOutlinedIcon />
      </IconButton>
    </Stack>
  )
}

export default NotificationMenuItem;
import { Card, CardContent, Chip, Container, Dialog, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import * as React from "react";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import SecondaryButton from "../../../controls/SecondaryButton";
import { Institution } from "../../../../models";
import CollegeListItem from "./CollegeListItem";
import SearchField from "../../../controls/SearchField";
import useAuth from "../../../../hooks/useAuth";
import TertiaryButton from "../../../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import FilterInstitutions, { FilterData } from "../../../filters/FilterInstitutions";
import SortOptions from "../../../sort/SortOptions";
import SearchIcon from '@mui/icons-material/Search';
import SortInstitutions from "../../../sort/SortInstitutions";

const perPage = 20;

const AdminColleges = () => {
  const [institutions, setInstitutions] = useState<Institution[]>([]);

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);

  const auth = useAuth();

  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<FilterData>();

  const [openSort, setOpenSort] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  // reset page when search, filters, or sort options change
  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const abortController = new AbortController();

    const params: string[] = [];

    if (sortOptions) {
      params.push(`sort=${sortOptions.column}`);
      params.push(`direction=${sortOptions.direction}`);
    }

    if (filters) {
      Object.keys(filters).map((key, index) => {
        const value = filters[key];
        if (value) {
          if (key === 'college') {
            params.push(`${key}=${value?.id || ''}`);
          } else if (key === 'degree') {
            params.push(`${key}=${value?.id || ''}`);
          } else if (value === 'Any') {
            // do nothing
          } else {
            params.push(`${key}=${value}`);
          }
        }
      })
    }

    const filterParams = params.join('&');

    const fetchInstitutions = async () => {
      setLoading(true);
      const token = auth.token();

      const url = filterParams ?
        `${window.SERVER_DATA.domain}/api/v1/institutions?q=${searchQuery}&${filterParams}&page=${page}&per_page=${perPage}` :
        `${window.SERVER_DATA.domain}/api/v1/institutions?q=${searchQuery}&page=${page}&per_page=${perPage}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      setLoading(false);
      if (response.ok) {
        const json: Institution[] = await response.json();
        console.log(json);
        if (page === 1) {
          setInstitutions(json);
        } else {
          setInstitutions(prev => {
            const filteredPrev = prev.filter(a => !json.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...json];
          });
        }
        if (json.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchInstitutions();

    return () => {
      abortController.abort();
    };
  }, [filters, sortOptions, searchQuery, page]);

  const handleSaveFilters = (filters: FilterData) => {
    setPage(1);
    setFilters(filters);
    setOpenFilters(false);
  }

  const handleSaveSort = (options: SortOptions) => {
    setPage(1);
    setSortOptions(options);
    setOpenSort(false);
  }

  const filteredInstitutions = institutions.filter((institution) => {
    let include = true;

    if (filters?.unified_auditions && filters?.unified_auditions !== 'Any' && institution.institution_degrees.filter(id => {
      console.log('comparing', id.unified_auditions, filters.unified_auditions)
      return id.unified_auditions === filters.unified_auditions;
    }).length === 0) include = false;
    
    return include;
  })

  const sortedInstitutions = [...filteredInstitutions];
  
  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          component={Link}
          to={`/institutions/new`}
        >
          Add a College
        </SecondaryButton>

        <Card>
          <CardContent>
          <Stack spacing={1}>
              <Stack spacing={2} direction="row">
                <Stack direction="row" spacing={2} sx={{flexGrow: 1}}>
                  <Typography variant="h6">Colleges</Typography>
                  <Chip label={sortedInstitutions.length} size="small" color="primary" />
                </Stack>
                
                <TertiaryButton
                  variant="text"
                  startIcon={<FilterAltOutlinedIcon />}
                  onClick={() => setOpenFilters(true)}
                >
                  Filter Colleges
                </TertiaryButton>
                <TertiaryButton
                  variant="text"
                  startIcon={<ImportExportOutlinedIcon />}
                  onClick={() => setOpenSort(true)}
                >
                  Sort Colleges
                </TertiaryButton>
                <TertiaryButton
                  variant="text"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    if (openSearch) {
                      setOpenSearch(false);
                      setSearchQuery('');
                    } else {
                      setOpenSearch(true);
                    }
                  }}
                >
                  Search Colleges
                </TertiaryButton>
              </Stack>
              {openSearch &&
                <SearchField label="Search Colleges" onSearch={setSearchQuery} realTime={true} />
              }
            </Stack>
          </CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '50%' }}>College</TableCell>
                  <TableCell>Applications</TableCell>
                  {filters?.unified_auditions &&
                    <TableCell>Unified Auditions</TableCell>
                  }
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {institutions.map(institution => {
                  return (
                    <CollegeListItem 
                      key={institution.id} 
                      institution={institution} 
                      filters={filters}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        {!reachedEnd &&
          <SecondaryButton variant="outlined" onClick={loadNextPage}>
            {isLoading ? 'Loading...' : 'Load More' }
          </SecondaryButton>
        }
      </Stack>

      <Dialog open={openFilters} fullWidth>
        <FilterInstitutions title="Filter Colleges" filters={filters} onCancel={() => setOpenFilters(false)} onSave={handleSaveFilters} />
      </Dialog>
      <Dialog open={openSort} fullWidth>
        <SortInstitutions title="Sort Colleges" options={sortOptions} onCancel={() => setOpenSort(false)} onSave={handleSaveSort} />
      </Dialog>
    </Container>
  )
}

export default AdminColleges;
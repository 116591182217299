import { Button, Dialog, DialogActions, DialogContent, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { DocumentFile, SharedDocument, User } from "../../models";
import { sharedDocumentRecipientName } from "../../models/SharedDocument";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import AdminsDropdown from "../filters/AdminsDropdown";
import StudentsDropdown from "../filters/StudentsDropdown";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import FindStudentsDialog from "../filters/FindStudentsDialog";
import useAuth from "../../hooks/useAuth";

const ShareDocument = ({document, onCancel, onShare, isAdmin}: { document: DocumentFile, onCancel: () => void, onShare: (documents: DocumentFile[]) => void, isAdmin: boolean }) => {  
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [sharedDocuments, setSharedDocuments] = useState<SharedDocument[]>([]);
  const [findStudentsOpen, setFindStudentsOpen] = useState(false);
  const auth = useAuth();
  
  useEffect(() => {
    const fetchSharedDocuments = async () => {
      const token = auth.token();
      const url = `${window.SERVER_DATA.domain}/api/v1/documents/${document.id}/shared_documents`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        setSharedDocuments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchSharedDocuments();
  }, [])

  const deleteSharedDocument = async (id: number) => {
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/shared_documents/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setSharedDocuments(sharedDocuments.filter(sd => sd.id !== id));
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleShare = async () => {
    const token = auth.token();

    const userIds = selectedUsers.map(u => u.id || 0).filter(id => id > 0);
    if (userIds.length === 0) { return }

    const body = {
      user_ids: userIds,
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/${document.id}/share`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onShare(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleAddStudents = (students: User[]) => {
    setSelectedUsers(students);
    setFindStudentsOpen(false);
  }

  return (
    <>
      <DialogContent>
        {isAdmin &&
          <Stack spacing={2}>
            <StudentsDropdown value={selectedUsers} onChange={setSelectedUsers} />
            <Button variant="outlined" onClick={() => setFindStudentsOpen(true)}>Find Students</Button>
          </Stack>
        }
        {!isAdmin &&
          <AdminsDropdown value={selectedUsers} onChange={setSelectedUsers} includeSelf={true} />
        }

        <Stack spacing={2} sx={{mt: 2}}>
          <Typography variant="subtitle2">People with access</Typography>
          {sharedDocuments.map(sd => (
            <Stack key={sd.id} direction="row" alignItems="center">
              <Typography variant="body2" sx={{flexGrow: 1}}>{sharedDocumentRecipientName(sd)}</Typography>
              <IconButton onClick={() => deleteSharedDocument(sd.id!)}>
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleShare}>Share</PrimaryButton>
      </DialogActions>
      <Dialog open={findStudentsOpen} fullWidth>
        <FindStudentsDialog students={selectedUsers} onCancel={() => setFindStudentsOpen(false)} onAdd={handleAddStudents} />
      </Dialog>
    </>
  )
};

export default ShareDocument;

import * as React from "react";
import { Assessment, Task } from "../../../../models";
import TaskForm from "../../../tasks/TaskForm";
import useAuth from "../../../../hooks/useAuth";

const AssessmentAddTask = ({ assessment, onCancel, onSave }: { assessment: Assessment, onCancel: () => void, onSave: (tasks: Task[]) => void }) => {
  const auth = useAuth();
  
  const createTask = async (task: Task) => {
    const token = auth.token();
    // const body = {
    //   title: task.title,
    //   notes: task.notes,
    //   type_type: task.task_type,
    //   due_on: task.due_on,
    //   user_ids: [user.id],
    // }
    const formData = new FormData();
    if (task.file) formData.append('attachment', task.file);
    formData.append('title', task.title);
    formData.append('notes', task.notes);
    formData.append('task_type', task.task_type);
    formData.append('due_on', task.due_on || '');
    formData.append('assessment_id', assessment?.id?.toString() || '');
    formData.append('user_ids', [assessment.user_id].toString());
    formData.append('include_collaborators', task.include_collaborators ? 'true' : 'false');
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/batch`, {
      method: 'POST',
      headers: new Headers({
        // 'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      // body: JSON.stringify(body),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <TaskForm 
      onCancel={handleCancel} 
      onSave={createTask} 
      showUsers={false} 
      institutionDegrees={[assessment.institution_degree]} 
      selectedInstitutionDegree={assessment.institution_degree}
    />
  )
}

export default AssessmentAddTask;
import { Box, Card, Checkbox, Dialog, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Task, TaskTemplate, User } from "../../../models";
import SortOptions from "../../sort/SortOptions";
import { FilterData } from "../../filters/FilterStudents";
import StudentListItem from "./StudentListItem/StudentListItem";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import StudentLoaderItem from "./StudentLoaderItem";
import SecondaryButton from "../../controls/SecondaryButton";
import AdminAddTask from "../tasks/AdminAddTask";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const perPage = 20;

const StudentsList = ({ archived, sortOptions, filters, isExpanded, query = '' }: { archived: boolean, sortOptions: SortOptions|undefined, filters: FilterData|undefined, isExpanded: boolean, query?: string }) => {

  const [students, setStudents] = useState<User[]>([]);
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);

  const [isLoading, setLoading] = useState(false);

  const [reachedEnd, setReachedEnd] = useState(false);
  const [page, setPage] = useState(1);

  const navigate = useNavigate();
  const auth = useAuth();

  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  // reset page when search, filters, or sort options change
  useEffect(() => {
    setPage(1);
  }, [filters, sortOptions, query]);

  useEffect(() => {
    const abortController = new AbortController();

    const params: string[] = [];

    if (sortOptions) {
      params.push(`sort=${sortOptions.column}`);
      params.push(`direction=${sortOptions.direction}`);
    }

    if (filters) {
      Object.keys(filters).map((key, index) => {
        const value = filters[key];
        if (value) {
          if (key === 'task_templates') {
            const taskTemplatesValue: string[] = [];
            value.map(taskTemplate => {
              taskTemplatesValue.push(`${taskTemplate.id}:${taskTemplate.value}`);
            });
            params.push(`${key}=${taskTemplatesValue.join(',')}`);
          } else if (key === 'college') {
            params.push(`${key}=${value?.id || ''}`);
          } else if (key === 'degree') {
            params.push(`${key}=${value?.id || ''}`);
          } else if (value === 'Any') {
            // do nothing
          } else {
            params.push(`${key}=${value}`);
          }
        }
      })
    }

    const filterParams = params.join('&');

    const fetchStudents = async () => {
      setLoading(true);

      const token = auth.token();

      const url = filterParams ?
        `${window.SERVER_DATA.domain}/api/v1/students?q=${query}&${filterParams}&page=${page}&per_page=${perPage}&archived=${archived ? 1 : 0}` :
        `${window.SERVER_DATA.domain}/api/v1/students?q=${query}&page=${page}&per_page=${perPage}&archived=${archived ? 1 : 0}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      setLoading(false);
      if (response.ok) {
        const json: User[] = await response.json();
        console.log(json);
        if (page === 1) {
          setStudents(json);
        } else {
          setStudents(prev => {
            const filteredPrev = prev.filter(a => !json.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...json];
          });
        }
        if (json.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchStudents();

    const fetchTaskTemplates = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      if (response.ok) {
        const json: TaskTemplate[] = await response.json();
        console.log(json);
        setTaskTemplates(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchTaskTemplates();

    return () => {
      abortController.abort();
    };
  }, [filters, sortOptions, page, query]);

  const headerTasks: TaskTemplate[] = [];
  filters?.task_templates?.map(filter => {
    const taskTemplate = taskTemplates.find(t => t.id === filter.id)
    if (taskTemplate) headerTasks.push(taskTemplate);
  })
  const sortTaskId = sortOptions?.column.includes('task_templates.') ? sortOptions?.column.split('.')[1] : null;
  if (sortTaskId) {
    const sortTask = taskTemplates.find(t => t.id === parseInt(sortTaskId));
    if (sortTask) {
      if (headerTasks.indexOf(sortTask) === -1) {
        headerTasks.push(sortTask);
      }
    }
  }

  const [openTask, setOpenTask] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState<User[]>([]);

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedStudents([...students])
      return;
    }
    setSelectedStudents([]);
  };

  const handleStudentCheckboxClick = (user: User) => {
    const existingUser = selectedStudents.find(s => s.id === user.id);
    if (existingUser) {
      setSelectedStudents(prev => prev.filter(s => s.id !== user.id));
    } else {
      setSelectedStudents(prev => [...prev, user]);
    }
  }

  const onNewTask = () => {
    setOpenTask(true);
  }

  const handleSaveTask = (newTasks: Task[]) => {
    setOpenTask(false);
    navigate('/tasks');
  }

  const numSelected = selectedStudents.length;
  const rowCount = students.length;

  console.log('students', students)

  return (
    <Box>
      <Card>
        <EnhancedTableToolbar numSelected={numSelected} onNewTask={onNewTask} />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={onSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                  />
                </TableCell>
                <TableCell sx={{ width: '40%' }}>Student</TableCell>
                <TableCell>College</TableCell>
                {filters?.prescreens &&
                  <TableCell>Prescreens</TableCell>
                }
                {filters?.auditions_portfolio_required &&
                  <TableCell>Auditions/Portfolio Required</TableCell>
                }
                {filters?.early_decision &&
                  <TableCell>Early Decision</TableCell>
                }
                {filters?.early_action &&
                  <TableCell>Early Action</TableCell>
                }
                {filters?.score_optional_enabled &&
                  <TableCell>Score Optional</TableCell>
                }
                {filters?.common_app &&
                  <TableCell>Common App</TableCell>
                }
                {filters?.graduation_year &&
                  <TableCell>Year of Intended Enrollment</TableCell>
                }
                {filters?.test_optional &&
                  <TableCell>Test Optional</TableCell>
                }
                {filters?.unified_auditions &&
                  <TableCell>Unified Auditions</TableCell>
                }
                {filters?.intended_college_major &&
                  <TableCell>Intended College Major</TableCell>
                }
                {headerTasks.map(taskTemplate =>
                  <TableCell key={taskTemplate.id}>{taskTemplates.find(t => t.id === taskTemplate.id)?.title}</TableCell>
                )}
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {students.map(student => {
                  const isSelected = selectedStudents.find(s => s.id === student.id) !== undefined;
                  return (
                    <StudentListItem 
                      key={student.id} 
                      student={student} 
                      graduationYear={filters?.graduation_year} 
                      columns={headerTasks} 
                      to={`/students/${student.id}`} 
                      isItemSelected={isSelected} 
                      onCheckboxClick={handleStudentCheckboxClick}
                      filters={filters}
                      expanded={isExpanded}
                    />
                  )
                })}
                {isLoading &&
                  <>
                    <StudentLoaderItem/>
                    <StudentLoaderItem/>
                    <StudentLoaderItem/>
                  </>
                }
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      {!reachedEnd && !isLoading &&
        <SecondaryButton variant="outlined" onClick={loadNextPage}>Load More</SecondaryButton>
      }

      <Dialog open={openTask} fullWidth>
        <AdminAddTask onCancel={() => setOpenTask(false)} onSave={handleSaveTask} showUsers={true} initialUsers={selectedStudents} />
      </Dialog>
    </Box>
  )
}

export default StudentsList;
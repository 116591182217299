import { Container, Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Assessment, Degree } from "../../../../models";
import AssessmentCard from "../../../assessments/AssessmentCard";
import useAuth from "../../../../hooks/useAuth";

const DegreeInactiveAssessments = ({ degree }: { degree: Degree }) => {
  const [assessments, setAssessments] = useState<Assessment[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    const fetchAssessments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees/${degree.id}/inactive_assessments`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setAssessments(json);
    }

    fetchAssessments();
  }, []);

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        {assessments.map(assessment => (
          <AssessmentCard assessment={assessment} key={assessment.id} onChange={() => {}} />
        ))}
      </Stack>
    </Container>
  )
}

export default DegreeInactiveAssessments;
import { Box, Divider, ListItem, ListItemIcon, ListItemText, Skeleton, Stack } from "@mui/material";
import * as React from "react";

const AnnouncementLoaderItem = () => {  
  return (
    <Box>
      <ListItem>
        <ListItemIcon sx={{ flexShrink: 1, minWidth: 38 }}>
          <Skeleton variant="circular" width={20} height={20} />
        </ListItemIcon>
        <ListItemText>
          <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
            <Stack sx={{ flexGrow: 1 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Stack>
          </Stack>
        </ListItemText>
      </ListItem>
      <Divider />
    </Box>
  )
}

export default AnnouncementLoaderItem;
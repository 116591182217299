import { Avatar, Box, Container, FormControl, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Institution } from "../../../../models";
import PaperDropzone from "../../../controls/PaperDropzone/PaperDropzone";
import PrimaryButton from "../../../controls/PrimaryButton";
import SecondaryButton from "../../../controls/SecondaryButton";
import { statesOptions, countriesOptions } from "../../../../models/Institution";
import RichTextEditor from "../../../controls/RichTextEditor";
import { MuiTelInput } from "mui-tel-input";

const InstitutionForm = ({ institution, onCancel, onSave }: { institution?: Institution, onCancel: () => void, onSave: (institution: Institution) => void }) => {
  const [file, setFile] = useState<File>();
  const [name, setName] = useState(institution?.name || '');
  const [city, setCity] = useState(institution?.city || '');
  const [state, setState] = useState(institution?.state || '');
  const [country, setCountry] = useState(institution?.country || '');
  const [centralOfficePhone, setCentralOfficePhone] = useState(institution?.central_office_phone || '');
  const [centralOfficeEmail, setCentralOfficeEmail] = useState(institution?.central_office_email || '');
  const [privateInstitutionNotes, setPrivateInstitutionNotes] = useState(institution?.private_institution_notes || '');
  const [applicationType, setApplicationType] = useState(institution?.application_type || '');
  const [applicationUrl, setApplicationUrl] = useState(institution?.application_url || '');
  const [commonApp, setCommonApp] = useState(institution?.common_app || '');
  const [coalitionApplicationInstitution, setCoalitionApplicationInstitution] = useState(institution?.coalition_application_institution || '');
  const [coalitionApplicationInstitutionUrl, setCoalitionApplicationInstitutionUrl] = useState(institution?.coalition_application_institution_url || '');
  const [other, setOther] = useState(institution?.other || '');
  const [campusVisitInformation, setCampusVisitInformation] = useState(institution?.campus_visit_information || '');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const handleSave = () => {
    const institution: Institution = {
      name: name,
      city: city,
      state: state,
      country: country,
      central_office_phone: centralOfficePhone,
      central_office_email: centralOfficeEmail,
      private_institution_notes: privateInstitutionNotes,
      application_type: applicationType,
      application_url: applicationUrl,
      common_app: commonApp,
      coalition_application_institution: coalitionApplicationInstitution,
      coalition_application_institution_url: coalitionApplicationInstitutionUrl,
      other: other,
      campus_visit_information: campusVisitInformation,
      file,
    }
    onSave(institution);
  }

  const onFileChange = (file: File) => {
    setFile(file);
  }

  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>
          
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Avatar src={institution?.institution_avatar?.url} alt={institution?.name} />
            <PaperDropzone onChange={onFileChange} sx={{ flexGrow: 1 }} />
          </Stack>

          <TextField 
            label="Name" 
            variant="outlined" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            required />

          <TextField 
            label="City" 
            variant="outlined" 
            value={city}
            onChange={(e) => setCity(e.target.value)} />

          <FormControl>
            <InputLabel id="state">State</InputLabel>
            <Select
              labelId="state"
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              {statesOptions.map(([label, value]) => (
                <MenuItem 
                  key={value || label} 
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {countriesOptions.map(([label, value]) => (
                <MenuItem 
                  key={value || label} 
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <MuiTelInput 
            label="Main Admissions Office Phone" 
            size="small" 
            forceCallingCode={true} 
            defaultCountry="US" 
            // disableDropdown 
            value={centralOfficePhone} 
            onChange={setCentralOfficePhone} />

          <TextField 
            label="Main Admissions Office Email" 
            variant="outlined" 
            value={centralOfficeEmail}
            onChange={(e) => setCentralOfficeEmail(e.target.value)} />

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Private College Notes</FormLabel>
            <RichTextEditor value={privateInstitutionNotes} onChange={setPrivateInstitutionNotes} />
          </FormControl>

          <FormControl>
            <InputLabel id="applicationType">Application Type</InputLabel>
            <Select
              labelId="applicationType"
              label="Application Type"
              value={applicationType}
              onChange={(e) => setApplicationType(e.target.value)}
            >
              <MenuItem value="Common App">Common App</MenuItem>
              <MenuItem value="Coalition App">Coalition App</MenuItem>
              <MenuItem value="College-specific App">College-specific App</MenuItem>
            </Select>
          </FormControl>

          {applicationType === 'College-specific App' &&
            <TextField 
              label="Application URL" 
              variant="outlined" 
              value={applicationUrl}
              onChange={(e) => setApplicationUrl(e.target.value)} />
          }
          
          <FormControl>
            <InputLabel id="commonApp">Common Application Institution</InputLabel>
            <Select
              labelId="commonApp"
              label="Common Application Institution"
              value={commonApp}
              onChange={(e) => setCommonApp(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="coalitionApplicationInstitution">Coalition Application Institution</InputLabel>
            <Select
              labelId="coalitionApplicationInstitution"
              label="Coalition Application Institution"
              value={coalitionApplicationInstitution}
              onChange={(e) => setCoalitionApplicationInstitution(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {coalitionApplicationInstitution === 'Yes' &&
            <TextField 
              label="Coalition Application Institution Website" 
              variant="outlined" 
              value={coalitionApplicationInstitutionUrl}
              onChange={(e) => setCoalitionApplicationInstitutionUrl(e.target.value)} />
          }

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Comments About The College</FormLabel>
            <RichTextEditor value={other} onChange={setOther} />
          </FormControl>

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Campus Visit Information</FormLabel>
            <RichTextEditor value={campusVisitInformation} onChange={setCampusVisitInformation} />
          </FormControl>

        </Stack>
      </Container>
      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default InstitutionForm;
import { Card, CardContent, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import * as React from "react";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Link } from "react-router-dom";
import SecondaryButton from "../../../controls/SecondaryButton";
import { Assessment, User } from "../../../../models";
import AssessmentListItem from "../../../assessments/AssessmentListItem";
import TertiaryButton from "../../../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import { useState } from "react";
import SortOptions from "../../../sort/SortOptions";
import FilterAssessments, { FilterData } from "../../../filters/FilterAssessments";
import SortAssessments from "../../../sort/SortAssessments";
import SearchIcon from '@mui/icons-material/Search';
import SearchField from "../../../controls/SearchField";
import AssessmentTableToolbar from "./AssessmentTableToolbar";
import useAuth from "../../../../hooks/useAuth";
import PrimaryButton from "../../../controls/PrimaryButton";
import CollectionDropdown from "../../../filters/CollectionDropdown";
import CollegeCollection from "../../../../models/CollegeCollection";

const StudentColleges = ({ student, isAdmin, onChange }: { student: User, isAdmin: boolean, onChange: (student: User) => void }) => {

  const studentAssessments = student.assessments?.filter(a => a.institution_degree) || [];
  const [assessments, setAssessments] = useState(studentAssessments);
  
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<FilterData>();

  const [openSort, setOpenSort] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>();

  const [openInactiveFilters, setOpenInactiveFilters] = useState(false);
  const [inactiveFilters, setInactiveFilters] = useState<FilterData>();

  const [openInactiveSort, setOpenInactiveSort] = useState(false);
  const [inactiveSortOptions, setInactiveSortOptions] = useState<SortOptions>();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [openInactiveSearch, setOpenInactiveSearch] = useState(false);
  const [inactiveSearchQuery, setInactiveSearchQuery] = useState('');

  const [selectedAssessments, setSelectedAssessments] = useState<Assessment[]>([]);
  const [selectedInactiveAssessments, setSelectedInactiveAssessments] = useState<Assessment[]>([]);

  const [collectionsOpen, setCollectionsOpen] = useState(false);
  const [collection, setCollection] = useState<CollegeCollection|null>(null);

  const auth = useAuth();

  const handleSaveFilters = (filters: FilterData) => {
    setFilters(filters);
    setOpenFilters(false);
  }

  const handleSaveSort = (options: SortOptions) => {
    setSortOptions(options);
    setOpenSort(false);
  }

  const handleSaveInactiveFilters = (filters: FilterData) => {
    // setPage(1);
    setInactiveFilters(filters);
    setOpenInactiveFilters(false);
  }

  const handleSaveInactiveSort = (options: SortOptions) => {
    // setPage(1);
    setInactiveSortOptions(options);
    setOpenInactiveSort(false);
  }

  const activeAssessments = assessments.filter(a => !a.inactive)
  const inactiveAssessments = assessments.filter(a => a.inactive)

  const queriedAssessments = activeAssessments.filter(a => {
    if (!searchQuery) return true;
    if (a.institution_degree?.institution?.name?.toLowerCase().includes(searchQuery.toLowerCase())) return true;
    if (a.institution_degree?.degree?.name?.toLowerCase().includes(searchQuery.toLowerCase())) return true;
    return false;
  });

  const filteredAssessments = queriedAssessments.filter(a => {
    let include = true;

    if (filters?.prescreens && filters?.prescreens !== 'Any' && a.institution_degree?.pre_screening !== filters.prescreens) include = false;
    if (filters?.auditions_portfolio_required && filters?.auditions_portfolio_required !== 'Any' && a.institution_degree?.required_audition_portfolio !== filters.auditions_portfolio_required) include = false;
    if (filters?.early_decision && filters?.early_decision !== 'Any' && a.institution_degree?.early_decision !== filters.early_decision) include = false;
    if (filters?.early_action && filters?.early_action !== 'Any' && a.institution_degree?.early_action !== filters.early_action) include = false;
    if (filters?.score_optional_enabled && filters?.score_optional_enabled !== 'Any' && a.institution_degree?.score_optional_enabled !== filters.score_optional_enabled) include = false;
    if (filters?.common_app && filters?.common_app !== 'Any' && a.institution_degree?.institution?.common_app !== filters.common_app) include = false;
    if (filters?.audition_date_start && filters?.audition_date_start !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates < filters.audition_date_start) include = false;
    if (filters?.audition_date_end && filters?.audition_date_end !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates > filters.audition_date_end) include = false;
    if (filters?.graduation_year && filters?.graduation_year !== 'Any' && a.user?.high_school_graduation_year?.toString() !== filters.graduation_year.toString()) include = false;
    if (filters?.college && a.institution_degree?.institution_id !== filters.college.id) include = false;
    if (filters?.degree && a.institution_degree?.degree_id !== filters.degree.id) include = false;
    if (filters?.applying && filters?.applying !== 'Any' && a.applying !== filters.applying) include = false;
    if (filters?.test_optional && filters?.test_optional !== 'Any' && !(filters?.test_optional === 'Please Select' && !a.test_optional) && a.test_optional !== filters.test_optional) include = false;
    if (filters?.academic_confidence && filters?.academic_confidence !== 'Any' && a.academic_confidence !== filters.academic_confidence) include = false;
    if (filters?.artistic_confidence && filters?.artistic_confidence !== 'Any' && a.artistic_confidence !== filters.artistic_confidence) include = false;
    if (filters?.unified_auditions && filters?.unified_auditions !== 'Any' && a.institution_degree?.unified_auditions !== filters.unified_auditions) include = false;
    if (
      filters?.application_status && filters?.application_status !== 'Any' && 
      (
        (filters?.application_status === 'Not Selected' && a.application_status) || 
        (filters?.application_status !== 'Not Selected' && a.application_status !== filters?.application_status)
      )
    ) include = false;
    if (
      filters?.supplemental_application_status && filters?.supplemental_application_status !== 'Any' && 
      (
        a.institution_degree?.supplemental_application_required !== 'Yes' ||
        !a.institution_degree?.supplemental_application_url ||
        (
          (filters?.supplemental_application_status === 'Not Selected' && a.supplemental_application_status) || 
          (filters?.supplemental_application_status !== 'Not Selected' && a.supplemental_application_status !== filters?.supplemental_application_status)
        )
      )
    ) include = false;
    if (
      filters?.admission_decision && filters?.admission_decision !== 'Any' &&
      (
        a.institution_degree?.pre_screening !== 'Yes' ||
        (
          (filters?.admission_decision === 'Not Selected' && a.admission_decision) || 
          (filters?.admission_decision !== 'Not Selected' && a.admission_decision !== filters?.admission_decision)
        )
      )
    ) include = false;

    return include;
  });

  const sortedAssessments = filteredAssessments.sort((a, b) => {
    let sort = 0;
    if (sortOptions?.column === 'lower(institutions.name)') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.institution?.name || '').localeCompare(a.institution_degree?.institution?.name || '');
      } else {
        sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
      }
    } else if (sortOptions?.column === 'lower(degrees.name)') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.degree?.name || '').localeCompare(a.institution_degree?.degree?.name || '');
      } else {
        sort = sort || (a.institution_degree?.degree?.name || '').localeCompare(b.institution_degree?.degree?.name || '');
      }
    } else if (sortOptions?.column === 'calc_application_deadline') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.calc_application_deadline || '').localeCompare(a.calc_application_deadline || '');
      } else {
        sort = sort || (a.calc_application_deadline || '').localeCompare(b.calc_application_deadline || '');
      }
    } else if (sortOptions?.column === 'calc_prescreen_deadline') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.calc_prescreen_deadline || '').localeCompare(a.calc_prescreen_deadline || '');
      } else {
        sort = sort || (a.calc_prescreen_deadline || '').localeCompare(b.calc_prescreen_deadline || '');
      }
    } else if (sortOptions?.column === 'artistic_confidence') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.artistic_confidence || '').localeCompare(a.artistic_confidence || '');
      } else {
        sort = sort || (a.artistic_confidence || '').localeCompare(b.artistic_confidence || '');
      }
    } else if (sortOptions?.column === 'academic_confidence') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.academic_confidence || '').localeCompare(a.academic_confidence || '');
      } else {
        sort = sort || (a.academic_confidence || '').localeCompare(b.academic_confidence || '');
      }
    } else {
      // default to institution name
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.institution?.name || '').localeCompare(a.institution_degree?.institution?.name || '');
      } else {
        sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
      }
    }
    return sort;
  });

  const queriedInactiveAssessments = inactiveAssessments.filter(a => {
    if (!inactiveSearchQuery) return true;
    if (a.institution_degree?.institution?.name?.toLowerCase().includes(inactiveSearchQuery.toLowerCase())) return true;
    if (a.institution_degree?.degree?.name?.toLowerCase().includes(inactiveSearchQuery.toLowerCase())) return true;
    return false;
  });

  const filteredInactiveAssessments = queriedInactiveAssessments.filter(a => {
    let include = true;

    if (inactiveFilters?.prescreens && inactiveFilters?.prescreens !== 'Any' && a.institution_degree?.pre_screening !== inactiveFilters.prescreens) include = false;
    if (inactiveFilters?.auditions_portfolio_required && inactiveFilters?.auditions_portfolio_required !== 'Any' && a.institution_degree?.required_audition_portfolio !== inactiveFilters.auditions_portfolio_required) include = false;
    if (inactiveFilters?.early_decision && inactiveFilters?.early_decision !== 'Any' && a.institution_degree?.early_decision !== inactiveFilters.early_decision) include = false;
    if (inactiveFilters?.early_action && inactiveFilters?.early_action !== 'Any' && a.institution_degree?.early_action !== inactiveFilters.early_action) include = false;
    if (inactiveFilters?.score_optional_enabled && inactiveFilters?.score_optional_enabled !== 'Any' && a.institution_degree?.score_optional_enabled !== inactiveFilters.score_optional_enabled) include = false;
    if (inactiveFilters?.common_app && inactiveFilters?.common_app !== 'Any' && a.institution_degree?.institution?.common_app !== inactiveFilters.common_app) include = false;
    if (inactiveFilters?.audition_date_start && inactiveFilters?.audition_date_start !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates < inactiveFilters.audition_date_start) include = false;
    if (inactiveFilters?.audition_date_end && inactiveFilters?.audition_date_end !== 'Any' && a.institution_degree?.audition_dates && a.institution_degree?.audition_dates > inactiveFilters.audition_date_end) include = false;
    if (inactiveFilters?.graduation_year && inactiveFilters?.graduation_year !== 'Any' && a.user?.high_school_graduation_year?.toString() !== inactiveFilters.graduation_year.toString()) include = false;
    if (inactiveFilters?.college && a.institution_degree?.institution_id !== inactiveFilters.college.id) include = false;
    if (inactiveFilters?.degree && a.institution_degree?.degree_id !== inactiveFilters.degree.id) include = false;
    if (inactiveFilters?.applying && inactiveFilters?.applying !== 'Any' && a.applying !== inactiveFilters.applying) include = false;
    if (inactiveFilters?.test_optional && inactiveFilters?.test_optional !== 'Any' && a.test_optional !== inactiveFilters.test_optional) include = false;
    if (inactiveFilters?.academic_confidence && inactiveFilters?.academic_confidence !== 'Any' && a.academic_confidence !== inactiveFilters.academic_confidence) include = false;
    if (inactiveFilters?.artistic_confidence && inactiveFilters?.artistic_confidence !== 'Any' && a.artistic_confidence !== inactiveFilters.artistic_confidence) include = false;
    if (inactiveFilters?.unified_auditions && inactiveFilters?.unified_auditions !== 'Any' && a.institution_degree?.unified_auditions !== inactiveFilters.unified_auditions) include = false;
    if (
      inactiveFilters?.application_status && inactiveFilters?.application_status !== 'Any' && 
      (
        (inactiveFilters?.application_status === 'Not Selected' && a.application_status) || 
        (inactiveFilters?.application_status !== 'Not Selected' && a.application_status !== inactiveFilters?.application_status)
      )
    ) include = false;
    if (
      inactiveFilters?.supplemental_application_status && inactiveFilters?.supplemental_application_status !== 'Any' && 
      (
        a.institution_degree?.supplemental_application_required !== 'Yes' ||
        !a.institution_degree?.supplemental_application_url ||
        (
          (inactiveFilters?.supplemental_application_status === 'Not Selected' && a.supplemental_application_status) || 
          (inactiveFilters?.supplemental_application_status !== 'Not Selected' && a.supplemental_application_status !== inactiveFilters?.supplemental_application_status)
        )
      )
    ) include = false;
    if (
      inactiveFilters?.admission_decision && inactiveFilters?.admission_decision !== 'Any' &&
      (
        a.institution_degree?.pre_screening !== 'Yes' ||
        (
          (inactiveFilters?.admission_decision === 'Not Selected' && a.admission_decision) || 
          (inactiveFilters?.admission_decision !== 'Not Selected' && a.admission_decision !== inactiveFilters?.admission_decision)
        )
      )
    ) include = false;

    return include;
  });

  const sortedInactiveAssessments = filteredInactiveAssessments.sort((a, b) => {
    let sort = 0;
    if (inactiveSortOptions?.column === 'lower(institutions.name)') {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.institution?.name || '').localeCompare(a.institution_degree?.institution?.name || '');
      } else {
        sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
      }
    } else if (inactiveSortOptions?.column === 'lower(degrees.name)') {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.degree?.name || '').localeCompare(a.institution_degree?.degree?.name || '');
      } else {
        sort = sort || (a.institution_degree?.degree?.name || '').localeCompare(b.institution_degree?.degree?.name || '');
      }
    } else if (inactiveSortOptions?.column === 'calc_application_deadline') {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.calc_application_deadline || '').localeCompare(a.calc_application_deadline || '');
      } else {
        sort = sort || (a.calc_application_deadline || '').localeCompare(b.calc_application_deadline || '');
      }
    } else if (inactiveSortOptions?.column === 'calc_prescreen_deadline') {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.calc_prescreen_deadline || '').localeCompare(a.calc_prescreen_deadline || '');
      } else {
        sort = sort || (a.calc_prescreen_deadline || '').localeCompare(b.calc_prescreen_deadline || '');
      }
    } else if (inactiveSortOptions?.column === 'artistic_confidence') {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.artistic_confidence || '').localeCompare(a.artistic_confidence || '');
      } else {
        sort = sort || (a.artistic_confidence || '').localeCompare(b.artistic_confidence || '');
      }
    } else if (inactiveSortOptions?.column === 'academic_confidence') {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.academic_confidence || '').localeCompare(a.academic_confidence || '');
      } else {
        sort = sort || (a.academic_confidence || '').localeCompare(b.academic_confidence || '');
      }
    } else {
      if (inactiveSortOptions?.direction === 'desc') {
        sort = sort || (b.institution_degree?.institution?.name || '').localeCompare(a.institution_degree?.institution?.name || '');
      } else {
        sort = sort || (a.institution_degree?.institution?.name || '').localeCompare(b.institution_degree?.institution?.name || '');
      }
    }
    return sort;
  });

  const handleAssessmentChange = (assessment: Assessment) => {
    setAssessments(prev => prev.map(a => a.id === assessment.id ? assessment : a));
  }

  const onDeactivate = async () => {
    // deactivate selected assessments
    const token = auth.token();
    const body = {
      ids: selectedAssessments.map(a => a.id),
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments/batch_deactivate`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      setAssessments(prev => prev.map(a => selectedAssessments.find(s => s.id === a.id) ? {...a, inactive: true} : a));
      onChange({...student, assessments: assessments.map(a => selectedAssessments.find(s => s.id === a.id) ? {...a, inactive: true} : a)});
      setSelectedAssessments([])
      setSelectedInactiveAssessments([])
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const onActivate = async () => {
    // activate selected inactive assessments
    const token = auth.token();
    const body = {
      ids: selectedInactiveAssessments.map(a => a.id),
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments/batch_activate`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      setAssessments(prev => prev.map(a => selectedInactiveAssessments.find(s => s.id === a.id) ? {...a, inactive: false} : a));
      onChange({...student, assessments: assessments.map(a => selectedInactiveAssessments.find(s => s.id === a.id) ? {...a, inactive: false} : a)});
      setSelectedAssessments([])
      setSelectedInactiveAssessments([])
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedAssessments([...sortedAssessments])
      return;
    }
    setSelectedAssessments([]);
  };

  const handleAssessmentCheckboxClick = (assessment: Assessment) => {
    const existingAssessment = selectedAssessments.find(s => s.id === assessment.id);
    if (existingAssessment) {
      setSelectedAssessments(prev => prev.filter(s => s.id !== assessment.id));
    } else {
      setSelectedAssessments(prev => [...prev, assessment]);
    }
  }

  const onSelectAllInactiveClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedInactiveAssessments([...sortedInactiveAssessments])
      return;
    }
    setSelectedInactiveAssessments([]);
  };

  const handleInactiveAssessmentCheckboxClick = (assessment: Assessment) => {
    const existingAssessment = selectedInactiveAssessments.find(s => s.id === assessment.id);
    if (existingAssessment) {
      setSelectedInactiveAssessments(prev => prev.filter(s => s.id !== assessment.id));
    } else {
      setSelectedInactiveAssessments(prev => [...prev, assessment]);
    }
  }

  const handleCancelCollection = () => {
    setCollectionsOpen(false);
    setCollection(null);
  }

  const handleAddCollection = async () => {
    if (collection) {
      const token = auth.token();
      const body = {
        institution_degree_ids: collection.college_collection_members.map(a => a.institution_degree_id),
      }
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${student.id}/batch_add`, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const json = await response.json();
        setAssessments(prev => [...prev, ...json]);
        onChange({...student, assessments: [...assessments, ...json]});
        setCollection(null);
        setCollectionsOpen(false);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
  }

  const numSelected = selectedAssessments.length;
  const rowCount = sortedAssessments.length;

  const numInactiveSelected = selectedInactiveAssessments.length;
  const inactiveRowCount = sortedInactiveAssessments.length;

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <SecondaryButton 
            variant="outlined" 
            startIcon={<ControlPointOutlinedIcon/>} 
            sx={{width: 1, maxWidth: 300}}
            component={Link}
            to={`/students/${student.id}/assessments/new`}
          >
            Add a College
          </SecondaryButton>

          <SecondaryButton 
            variant="outlined" 
            startIcon={<ControlPointOutlinedIcon/>} 
            sx={{width: 1, maxWidth: 300}}
            onClick={() => setCollectionsOpen(true)}
          >
            Add Colleges from Collection
          </SecondaryButton>
        </Stack>

        <Card>
          <CardContent>
            <Stack spacing={1}>
              <Stack spacing={2} direction="row">
                <Stack direction="row" spacing={2} sx={{flexGrow: 1}}>
                  <Typography variant="h6">Active Colleges</Typography>
                  <Chip label={sortedAssessments.length} size="small" color="primary" />
                </Stack>
                
                <TertiaryButton
                  variant="text"
                  startIcon={<FilterAltOutlinedIcon />}
                  onClick={() => setOpenFilters(true)}
                >
                  Filter Colleges
                </TertiaryButton>
                <TertiaryButton
                  variant="text"
                  startIcon={<ImportExportOutlinedIcon />}
                  onClick={() => setOpenSort(true)}
                >
                  Sort Colleges
                </TertiaryButton>
                <TertiaryButton
                  variant="text"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    if (openSearch) {
                      setOpenSearch(false);
                      setSearchQuery('');
                    } else {
                      setOpenSearch(true);
                    }
                  }}
                >
                  Search Colleges
                </TertiaryButton>
              </Stack>
              {openSearch &&
                <SearchField label="Search Colleges" onSearch={setSearchQuery} realTime={true} />
              }
            </Stack>
          </CardContent>
          <AssessmentTableToolbar numSelected={numSelected} onClick={onDeactivate} action="Deactivate" />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={onSelectAllClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell>College</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Confidence</TableCell>
                  <TableCell>Application Deadline</TableCell>
                  {filters?.prescreens && filters?.prescreens !== 'Any' && <TableCell>Prescreens</TableCell>}
                  {filters?.auditions_portfolio_required && filters?.auditions_portfolio_required !== 'Any' && <TableCell>Auditions/Portfolio Required</TableCell>}
                  {filters?.early_decision && filters?.early_decision !== 'Any' && <TableCell>Early Decision</TableCell>}
                  {filters?.early_action && filters?.early_action !== 'Any' && <TableCell>Early Action</TableCell>}
                  {filters?.score_optional_enabled && filters?.score_optional_enabled !== 'Any' && <TableCell>Score Optional</TableCell>}
                  {filters?.common_app && filters?.common_app !== 'Any' && <TableCell>Common App</TableCell>}
                  {(filters?.audition_date_start || filters?.audition_date_end) && <TableCell>Audition Date</TableCell>}
                  {filters?.graduation_year && filters?.graduation_year !== 'Any' && <TableCell>Year of Intended Enrollment</TableCell>}
                  {filters?.test_optional && filters?.test_optional !== 'Any' && <TableCell>Test Optional Recommendation</TableCell>}
                  {filters?.unified_auditions && filters?.unified_auditions !== 'Any' && <TableCell>Unified Auditions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAssessments.map(assessment => {
                  const isSelected = selectedAssessments.find(s => s.id === assessment.id) !== undefined;
                  return <AssessmentListItem key={assessment.id} assessment={assessment} filters={filters} isAdmin={isAdmin} onChange={handleAssessmentChange} isSelectable={true} isItemSelected={isSelected} onCheckboxClick={handleAssessmentCheckboxClick} />
                })}
                {sortedAssessments.length === 0 &&
                  <TableRow>
                    <TableCell colSpan={10}>No colleges found</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>

        <Card>
          <CardContent>
            <Stack spacing={1}>
              <Stack spacing={2} direction="row">
                <Stack direction="row" spacing={2} sx={{flexGrow: 1}}>
                  <Typography variant="h6">Inactive Colleges</Typography>
                  <Chip label={sortedInactiveAssessments.length} size="small" color="primary" />
                </Stack>
                <TertiaryButton
                  variant="text"
                  startIcon={<FilterAltOutlinedIcon />}
                  onClick={() => setOpenInactiveFilters(true)}
                >
                  Filter Colleges
                </TertiaryButton>
                <TertiaryButton
                  variant="text"
                  startIcon={<ImportExportOutlinedIcon />}
                  onClick={() => setOpenInactiveSort(true)}
                >
                  Sort Colleges
                </TertiaryButton>
                <TertiaryButton
                  variant="text"
                  startIcon={<SearchIcon />}
                  onClick={() => {
                    if (openInactiveSearch) {
                      setOpenInactiveSearch(false);
                      setInactiveSearchQuery('');
                    } else {
                      setOpenInactiveSearch(true);
                    }
                  }}
                >
                  Search Colleges
                </TertiaryButton>
              </Stack>
              {openInactiveSearch &&
                <SearchField label="Search Colleges" onSearch={setInactiveSearchQuery} realTime={true} />
              }
            </Stack>
          </CardContent>
          <AssessmentTableToolbar numSelected={numInactiveSelected} onClick={onActivate} action="Activate" />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={numInactiveSelected > 0 && numInactiveSelected < inactiveRowCount}
                      checked={inactiveRowCount > 0 && numInactiveSelected === inactiveRowCount}
                      onChange={onSelectAllInactiveClick}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell>College</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Confidence</TableCell>
                  <TableCell>Application Deadline</TableCell>
                  {inactiveFilters?.prescreens && inactiveFilters?.prescreens !== 'Any' && <TableCell>Prescreens</TableCell>}
                  {inactiveFilters?.auditions_portfolio_required && inactiveFilters?.auditions_portfolio_required !== 'Any' && <TableCell>Auditions/Portfolio Required</TableCell>}
                  {inactiveFilters?.early_decision && inactiveFilters?.early_decision !== 'Any' && <TableCell>Early Decision</TableCell>}
                  {inactiveFilters?.early_action && inactiveFilters?.early_action !== 'Any' && <TableCell>Early Action</TableCell>}
                  {inactiveFilters?.score_optional_enabled && inactiveFilters?.score_optional_enabled !== 'Any' && <TableCell>Score Optional</TableCell>}
                  {inactiveFilters?.common_app && inactiveFilters?.common_app !== 'Any' && <TableCell>Common App</TableCell>}
                  {(inactiveFilters?.audition_date_start || inactiveFilters?.audition_date_end) && <TableCell>Audition Date</TableCell>}
                  {inactiveFilters?.graduation_year && inactiveFilters?.graduation_year !== 'Any' && <TableCell>Year of Intended Enrollment</TableCell>}
                  {inactiveFilters?.test_optional && inactiveFilters?.test_optional !== 'Any' && <TableCell>Test Optional Recommendation</TableCell>}
                  {inactiveFilters?.unified_auditions && inactiveFilters?.unified_auditions !== 'Any' && <TableCell>Unified Auditions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedInactiveAssessments.map(assessment => {
                  const isSelected = selectedInactiveAssessments.find(s => s.id === assessment.id) !== undefined;
                  return <AssessmentListItem key={assessment.id} assessment={assessment} filters={inactiveFilters} isAdmin={isAdmin} onChange={handleAssessmentChange} isSelectable={true} isItemSelected={isSelected} onCheckboxClick={handleInactiveAssessmentCheckboxClick} />
                })}
                {sortedInactiveAssessments.length === 0 &&
                  <TableRow>
                    <TableCell colSpan={10}>No colleges found</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>

      <Dialog open={openFilters} fullWidth>
        <FilterAssessments title="Filter Colleges" filters={filters} onCancel={() => setOpenFilters(false)} onSave={handleSaveFilters} />
      </Dialog>
      <Dialog open={openSort} fullWidth>
        <SortAssessments title="Sort Colleges" options={sortOptions} onCancel={() => setOpenSort(false)} onSave={handleSaveSort} />
      </Dialog>

      <Dialog open={openInactiveFilters} fullWidth>
        <FilterAssessments title="Filter Colleges" filters={inactiveFilters} onCancel={() => setOpenInactiveFilters(false)} onSave={handleSaveInactiveFilters} />
      </Dialog>
      <Dialog open={openInactiveSort} fullWidth>
        <SortAssessments title="Sort Colleges" options={inactiveSortOptions} onCancel={() => setOpenInactiveSort(false)} onSave={handleSaveInactiveSort} />
      </Dialog>

      <Dialog open={collectionsOpen} fullWidth>
        <DialogContent>
          <CollectionDropdown value={collection} onChange={setCollection} error={null} />
        </DialogContent>
        <DialogActions>
          <SecondaryButton variant="outlined" sx={{width: 1}} onClick={handleCancelCollection}>Cancel</SecondaryButton>
          <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleAddCollection}>Add Colleges</PrimaryButton>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default StudentColleges;
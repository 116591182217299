import { IconButton, ListItem, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { InstitutionDegree } from "../../../../models";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";

const AdminDegreeItem = ({ institutionDegree }: { institutionDegree: InstitutionDegree }) => {
  const [anchorElInstitutionDegree, setAnchorElInstitutionDegree] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const auth = useAuth();

  const deleteInstitutionDegree = async (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${institutionDegree.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const handleOpenInstitutionDegreeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorElInstitutionDegree(event.currentTarget);
    return false;
  };

  const handleCloseInstitutionDegreeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorElInstitutionDegree(null);
  };

  if (deleted) {
    return null;
  }

  return (
    <ListItem 
      secondaryAction={
        <IconButton 
          edge="end" 
          onClick={handleOpenInstitutionDegreeMenu}
        >
          <MoreVertIcon />
        </IconButton>
      }
      button
      component={Link}
      to={`/institution_degrees/${institutionDegree.id}`}
    >
      <ListItemText 
        primary={institutionDegree.degree?.name}
       />
      <Menu
        anchorEl={anchorElInstitutionDegree}
        keepMounted
        open={Boolean(anchorElInstitutionDegree)}
        onClose={handleCloseInstitutionDegreeMenu}
      >
        <MenuItem onClick={deleteInstitutionDegree}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </ListItem>
  )
}

export default AdminDegreeItem;
import Assessment from "./Assessment";
import User from "./User";
import DocumentFile from "./DocumentFile";

export default interface SharedDocument {
  id?: number;
  document_id?: number;
  recipient_id?: number;
  recipient_type: string;

  document?: DocumentFile;
  recipient?: User|Assessment;
}

const sharedDocumentRecipientName = (sharedDocument: SharedDocument): string|undefined => {
  if (sharedDocument.recipient_type === 'User') {
    const user = sharedDocument.recipient as User;
    return `${user.first_name} ${user.last_name}`;
  }
}

export { sharedDocumentRecipientName }
import * as React from "react";
import useAuth from "../hooks/useAuth";
import AdminApp from "./admin/AdminApp";
import StudentApp from "./student/StudentApp";

const Home = () => {
  const auth = useAuth();

  return (
    <>
      {auth.user.admin || auth.user.superadmin ? (
        <AdminApp/>
      ) : (
        <StudentApp/>
      )}
    </>
  )
}

export default Home;
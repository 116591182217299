import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from "@mui/material"
import * as React from "react"
import { useState } from "react"
import { ChatMessage, User } from "../../models"
import PrimaryButton from "../controls/PrimaryButton"
import SecondaryButton from "../controls/SecondaryButton"
import StudentDropdown from "../filters/StudentDropdown"
import FindStudentsDialog from "../filters/FindStudentsDialog"

const ChatDialog = ({ onCancel, onSend }: { onCancel: () => void, onSend: (chatMessage: ChatMessage) => void }) => {
  const [student, setStudent] = useState<User|null>(null);
  const [body, setBody] = useState('');
  const [findStudentsOpen, setFindStudentsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const handleSend = () => {
    if (!student?.id) return;
    if (!body) return;

    const message: ChatMessage = {
      body,
      chat_id: student.id,
    }
    onSend(message);
  }

  const handleAddStudents = (students: User[]) => {
    setSelectedUsers(students);
    setFindStudentsOpen(false);
  }

  const handleCancelFindStudents = () => {
    setSelectedUsers([]);
    setFindStudentsOpen(false);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>
          <StudentDropdown value={student} onChange={setStudent} filteredUsers={selectedUsers} />
          <Button variant="outlined" onClick={() => setFindStudentsOpen(true)}>Find Students</Button>

          <TextField 
            label="Message"
            placeholder="Type a message..." 
            variant="outlined" 
            multiline
            rows={undefined}
            value={body}
            onChange={(e) => setBody(e.target.value)} />
            
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSend}>Send</PrimaryButton>
      </DialogActions>
      <Dialog open={findStudentsOpen} fullWidth>
        <FindStudentsDialog students={selectedUsers} onCancel={() => handleCancelFindStudents()} onAdd={handleAddStudents} />
      </Dialog>
    </>
  )
}

export default ChatDialog
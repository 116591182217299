import { Container, Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import AdminCard from "./AdminCard";
import SecondaryButton from "../../../controls/SecondaryButton";
import { User } from "../../../../models";
import useAuth from "../../../../hooks/useAuth";

const Administrators = () => {
  const [admins, setAdmins] = useState<User[]>([]);
  const auth = useAuth();

  useEffect(() => {
    const fetchAdmins = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setAdmins(json);
    }

    fetchAdmins();
  }, []);
  
  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          component={Link}
          to={`/admins/new`}
        >
          Invite an Administrator
        </SecondaryButton>
        
        {admins.map(admin => (
          <AdminCard key={admin.id} user={admin} />
        ))}
      </Stack>
    </Container>
  )
}

export default Administrators;
import * as React from "react";
import { StandardizedTest } from "../../models";
import StandardizedTestForm from "./StandardizedTestForm";
import useAuth from "../../hooks/useAuth";

const AddStandardizedTest = ({ studentId, onCancel, onSave }: { studentId: number, onCancel: () => void, onSave: (standardizedTest: StandardizedTest) => void }) => {
  const auth = useAuth();
  
  const createStandardizedTest = async (standardizedTest: StandardizedTest) => {
    const token = auth.token();

    const formData = new FormData();
    if (standardizedTest.file) {
      formData.append('attachment', standardizedTest.file);
    }
    formData.append('test_type', standardizedTest.test_type);
    formData.append('date', standardizedTest.date);
    formData.append('total_score', standardizedTest.total_score?.toString() || '');
    formData.append('notes', standardizedTest.notes);

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${studentId}/standardized_tests`, {
      method: 'POST',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <StandardizedTestForm onCancel={handleCancel} onSave={createStandardizedTest} />
  )
}

export default AddStandardizedTest;
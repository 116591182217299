import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import './PaperFilePreview.css';

const isImage = (file: File) => {
  return file.type.split('/')[0] === 'image';
}

const previewIcon = (file: File) => {
  if (isImage(file)) {
    const preview = URL.createObjectURL(file);
    return <img src={preview} onLoad={() => { URL.revokeObjectURL(preview) }} className="paper-file-preview__image" />
  }
  return <AttachFileOutlinedIcon />
}

const PaperFilePreview = ({ file, onRemove }: { file: File, onRemove: (e: MouseEvent<HTMLElement>) => void }) => {
  const preview = URL.createObjectURL(file);

  return (
    <Stack direction="row" spacing={1} sx={{alignItems: 'center'}} p={1}>
      {previewIcon(file)}
      <Typography variant="body2" sx={{ flexGrow: 1 }}>{file.name}</Typography>
      <IconButton onClick={(e) => onRemove(e)}><CancelIcon /></IconButton>
    </Stack>
  )
}

export default PaperFilePreview;
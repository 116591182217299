import { Avatar, Box, CardHeader, Divider, IconButton, Menu, MenuItem, MenuList, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useUser from "../../hooks/useUser";
import { Collaboration, User } from "../../models";

const AuthMenu = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState<User>();
  const [invitations, setInvitations] = useState<Collaboration[]>([]);
  const [anchorElInvitations, setAnchorElInvitations] = useState<null|HTMLElement>(null);
  const currentUser = useUser();

  useEffect(() => {
    const fetchUser = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users/${auth.user.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setUser(json);
    }

    const fetchInvitations = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/invitations`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setInvitations(json);
    }

    if (auth.user) {
      fetchUser();
      fetchInvitations();
    }
  }, [auth]);
  
  if (!auth.user || !user) {
    return <div/>;
  }

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseInvitationsMenu = () => {
    setAnchorElInvitations(null);
  }

  let menuItems = [
    <MenuItem component={Link} to="/user" key="0">
      <CardHeader
        avatar={<Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar?.url} />}
        title={`${user.first_name} ${user.last_name}`}
        subheader={user.email}
      />
    </MenuItem>,
    <Divider key="1" />
  ];

  // if (invitations.length > 0) {
  //   menuItems.push(<MenuItem onClick={(e) => setAnchorElInvitations(anchorElInvitations ? null : e.currentTarget)} key="2">
  //     <Typography textAlign="center">Switch to Student...</Typography>
  //   </MenuItem>);
  //   menuItems.push(<Divider key="3" />);
  // }

  menuItems.push(
    <MenuItem onClick={() => auth.signout(() => navigate("/"))} key="4">
      <Typography textAlign="center">Sign Out</Typography>
    </MenuItem>  
  );

  return (
    <Box sx={{ flexGrow: 0 }}>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
        <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar?.url} />
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {menuItems}
      </Menu>
      {currentUser &&
        <Menu 
          anchorEl={anchorElInvitations} 
          open={Boolean(anchorElInvitations)}
          keepMounted
          onClose={handleCloseInvitationsMenu}
        >
          {/*
          <MenuItem 
            onClick={() => currentUser.switchUser(user.id!)}
            sx={{backgroundColor: currentUser.userId === user.id ? 'primary.main' : 'transparent'}}
          >
            <CardHeader
              avatar={<Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar?.url} />}
              title={`${user.first_name} ${user.last_name}`}
              subheader={user.email}
            />
          </MenuItem>
          */}
          {/*invitations.map((invitation) => 
            <MenuItem 
              key={invitation.id} 
              onClick={() => currentUser.switchUser(invitation.user_id!)}
              sx={{backgroundColor: currentUser.userId === invitation.user_id ? 'primary.main' : 'transparent'}}
            >
              <CardHeader
                avatar={<Avatar alt={`${invitation.user?.first_name} ${invitation.user?.last_name}`} src={invitation.user?.avatar?.url} />}
                title={`${invitation.user?.first_name} ${invitation.user?.last_name}`}
                subheader={invitation.user?.email}
              />
            </MenuItem>
          )*/}
        </Menu>
      }
    </Box>
  )
}

export default AuthMenu;
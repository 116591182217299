import { Box, Card, CardContent, CardHeader, Skeleton, Stack, TableCell, TableRow } from "@mui/material";
import * as React from "react";

const ChatLoaderItem = () => {  
  return (
    <Card>
      <CardContent>
        <CardHeader sx={{ p: 0, textDecoration: 'none', color: 'inherit' }}
          avatar={<Skeleton variant="circular" width={40} height={40} />}
          title={<Skeleton variant="text" />}
          subheader={<Skeleton variant="text" />}
        />
      </CardContent>
    </Card>
  )
}

export default ChatLoaderItem;
import { convertFromHTML } from "draft-convert";
import * as React from "react";
import { CompositeDecorator, Editor, EditorState } from 'draft-js';
import RichTextLink from './RichTextLink';
import { Box } from "@mui/material";

const RichTextViewer = ({ value }: { value: string }) => {

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  };

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: RichTextLink,
    },
  ]);

  const editorState = EditorState.createWithContent(convertFromHTML({
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
            return createEntity(
                'LINK',
                'MUTABLE',
                {url: node.href}
            )
        }
      },
    })(value ?? ''), decorator)

  const onDraftChange = (editorState: EditorState) => {
  }

  return (
    <Box sx={{ fontSize: 12, lineHeight: '18.84px' }}>
      <Editor
        editorState={editorState}
        readOnly={true}
        onChange={onDraftChange}
      />
    </Box>
  )
}

export default RichTextViewer;
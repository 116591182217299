import { Box, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Background from "../controls/Background";
import useRouteMatch from "../../hooks/useRouteMatch";
import { useState } from "react";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import logo from '../logo.svg';
import StyledDrawer from "../controls/StyledDrawer";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const StudentLayout = ({ tasksCount }: { tasksCount: number }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(['/', '/colleges/*', '/tasks/*', '/documents/*', '/settings/*']);
  const [value, setValue] = useState(routeMatch?.pattern?.path || '/');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const handleSearch = (query) => {
    navigate(`/search?q=${query}`);
  }

  return (
    <Background sx={{ display: 'flex' }}>
      <StyledDrawer
        variant="permanent"
        anchor="left"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ p: '8px' }}>
          <Link to="/" onClick={() => setValue('/')}>
            <img src={logo} alt="ArtsBridge College Guide" width="270" height="65" />
          </Link>
        </Box>

        <List>
          <ListItem>
            <ListItemButton selected={value === '/'} component={Link} to="/" onClick={() => setValue('/')}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected={value === '/colleges/*'} component={Link} to="/colleges" onClick={() => setValue('/colleges/*')}>
              <ListItemIcon>
                <SchoolOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Colleges" />
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <>
                {tasksCount > 0 &&
                  <Chip label={tasksCount} color="error" />
                }
              </>
            }
          >
            <ListItemButton selected={value === '/tasks/*'} component={Link} to="/tasks" onClick={() => setValue('/tasks/*')}>
              <ListItemIcon>
                <InventoryOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Tasks/Events" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected={value === '/documents/*'} component={Link} to="/documents" onClick={() => setValue('/documents/*')}>
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Documents" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected={value === '/settings/*'} component={Link} to="/settings" onClick={() => setValue('/settings/*')}>
              <ListItemIcon>
                <MoreHorizIcon />
              </ListItemIcon>
              <ListItemText primary="More" />
            </ListItemButton>
          </ListItem>
        </List>
      </StyledDrawer>

      <Outlet />
    </Background>
  )
}

export default StudentLayout;
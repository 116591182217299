import { Box, Container, Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chat } from "../../../models";
import ChatLoaderItem from "../../messages/ChatLoaderItem";
import useAuth from "../../../hooks/useAuth";
import ChatListItem from "./ChatListItem";

const ChatsList = () => {
  const navigate = useNavigate();
  const [chats, setChats] = useState<Chat[]>([]);
  const [isLoading, setLoading] = useState(false);
  const auth = useAuth();
  
  useEffect(() => {
    const fetchChats = async () => {
      setLoading(true);
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/chats`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      setLoading(false);
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setChats(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchChats();
  }, []);    

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        {chats.map(chat => (
          <Box key={chat.id}>
            {chat.user &&
              <ChatListItem chat={chat} to={`/chats/${chat.user.id}`} />
            }
          </Box>
        ))}
        {isLoading &&
          <>
            <ChatLoaderItem/>
            <ChatLoaderItem/>
            <ChatLoaderItem/>
          </>
        }
      </Stack>
    </Container>
  );
}

export default ChatsList;
import * as React from "react";
import { Route, Routes } from "react-router-dom";
import StudentLayout from "./StudentLayout";
import Colleges from "./colleges/Colleges";
import StudentDashboard from "./dashboard/StudentDashboard";
import Documents from "../documents/Documents";
import Settings from './settings/Settings';
import ChatDetail from "../messages/ChatDetail";
import AssessmentDetail from "../admin/students/colleges/AssessmentDetail";
import SearchResults from "../SearchResults";
import StudentContent from "./StudentContent";
import EditStudentProfile from "../auth/EditStudentProfile";
import UserProvider from "../../providers/UserProvider";
import { useEffect, useState } from "react";
import { Notification } from '../../models';
import NotificationsList from "../notifications/NotificationsList";
import StudentTasks from "./tasks/StudentTasks";
import useAuth from "../../hooks/useAuth";

const StudentApp = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const auth = useAuth();
  const [tasksCount, setTasksCount] = useState<number>(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/notifications`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setNotifications(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchNotifications();
  }, []);

  const handleDismiss = (notification: Notification) => {
    setNotifications(notifications.filter(n => n.id !== notification.id))
  }

  const refreshTasks = async () => {
    const token = auth.token();
    let url = `${window.SERVER_DATA.domain}/api/v1/tasks/incomplete`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setTasksCount(json.tasks);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  useEffect(() => {
    refreshTasks();
  }, []);

  return (
    <UserProvider>
      <Routes>
        <Route element={<StudentLayout tasksCount={tasksCount} />}>
          <Route element={<StudentContent tasksCount={tasksCount} notifications={notifications} onDismiss={handleDismiss} />}>
            <Route path="/" element={<StudentDashboard notifications={notifications} onDismiss={handleDismiss} />} />
            <Route path="/colleges" element={<Colleges />} />
            <Route path="/tasks" element={<StudentTasks isAdmin={false} onChange={() => refreshTasks()} />} />
            <Route path="/documents" element={<Documents isAdmin={false} />} />
            <Route path="/documents/:id" element={<Documents isAdmin={false} />} />
            <Route path="/settings/*" element={<Settings />} />
          </Route>

          <Route path="/user" element={<EditStudentProfile />} />

          <Route path="/chats/:id" element={<ChatDetail />} />

          <Route path="/assessments/:id/*" element={<AssessmentDetail isAdmin={false} />} />
        
          <Route path="/search" element={<SearchResults />} />

          <Route path="/notifications" element={<NotificationsList notifications={notifications} onDismiss={handleDismiss} />} />
        </Route>
      </Routes>
    </UserProvider>
  )
}

export default StudentApp;
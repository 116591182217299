import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { InstitutionDegree, Task, User } from "../../models";
import { taskTypes } from "../../models/Task";
import PaperDropzone from "../controls/PaperDropzone/PaperDropzone";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import CollegesDropdown from "../filters/CollegesDropdown";
import StudentsDropdown from "../filters/StudentsDropdown";
import FindStudentsDialog from "../filters/FindStudentsDialog";
import RichTextEditor from "../controls/RichTextEditor";
import StudentDropdown from "../filters/StudentDropdown";

const TaskForm = ({ 
  task,
  onCancel, 
  onSave, 
  showUsers, 
  initialUsers, 
  institutionDegrees, 
  isEditing = false, 
  showCollaborators = false,  
  selectedInstitutionDegree,
  availableUsers = [],
}: { 
  task?: Task, 
  onCancel: () => void, 
  onSave: (task: Task) => void, 
  showUsers: boolean, 
  initialUsers?: User[], 
  institutionDegrees?: InstitutionDegree[], 
  isEditing?: boolean, 
  showCollaborators?: boolean,
  selectedInstitutionDegree?: InstitutionDegree,
  availableUsers?: User[],
}) => {
  let taskDueOn = '';
  if (task?.due_on) {
    const date = parseISO(task.due_on);
    taskDueOn = format(date, 'yyyy-MM-dd');
  }
  
  const [title, setTitle] = useState(task?.title || '');
  const [notes, setNotes] = useState(task?.notes || '');
  const [taskType, setTaskType] = useState(task?.task_type || '');
  const [dueOn, setDueOn] = useState(taskDueOn);
  const [selectedUsers, setSelectedUsers] = useState<User[]>(initialUsers || []);
  const [selectedColleges, setSelectedColleges] = useState<InstitutionDegree[]>(selectedInstitutionDegree ? [selectedInstitutionDegree] : []);
  const [file, setFile] = useState<File>();
  const [includeCollaborators, setIncludeCollaborators] = useState(false);
  const [taskTypeError, setTaskTypeError] = useState(false);
  const [dueOnError, setDueOnError] = useState(false);
  const [studentError, setStudentError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [assignToMe, setAssignToMe] = useState(false);
  const [collegesError, setCollegesError] = useState(false);
  const [findStudentsOpen, setFindStudentsOpen] = useState(false);
  const [url, setUrl] = useState(task?.url || '');

  const handleSave = () => {
    const isTaskOrQuestion = taskType === 'task' || taskType === 'question'

    setTaskTypeError(!taskType);
    setTitleError((!title || title === '') && isTaskOrQuestion)
    setDueOnError(!dueOn && taskType !== '' && !isTaskOrQuestion)
    setStudentError(selectedUsers.length === 0 && showUsers && !assignToMe)
    setStudentError(selectedUsers.length === 0 && availableUsers.length > 0 && !assignToMe)
    setCollegesError(selectedColleges.length === 0 && taskType !== '' && !isTaskOrQuestion);


    if (!taskType && !task?.task_template_id) {
      return;
    }

    if ((!title || title === '') && isTaskOrQuestion) {
      return;
    }

    if (taskType !== '' && !isTaskOrQuestion && !dueOn) {
      return;
    }

    if (selectedUsers.length === 0 && showUsers && !assignToMe) {
      return;
    }

    if (selectedUsers.length === 0 && availableUsers.length > 0 && !assignToMe) {
      return;
    }

    if (taskType !== '' && !isTaskOrQuestion && selectedColleges.length === 0) {
      return;
    }

    const newTask: Task = {
      title,
      notes,
      task_type: taskType,
      due_on: dueOn,
      user_ids: assignToMe ? [] : selectedUsers.map(u => u.id || 0).filter(id => id > 0),
      include_collaborators: includeCollaborators,
      institution_degree_ids: selectedColleges.map(c => c.id || 0).filter(id => id > 0),
      file,
      url,
    }
    onSave(newTask);
  }

  const onFileChange = (file: File) => {
    setFile(file);
  }

  const handleAddStudents = (students: User[]) => {
    setSelectedUsers(students);
    setFindStudentsOpen(false);
  }

  const titleLabel = taskType === 'task' ? 'Enter task/event name' : 'Ask a question';
  const isTaskOrQuestion = taskType === 'task' || taskType === 'question';

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          {!task?.task_template_id &&
            <FormControl fullWidth error={taskTypeError}>
              <InputLabel id="taskTypeLabel">Task/Event Type</InputLabel>
              <Select
                labelId="taskTypeLabel"
                id="taskType"
                label="Task/Event Type"
                value={taskType}
                onChange={(e) => setTaskType(e.target.value)}
                required
              >
                {taskTypes.map(([label, value]) => (
                  <MenuItem
                    key={value || label}
                    value={value || label}
                  >{label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{taskTypeError && 'Please select a task type'}</FormHelperText>
            </FormControl>
          }
          
          {isTaskOrQuestion &&
            <TextField 
              label={titleLabel}
              variant="outlined" 
              value={title}
              onChange={(e) => setTitle(e.target.value)} 
              required={isTaskOrQuestion}
              error={titleError}
              helperText={titleError && 'Please enter a task/event name'}
            />
          }

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Notes</FormLabel>
            <RichTextEditor value={notes} onChange={setNotes} />
          </FormControl>

          <TextField
            label="Set due/event date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={dueOn}
            onChange={(e) => setDueOn(e.target.value)}
            required={taskType !== '' && !isTaskOrQuestion}
            helperText={dueOnError && 'Please set a due/event date'}
            error={dueOnError}
          />

          {showUsers && (
            <>
              <FormControlLabel control={<Checkbox checked={assignToMe} onChange={() => setAssignToMe(!assignToMe)} />} label="Assign to me" />
              {!assignToMe &&
                <>
                  <StudentsDropdown value={selectedUsers} onChange={setSelectedUsers} required={true} error={studentError} />
                  <Button variant="outlined" onClick={() => setFindStudentsOpen(true)}>Find Students</Button>
                  {selectedUsers.length > 0 &&
                    <FormControlLabel control={<Checkbox checked={includeCollaborators} onChange={() => setIncludeCollaborators(!includeCollaborators)} />} label="Include Collaborators" />
                  }
                </>
              }
            </>
          )}

          {availableUsers.length > 0 && (
            <>
              <FormControlLabel control={<Checkbox checked={assignToMe} onChange={() => setAssignToMe(!assignToMe)} />} label="Assign to me" />
              {!assignToMe &&
                <StudentDropdown 
                  value={selectedUsers.length > 0 ? selectedUsers[0] : null}
                  onChange={(value) => setSelectedUsers([value])}
                  filteredUsers={availableUsers}
                  error={studentError}
                />
              }
            </>
          )}

          {showCollaborators && (
            <FormControlLabel control={<Checkbox checked={includeCollaborators} onChange={() => setIncludeCollaborators(!includeCollaborators)} />} label="Include Collaborators" />
          )}

          <CollegesDropdown 
            value={selectedColleges} 
            filter={institutionDegrees} 
            onChange={setSelectedColleges} 
            required={taskType !== '' && !isTaskOrQuestion}
            error={collegesError}
            disabled={selectedInstitutionDegree !== undefined} 
          />

          <PaperDropzone onChange={onFileChange} />

          <TextField 
            label="Or link to a file" 
            variant="outlined" 
            value={url}
            onChange={(e) => setUrl(e.target.value)} />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
      <Dialog open={findStudentsOpen} fullWidth>
        <FindStudentsDialog students={selectedUsers} onCancel={() => setFindStudentsOpen(false)} onAdd={handleAddStudents} />
      </Dialog>
    </>
  )
}

export default TaskForm;
import { Card, CardContent, CardHeader, Dialog, DialogTitle, Grid, IconButton, Link, Stack, Typography } from "@mui/material"
import { format, parseISO } from "date-fns";
import * as React from "react"
import { Assessment, InstitutionDegree } from '../../../../../../models';
import EditPrescreen from "./EditPrescreen";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useState } from "react";
import RichTextViewer from "../../../../../controls/RichTextViewer";

const PrescreenInfo = ({ assessment, isAdmin, onChange }: { assessment: Assessment, isAdmin: boolean, onChange: (institutionDegree: InstitutionDegree) => void }) => {
  const [open, setOpen] = useState(false);

  const collegeInstitutionDegree = assessment.institution_degree;
  // const [collegeInstitutionDegree, setCollegeInstitutionDegree] = useState(institutionDegree);

  const handleSaveInstitutionDegree = (updatedInstitutionDegree: InstitutionDegree) => {
    // setCollegeInstitutionDegree({...collegeInstitutionDegree, ...updatedInstitutionDegree});
    setOpen(false);
    onChange({...collegeInstitutionDegree, ...updatedInstitutionDegree});
  }

  const action = isAdmin ? (
    <IconButton onClick={() => setOpen(true)}>
      <EditOutlinedIcon />
    </IconButton>
  ) : null;

  return (
    <Card>
      <CardHeader 
          title="Prescreen"
          action={action}
          sx={{ pb: 0 }}
        />
      <CardContent>

        {/*collegeInstitutionDegree.pre_screening_deadline &&
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Regular Decision Prescreen Deadline:</Typography>
            <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.pre_screening_deadline), 'MMM d, yyyy')}</Typography>
          </Stack>
        */}

        {/*collegeInstitutionDegree.prescreen_deadline_2 &&
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Early Action Prescreen Deadline:</Typography>
            <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_2), 'MMM d, yyyy')}</Typography>
          </Stack>
        */}

        {/*collegeInstitutionDegree.prescreen_deadline_3 &&
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Early Decision Prescreen Deadline:</Typography>
            <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_3), 'MMM d, yyyy')}</Typography>
          </Stack>
        */}

        {/*collegeInstitutionDegree.prescreen_deadline_4 &&
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Other Decision Prescreen Deadline:</Typography>
            <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_4), 'MMM d, yyyy')}</Typography>
          </Stack>
        */}

        {/*collegeInstitutionDegree.prescreen_deadline_5 &&
          <Stack direction="row" spacing={1}>
            <Typography variant="subtitle2">Other Decision 2 Prescreen Deadline:</Typography>
            <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_5), 'MMM d, yyyy')}</Typography>
          </Stack>
        */}

        
        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Prescreen Requirements:</Typography>
          <RichTextViewer value={collegeInstitutionDegree.pre_screening_requirements_url} />
        </Stack>

        <Stack direction="row" spacing={1}>
          <Typography variant="subtitle2">Prescreen Artistic Upload Website:</Typography>
          <Link variant="body2" href={collegeInstitutionDegree.prescreen_artistic_upload_url}>{collegeInstitutionDegree.prescreen_artistic_upload_url}</Link>
        </Stack>


        <>
          {assessment.applying === 'Regular Decision' && collegeInstitutionDegree.regular_deadline && 
            <>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.pre_screening_deadline &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Regular Decision Prescreen Deadline:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.pre_screening_deadline), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </>
          }

          {assessment.applying === 'Early Action' && collegeInstitutionDegree.early_action === 'Yes' &&
            <>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_2 &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Early Action Prescreen Deadline:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_2), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </>
          }

          {assessment.applying === 'Early Decision' && collegeInstitutionDegree.early_decision === 'Yes' &&
            <>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_3 &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Early Decision Prescreen Deadline:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_3), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </>
          }

          {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision === 'Yes' &&
            <>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_4 &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Other Decision Prescreen Deadline:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_4), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </>
          }

          {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision_two === 'Yes' &&
            <>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_5 &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Other Decision 2 Prescreen Deadline:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_5), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </>
          }
        </>
      </CardContent>

      <Dialog open={open} fullWidth>
        <DialogTitle>{`${collegeInstitutionDegree.institution?.name} ${collegeInstitutionDegree.degree?.name}`}</DialogTitle>
        <EditPrescreen institutionDegree={collegeInstitutionDegree} onCancel={() => setOpen(false)} onSave={handleSaveInstitutionDegree} />
      </Dialog>
    </Card>
  )
}

export default PrescreenInfo
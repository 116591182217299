import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import InstitutionForm from "./InstitutionForm";
import { Institution } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const AddInstitution = () => {
  const navigate = useNavigate();  
  const auth = useAuth();

  const createInstitution = async (institution: Institution) => {
    const token = auth.token();
    // const body = {
    //   name: institution.name,
    //   city: institution.city,
    //   state: institution.state,
    //   country: institution.country,
    //   central_office_phone: institution.central_office_phone,
    //   central_office_email: institution.central_office_email,
    //   private_institution_notes: institution.private_institution_notes,
    //   application_type: institution.application_type,
    //   application_url: institution.application_url,
    //   common_app: institution.common_app,
    //   coalition_application_institution: institution.coalition_application_institution,
    //   coalition_application_institution_url: institution.coalition_application_institution_url,
    //   other: institution.other,
    //   campus_visit_information: institution.campus_visit_information,
    // }
    
    const formData = new FormData();
    if (institution.file) formData.append('institution_avatar', institution.file);
    formData.append('name', institution.name);
    formData.append('city', institution.city);
    formData.append('state', institution.state);
    formData.append('country', institution.country);
    formData.append('central_office_phone', institution.central_office_phone);
    formData.append('central_office_email', institution.central_office_email);
    formData.append('private_institution_notes', institution.private_institution_notes);
    formData.append('application_type', institution.application_type);
    formData.append('application_url', institution.application_url);
    formData.append('common_app', institution.common_app);
    formData.append('coalition_application_institution', institution.coalition_application_institution);
    formData.append('coalition_application_institution_url', institution.coalition_application_institution_url);
    formData.append('other', institution.other);
    formData.append('campus_visit_information', institution.campus_visit_information);

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institutions`, {
      method: 'POST',
      headers: new Headers({
        // 'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      // body: JSON.stringify(body),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      navigate(`/config/colleges`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/config/colleges`);
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/config/colleges`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Colleges
            </Typography>
            <Typography variant="subtitle2">
              Add a College
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <InstitutionForm onCancel={handleCancel} onSave={createInstitution} />
      </Box>
    </Box>
  )
}

export default AddInstitution;
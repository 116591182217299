import { Avatar, Box, Breadcrumbs, Card, CardHeader, CardMedia, Container, IconButton, Stack, Tab, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import AssessmentOverview from "./AssessmentOverview/AssessmentOverview";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useRouteMatch from "../../../../hooks/useRouteMatch";
import AssessmentTasks from "./AssessmentTasks";
import Background from "../../../controls/Background";
import AssessmentDocuments from "./AssessmentDocuments";
import RoundedTopTabs from "../../../controls/RoundedTopTabs";
import { Assessment } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const AssessmentDetail = ({ isAdmin }: { isAdmin: boolean }) => {
  const params = useParams();
  const [assessment, setAssessment] = useState<Assessment>();
  const routeMatch = useRouteMatch([`/assessments/${params.id}`, `/assessments/${params.id}/tasks`, `/assessments/${params.id}/documents/*`]);
  const [value, setValue] = useState(routeMatch?.pattern?.path || `/assessments/${params.id}`);
  const auth = useAuth();
  
  useEffect(() => {
    const fetchAssessment = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAssessment(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchAssessment();
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!assessment) {
    return <div/>;
  }

  const backLink = isAdmin ? `/students/${assessment.user_id}/colleges` : '/colleges';
  
  const userName = `${assessment.user?.first_name} ${assessment.user?.last_name}`;

  return (
    <Box sx={{ width: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={backLink}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            {isAdmin && (
              <Typography variant="subtitle2">
                {userName}
              </Typography>
            )}
            <Typography variant="subtitle2">
              Colleges
            </Typography>
          </Breadcrumbs>
          
        </Toolbar>
      </ResponsiveAppBar>
      
      <Background>
        <Toolbar />
        <Stack spacing={2} sx={{ backgroundColor: 'background.default', borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }}>
          <CardHeader sx={{ pb: 0 }}
            avatar={<Avatar alt={`${assessment.user?.first_name} ${assessment.user?.last_name}`} src={assessment.user?.avatar?.url} />}
            title={`${assessment.user?.first_name} ${assessment.user?.last_name}`}
            action={isAdmin ? (
              <IconButton component={Link} to={`/assessments/${assessment.id}/edit`}>
                <EditOutlinedIcon/>
              </IconButton>
            ) : null}
          />
          <CardHeader sx={{ pt: 0 }}
            title={assessment.institution_degree?.institution?.name}
            subheader={assessment.institution_degree?.degree?.name}
          />
        </Stack>

        <Container sx={{ py: 2 }}>
          <Stack spacing={2}>
            <Card sx={{ borderRadius: '20px' }}>
              <CardMedia
                component="img"
                height="194"
                image={assessment.institution_degree?.institution?.institution_avatar?.url}
                alt={assessment.institution_degree?.institution?.name}
              />
            </Card>

            <Box>
              <TabContext value={value}>
                <RoundedTopTabs 
                  value={value} 
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Overview" value={`/assessments/${params.id}`} component={Link} to={`/assessments/${params.id}`} />
                  <Tab label="Tasks/Events" value={`/assessments/${params.id}/tasks`} component={Link} to={`/assessments/${params.id}/tasks`} />
                  <Tab label="Documents" value={`/assessments/${params.id}/documents/*`} component={Link} to={`/assessments/${params.id}/documents`} />
                </RoundedTopTabs>
                <TabPanel value={`/assessments/${params.id}`} sx={{p: 0}}>
                  <AssessmentOverview assessment={assessment} isAdmin={isAdmin} onChange={setAssessment} />
                </TabPanel>
                <TabPanel value={`/assessments/${params.id}/tasks`} sx={{p: 0}}>
                  <AssessmentTasks assessment={assessment} onChange={setAssessment} />
                </TabPanel>
                <TabPanel value={`/assessments/${params.id}/documents/*`} sx={{p: 0}}>
                  <AssessmentDocuments assessment={assessment} onChange={setAssessment} />
                </TabPanel>
              </TabContext>
            </Box>
          </Stack>
        </Container>
      </Background>
    </Box>
  );
}

export default AssessmentDetail;
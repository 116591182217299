import { Box, Container, Dialog, Stack, Tab } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useState } from "react";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Link, useSearchParams } from "react-router-dom";
import SecondaryButton from "../../controls/SecondaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import TertiaryButton from "../../controls/TertiaryButton";
import SortStudents from "../../sort/SortStudents";
import SortOptions from "../../sort/SortOptions";
import FilterStudents, { FilterData } from "../../filters/FilterStudents";
import { TabContext, TabPanel } from "@mui/lab";
import RoundedTopTabs from "../../controls/RoundedTopTabs";
import useRouteMatch from "../../../hooks/useRouteMatch";
import StudentsList from "./StudentsList";
import SearchField from "../../controls/SearchField";
import SearchIcon from '@mui/icons-material/Search';

const Students = () => {
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<FilterData>();

  const [openSort, setOpenSort] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();

  const graduationYear = new Date().getMonth() >= 5 ? new Date().getFullYear() + 1 : new Date().getFullYear();

  const applicationStatus = searchParams.get('status');
  if (applicationStatus && !filters) {
    setFilters({ 
      application_status: applicationStatus as string, 
      graduation_year: graduationYear.toString(), 
    });
  }

  const supplementalStatus = searchParams.get('supplemental_status');
  if (supplementalStatus && !filters) {
    setFilters({ 
      supplemental_application_status: supplementalStatus as string, 
      graduation_year: graduationYear.toString(), 
    });
  }

  const prescreenStatus = searchParams.get('prescreen_status');
  if (prescreenStatus && !filters) {
    setFilters({ 
      admission_decision: prescreenStatus as string, 
      graduation_year: graduationYear.toString(), 
    });
  }

  const routeMatch = useRouteMatch([`/students`, `/students/archived`]);
  const [value, setValue] = useState(routeMatch?.pattern?.path || `/students`);

  const isExpanded = searchParams.get('expanded') === '1';

  const handleSaveFilters = (filters: FilterData) => {
    setFilters(filters);
    setOpenFilters(false);
  }

  const handleSaveSort = (options: SortOptions) => {
    setSortOptions(options);
    setOpenSort(false);
  }

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSearchQuery = (query: string) => {
    setSearchQuery(query);
  }

  return (
    <Box>
      <Stack spacing={1} sx={{
        pb: 2,
        px: 2,
        backgroundColor: 'background.default',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      }}>
        <Stack spacing={2} direction="row">
          <TertiaryButton
            variant="text"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => setOpenFilters(true)}
          >
            Filter Students
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<ImportExportOutlinedIcon />}
            onClick={() => setOpenSort(true)}
          >
            Sort Students
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (openSearch) {
                setOpenSearch(false);
                handleSearchQuery('');
              } else {
                setOpenSearch(true);
              }
            }}
          >
            Search Students
          </TertiaryButton>
        </Stack>
        {openSearch &&
          <SearchField label="Search Students" onSearch={handleSearchQuery} realTime={true} />
        }
      </Stack>

      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>
          <SecondaryButton
            variant="outlined"
            startIcon={<ControlPointOutlinedIcon />}
            sx={{ width: 1, maxWidth: 300 }}
            component={Link}
            to={`/students/new`}
          >
            Invite a Student
          </SecondaryButton>
          
          <Box>
            <TabContext value={value}>
              <RoundedTopTabs 
                value={value} 
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Active" value={`/students`} component={Link} to={`/students`} />
                <Tab label="Archived" value={`/students/archived`} component={Link} to={`/students/archived`} />
              </RoundedTopTabs>
              <TabPanel value={`/students`} sx={{p: 0}}>
                <StudentsList 
                  archived={false} 
                  sortOptions={sortOptions} 
                  filters={filters} 
                  isExpanded={isExpanded} 
                  query={searchQuery}
                />
              </TabPanel>
              <TabPanel value={`/students/archived`} sx={{p: 0}}>
                <StudentsList 
                  archived={true} 
                  sortOptions={sortOptions} 
                  filters={filters} 
                  isExpanded={isExpanded} 
                  query={searchQuery}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Stack>
      </Container>

      <Dialog open={openFilters} fullWidth>
        <FilterStudents title="Filter Students" filters={filters} onCancel={() => setOpenFilters(false)} onSave={handleSaveFilters} />
      </Dialog>

      <Dialog open={openSort} fullWidth>
        <SortStudents title="Sort Students" options={sortOptions} onCancel={() => setOpenSort(false)} onSave={handleSaveSort} />
      </Dialog>
    </Box>
  )
}

export default Students;
import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import * as React from "react";
import { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { InstitutionDegree, User } from "../../models";
import useAuth from "../../hooks/useAuth";

const AdminsDropdown = ({ value, onChange, includeSelf } : { value: User[], onChange: (value: User[]) => void, includeSelf: boolean }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [admins, setAdmins] = useState<User[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    if (open) {
      const fetchAdmins = async () => {
        const token = auth.token();
        const params = includeSelf ? 'admin=1&include_self=1' : 'admin=1';
        const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?${params}`, {
          method: 'GET',
          headers: new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
          }),
        });
        if (response.ok) {
          const json: User[] = await response.json();
          console.log(json);
          setAdmins(json);
        } else {
          const json = await response.json();
          console.error(json);
        }
      }
      fetchAdmins();
    }
  }, [open]);

  useEffect(() => {
    const fetchAdmins = async () => {
      const token = auth.token();
      const params = includeSelf ? `admin=1&include_self=1&q=${inputValue}` : `admin=1&q=${inputValue}`;
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?${params}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: User[] = await response.json();
        console.log(json);
        setAdmins(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchAdmins();
  }, [inputValue]);

  const handleChange = (event: SyntheticEvent, value: (User[]), reason: AutocompleteChangeReason) => {
    onChange(value);
  }

  const sortedAdmins = admins.sort((a, b) => {
    const aName = `${a.last_name.toLowerCase()}, ${a.first_name.toLowerCase()}`;
    const bName = `${b.last_name.toLowerCase()}, ${b.first_name.toLowerCase()}`;
    return aName.localeCompare(bName);
  });
  
  return (
    <Autocomplete 
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Assign people"
        />
      )} 
      value={value}
      options={sortedAdmins}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.first_name} ${option.last_name}`}
      onChange={(event, value, reason) => handleChange(event, value as User[], reason)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  )
}

export default AdminsDropdown;

import { ClickAwayListener, TextField } from "@mui/material";
import * as React from "react";
import { useRef, useState } from "react";
import { debounce } from 'lodash';
import { Variant } from "@mui/material/styles/createTypography";
import useAuth from "../../hooks/useAuth";

const EditableField = ({ endpoint, field, value, wrap, validate = (value) => true, variant = 'standard', fontSize = '0.875rem', fontWeight = 'normal' }: { endpoint: string, field: string, value: string, wrap?: string, validate?: (value: string) => boolean, variant?: "standard" | "filled" | "outlined" | undefined, fontSize?: string, fontWeight?: string }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);
  const auth = useAuth();
  
  const handleChange = async (value: string) => {
    const token = auth.token();

    let body: any;
    if (wrap) {
      body = {
        [wrap]: {
          [field]: value
        }
      }
    } else {
      body = {
        [field]: value
      }
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/${endpoint}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      console.log(body);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const debouncedChange = useRef(debounce(handleChange, 300));

  const onChange = (value: string) => {
    setCurrentValue(value);
    if (validate(value)) {
      debouncedChange.current(value);
    }
  }

  if (!isEditing) {
    return (
      <TextField 
        variant={variant as any}
        value={currentValue}
        onClick={() => setIsEditing(true)}
        inputProps={{
          style: {
            // fontSize: '0.875rem',
            fontSize,
            fontWeight,
          }
        }}
        InputProps={{
          disableUnderline: true,
          readOnly: true,
        }}
        size="small"
        fullWidth
      />
    )
  }

  return (
    <ClickAwayListener onClickAway={() => setIsEditing(false)}>
      <TextField 
        variant={variant as any}
        value={currentValue}
        onChange={(e) => onChange(e.target.value)} 
        onBlur={() => setIsEditing(false)}
        inputProps={{
          style: {
            // fontSize: '0.875rem',
            fontSize,
            fontWeight,
          }
        }}
        InputProps={{
          disableUnderline: true
        }}
        size="small"
        fullWidth
        autoFocus
      />
    </ClickAwayListener>
  )
}

export default EditableField;
import * as React from "react";
import useAuth from "../../hooks/useAuth";
import { Task } from "../../models";
import { Avatar, Checkbox, Chip, DialogContent, DialogTitle, IconButton, Link, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import { formatTaskType } from "../../models/Task";
import RichTextViewer from "../controls/RichTextViewer";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { formatDistance, parseISO } from "date-fns";

const ViewTask = ({ taskId, onClose, onChange }: { taskId: number, onClose: () => void, onChange: (task: Task) => void }) => {
  const auth = useAuth();

  const [task, setTask] = React.useState<Task>();
  const [missing, setMissing] = React.useState(false);
  const [completedAt, setCompletedAt] = React.useState<string|null>(task?.completed_at || null);

  React.useEffect(() => {
    const fetchTask = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${taskId}`, {
        method: 'GET',
        headers: new Headers({
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setTask(json);
        setCompletedAt(json.completed_at || null);
      } else {
        const json = await response.json();
        console.error(json);
        setMissing(true);
      }
    }

    fetchTask();
  }, [taskId]);

  const completeTask = async () => {
    const token = auth.token();

    const now = new Date().toISOString();
    const newValue = completed ? null : now;

    let body = {
      completed_at: newValue
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      setCompletedAt(newValue);
      onChange({ ...task, completed_at: newValue ? newValue : undefined });
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  if (missing) {
    return (
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>That task could not be found</Typography>
            <Typography variant="body1">It may have been removed or unshared.</Typography>
          </Stack>
        </DialogContent>
      </>
    )
  }

  if (!task) { return null }

  const completed = completedAt !== null;
  const isTaskOrQuestion = task.task_type === 'task' || task.task_type === 'question';
  const taskTitle = task.task_type && !isTaskOrQuestion ? formatTaskType(task.task_type) : task.title;
  const isAdmin = task.user?.admin || task.user?.superadmin || false;
  const userUrl = isAdmin ? `/admins/${task.user_id}` : `/students/${task.user_id}`;
  const filename = (task.documents || []).length > 0 && task.documents[0].attachment_identifier;
  const remindedAt = task.reminded_at ? parseISO(task.reminded_at) : null;
  const today = new Date();
  const dueDate = task.due_on ? new Date(task.due_on) : null;
  const pastDue = dueDate && (dueDate.valueOf() < today.valueOf());

  return (
    <>
      <DialogTitle>Task Details</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ pt: 0 }}>
        <Stack direction="row" alignItems="flex-start" spacing={1}>
          <Checkbox 
            checked={completed} 
            onClick={() => completeTask()} 
            sx={{ pt: 0 }}
            icon={<RadioButtonUncheckedOutlinedIcon />}
            checkedIcon={<RadioButtonCheckedOutlinedIcon />}
          />
          <Stack sx={{flexGrow: 1}} spacing={1}>

            <Typography variant="subtitle2" style={{fontSize: 14, fontWeight: 500}}>
              {taskTitle}
            </Typography>
            
            {task.notes && <RichTextViewer value={task.notes} /> }

            {task.user?.id !== auth.user.id &&
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Avatar sx={{ width: 24, height: 24 }} src={task.user?.avatar?.url} alt={`${task.user?.first_name} ${task.user?.last_name}`} />
                <Link variant="body2" color="inherit" underline="hover" href={userUrl}>{`${task.user?.first_name} ${task.user?.last_name}`}</Link>
              </Stack>
            }
            {task.assessment &&
              <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                <Avatar sx={{ width: 24, height: 24 }} src={task.assessment?.institution_degree?.institution?.institution_avatar?.url} alt={`${task.assessment?.institution_degree?.institution?.name} ${task.assessment?.institution_degree?.degree?.name}`} />
                <Link variant="body2" color="inherit" underline="hover" href={`/assessments/${task.assessment.id}/tasks`}>{`${task.assessment?.institution_degree?.institution?.name} ${task.assessment?.institution_degree?.degree?.name}`}</Link>
              </Stack>
            }
            
            {filename &&
              <Stack spacing={1} sx={{ flexGrow: 1, alignItems: 'center' }} direction="row">
                <InsertDriveFileOutlinedIcon />
                <Typography variant="body2" component={Link} href={task.documents[0].attachment?.url}>{filename}</Typography>
              </Stack>
            }
            {task.url && (
              <Link variant="body2" href={task.url}>Link</Link>
            )}

            {task.task_type === 'question' &&
              <>
                {task.answer &&
                  <Typography variant="body2">{task.answer}</Typography>
                }
              </>
            }

          </Stack>

          
          {remindedAt && auth.user.admin &&
            <Chip label={`Reminded ${formatDistance(remindedAt, new Date())} ago`} variant="filled" size="small" />
          }

          {pastDue && !completed &&
            <Chip label="Past Due" variant="filled" size="small" color="error" />
          }
        </Stack>
      </DialogContent>
    </>
  )
}

export default ViewTask;
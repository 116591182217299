import { Box, Card, CardContent, Container, Dialog, Divider, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Announcement } from "../../models";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { formatDistance, parseISO, set } from "date-fns";
import SecondaryButton from "../controls/SecondaryButton";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import NewAnnouncement from "./NewAnnouncement";
import RichTextViewer from "../controls/RichTextViewer";
import AnnouncementLoaderItem from "./AnnouncementLoaderItem";

const AnnouncementsList = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [announcementsOpen, setAnnouncementsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAnnouncements = async () => {
      setLoading(true);
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/announcements`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      setLoading(false);
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAnnouncements(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchAnnouncements();
  }, [])

  const handleSave = (announcement: Announcement) => {
    setAnnouncements([announcement, ...announcements]);
    setAnnouncementsOpen(false);
  }

  
  
  if (!auth.user) return null;

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          onClick={() => setAnnouncementsOpen(true)}
        >
          Create Announcement
        </SecondaryButton>

        <Card>
          <CardContent sx={{ p: 0 }}>
            <List>
              {announcements.map(announcement => (
                <Box key={announcement.id}>
                  <ListItem>
                    <ListItemIcon sx={{ flexShrink: 1, minWidth: 38 }}>
                      <NotificationsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
                        <Stack sx={{ flexGrow: 1 }}>
                          <Typography component={Link} to={`/announcements/${announcement.id}`} variant="body2" color="text.secondary">Sent {formatDistance(parseISO(announcement.created_at), new Date())} ago to {announcement.notifications.length} {announcement.notifications.length === 1 ? 'person' : 'people'}</Typography>
                          <Typography variant="subtitle1">{announcement.title}</Typography>
                          <RichTextViewer value={announcement.message} />
                        </Stack>
                      </Stack>
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </Box>
              ))}
              {isLoading &&
                <>
                  <AnnouncementLoaderItem/>
                  <AnnouncementLoaderItem/>
                  <AnnouncementLoaderItem/>
                </>
              }
            </List>
          </CardContent>
        </Card>
      </Stack>
      <Dialog open={announcementsOpen} fullWidth>
        <NewAnnouncement onCancel={() => setAnnouncementsOpen(false)} onSave={handleSave} />
      </Dialog>
    </Container>
  );
}

export default AnnouncementsList;
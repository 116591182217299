import { Box, Button, Card, CardContent, Container, IconButton, List, ListItem, ListItemButton, ListItemText, Stack } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import SecondaryButton from "../../../controls/SecondaryButton";
import { Institution } from "../../../../models";
import AdminInstitutionDegreeItem from "./AdminInstitutionDegreeItem";

const InstitutionDegrees = ({ institution }: { institution: Institution }) => {
  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2} sx={{py: 1}}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          component={Link}
          to={`/institutions/${institution.id}/institution_degrees/new`}
        >
          Add a Degree
        </SecondaryButton>

        <Card>
          <CardContent>
            <List>
              {institution.institution_degrees?.map(institutionDegree => (
                <AdminInstitutionDegreeItem key={institutionDegree.id} institutionDegree={institutionDegree} />
              ))}
            </List>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  )
}

export default InstitutionDegrees;
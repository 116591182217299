import Assessment from "./Assessment";
import InstitutionDegree from "./InstitutionDegree";

export default interface Institution {
  id?: number;
  name: string;
  institution_avatar?: { url: string };
  city: string;
  state: string;
  country: string;
  assessments?: Assessment[];
  central_office_phone: string;
  central_office_email: string;
  private_institution_notes: string;
  application_type: string;
  application_url: string;
  common_app: string;
  coalition_application_institution: string;
  coalition_application_institution_url: string;
  other: string;
  campus_visit_information: string;
  institution_degrees?: InstitutionDegree[];
  updated_at?: string;
  file?: File;
  archived?: boolean;
}

export const statesOptions = [
  ['Alaska', 'AK'],
  ['Alabama', 'AL'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Lousiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Virginia', 'VA'],
  ['Vermont', 'VT'],
  ['Washington', 'WA'],
  ['Wisconsin', 'WI'],
  ['West Virigina', 'WV'],
  ['Wyoming', 'WY']
];

export const countriesOptions = [
  ['Afghanistan'],
  ['Aland Islands'],
  ['Albania'],
  ['Algeria'],
  ['American Samoa'],
  ['Andorra'],
  ['Angola'],
  ['Anguilla'],
  ['Antarctica'],
  ['Antigua And Barbuda'],
  ['Argentina'],
  ['Armenia'],
  ['Aruba'],
  ['Australia'],
  ['Austria'],
  ['Azerbaijan'],
  ['Bahamas'],
  ['Bahrain'],
  ['Bangladesh'],
  ['Barbados'],
  ['Belarus'],
  ['Belgium'],
  ['Belize'],
  ['Benin'],
  ['Bermuda'],
  ['Bhutan'],
  ['Bolivia'],
  ['Bosnia and Herzegowina'],
  ['Botswana'],
  ['Bouvet Island'],
  ['Brazil'],
  ['British Indian Ocean Territory'],
  ['Brunei Darussalam'],
  ['Bulgaria'],
  ['Burkina Faso'],
  ['Burundi'],
  ['Cambodia'],
  ['Cameroon'],
  ['Canada'],
  ['Cape Verde'],
  ['Cayman Islands'],
  ['Central African Republic'],
  ['Chad'],
  ['Chile'],
  ['China'],
  ['Christmas Island'],
  ['Cocos (Keeling) Islands'],
  ['Colombia'],
  ['Comoros'],
  ['Congo'],
  ['Congo, the Democratic Republic of the'],
  ['Cook Islands'],
  ['Costa Rica'],
  ['Croatia'],
  ['Cuba'],
  ['Cyprus'],
  ['Czech Republic'],
  ['Denmark'],
  ['Djibouti'],
  ['Dominica'],
  ['Dominican Republic'],
  ['Ecuador'],
  ['Egypt'],
  ['El Salvador'],
  ['Equatorial Guinea'],
  ['Eritrea'],
  ['Estonia'],
  ['Ethiopia'],
  ['Falkland Islands (Malvinas)'],
  ['Faroe Islands'],
  ['Fiji'],
  ['Finland'],
  ['France'],
  ['French Guiana'],
  ['French Polynesia'],
  ['French Southern Territories'],
  ['Gabon'],
  ['Gambia'],
  ['Georgia'],
  ['Germany'],
  ['Ghana'],
  ['Gibraltar'],
  ['Greece'],
  ['Greenland'],
  ['Grenada'],
  ['Guadeloupe'],
  ['Guam'],
  ['Guatemala'],
  ['Guernsey'],
  ['Guinea'],
  ['Guinea-Bissau'],
  ['Guyana'],
  ['Haiti'],
  ['Heard and McDonald Islands'],
  ['Holy See (Vatican City State)'],
  ['Honduras'],
  ['Hong Kong'],
  ['Hungary'],
  ['Iceland'],
  ['India'],
  ['Indonesia'],
  ['Iran, Islamic Republic of'],
  ['Iraq'],
  ['Ireland'],
  ['Isle of Man'],
  ['Israel'],
  ['Italy'],
  ['Jamaica'],
  ['Japan'],
  ['Jersey'],
  ['Jordan'],
  ['Kazakhstan'],
  ['Kenya'],
  ['Kiribati'],
  ['Korea, Republic of'],
  ['Kuwait'],
  ['Kyrgyzstan'],
  ['Laos'],
  ['Latvia'],
  ['Lebanon'],
  ['Lesotho'],
  ['Liberia'],
  ['Libyan Arab Jamahiriya'],
  ['Liechtenstein'],
  ['Lithuania'],
  ['Luxembourg'],
  ['Macao'],
  ['Macedonia, The Former Yugoslav Republic Of'],
  ['Madagascar'],
  ['Malawi'],
  ['Malaysia'],
  ['Maldives'],
  ['Mali'],
  ['Malta'],
  ['Marshall Islands'],
  ['Martinique'],
  ['Mauritania'],
  ['Mauritius'],
  ['Mayotte'],
  ['Mexico'],
  ['Micronesia, Federated States of'],
  ['Moldova, Republic of'],
  ['Monaco'],
  ['Mongolia'],
  ['Montenegro'],
  ['Montserrat'],
  ['Morocco'],
  ['Mozambique'],
  ['Myanmar'],
  ['Namibia'],
  ['Nauru'],
  ['Nepal'],
  ['Netherlands'],
  ['Netherlands Antilles'],
  ['New Caledonia'],
  ['New Zealand'],
  ['Nicaragua'],
  ['Niger'],
  ['Nigeria'],
  ['Niue'],
  ['Norfolk Island'],
  ['Northern Mariana Islands'],
  ['Norway'],
  ['Oman'],
  ['Pakistan'],
  ['Palau'],
  ['Palestinian Territory, Occupied'],
  ['Panama'],
  ['Papua New Guinea'],
  ['Paraguay'],
  ['Peru'],
  ['Philippines'],
  ['Pitcairn'],
  ['Poland'],
  ['Portugal'],
  ['Puerto Rico'],
  ['Qatar'],
  ['Reunion'],
  ['Romania'],
  ['Russian Federation'],
  ['Rwanda'],
  ['Saint Barthelemy'],
  ['Saint Helena'],
  ['Saint Kitts and Nevis'],
  ['Saint Lucia'],
  ['Saint Pierre and Miquelon'],
  ['Saint Vincent and the Grenadines'],
  ['Samoa'],
  ['San Marino'],
  ['Sao Tome and Principe'],
  ['Saudi Arabia'],
  ['Senegal'],
  ['Serbia'],
  ['Seychelles'],
  ['Sierra Leone'],
  ['Singapore'],
  ['Slovakia'],
  ['Slovenia'],
  ['Solomon Islands'],
  ['Somalia'],
  ['South Africa'],
  ['South Georgia and the South Sandwich Islands'],
  ['Spain'],
  ['Sri Lanka'],
  ['Sudan'],
  ['Suriname'],
  ['Svalbard and Jan Mayen'],
  ['Swaziland'],
  ['Sweden'],
  ['Switzerland'],
  ['Syrian Arab Republic'],
  ['Taiwan, Province of China'],
  ['Tajikistan'],
  ['Tanzania, United Republic of'],
  ['Thailand'],
  ['Timor-Leste'],
  ['Togo'],
  ['Tokelau'],
  ['Tonga'],
  ['Trinidad and Tobago'],
  ['Tunisia'],
  ['Turkey'],
  ['Turkmenistan'],
  ['Turks and Caicos Islands'],
  ['Tuvalu'],
  ['Uganda'],
  ['Ukraine'],
  ['United Arab Emirates'],
  ['United Kingdom'],
  ['United States'],
  ['United States Minor Outlying Islands'],
  ['Uruguay'],
  ['Uzbekistan'],
  ['Vanuatu'],
  ['Venezuela'],
  ['Viet Nam'],
  ['Virgin Islands, British'],
  ['Virgin Islands, U.S.'],
  ['Wallis and Futuna'],
  ['Western Sahara'],
  ['Yemen'],
  ['Zambia'],
  ['Zimbabwe']
];
import { Button, Dialog, DialogActions, DialogContent, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { DocumentFile, User } from "../../models";
import PaperDropzone from "../controls/PaperDropzone/PaperDropzone";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import FindStudentsDialog from "../filters/FindStudentsDialog";
import StudentsDropdown from "../filters/StudentsDropdown";

const DocumentForm = ({ document, onCancel, onSave, showUsers }: { document?: DocumentFile, onCancel: () => void, onSave: (document: DocumentFile) => void, showUsers: boolean }) => {
  
  const [name, setName] = useState(document?.name || '');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [file, setFile] = useState<File>();
  const [url, setUrl] = useState(document?.url || '');
  const [nameError, setNameError] = useState(false);
  const [findStudentsOpen, setFindStudentsOpen] = useState(false);

  const handleSave = () => {
    setNameError(!name);

    if (!name) {
      return;
    }

    const document: DocumentFile = {
      name,
      user_ids: selectedUsers.map(u => u.id || 0).filter(id => id > 0),
      file,
      url,
    }
    onSave(document);
  }

  const onFileChange = (file: File) => {
    setFile(file);
  }

  const handleAddStudents = (students: User[]) => {
    // setSelectedUsers([...selectedUsers, ...students]);
    setSelectedUsers(students);
    setFindStudentsOpen(false);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          <TextField 
            label="Enter document name" 
            variant="outlined" 
            value={name}
            onChange={(e) => setName(e.target.value)} 
            required
            error={nameError}
            helperText={nameError && "Please name your document"}
          />

          <PaperDropzone onChange={onFileChange} />

          <TextField 
            label="Or link to a file" 
            variant="outlined" 
            value={url}
            onChange={(e) => setUrl(e.target.value)} />

          {showUsers && (
            <Stack spacing={2}>
              <StudentsDropdown value={selectedUsers} onChange={setSelectedUsers} />
              <Button variant="outlined" onClick={() => setFindStudentsOpen(true)}>Find Students</Button>
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
      <Dialog open={findStudentsOpen} fullWidth>
        <FindStudentsDialog students={selectedUsers} onCancel={() => setFindStudentsOpen(false)} onAdd={handleAddStudents} />
      </Dialog>
    </>
  )
}

export default DocumentForm;
import { Box, Card, CardContent, Container, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import SecondaryButton from "../../../controls/SecondaryButton";
import TertiaryButton from "../../../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import DocumentItem from "../../../documents/DocumentItem";
import UploadDocument from "../../documents/UploadDocument";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Assessment, DocumentFile, Folder } from "../../../../models";
import { Link, useParams } from "react-router-dom";
import FolderItem from "../../../documents/FolderItem";
import NewFolder from "../../documents/NewFolder";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import useAuth from "../../../../hooks/useAuth";

const perPage = 20;

const AssessmentDocuments = ({ assessment, onChange }: { assessment: Assessment, onChange: (assessment: Assessment) => void }) => {
  const [open, setOpen] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);

  // const folders = assessment.folders || [];
  // const [documents, setDocuments] = useState(assessment.documents || []);
  // const [documents, setDocuments] = useState<DocumentFile[]>(assessment.documents || []);
  const [parentId, setParentId] = useState<number|undefined>(undefined);
  const [folderName, setFolderName] = useState<string|undefined>(undefined);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const auth = useAuth();
  
  const routeParams = useParams();
  const tempFolderId = routeParams['*']?.split('/')[1];
  const folderId = tempFolderId ? parseInt(tempFolderId) : null;

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const params: string[] = [];
    const filterParams = params.join('&');

    const fetchFolder = async () => {
      setLoading(true);
      const token = auth.token();
      const baseRoute = folderId ? `/folders/${folderId}` : '/folders';
      const url = filterParams ? `${window.SERVER_DATA.domain}/api/v1${baseRoute}?user_id=${assessment.user_id}&assessment_id=${assessment.id}&page=${page}&per_page=${perPage}&${filterParams}` : `${window.SERVER_DATA.domain}/api/v1${baseRoute}?user_id=${assessment.user_id}&assessment_id=${assessment.id}&page=${page}&per_page=${perPage}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      setLoading(false);
      if (response.ok) {
        const { folder_id, name, folders, documents }: { folder_id: number|undefined, name: string|undefined, folders: Folder[], documents: DocumentFile[] } = await response.json();

        if (page === 1) {
          setParentId(folder_id);
          setFolderName(name);
          setFolders(folders);
          setDocuments(documents);
        } else {
          setFolders(prev => {
            const filteredPrev = prev.filter(a => !folders.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...folders];
          });
          setDocuments(prev => {
            const filteredPrev = prev.filter(a => !documents.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...documents];
          });
        }

        if (folders.length < perPage && documents.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchFolder();
  }, [routeParams]);

  const handleSave = (newDocuments: DocumentFile[]) => {
    const filteredDocuments = newDocuments.filter(d => d.folder_id === folderId);
    setDocuments(documents.concat(filteredDocuments));
    setOpen(false);
  }

  const handleSaveFolder = (newFolder: Folder) => {
    setFolders([...folders, newFolder]);
    setOpenFolder(false);
    // onChange({...student, folders: [...folders, newFolder]});
  }

  const handleChangeDocument = (document: DocumentFile) => {
    setDocuments(documents.map(d => d.id === document.id ? document : d));
  }

  const filteredDocuments = documents.filter(d => d.folder_id === folderId);

  const sortedDocuments = filteredDocuments.sort((a, b) => {
    return (a.created_at || new Date()) > (b.created_at || new Date()) ? -1 : 1;
  });

  const backLink = parentId ? `/assessments/${assessment.id}/documents/${parentId}` : `/assessments/${assessment.id}/documents`;
  
  return (
    <Stack spacing={2}>
      <Container sx={{ 
        py: 2, 
        backgroundColor: 'background.default', 
        borderBottomLeftRadius: 20, 
        borderBottomRightRadius: 20,
      }}>
        <Stack direction="row" spacing={2}>
          <SecondaryButton 
            variant="outlined" 
            startIcon={<FileUploadOutlinedIcon/>} 
            sx={{width: 1, maxWidth: 300}}
            onClick={() => setOpen(true)}
          >
            Upload
          </SecondaryButton>
          <SecondaryButton 
            variant="outlined" 
            startIcon={<CreateNewFolderOutlinedIcon/>} 
            sx={{width: 1, maxWidth: 300}}
            onClick={() => setOpenFolder(true)}
          >
            New Folder
          </SecondaryButton>
        </Stack>
      </Container>

      <Box>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              {folderName &&
                <Stack direction="row" alignItems="center">
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    sx={{ mr: 2 }}
                    component={Link}
                    to={backLink}
                  >
                    <ArrowBackOutlinedIcon />
                  </IconButton>
                  <Typography variant="h6">{folderName}</Typography>
                </Stack>
              }
              {folders.map(folder => (
                <FolderItem key={folder.id} folder={folder} root={`/assessments/${assessment.id}/documents`} />
              ))}
              {sortedDocuments.map(document => (
                <DocumentItem key={document.id} document={document} onChange={handleChangeDocument} isAdmin={false} />
              ))}
              {folders.length === 0 && sortedDocuments.length === 0 &&
                <Typography variant="body2" pt={1}>No documents</Typography>
              }
            </Stack>
          </CardContent>
        </Card>
      </Box>

      {assessment.user_id &&
        <>
          <Dialog open={open} fullWidth>
            <UploadDocument 
              userId={assessment.user_id} 
              onCancel={() => setOpen(false)} 
              onSave={handleSave}
              showUsers={false}
              assessmentId={assessment.id}
              folderId={folderId}
            />
            
          </Dialog>
          <Dialog open={openFolder} fullWidth>
            <NewFolder userId={assessment.user_id} assessmentId={assessment.id} onCancel={() => setOpenFolder(false)} onSave={handleSaveFolder} folderId={folderId} />
          </Dialog>
        </>
      }
    </Stack>
  )
}

export default AssessmentDocuments;
import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AdminForm from "./AdminForm";
import { User } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const InviteAdmin = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const createAdmin = async (user: User) => {
    const token = auth.token();
    // const body = {
    //   user: {
    //     first_name: admin.first_name,
    //     last_name: admin.last_name,
    //     email: admin.email,
    //     biography: admin.biography,
    //   }
    // }
    const formData = new FormData();
    if (user.file) formData.append('avatar', user.file);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('email', user.email);
    formData.append('biography', user.biography);
    formData.append('superadmin', user.superadmin ? 'true' : 'false');
    formData.append('notify', user.notify ? 'true' : 'false');
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins`, {
      method: 'POST',
      headers: new Headers({
        // 'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      // body: JSON.stringify(body),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      navigate(`/config/admins`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/config/admins`);
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/config/admins`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Administrators
            </Typography>
            <Typography variant="subtitle2">
              Invite Administrator
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <AdminForm onCancel={handleCancel} onSave={createAdmin} />
      </Box>
    </Box>
  )
}

export default InviteAdmin;
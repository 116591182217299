import { Box, Chip, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Link, Outlet } from "react-router-dom";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import useRouteMatch from "../../hooks/useRouteMatch";
import { useState } from "react";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Background from "../controls/Background";
import logo from '../logo.svg';
import StyledDrawer from "../controls/StyledDrawer";

const AdminLayout = ({ tasksCount }: { tasksCount: number }) => {
  const routeMatch = useRouteMatch(['/', '/students/*', '/tasks/*', '/documents/*', '/config/*']);
  const [value, setValue] = useState(routeMatch?.pattern?.path || '/');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const drawerWidth = isMobile ? 0 : 300;

  return (
    <Background sx={{ display: 'flex' }}>
      <StyledDrawer
        variant="permanent"
        anchor="left"
        sx={{ 
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Box sx={{ p: '8px' }}>
          <Link to="/" onClick={() => setValue('/')}>
            <img src={logo} alt="ArtsBridge College Guide" width="270" height="65" />
          </Link>
        </Box>

        <List>
          <ListItem>
            <ListItemButton selected={value === '/'} component={Link} to="/" onClick={() => setValue('/')}>
              <ListItemIcon>
                <HomeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected={value === '/students/*'} component={Link} to="/students" onClick={() => setValue('/students/*')}>
              <ListItemIcon>
                <PeopleAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Students" />
            </ListItemButton>
          </ListItem>
          <ListItem
            secondaryAction={
              <>
                {tasksCount > 0 &&
                  <Chip label={tasksCount} color="error" />
                }
              </>
            }
          >
            <ListItemButton selected={value === '/tasks/*'} component={Link} to="/tasks" onClick={() => setValue('/tasks/*')}>
              <ListItemIcon>
                <InventoryOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Tasks/Events" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected={value === '/documents/*'} component={Link} to="/documents" onClick={() => setValue('/documents/*')}>
              <ListItemIcon>
                <DescriptionOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Documents" />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton selected={value === '/config/*'} component={Link} to="/config" onClick={() => setValue('/config/*')}>
              <ListItemIcon>
                <TuneOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Configuration" />
            </ListItemButton>
          </ListItem>
        </List>
      </StyledDrawer>

      <Outlet />
    </Background>
  )
}

export default AdminLayout;
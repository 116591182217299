import { Box, Card, CardContent, Container, Dialog, FormControl, IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import DocumentItem from "./DocumentItem";
import UploadDocument from "../admin/documents/UploadDocument";
import TertiaryButton from "../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SecondaryButton from "../controls/SecondaryButton";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { Collaboration, DocumentFile, SharedDocument, User } from "../../models";
import FilterDocuments, { FilterData } from "../filters/FilterDocuments";
import SortDocuments from "../sort/SortDocuments";
import SortOptions from "../sort/SortOptions";
import Folder from "../../models/Folder";
import FolderItem from "./FolderItem";
import NewFolder from "../admin/documents/NewFolder";
import { Link, useParams, useSearchParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import useUser from "../../hooks/useUser";
import SharedDocumentItem from "./SharedDocumentItem";
import SearchIcon from '@mui/icons-material/Search';
import SearchField from "../controls/SearchField";
import ViewDocument from "./ViewDocument";

const perPage = 20;

const Documents = ({ isAdmin }: { isAdmin: boolean }) => {
  const auth = useAuth();
  const [parentId, setParentId] = useState<number|undefined>(undefined);
  const [folderName, setFolderName] = useState<string|undefined>(undefined);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [open, setOpen] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);
  const [sharedDocuments, setSharedDocuments] = useState<SharedDocument[]>([]);
  const [invitations, setInvitations] = useState<Collaboration[]>([]);
  const [documentGroup, setDocumentGroup] = useState('me');

  const currentUser = useUser();

  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<FilterData>();
  const handleSaveFilters = (filters: FilterData) => {
    setPage(1);
    setFilters(filters);
    setOpenFilters(false);
  }

  const [openSort, setOpenSort] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>();
  const handleSaveSort = (options: SortOptions) => {
    setPage(1);
    setSortOptions(options);
    setOpenSort(false);
  }

  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const folderId = routeParams.id ? parseInt(routeParams.id) : null;

  const [openDocument, setOpenDocument] = useState(false)
  const documentId = searchParams.get('document') ? parseInt(searchParams.get('document')) : null;
  useEffect(() => {
    if (documentId) {
      setOpenDocument(true)
    }
  }, [documentId])

  useEffect(() => {
    const abortController = new AbortController();

    const params: string[] = [];

    if (sortOptions) {
      params.push(`sort=${sortOptions.column}`);
      params.push(`direction=${sortOptions.direction}`);
    }

    if (filters) {
      Object.keys(filters).map((key, index) => {
        const value = filters[key];
        if (value) {
          if (key === 'users') {
            params.push(`user_ids=${value.map(u => u.id).join(',')}`);
          } else if (key === 'shared_users') {
            params.push(`shared_user_ids=${value.map(u => u.id).join(',')}`);
          } else {
            params.push(`${key}=${value}`);
          }
        }
      })
    }

    const filterParams = params.join('&');

    const fetchFolder = async () => {
      setLoading(true);

      console.log('fetch folder', currentUser?.userId, auth.user.id, documentGroup)
      const userId = currentUser?.userId || auth.user.id;
      const finalUserId = documentGroup === 'me' || documentGroup === 'all' ? userId : documentGroup;

      const token = auth.token();
      const baseRoute = folderId ? `/folders/${folderId}` : '/folders';
      const url = filterParams ? 
        `${window.SERVER_DATA.domain}/api/v1${baseRoute}?q=${searchQuery}&user_id=${finalUserId}&page=${page}&per_page=${perPage}&${filterParams}` : 
        `${window.SERVER_DATA.domain}/api/v1${baseRoute}?q=${searchQuery}&user_id=${finalUserId}&page=${page}&per_page=${perPage}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      setLoading(false);
      if (response.ok) {
        const { folder_id, name, folders, documents }: { folder_id: number|undefined, name: string|undefined, folders: Folder[], documents: DocumentFile[] } = await response.json();

        if (page === 1) {
          setParentId(folder_id);
          setFolderName(name);
          setFolders(folders);
          setDocuments(documents);
        } else {
          setFolders(prev => {
            const filteredPrev = prev.filter(a => !folders.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...folders];
          });
          setDocuments(prev => {
            const filteredPrev = prev.filter(a => !documents.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...documents];
          });
        }

        if (folders.length < perPage && documents.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchFolder();

    const fetchSharedDocuments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/shared_documents?q=${searchQuery}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      if (response.ok) {
        const json = await response.json();
        setSharedDocuments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchSharedDocuments();

    return () => {
      abortController.abort();
    };

  }, [auth, currentUser, filters, sortOptions, routeParams, searchQuery, documentGroup]);

  useEffect(() => {
    const fetchInvitations = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/invitations`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setInvitations(json);
    }

    fetchInvitations();
  }, [])
  
  const userId = documentGroup === 'me' || documentGroup === 'all' ? (currentUser?.userId || auth.user.id) : documentGroup;

  const handleSave = (newDocuments: DocumentFile[]) => {
    const filteredDocuments = newDocuments.filter(d => d.user_id === userId);
    setDocuments(documents.concat(filteredDocuments));
    setOpen(false);
  }

  const handleSaveFolder = (newFolder: Folder) => {
    setFolders([...folders, newFolder]);
    setOpenFolder(false);
  }

  const handleChangeDocument = (document: DocumentFile) => {
    setDocuments(documents.map(d => d.id === document.id ? document : d));
  }

  const handleChangeSharedDocument = (sharedDocument: SharedDocument) => {
    // TODO: implement editing shared documents
  }

  const handleChangeDocumentGroup = (value: string) => {
    setReachedEnd(false);
    setPage(1);
    setDocumentGroup(value);
  }

  const backLink = parentId ? `/documents/${parentId}` : '/documents';

  const searchedDocuments = documents.filter(d => {
    if (searchQuery === '') return true;
    return d.name.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const filteredDocuments = searchedDocuments.filter(d => d.folder_id === folderId);

  const sortedDocuments = filteredDocuments.sort((a, b) => {
    // return (a.created_at || new Date()) > (b.created_at || new Date()) ? -1 : 1;

    let sort = 0;
    if (sortOptions?.column === 'documents.name') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.name || '').localeCompare(a.name || '');
      } else {
        sort = sort || (a.name || '').localeCompare(b.name || '');
      }
    } else if (sortOptions?.column === 'documents.created_at') {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (a.created_at || new Date()) > (b.created_at || new Date()) ? -1 : 1;
      } else {
        sort = sort || (b.created_at || new Date()) > (a.created_at || new Date()) ? -1 : 1;
      }
    } else {
      if (sortOptions?.direction === 'desc') {
        sort = sort || (b.created_at || new Date()) > (a.created_at || new Date()) ? -1 : 1;
      } else {
        sort = sort || (a.created_at || new Date()) > (b.created_at || new Date()) ? -1 : 1;
      }
    }
    return sort;
  });

  const filteredInvitations = invitations.filter(invitation => invitation.user?.id);

  return (
    <Box>
      <Stack spacing={1} sx={{
        pb: 2,
        px: 2,
        backgroundColor: 'background.default',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      }}>
        <Stack spacing={2} direction="row">
          <TertiaryButton
            variant="text"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => setOpenFilters(true)}
          >
            Filter Documents
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<ImportExportOutlinedIcon />}
            onClick={() => setOpenSort(true)}
          >
            Sort Documents
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (openSearch) {
                setOpenSearch(false);
                setSearchQuery('');
              } else {
                setOpenSearch(true);
              }
            }}
          >
            Search Documents
          </TertiaryButton>
        </Stack>
        {openSearch &&
          <SearchField label="Search Documents" onSearch={setSearchQuery} realTime={true} />
        }
      </Stack>

      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2} flexGrow={1}>
              <SecondaryButton
                variant="outlined"
                startIcon={<FileUploadOutlinedIcon />}
                sx={{ width: 1, maxWidth: 300 }}
                onClick={() => setOpen(true)}
              >
                Upload
              </SecondaryButton>
              <SecondaryButton
                variant="outlined"
                startIcon={<CreateNewFolderOutlinedIcon />}
                sx={{ width: 1, maxWidth: 300 }}
                onClick={() => setOpenFolder(true)}
              >
                New Folder
              </SecondaryButton>
            </Stack>

            {filteredInvitations.length > 0 &&
              <FormControl>
                <Select
                  sx={{ background: 'white' }}
                  value={documentGroup}
                  onChange={(e) => handleChangeDocumentGroup(e.target.value)}
                  size="small"
                >
                  {/* <MenuItem value="all">All Documents</MenuItem> */}
                  <MenuItem value="me">My Documents</MenuItem>
                  {filteredInvitations.map((invitation) => 
                    <MenuItem key={invitation.id} value={invitation.user?.id}>{`${invitation.user?.first_name} ${invitation.user?.last_name}`} Documents</MenuItem>
                  )}
                </Select>
              </FormControl>
            }
          </Stack>
          <Stack spacing={2}>
            <Box>
              <Typography variant="overline">MY DOCUMENTS</Typography>
              <Card>
                <CardContent>
                  {folderName &&
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}
                        component={Link}
                        to={backLink}
                      >
                        <ArrowBackOutlinedIcon />
                      </IconButton>
                      <Typography variant="h6">{folderName}</Typography>
                    </Stack>
                  }
                  {folders.map(folder => (
                    <FolderItem key={folder.id} folder={folder} />
                  ))}
                  {sortedDocuments.map(document => (
                    <DocumentItem key={document.id} document={document} onChange={handleChangeDocument} isAdmin={isAdmin} />
                  ))}
                  {folders.length === 0 && sortedDocuments.length === 0 &&
                    <Typography variant="body2" pt={1}>No documents</Typography>
                  }
                </CardContent>
              </Card>
            </Box>

            {(!filters || filters?.shared_users.length === 0) &&
              <Box>
                <Typography variant="overline">SHARED WITH ME</Typography>
                <Card>
                  <CardContent>
                    {sharedDocuments.map(sharedDocument => (
                      <SharedDocumentItem key={sharedDocument.id} sharedDocument={sharedDocument} onChange={handleChangeSharedDocument} isAdmin={isAdmin} />
                    ))}
                    {sharedDocuments.length === 0 &&
                      <Typography variant="body2" pt={1}>No shared documents</Typography>
                    }
                  </CardContent>
                </Card>
              </Box>
            }
          </Stack>

          {!reachedEnd &&
            <SecondaryButton variant="outlined" onClick={loadNextPage}>
              {isLoading ? 'Loading...' : 'Load More'}
            </SecondaryButton>
          }
        </Stack>
      </Container>

      <Dialog open={open} fullWidth>
        <UploadDocument userId={userId} onCancel={() => setOpen(false)} onSave={handleSave} showUsers={isAdmin} folderId={folderId} />
      </Dialog>

      <Dialog open={openFolder} fullWidth>
        <NewFolder userId={userId} onCancel={() => setOpenFolder(false)} onSave={handleSaveFolder} folderId={folderId} />
      </Dialog>

      <Dialog open={openFilters} fullWidth>
        <FilterDocuments title="Filter Documents" filters={filters} onCancel={() => setOpenFilters(false)} onSave={handleSaveFilters} />
      </Dialog>

      <Dialog open={openSort} fullWidth>
        <SortDocuments title="Sort Documents" options={sortOptions} onCancel={() => setOpenSort(false)} onSave={handleSaveSort} />
      </Dialog>
      
      <Dialog open={openDocument} fullWidth>
        <ViewDocument documentId={documentId} onClose={() => setOpenDocument(false)} />
      </Dialog>
    </Box>
  )
}

export default Documents;
import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import DegreeForm from "./DegreeForm";
import { Degree } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const EditDegree = () => {
  const params = useParams();
  const [degree, setDegree] = useState<Degree>();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const fetchDegree = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setDegree(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchDegree();
  }, []);

  const updateDegree = async (degree: Degree) => {
    const token = auth.token();
    const body = {
      name: degree.name,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees/${params.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      navigate(`/degrees/${params.id}`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/degrees/${params.id}`);
  }

  if (!degree) {
    return (<div/>)
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/degrees/${params.id}`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Degrees
            </Typography>
            <Typography variant="subtitle2">
              {degree.name}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <DegreeForm degree={degree} onCancel={handleCancel} onSave={updateDegree} />
      </Box>
    </Box>
  )
}

export default EditDegree;
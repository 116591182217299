import { Avatar, Box, Breadcrumbs, Card, CardContent, CardHeader, Container, Dialog, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Announcement, Notification } from "../../models";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { formatDistance, parseISO, set } from "date-fns";
import RichTextViewer from "../controls/RichTextViewer";
import ResponsiveAppBar from "../controls/ResponsiveAppBar";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

const AnnouncementDetail = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  
  const [announcement, setAnnouncement] = useState<Announcement>();

  useEffect(() => {
    const fetchAnnouncement = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/announcements/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAnnouncement(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchAnnouncement();
  }, []);

  
  if (!auth.user) return null;
  if (!announcement) return null;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Announcments
            </Typography>
            <Typography variant="subtitle2">
              Sent {formatDistance(parseISO(announcement.created_at), new Date())} ago
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>

        <Container sx={{ py: 2 }}>
          <Stack spacing={2}>
            <Card>
              <CardContent sx={{ p: 0 }}>
                <Box key={announcement.id}>
                  <ListItem>
                    <ListItemText>
                      <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
                        <Stack sx={{ flexGrow: 1 }}>
                          <Typography variant="body2" color="text.secondary">Sent {formatDistance(parseISO(announcement.created_at), new Date())} ago</Typography>
                          <Typography variant="subtitle1">{announcement.title}</Typography>
                          {/* <Typography variant="body2">{announcement.message}</Typography> */}
                          <RichTextViewer value={announcement.message} />
                        </Stack>
                      </Stack>
                    </ListItemText>
                  </ListItem>

                  <Divider />
                  {announcement.notifications.length > 0 ? (
                    <ListItem>
                      <ListItemText>
                        <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
                          <Stack sx={{ flexGrow: 1 }}>
                            <Typography variant="body2" color="text.secondary">Sent to:</Typography>
                            {announcement.notifications.map(notification => (
                              <CardHeader
                                avatar={<Avatar alt={`${notification.user.first_name} ${notification.user.last_name}`} src={notification.user.avatar?.url} />}
                                title={`${notification.user.first_name} ${notification.user.last_name}`}
                                subheader={notification.deliveries.length > 0 ? 'Read' : 'Unread'}
                              />
                            ))}
                          </Stack>
                        </Stack>
                      </ListItemText>
                    </ListItem>
                  ): (
                    <ListItem>
                      <ListItemText>
                        <Stack direction="row" sx={{ width: 1, alignItems: 'start' }}>
                          <Stack sx={{ flexGrow: 1 }}>
                            <Typography variant="body2" color="text.secondary">No recipients</Typography>
                          </Stack>
                        </Stack>
                      </ListItemText>
                    </ListItem>
                  )}

                </Box>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default AnnouncementDetail;
import * as React from "react";
import { InstitutionDegree } from "../../../../../../models";
import EditCollegeDatesForm from "./EditCollegeDatesForm";
import useAuth from "../../../../../../hooks/useAuth";

const EditCollegeDates = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  const auth = useAuth();
  
  const updateInstitutionDegree = async (institutionDegree: InstitutionDegree) => {
    const token = auth.token();

    const body = {
      early_action: institutionDegree.early_action,
      early_action_date: institutionDegree.early_action_date,
      early_decision: institutionDegree.early_decision,
      early_decision_date: institutionDegree.early_decision_date,
      other_decision: institutionDegree.other_decision,
      other_decision_details: institutionDegree.other_decision_details,
      other_decision_date: institutionDegree.other_decision_date,
      other_decision_two: institutionDegree.other_decision_two,
      other_decision_details_two: institutionDegree.other_decision_details_two,
      other_decision_date_two: institutionDegree.other_decision_date_two,
      regular_deadline: institutionDegree.regular_deadline,
    }
    
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${institutionDegree.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onSave({...institutionDegree, ...body});
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <EditCollegeDatesForm institutionDegree={institutionDegree} onCancel={handleCancel} onSave={updateInstitutionDegree} />
  )
}

export default EditCollegeDates;
import { Box, CardHeader, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TaskTemplate } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const TaskTemplateDetail = () => {
  const params = useParams();
  const [taskTemplate, setTaskTemplate] = useState<TaskTemplate>();
  const [value, setValue] = useState('1');
  const auth = useAuth();
  
  useEffect(() => {
    const fetchTaskTemplate = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setTaskTemplate(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchTaskTemplate();
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!taskTemplate) {
    return <div/>
  }
  
  return (
    <>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to="/config/tasks"
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Typography variant="subtitle2">
            Tasks
          </Typography>
        </Toolbar>
      </ResponsiveAppBar>
      
      <Box>
        <Toolbar />
        <CardHeader
          title={taskTemplate.title}
          action={
            <IconButton component={Link} to={`/task_templates/${taskTemplate.id}/edit`}>
              <EditOutlinedIcon/>
            </IconButton>
          }
        />
      </Box>
    </>
  )
}

export default TaskTemplateDetail;
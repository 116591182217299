import { Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import * as React from "react";
import SecondaryButton from "../../../controls/SecondaryButton";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import CollegeCollection from "../../../../models/CollegeCollection";
import PrimaryButton from "../../../controls/PrimaryButton";
import useAuth from "../../../../hooks/useAuth";

const AdminCollegeCollections = () => {

  const [collegeCollections, setCollegeCollections] = useState<CollegeCollection[]>([]);
  const [addCollectionOpen, setAddCollectionOpen] = useState(false);
  const [name, setName] = useState('');
  const auth = useAuth();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchCollections = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/college_collections`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      if (response.ok) {
        const json: CollegeCollection[] = await response.json();
        console.log(json);
          setCollegeCollections(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchCollections();

    return () => {
      abortController.abort();
    };
  }, [])

  const handleSave = async () => {
    const token = auth.token();
    const body = {
      name: name,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/college_collections`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setCollegeCollections([...collegeCollections, json]);

      setName('');
      setAddCollectionOpen(false);

    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    setName('');
    setAddCollectionOpen(false);
  }

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          // component={Link}
          // to={`/institutions/new`}
          onClick={() => setAddCollectionOpen(true)}
        >
          Add a Collection
        </SecondaryButton>

        <Card>
          <CardContent>
          <Stack spacing={1}>
              <Stack spacing={2} direction="row">
                <Stack direction="row" spacing={2} sx={{flexGrow: 1}}>
                  <Typography variant="h6">Collections</Typography>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '50%' }}>Name</TableCell>
                  <TableCell>Programs</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collegeCollections.map(collection => {
                  const degreeList = collection.college_collection_members?.map(m => `${m.institution_degree?.institution?.name} ${m.institution_degree?.degree?.name}`).join(', ');
                  return (
                    <TableRow key={collection.id}>
                      <TableCell>
                        <Link to={`/collections/${collection.id}`}>{collection.name}</Link>
                      </TableCell>
                      <TableCell>
                        {degreeList}
                      </TableCell>        
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Stack>
      <Dialog open={addCollectionOpen} fullWidth>
        <DialogContent>
          <Stack spacing={2}>
            <TextField 
              label="Name" 
              variant="outlined" 
              value={name}
              onChange={(e) => setName(e.target.value)}
              required />
          </Stack>
        </DialogContent>
        <DialogActions>
          <SecondaryButton variant="outlined" sx={{width: 1}} onClick={handleCancel}>Cancel</SecondaryButton>
          <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default AdminCollegeCollections;

import { Avatar, Card, CardActionArea, CardHeader, Container, IconButton, Stack } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Institution } from "../../../../models";

const InstitutionAssessments = ({ institution }: { institution: Institution }) => {
  return (
    <Container sx={{py: 2}}>
      <Stack spacing={2}>
        {institution.assessments?.filter(assessment => !assessment.inactive && !assessment.user?.archived).map(assessment => (
          <Card key={assessment.id}>
            <CardActionArea component={Link} to={`/assessments/${assessment.id}`}>
              <CardHeader
                avatar={<Avatar alt={`${assessment.user?.first_name} ${assessment.user?.last_name}`} src={assessment.user?.avatar?.url} />}
                action={
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                }
                title={`${assessment.user?.first_name} ${assessment.user?.last_name}`}
                subheader={assessment.institution_degree?.degree?.name}
              />
            </CardActionArea>
          </Card>
        ))}
      </Stack>
    </Container>
  )
}

export default InstitutionAssessments;
import { Checkbox, Stack, Typography } from "@mui/material";
import React from "react";
import { Folder } from "../../models";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';

const SelectableFolder = ({folder, selectedId, onCheckboxClick}: { folder: Folder, selectedId: number|undefined, onCheckboxClick: (folder: Folder|undefined) => void }) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
          <Checkbox
            color="primary"
            checked={folder.id === selectedId}
            onClick={(e) => {
              if (folder.id === selectedId) {
                onCheckboxClick(undefined)
              } else {
                onCheckboxClick(folder)
              }
            }}
          />
        <FolderOutlinedIcon color="primary" />
        <Typography variant="subtitle1">{folder.name}</Typography>
      </Stack>
      <Stack sx={{pl: 2}}>
        {folder.folders?.map(f2 => (
          <SelectableFolder key={f2.id} folder={f2} selectedId={selectedId} onCheckboxClick={onCheckboxClick} />
        ))}
      </Stack>
    </Stack>
  )
}

export default SelectableFolder;

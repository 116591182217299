import { Box, Card, Link, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../controls/PrimaryButton";
import logo from '../logo.svg';
import useAuth from "../../hooks/useAuth";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  const [email, setEmail] = useState('');
  const [error, setError] = useState<string|undefined>();
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const token = auth.token();
    const body = {
      email
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/passwords`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      // navigate(`/login`);
      setSuccess(true);
    } else {
      const json = await response.json();
      console.error(json);
      setError(json.errors[0].detail);
    }
  }

  if (success) {
    return (
      <Box sx={{ 
        p: 2, 
        position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, 
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        backgroundColor: 'grey.100',
      }}>
        <Card sx={{ width: 1, p: 2, maxWidth: 400 }}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} sx={{alignItems: 'center'}}>
              <img src={logo} alt="ArtsBridge College Guide" width="270" height="65" />
              <Typography variant="h5">Check your inbox!</Typography>

              <Typography variant="body1">We've sent you an email with a link to reset your password. If you don't see the password in your inbox, please make sure to check your Spam folder.</Typography>
  
              <Link href="/login">Back to sign in</Link>
            </Stack>
          </form>
        </Card>
      </Box>
    )
  }

  return (
    <Box sx={{ 
      p: 2, 
      position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, 
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: 'grey.100',
    }}>
      <Card sx={{ width: 1, p: 2, maxWidth: 400 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{alignItems: 'center'}}>
            <img src={logo} alt="ArtsBridge College Guide" width="270" height="65" />
            <Typography variant="h5">Forgot your password?</Typography>

            <TextField
              name="email"
              label="Email Address"
              type="email"
              autoComplete="email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!error}
              helperText={error}
              required
            />

            <PrimaryButton 
              variant="contained" 
              type="submit"
              fullWidth
            >
              Send password reset instructions
            </PrimaryButton>

            <Link href="/login">Back to sign in</Link>
          </Stack>
        </form>
      </Card>
    </Box>
  )
}

export default ForgotPassword;
import { Box, Card, CardContent, Container, Dialog, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import TaskItem from "../../tasks/TaskItem";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import AddTask from "../../student/tasks/AddTask";
import useAuth from "../../../hooks/useAuth";
import { format, parse } from "date-fns";
import TertiaryButton from "../../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SecondaryButton from "../../controls/SecondaryButton";
import { Task } from "../../../models";
import AdminAddTask from './AdminAddTask';
import SortOptions from "../../sort/SortOptions";
import SortTasks from "../../sort/SortTasks";
import FilterTasks, { FilterData } from "../../filters/FilterTasks";
import useUser from "../../../hooks/useUser";
import TemplatedTaskItem from "../../tasks/TemplatedTaskItem";
import SearchIcon from '@mui/icons-material/Search';
import SearchField from "../../controls/SearchField";
import { useSearchParams } from "react-router-dom";
import ViewTask from "../../tasks/ViewTask";

const perPage = 20;

const AdminTasks = ({ isAdmin, onChange }: { isAdmin: boolean, onChange?: () => void }) => {
  const auth = useAuth();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [open, setOpen] = useState(false);
  const [taskGroup, setTaskGroup] = useState('all');

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState<FilterData>();
  const handleSaveFilters = (filters: FilterData) => {
    setReachedEnd(false);
    setPage(1);
    setFilters(filters);
    setOpenFilters(false);
  }

  const [openSort, setOpenSort] = useState(false);
  const [sortOptions, setSortOptions] = useState<SortOptions>();
  const handleSaveSort = (options: SortOptions) => {
    setReachedEnd(false);
    setPage(1);
    setSortOptions(options);
    setOpenSort(false);
  }

  const handleChangeTaskGroup = (value: string) => {
    setReachedEnd(false);
    setPage(1);
    setTaskGroup(value);
  }

  const currentUser = useUser();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const [openTask, setOpenTask] = useState(false)
  const taskId = searchParams.get('task') ? parseInt(searchParams.get('task')) : null;
  useEffect(() => {
    if (taskId) {
      setOpenTask(true)
    }
  }, [taskId])

  useEffect(() => {
    const abortController = new AbortController();

    const params: string[] = [];

    if (sortOptions) {
      params.push(`sort=${sortOptions.column}`);
      params.push(`direction=${sortOptions.direction}`);
    }

    if (filters) {
      Object.keys(filters).map((key, index) => {
        const value = filters[key];
        if (value) {
          if (key === 'college') {
            params.push(`${key}=${value?.id || ''}`);
          } else {
            params.push(`${key}=${value}`);
          }
        }
      })
    }

    const filterParams = params.join('&');

    const fetchTasks = async () => {
      setLoading(true);

      let userId = currentUser?.userId || undefined;
      if (filters && filters['user']) {
        userId = filters['user'].id;
      }

      const token = auth.token();

      let url = `${window.SERVER_DATA.domain}/api/v1/tasks`;
      if (taskGroup === 'me') {
        url = `${url}?q=${searchQuery}&page=${page}&per_page=${perPage}&user_id=${auth.user.id}`;
      } else if (userId) {
        url = `${url}/all?q=${searchQuery}&page=${page}&per_page=${perPage}&user_id=${userId}`;
      } else {
        url = `${url}/all?q=${searchQuery}&page=${page}&per_page=${perPage}`;
      }
      if (filterParams) {
        url = `${url}&${filterParams}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      setLoading(false);
      if (response.ok) {
        const json: Task[] = await response.json();
        console.log(json);
        if (page === 1) {
          setTasks(json);
        } else {
          setTasks(prev => {
            const filteredPrev = prev.filter(a => !json.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...json];
          });
        }
        if (json.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchTasks();

    return () => {
      abortController.abort();
    };
  }, [auth, currentUser, filters, sortOptions, taskGroup, page, searchQuery]);

  let userId = currentUser?.userId || auth.user.id;
  if (filters && filters['user']) {
    userId = filters['user'].id;
  }

  const handleSave = (newTasks: Task[]) => {
    const filteredTasks = newTasks.filter(t => t.user_id === userId);
    setTasks(tasks.concat(filteredTasks));
    setOpen(false);
  }

  const updateTask = (newTask: Task) => {
    setTasks(tasks.map(t => t.id === newTask.id ? newTask : t));
    if (onChange) {
      onChange();
    }
  }

  const filteredTasks = taskGroup === 'me' ? tasks.filter(task => task.user_id === auth.user.id) : tasks;

  const completedTasks = filteredTasks.filter(task => task.completed_at)
  const incompleteTasks = filteredTasks.filter(task => !task.completed_at)

  const generalTasks = incompleteTasks.filter(task => !task.due_on).sort((a, b) => (a.order || 0) - (b.order || 0));

  const dueTasks: { [key: string]: Task[] } = {};
  for (const task of incompleteTasks) {
    if (task.due_on) {
      (dueTasks[task.due_on] ||= []).push(task);
    }
  }
  const sortableDueTasks = Object.entries(dueTasks);
  const sortedDueTasksArray = sortableDueTasks.sort(([a,], [b,]) => a.localeCompare(b));
  const sortedDueTasks = Object.fromEntries(sortedDueTasksArray);

  const showUser = taskGroup === 'all';

  return (
    <Box>
      <Stack spacing={1} sx={{
        pb: 2,
        px: 2,
        backgroundColor: 'background.default',
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
      }}>
        <Stack spacing={2} direction="row">
          <TertiaryButton
            variant="text"
            startIcon={<FilterAltOutlinedIcon />}
            onClick={() => setOpenFilters(true)}
          >
            Filter Tasks/Events
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<ImportExportOutlinedIcon />}
            onClick={() => setOpenSort(true)}
          >
            Sort Tasks/Events
          </TertiaryButton>
          <TertiaryButton
            variant="text"
            startIcon={<SearchIcon />}
            onClick={() => {
              if (openSearch) {
                setOpenSearch(false);
                setSearchQuery('');
              } else {
                setOpenSearch(true);
              }
            }}
          >
            Search Tasks/Events
          </TertiaryButton>
        </Stack>
        {openSearch &&
          <SearchField label="Search Tasks/Events" onSearch={setSearchQuery} realTime={true} />
        }
      </Stack>

      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <SecondaryButton
              variant="outlined"
              startIcon={<ControlPointOutlinedIcon />}
              sx={{ width: 1, maxWidth: 300 }}
              onClick={() => setOpen(true)}
            >
              New Task/Event
            </SecondaryButton>

            <FormControl>
              <Select
                sx={{ background: 'white' }}
                value={taskGroup}
                onChange={(e) => handleChangeTaskGroup(e.target.value)}
                size="small"
              >
                <MenuItem value="all">All Tasks/Events</MenuItem>
                <MenuItem value="me">My Tasks/Events</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          {Object.keys(sortedDueTasks).map((key, index) => (
            <Box key={key}>
              {sortedDueTasks[key].filter(t => !t.deleted_at).length > 0 &&
                <>
                  <Typography variant="overline">{format(parse(key, 'yyyy-MM-dd', new Date()), "MMM d, yyyy")}</Typography>
                  <Card>
                    <CardContent>
                      <Stack spacing={2}>
                        {sortedDueTasks[key].map(task => (
                          (task.task_template_id ? (
                            <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} showUser={showUser} />
                          ): (
                            <TaskItem key={task.id} task={task} onChange={updateTask} showUser={showUser} />
                          ))
                        ))}
                      </Stack>
                    </CardContent>
                  </Card>
                </>
              }
            </Box>
          ))}

          <Box>
            <Typography variant="overline">GENERAL TASKS/EVENTS</Typography>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  {generalTasks.map(task => (
                    (task.task_template_id ? (
                      <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} showUser={showUser} />
                    ): (
                      <TaskItem key={task.id} task={task} onChange={updateTask} showUser={showUser} />
                    ))
                  ))}
                  {generalTasks.length === 0 && (
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      You're all caught up.
                    </Typography>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Box>

          {completedTasks.length > 0 &&
            <Box>
              <Typography variant="overline">COMPLETED TASKS/EVENTS</Typography>
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    {completedTasks.map(task => (
                      (task.task_template_id ? (
                        <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} showUser={showUser} />
                      ): (
                        <TaskItem key={task.id} task={task} onChange={updateTask} showUser={showUser} />
                      ))
                    ))}
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          }

          {!reachedEnd &&
            <SecondaryButton variant="outlined" onClick={loadNextPage}>
              {isLoading ? 'Loading...' : 'Load More'}
            </SecondaryButton>
          }

          <Dialog open={open} fullWidth>
            {isAdmin ? (
              <AdminAddTask onCancel={() => setOpen(false)} onSave={handleSave} showUsers={isAdmin} />
            ) : (
              <AddTask onCancel={() => setOpen(false)} onSave={handleSave} showUsers={isAdmin} availableUsers={[]} />
            )}
          </Dialog>
        </Stack>
      </Container>

      <Dialog open={openFilters} fullWidth>
        <FilterTasks title="Filter Tasks/Events" filters={filters} onCancel={() => setOpenFilters(false)} onSave={handleSaveFilters} showUsers={showUser} showColleges={true} />
      </Dialog>

      <Dialog open={openSort} fullWidth>
        <SortTasks title="Sort Tasks/Events" options={sortOptions} onCancel={() => setOpenSort(false)} onSave={handleSaveSort} />
      </Dialog>

      <Dialog open={openTask} fullWidth>
        <ViewTask taskId={taskId} onClose={() => setOpenTask(false)} onChange={updateTask} />
      </Dialog>
    </Box>
  )
}

export default AdminTasks;
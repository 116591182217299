import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import StudentForm from "./StudentForm";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { User } from "../../../models";
import ResponsiveAppBar from "../../controls/ResponsiveAppBar";
import { useState } from "react";
import useAuth from "../../../hooks/useAuth";

const InviteStudent = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  
  const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);

  const createStudent = async (user: User) => {
    setErrorMessage(undefined);

    const token = auth.token();
    // const body = {
    //   user: {
    //     first_name: student.first_name,
    //     last_name: student.last_name,
    //     email: student.email,
    //     biography: student.biography,
    //     high_school_graduation_year: student.high_school_graduation_year,
    //     paid_student: student.paid_student,
    //     transfer_student: student.transfer_student,
    //     gap_year: student.gap_year,
    //     transfer_deadline: student.transfer_deadline,
    //     transfer_website: student.transfer_website,
    //     transfer_notes: student.transfer_notes,
    //     notes_from: student.notes_from,
    //   }
    // }
    const formData = new FormData();
    if (user.file) formData.append('avatar', user.file);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('email', user.email);
    formData.append('phone', user.phone || '');
    formData.append('biography', user.biography);
    formData.append('high_school_graduation_year', user.high_school_graduation_year?.toString() || '');
    formData.append('actual_graduation_year', user.actual_graduation_year?.toString() || '');
    formData.append('paid_student', user.paid_student?.toString() || '');
    formData.append('transfer_student', user.transfer_student?.toString() || '');
    formData.append('gap_year', user.gap_year?.toString() || '');
    formData.append('transfer_deadline', user.transfer_deadline || '');
    formData.append('transfer_website', user.transfer_website || '');
    formData.append('transfer_notes', user.transfer_notes || '');
    formData.append('high_school', user.high_school || '');
    formData.append('intended_college_major', user.intended_college_major || '');
    formData.append('artsbridge_programs_attended', user.artsbridge_programs_attended || '');
    formData.append('consultant_id', user.consultant_id?.toString() || '');
    formData.append('notes_from', user.notes_from || '');
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students`, {
      method: 'POST',
      headers: new Headers({
        // 'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      // body: JSON.stringify(body),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      navigate(`/students/${json.id}`);
    } else {
      const json = await response.json();
      // console.error(json);
      for (const [key, value] of Object.entries(json)) {
        const str = `${key} ${value}`;
        const str2 = str.charAt(0).toUpperCase() + str.slice(1) + '.';
        console.error(str2);
        setErrorMessage(str2);
        break;
      }
    }
  }

  const handleCancel = () => {
    navigate(`/students`);
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/students`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Students
            </Typography>
            <Typography variant="subtitle2">
              Invite Student
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <StudentForm onCancel={handleCancel} onSave={createStudent} />
      </Box>

      <Dialog
        open={errorMessage !== undefined}
        onClose={() => setErrorMessage(undefined)}
      >
        <DialogTitle id="alert-dialog-title">
          {"Could not save student"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage(undefined)}>Okay</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default InviteStudent;
import { DialogActions, DialogContent, FormControl, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { InstitutionDegree } from "../../../../../../models";
import PrimaryButton from "../../../../../controls/PrimaryButton";
import SecondaryButton from "../../../../../controls/SecondaryButton";
import { format, parseISO } from "date-fns";
import RichTextEditor from "../../../../../controls/RichTextEditor";

const PrescreenForm = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  
  let institutionDegreePreScreeningDeadline = '';
  if (institutionDegree?.pre_screening_deadline) {
    const date = parseISO(institutionDegree.pre_screening_deadline);
    institutionDegreePreScreeningDeadline = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline2 = '';
  if (institutionDegree?.prescreen_deadline_2) {
    const date = parseISO(institutionDegree.prescreen_deadline_2);
    institutionDegreePrescreenDeadline2 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline3 = '';
  if (institutionDegree?.prescreen_deadline_3) {
    const date = parseISO(institutionDegree.prescreen_deadline_3);
    institutionDegreePrescreenDeadline3 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline4 = '';
  if (institutionDegree?.prescreen_deadline_4) {
    const date = parseISO(institutionDegree.prescreen_deadline_4);
    institutionDegreePrescreenDeadline4 = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreePrescreenDeadline5 = '';
  if (institutionDegree?.prescreen_deadline_5) {
    const date = parseISO(institutionDegree.prescreen_deadline_5);
    institutionDegreePrescreenDeadline5 = format(date, 'yyyy-MM-dd');
  }
  
  const [preScreening, setPreScreening] = useState(institutionDegree?.pre_screening || '');
  const [preScreeningDeadline, setPreScreeningDeadline] = useState(institutionDegreePreScreeningDeadline);
  const [multiplePrescreens, setMultiplePrescreens] = useState(institutionDegree?.multiple_prescreens || '');
  const [prescreenDeadline2, setPrescreenDeadline2] = useState(institutionDegreePrescreenDeadline2);
  const [prescreenDeadline3, setPrescreenDeadline3] = useState(institutionDegreePrescreenDeadline3);
  const [prescreenDeadline4, setPrescreenDeadline4] = useState(institutionDegreePrescreenDeadline4);
  const [prescreenDeadline5, setPrescreenDeadline5] = useState(institutionDegreePrescreenDeadline5);
  const [preScreeningRequirementsUrl, setPreScreeningRequirementsUrl] = useState(institutionDegree?.pre_screening_requirements_url || '');
  const [prescreenArtisticUploadUrl, setPrescreenArtisticUploadUrl] = useState(institutionDegree?.prescreen_artistic_upload_url || '');
  
  const handleSave = () => {
    const updatedInstitutionDegree: InstitutionDegree = {...institutionDegree,
      pre_screening: preScreening,
      pre_screening_deadline: preScreeningDeadline,
      multiple_prescreens: multiplePrescreens,
      prescreen_deadline_2: prescreenDeadline2,
      prescreen_deadline_3: prescreenDeadline3,
      prescreen_deadline_4: prescreenDeadline4,
      prescreen_deadline_5: prescreenDeadline5,
      pre_screening_requirements_url: preScreeningRequirementsUrl,
      prescreen_artistic_upload_url: prescreenArtisticUploadUrl,
    }

    onSave(updatedInstitutionDegree);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>

          <FormControl>
            <InputLabel id="preScreening">Prescreen Required</InputLabel>
            <Select
              labelId="preScreening"
              label="Prescreen Required"
              value={preScreening || ''}
              onChange={(e) => setPreScreening(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {preScreening &&
            <>
              <TextField
                label="Regular Decision Prescreen Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={preScreeningDeadline}
                onChange={(e) => setPreScreeningDeadline(e.target.value)}
              />

              <TextField
                label="Early Action Prescreen Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={prescreenDeadline2}
                onChange={(e) => setPrescreenDeadline2(e.target.value)}
              />

              <TextField
                label="Early Decision Prescreen Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={prescreenDeadline3}
                onChange={(e) => setPrescreenDeadline3(e.target.value)}
              />

              <TextField
                label="Other Decision Prescreen Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={prescreenDeadline4}
                onChange={(e) => setPrescreenDeadline4(e.target.value)}
              />

              <TextField
                label="Other Decision 2 Prescreen Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={prescreenDeadline5}
                onChange={(e) => setPrescreenDeadline5(e.target.value)}
              />

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Prescreen Requirements</FormLabel>
                <RichTextEditor value={preScreeningRequirementsUrl} onChange={setPreScreeningRequirementsUrl} />
              </FormControl>

              <TextField 
                label="Prescreen Artistic Upload Website" 
                variant="outlined" 
                value={prescreenArtisticUploadUrl}
                onChange={(e) => setPrescreenArtisticUploadUrl(e.target.value)} />

            </>
          }

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default PrescreenForm;
import { Box, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { Institution, TaskTemplate } from "../../../../models";
import PrimaryButton from "../../../controls/PrimaryButton";
import SecondaryButton from "../../../controls/SecondaryButton";
import useAuth from "../../../../hooks/useAuth";

const TaskTemplateForm = ({ taskTemplate, onCancel, onSave }: { taskTemplate?: TaskTemplate, onCancel: () => void, onSave: (taskTemplate: TaskTemplate) => void }) => {
  let taskDueOn = '';
  if (taskTemplate?.due_on) {
    const date = parseISO(taskTemplate.due_on);
    taskDueOn = format(date, 'yyyy-MM-dd');
  }
  
  const [title, setTitle] = useState(taskTemplate?.title || '');
  const [notes, setNotes] = useState(taskTemplate?.notes || '');
  const [taskType, setTaskType] = useState(taskTemplate?.task_type || 'task');
  const [institutionId, setInstitutionId] = useState(taskTemplate?.institution_id);
  const [dueOn, setDueOn] = useState(taskDueOn);

  const [institutions, setInstitutions] = useState<Institution[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const auth = useAuth();

  useEffect(() => {
    const fetchInstitutions = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institutions`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setInstitutions(json);
    }

    fetchInstitutions();
  }, []);

  const handleSave = () => {
    const taskTemplate: TaskTemplate = {
      title,
      notes,
      task_type: taskType,
      institution_id: institutionId,
      due_on: dueOn,
    }
    onSave(taskTemplate);
  }

  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>

          <TextField 
            label="Name" 
            variant="outlined" 
            value={title}
            onChange={(e) => setTitle(e.target.value)} />

          <TextField 
            label="Notes" 
            variant="outlined" 
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)} />

          <FormControl>
            <InputLabel id="institution_id">Assign to a specific college</InputLabel>
            <Select
              labelId="institution_id"
              label="Assign to a specific college"
              value={institutionId || ''}
              onChange={(e) => setInstitutionId(+e.target.value)}
            >
              {institutions.map(institution => (
                <MenuItem key={institution.id} value={institution.id}>{institution.name}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Set Due Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={dueOn}
            onChange={(e) => setDueOn(e.target.value)}
          />

        </Stack>
      </Container>
      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default TaskTemplateForm;
import { Badge, BottomNavigationAction, Box, IconButton, Paper, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import AuthMenu from "../auth/AuthMenu";
import SearchField from "../controls/SearchField";
import AnnouncementsMenu from "../announcements/AnnouncementsMenu";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CustomBottomNavigation from '../controls/CustomBottomNavigation';
import useRouteMatch from "../../hooks/useRouteMatch";
import { useState } from "react";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ResponsiveAppBar from "../controls/ResponsiveAppBar";
import { Notification } from "../../models";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const StudentContent = ({ tasksCount, notifications, onDismiss }: { tasksCount: number, notifications: Notification[], onDismiss: (notification: Notification) => void }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(['/', '/colleges/*', '/tasks/*', '/documents/*', '/settings/*']);
  const [value, setValue] = useState(routeMatch?.pattern?.path || '/');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const handleSearch = (query) => {
    navigate(`/search?q=${query}`);
  }

  return (
    <Box sx={{ flexGrow: 1, overflowX: 'clip' }}>
      <ResponsiveAppBar>
        <Toolbar>
          {/*
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          */}

          <SearchField onSearch={handleSearch} />

          <AnnouncementsMenu isAdmin={false} notifications={notifications} onDismiss={onDismiss} />

          <IconButton sx={{ mr: 1 }} component={Link} to={`/chats/${auth.user.id}`}>
            <Badge badgeContent={0} color="error">
              <EmailOutlinedIcon />
            </Badge>
          </IconButton>

          <AuthMenu/>
        </Toolbar>
      </ResponsiveAppBar>

      <Box 
        component="main" 
        sx={{ flexGrow: 1, pb: 7 }}
      >
        <Toolbar />
        <Outlet />
      </Box>
      
      {isMobile &&
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <CustomBottomNavigation 
            showLabels={false}
            value={value} 
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction value="/" component={Link} to="/" label="Dashboard" icon={<HomeOutlinedIcon />} />
            <BottomNavigationAction value="/colleges/*" component={Link} to="/colleges" label="Colleges" icon={<SchoolOutlinedIcon />} />
            <BottomNavigationAction value="/tasks/*" component={Link} to="/tasks" label="Tasks/Events" icon={
              <Badge color="error" badgeContent={tasksCount}>
                <InventoryOutlinedIcon />
              </Badge>
            } />
            <BottomNavigationAction value="/documents/*" component={Link} to="/documents" label="Documents" icon={<DescriptionOutlinedIcon />} />
            <BottomNavigationAction value="/settings/*" component={Link} to="/settings" label="More" icon={<MoreHorizIcon />} />
          </CustomBottomNavigation>
        </Paper>
      }
    </Box>
  )
}

export default StudentContent;
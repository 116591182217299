import * as React from "react";
import { Announcement } from "../../models";
import AnnouncementForm from "./AnnouncementForm";
import useAuth from "../../hooks/useAuth";

const NewAnnouncement = ({ onCancel, onSave }: { onCancel: () => void, onSave: (announcement: Announcement) => void }) => {
  const auth = useAuth();
  
  const createAnnouncement = async (announcement: Announcement) => {
    const token = auth.token();
    const body = {
      title: announcement.title,
      message: announcement.message,
      user_ids: announcement.user_ids?.toString() || '',
      include_collaborators: announcement.include_collaborators,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/announcements`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <AnnouncementForm onCancel={handleCancel} onSave={createAnnouncement} />
  )
}

export default NewAnnouncement;
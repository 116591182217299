import { alpha, InputBase, styled } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.light, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.15),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  width: '100%',
}));

const SearchField = ({ label = 'Search', value, realTime = false, onSearch }: { label?: string, value?: string|null, realTime?: boolean, onSearch: (query: string) => void }) => {
  const [query, setQuery] = useState(value || '');
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // if (event.key === 'Backspace' || event.key === 'Delete') {
    //   return;
    // }
    // if (event.key === 'Enter') {
    //   onSearch(event.target.value);
    // } else if (realTime) {
    //   onSearch(event.target.value);
    // }
  }

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      if (realTime) {
        onSearch(event.target.value);
      }
    }
    if (event.key === 'Enter') {
      onSearch(event.target.value);
    } else if (realTime) {
      onSearch(event.target.value);
    }
  }

  return (
    <Search sx={{ flexGrow: 1 }}>
      <SearchIconWrapper>
        <SearchIcon fontSize="small" />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={label}
        value={query}
        onChange={(event) => setQuery(event.target.value)}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      />
    </Search>
  )
}

export default SearchField;
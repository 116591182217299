import { Paper, SxProps, Theme, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import Dropzone from 'react-dropzone'
import PaperFilePreview from "./PaperFilePreview";

const PaperDropzone = ({ sx = [], onChange }: { sx?: SxProps<Theme>, onChange: (file: File) => void}) => {
  const [file, setFile] = useState<File>();

  const onDrop = (acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setFile(newFile);
      onChange(newFile);
    }
  }

  const onRemove = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setFile(undefined);
  }

  return (
    <Dropzone onDrop={onDrop} multiple={false}>
      {({getRootProps, getInputProps}) => (
        <Paper {...getRootProps()} sx={[{ border: 'dashed', cursor: 'pointer',  }, ...(Array.isArray(sx) ? sx : [sx]), ]} elevation={0}>
          <input {...getInputProps()} />
          <Typography p={2} align="center" variant="subtitle2">Drag and drop a file, or click to select file</Typography>
          {file && 
            <PaperFilePreview file={file} onRemove={onRemove} />
          }
        </Paper>
      )}
    </Dropzone>
  )
}

export default PaperDropzone;
import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Institution, InstitutionDegree } from "../../../../../../models";
import PrimaryButton from "../../../../../controls/PrimaryButton";
import SecondaryButton from "../../../../../controls/SecondaryButton";
import { statesOptions, countriesOptions } from "../../../../../../models/Institution";
import { format, parseISO } from "date-fns";

const EditCollegeDatesForm = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  
  let institutionDegreEarlyActionDate = '';
  if (institutionDegree?.early_action_date) {
    const date = parseISO(institutionDegree.early_action_date);
    institutionDegreEarlyActionDate = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreEarlyDecisionDate = '';
  if (institutionDegree?.early_decision_date) {
    const date = parseISO(institutionDegree.early_decision_date);
    institutionDegreEarlyDecisionDate = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreOtherDecisionDate = '';
  if (institutionDegree?.other_decision_date) {
    const date = parseISO(institutionDegree.other_decision_date);
    institutionDegreOtherDecisionDate = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreOtherDecisionDateTwo = '';
  if (institutionDegree?.other_decision_date_two) {
    const date = parseISO(institutionDegree.other_decision_date_two);
    institutionDegreOtherDecisionDateTwo = format(date, 'yyyy-MM-dd');
  }

  let institutionDegreeRegularDeadline = '';
  if (institutionDegree?.regular_deadline) {
    const date = parseISO(institutionDegree.regular_deadline);
    institutionDegreeRegularDeadline = format(date, 'yyyy-MM-dd');
  }
  
  const [earlyAction, setEarlyAction] = useState(institutionDegree?.early_action || '');
  const [earlyActionDate, setEarlyActionDate] = useState(institutionDegreEarlyActionDate);
  const [earlyDecision, setEarlyDecision] = useState(institutionDegree?.early_decision || '');
  const [earlyDecisionDate, setEarlyDecisionDate] = useState(institutionDegreEarlyDecisionDate);
  const [otherDecision, setOtherDecision] = useState(institutionDegree?.other_decision || '');
  const [otherDecisionDetails, setOtherDecisionDetails] = useState(institutionDegree?.other_decision_details || '');
  const [otherDecisionDate, setOtherDecisionDate] = useState(institutionDegreOtherDecisionDate);
  const [otherDecisionTwo, setOtherDecisionTwo] = useState(institutionDegree?.other_decision_two || '');
  const [otherDecisionDetailsTwo, setOtherDecisionDetailsTwo] = useState(institutionDegree?.other_decision_details_two || '');
  const [otherDecisionDateTwo, setOtherDecisionDateTwo] = useState(institutionDegreOtherDecisionDateTwo);
  const [regularDeadline, setRegularDeadline] = useState(institutionDegreeRegularDeadline);

  const handleSave = () => {
    const updatedInstitutionDegree: InstitutionDegree = {...institutionDegree,
      early_action: earlyAction,
      early_action_date: earlyActionDate,
      early_decision: earlyDecision,
      early_decision_date: earlyDecisionDate,
      other_decision: otherDecision,
      other_decision_details: otherDecisionDetails,
      other_decision_date: otherDecisionDate,
      other_decision_two: otherDecisionTwo,
      other_decision_details_two: otherDecisionDetailsTwo,
      other_decision_date_two: otherDecisionDateTwo,
      regular_deadline: regularDeadline,
    }

    onSave(updatedInstitutionDegree);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>

        <FormControl>
            <InputLabel id="earlyAction">Early Action</InputLabel>
            <Select
              labelId="earlyAction"
              label="Early Action"
              value={earlyAction || ''}
              onChange={(e) => setEarlyAction(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {earlyAction === 'Yes' &&
            <TextField
              label="Early Action Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={earlyActionDate}
              onChange={(e) => setEarlyActionDate(e.target.value)}
            />
          }

          <FormControl>
            <InputLabel id="earlyDecision">Early Decision</InputLabel>
            <Select
              labelId="earlyDecision"
              label="Early Decision"
              value={earlyDecision || ''}
              onChange={(e) => setEarlyDecision(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {earlyDecision === 'Yes' &&
            <TextField
              label="Early Decision Date"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={earlyDecisionDate}
              onChange={(e) => setEarlyDecisionDate(e.target.value)}
            />
          }

          <FormControl>
            <InputLabel id="otherDecision">Other Decision</InputLabel>
            <Select
              labelId="otherDecision"
              label="Other Decision"
              value={otherDecision || ''}
              onChange={(e) => setOtherDecision(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {otherDecision === 'Yes' &&
            <>
              <TextField 
                label="Other Decision Details" 
                variant="outlined"
                value={otherDecisionDetails}
                onChange={(e) => setOtherDecisionDetails(e.target.value)} />

              <TextField
                label="Other Decision Date"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={otherDecisionDate}
                onChange={(e) => setOtherDecisionDate(e.target.value)}
              />
            </>
          }

          <FormControl>
            <InputLabel id="otherDecisionTwo">Other Decision 2</InputLabel>
            <Select
              labelId="otherDecisionTwo"
              label="Other Decision 2"
              value={otherDecisionTwo || ''}
              onChange={(e) => setOtherDecisionTwo(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {otherDecisionTwo === 'Yes' &&
            <>
              <TextField 
                label="Other Decision Details 2" 
                variant="outlined"
                value={otherDecisionDetailsTwo}
                onChange={(e) => setOtherDecisionDetailsTwo(e.target.value)} />

              <TextField
                label="Other Decision Date 2"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={otherDecisionDateTwo}
                onChange={(e) => setOtherDecisionDateTwo(e.target.value)}
              />
            </>
          }

          <TextField
            label="Regular Deadline"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={regularDeadline}
            onChange={(e) => setRegularDeadline(e.target.value)}
          />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default EditCollegeDatesForm;
import { Box, Breadcrumbs, Card, CardContent, Container, Divider, IconButton, Link, List, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { Notification } from "../../models";
import useAuth from "../../hooks/useAuth";
import ResponsiveAppBar from "../controls/ResponsiveAppBar";
import NotificationItem from "./NotificationItem";

const NotificationsList = ({ notifications, onDismiss }: { notifications: Notification[], onDismiss: (notification: Notification) => void }) => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();

  const handleDismiss = (notification: Notification) => {
    onDismiss(notification);
  }

  
  if (!auth.user) return null;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Notifications
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <Container sx={{ py: 2 }}>
          <Card>
            <CardContent sx={{ p: 0 }}>
              <List>
                {notifications.map(notification => (
                  <Box key={notification.id}>
                    <NotificationItem notification={notification} onDismiss={handleDismiss} />
                    <Divider />
                  </Box>
                ))}
                {notifications.length === 0 && (
                  <Box sx={{ p: 2 }}>
                    <Typography variant="h5" color="text.primary">
                      You're all caught up.
                    </Typography>
                    <Link variant="body2" href="/">View Dashboard</Link>
                  </Box>
                )}
              </List>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </Box>
  );
}

export default NotificationsList;
import * as React from "react";
import { useEffect, useState } from "react";
import { InstitutionDegree, Task, User } from "../../models";
import TaskForm from "./TaskForm";
import useAuth from "../../hooks/useAuth";

const EditTask = ({ task, onCancel, onSave }: { task: Task, onCancel: () => void, onSave: (task: Task) => void }) => {
  const auth = useAuth();
  
  const updateTask = async (newTask: Task) => {
    const token = auth.token();
    
    const formData = new FormData();
    if (newTask.file) formData.append('attachment', newTask.file);
    formData.append('title', newTask.title);
    formData.append('notes', newTask.notes);
    formData.append('due_on', newTask.due_on || '');
    formData.append('notes', newTask.notes);
    if (newTask.url) {
      formData.append('url', newTask.url);
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}`, {
      method: 'PATCH',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      await fetchTask();
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const fetchTask = async () => {
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <TaskForm task={task} onCancel={handleCancel} onSave={updateTask} showUsers={false} initialUsers={[]} isEditing={true} />
  )
}

export default EditTask;
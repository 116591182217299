import { Box, CardHeader, Skeleton, Stack, TableCell, TableRow } from "@mui/material";
import * as React from "react";

const StudentLoaderItem = () => {  
  return (
    <React.Fragment>
      <TableRow
        hover
        selected={false}
      >
        <TableCell padding="checkbox">
          <Box sx={{ width: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Skeleton variant="rectangular" width={18} height={18} />
          </Box>
        </TableCell>
        <TableCell>
          <CardHeader sx={{ p: 0, textDecoration: 'none', color: 'inherit' }}
            avatar={<Skeleton variant="circular" width={40} height={40} />}
            title={<Skeleton variant="text" />}
            subheader={<Skeleton variant="text" />}
          />
        </TableCell>
        <TableCell>
          <Stack>
            <Skeleton variant="text" />
            <Box sx={{display: 'flex'}}>
              <Skeleton variant="circular" width={40} height={40} />
            </Box>
          </Stack>
        </TableCell>            
        <TableCell align="right"></TableCell>
      </TableRow>
    </React.Fragment>
  )
}

export default StudentLoaderItem;
import * as React from "react";
import { DocumentFile } from "../../models";
import { Avatar, DialogContent, IconButton, Link, Stack, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import useAuth from "../../hooks/useAuth";
import CloseIcon from '@mui/icons-material/Close';

const ViewDocument = ({ documentId, onClose }: { documentId: number, onClose: () => void }) => {
  const auth = useAuth();

  const [document, setDocument] = React.useState<DocumentFile>();
  const [missing, setMissing] = React.useState(false);

  React.useEffect(() => {
    const fetchDocument = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/${documentId}`, {
        method: 'GET',
        headers: new Headers({
          authorization: `Bearer ${token}`
        }),
      });
      console.log('response', response)
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setDocument(json);
      } else {
        console.error('could not get doc', response);
        // const json = await response.json();
        // console.error(json);
        setMissing(true);
      }
    }

    fetchDocument();
  }, [documentId]);


  if (missing) {
    return (
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>That doocument could not be found</Typography>
            <Typography variant="body1">It may have been removed or unshared.</Typography>
          </Stack>
        </DialogContent>
      </>
    )
  }

  if (!document) { return null }

  return (
    <>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack spacing={1}>

          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{document.name}</Typography>
          <Typography variant="body2">
            {`${document.user?.first_name} ${document.user?.last_name}`}
            {' '}
            {document.created_at && format(parseISO(document.created_at), 'MMM d, yyyy')}
          </Typography>
          {document.attachment?.url && (
            <Link variant="body2" href={document.attachment.url}>Download</Link>
          )}
          {document.url && (
            <Link variant="body2" href={document.url}>Link</Link>
          )}
          {document.assessment &&
            <Stack direction="row" spacing={1} sx={{ pt: 1, alignItems: 'center' }}>
              <Avatar sx={{ width: 24, height: 24 }} src={document.assessment?.institution_degree?.institution?.institution_avatar?.url} alt={`${document.assessment?.institution_degree?.institution?.name} ${document.assessment?.institution_degree?.degree?.name}`} />
              <Link variant="body2" color="inherit" underline="hover" href={`/assessments/${document.assessment.id}/documents`}>{`${document.assessment?.institution_degree?.institution?.name} ${document.assessment?.institution_degree?.degree?.name}`}</Link>
            </Stack>
          }        
        </Stack>
      </DialogContent>
    </>
  )
}

export default ViewDocument;
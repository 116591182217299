import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Institution } from "../../../../../../models";
import PrimaryButton from "../../../../../controls/PrimaryButton";
import SecondaryButton from "../../../../../controls/SecondaryButton";
import { statesOptions, countriesOptions } from "../../../../../../models/Institution";
import { MuiTelInput } from "mui-tel-input";

const EditCollegeForm = ({ institution, onCancel, onSave }: { institution: Institution, onCancel: () => void, onSave: (institution: Institution) => void }) => {
  
  const [city, setCity] = useState(institution.city);
  const [state, setState] = useState(institution.state);
  const [country, setCountry] = useState(institution.country);
  const [centralOfficePhone, setCentralOfficePhone] = useState(institution.central_office_phone);
  const [centralOfficeEmail, setCentralOfficeEmail] = useState(institution.central_office_email);
  const [commonApp, setCommonApp] = useState(institution.common_app || '');
  const [coalitionApplicationInstitution, setCoalitionApplicationInstitution] = useState(institution?.coalition_application_institution || '');
  const [coalitionApplicationInstitutionUrl, setCoalitionApplicationInstitutionUrl] = useState(institution?.coalition_application_institution_url || '');
  const [applicationUrl, setApplicationUrl] = useState(institution?.application_url || '');

  const handleSave = () => {
    const updatedInstitution: Institution = {...institution,
      city,
      state,
      country,
      central_office_phone: centralOfficePhone,
      central_office_email: centralOfficeEmail,
      common_app: commonApp,
    }

    onSave(updatedInstitution);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>

          <TextField 
            label="City" 
            variant="outlined" 
            value={city}
            onChange={(e) => setCity(e.target.value)} />

          <FormControl>
            <InputLabel id="state">State</InputLabel>
            <Select
              labelId="state"
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              {statesOptions.map(([label, value]) => (
                <MenuItem 
                  key={value || label} 
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            >
              {countriesOptions.map(([label, value]) => (
                <MenuItem 
                  key={value || label} 
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <MuiTelInput 
            label="Main Admissions Office Phone" 
            size="small" 
            forceCallingCode={true} 
            defaultCountry="US" 
            // disableDropdown 
            value={centralOfficePhone} 
            onChange={setCentralOfficePhone} />

          <TextField 
            label="Main Admissions Office Email" 
            variant="outlined" 
            value={centralOfficeEmail}
            onChange={(e) => setCentralOfficeEmail(e.target.value)} />

          {institution.application_type === 'College-specific App' &&
            <TextField 
              label="Application URL" 
              variant="outlined" 
              value={applicationUrl}
              onChange={(e) => setApplicationUrl(e.target.value)} />
          }

          <FormControl>
            <InputLabel id="commonApp">Common Application Institution</InputLabel>
            <Select
              labelId="commonApp"
              label="Common Application Institution"
              value={commonApp}
              onChange={(e) => setCommonApp(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="coalitionApplicationInstitution">Coalition Application Institution</InputLabel>
            <Select
              labelId="coalitionApplicationInstitution"
              label="Coalition Application Institution"
              value={coalitionApplicationInstitution}
              onChange={(e) => setCoalitionApplicationInstitution(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {coalitionApplicationInstitution === 'Yes' &&
            <TextField 
              label="Coalition Application Institution Website" 
              variant="outlined" 
              value={coalitionApplicationInstitutionUrl}
              onChange={(e) => setCoalitionApplicationInstitutionUrl(e.target.value)} />
          }

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default EditCollegeForm;
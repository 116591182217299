import { BottomNavigation, styled } from "@mui/material";

const CustomBottomNavigation = styled(BottomNavigation)(({ theme }) => `
  & .MuiBottomNavigationAction-root {
    &.Mui-selected {
      padding-top: 14px;
      & .MuiSvgIcon-root {
        z-index: 1;
        color: ${theme.palette.common.white};
      }
      &:before {
        content: '';
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: ${theme.palette.primary.main};
        position: absolute;
        top: 7px;
      }
    }
  }
  & .MuiBottomNavigationAction-label {
    &.Mui-selected {
      opacity: 0;
      font-size: 0.75rem;
    }
  }
`);

export default CustomBottomNavigation;
import * as React from "react";
import StatusListItem from "./StatusListItem";
import './StatusList.css';
import { Assessment } from "../../../models";

const ConfidenceList = ({ assessment, isAdmin, onChange }: { assessment: Assessment, isAdmin: boolean, onChange: (assessment: Assessment) => void }) => {

  const confidenceOptions = [
    ["Please Select", ""],
    ["Likely", "Likely"],
    ["Likely/Target", "Likely/Target"],
    ["Target", "Target"],
    ["Target/Stretch", "Target/Stretch"],
    ["Stretch", "Stretch"],
    ["High Stretch", "High Stretch"],
    ["Not Applicable", "Not Applicable"],
  ];

  if (!assessment.id) return null;

  return (
    <ul className="status-list">
      <li>
        <StatusListItem id={assessment.id} field="artistic_confidence" label="Artistic Confidence:" value={assessment.artistic_confidence} options={confidenceOptions} isEditable={isAdmin} onChange={(artistic_confidence) => onChange({...assessment, artistic_confidence})} />
      </li>
      <li>
        <StatusListItem id={assessment.id} field="academic_confidence" label="Academic Confidence:" value={assessment.academic_confidence} options={confidenceOptions} isEditable={isAdmin} onChange={(academic_confidence) => onChange({...assessment, academic_confidence})} />
      </li>      
    </ul>
  )
}

export default ConfidenceList;
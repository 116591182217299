import { Link } from "@mui/material";
import React from "react";

const RichTextLink = ({ contentState, entityKey, children }) => {
  const {url} = contentState.getEntity(entityKey).getData();
  return (
    <Link href={url}>{children}</Link>
  )
}

export default RichTextLink;
import * as React from 'react';
import { Box, Card, CardContent, CardHeader, Container, Dialog, DialogActions, DialogContent, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import CollegeCollection from "../../../../models/CollegeCollection";
import SecondaryButton from "../../../controls/SecondaryButton";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Link, useParams } from "react-router-dom";
import { MouseEvent, useEffect, useState } from 'react';
import useAuth from '../../../../hooks/useAuth';
import ResponsiveAppBar from '../../../controls/ResponsiveAppBar';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PrimaryButton from '../../../controls/PrimaryButton';
import { InstitutionDegree } from '../../../../models';
import InstitutionDegreeDropdown from '../../../filters/InstitutionDegreeDropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const CollegeCollectionDetails = () => {
  const params = useParams();
  const [collection, setCollection] = useState<CollegeCollection>();
  const auth = useAuth();
  const [open, setOpen] = useState(false);
  const [institutionDegree, setInstitutionDegree] = useState<InstitutionDegree|null>(null);
  const [anchorElInstitutionDegree, setAnchorElInstitutionDegree] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const fetchCollection = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/college_collections/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setCollection(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchCollection();
  }, []);

  const handleCancel = () => {
    setInstitutionDegree(null);
    setOpen(false);
  }

  const handleSave = async () => {
    if (institutionDegree) {
      const token = auth.token();
      const body = {
        institution_degree_id: institutionDegree.id,
      }
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/college_collections/${collection.id}/college_collection_members`, {
        method: 'POST',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        body: JSON.stringify(body),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setCollection({...collection, college_collection_members: [...collection.college_collection_members, json]});
        setInstitutionDegree(null);
        setOpen(false);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
  }

  const handleOpenInstitutionDegreeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorElInstitutionDegree(event.currentTarget);
    return false;
  };

  const handleCloseInstitutionDegreeMenu = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setAnchorElInstitutionDegree(null);
  };

  const deleteMember = async (id: number) => {
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/college_collection_members/${id}`, {
      method: 'DELETE',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setCollection({...collection, college_collection_members: [...collection.college_collection_members].filter(m => m.id !== id)});
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  if (!collection) return null;
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to="/config/collections"
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Typography variant="subtitle2">
            Collections
          </Typography>
        </Toolbar>
      </ResponsiveAppBar>

      <Box>
        <Toolbar />
        <CardHeader
          title={collection.name}
          // action={
          //   <IconButton component={Link} to={`/collections/${collection.id}/edit`}>
          //     <EditOutlinedIcon/>
          //   </IconButton>
          // }
          sx={{ backgroundColor: 'background.default' }}
        />

        <Container sx={{ py: 2 }}>
          <Stack spacing={2} sx={{py: 1}}>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<ControlPointOutlinedIcon/>} 
              sx={{width: 1, maxWidth: 300}}
              onClick={() => setOpen(true)}
            >
              Add a Program
            </SecondaryButton>

            <Card>
              <CardContent>
                <List>
                  {collection.college_collection_members?.map(member => (
                    <ListItem 
                      key={member.id}
                      secondaryAction={
                        <IconButton 
                          edge="end" 
                          onClick={handleOpenInstitutionDegreeMenu}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={member.institution_degree?.institution?.name} secondary={member.institution_degree?.degree?.name}/>
                      <Menu
                        anchorEl={anchorElInstitutionDegree}
                        keepMounted
                        open={Boolean(anchorElInstitutionDegree)}
                        onClose={handleCloseInstitutionDegreeMenu}
                      >
                        <MenuItem onClick={() => deleteMember(member.id)}>
                          <ListItemIcon>
                            <DeleteOutlineOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText>Remove</ListItemText>
                        </MenuItem>
                      </Menu>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Stack>
        </Container>
      </Box>

      <Dialog open={open} fullWidth>
        <DialogContent>
          <Stack spacing={2}>
            <InstitutionDegreeDropdown value={institutionDegree} onChange={setInstitutionDegree} error={null} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <SecondaryButton variant="outlined" sx={{width: 1}} onClick={handleCancel}>Cancel</SecondaryButton>
          <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Add</PrimaryButton>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default CollegeCollectionDetails;
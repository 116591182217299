import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import AddInstitution from "./configuration/colleges/AddInstitution";
import AdminConfiguration from "./configuration/AdminConfiguration";
import EditInstitution from "./configuration/colleges/EditInstitution";
import AdminDashboard from "./dashboard/AdminDashboard";
import AddAssessment from "./students/colleges/AddAssessment";
import AssessmentDetail from "./students/colleges/AssessmentDetail";
import EditStudent from "./students/EditStudent";
import InviteStudent from "./students/InviteStudent";
import StudentDetail from "./students/StudentDetail";
import Students from "./students/Students";
import InstitutionDetail from "./configuration/colleges/InstitutionDetail";
import AddDegree from "./configuration/degrees/AddDegree";
import DegreeDetail from "./configuration/degrees/DegreeDetail";
import EditDegree from "./configuration/degrees/EditDegree";
import AddInstitutionDegree from "./configuration/colleges/AddInstitutionDegree";
import InstitutionDegreeDetail from "./configuration/colleges/InstitutionDegreeDetail";
import InviteAdmin from "./configuration/administrators/InviteAdmin";
import AdminDetail from "./configuration/administrators/AdminDetail";
import EditAdmin from "./configuration/administrators/EditAdmin";
import AddTaskTemplate from "./configuration/tasks/AddTaskTemplate";
import EditTaskTemplate from "./configuration/tasks/EditTaskTemplate";
import TaskTemplateDetail from "./configuration/tasks/TaskTemplateDetail";
import Documents from "../documents/Documents";
import EditAdminProfile from "../auth/EditAdminProfile";
import EditAssessment from "./students/colleges/EditAssessment";
import EditInstitutionDegree from "./configuration/colleges/EditInstitutionDegree";
import SearchResults from "../SearchResults";
import ChatDetail from "../messages/ChatDetail";
import AdminContent from "./AdminContent";
import NotificationsList from "../notifications/NotificationsList";
import { useEffect, useState } from "react";
import { Notification } from '../../models';
import AdminTasks from "./tasks/AdminTasks";
import useAuth from "../../hooks/useAuth";
import AnnouncementsList from "../announcements/AnnouncementsList";
import Messages from "./messages/Messages";
import CollegeCollectionDetails from "./configuration/collections/CollegeCollectionDetails";
import AnnouncementDetail from "../announcements/AnnouncementDetail";

const AdminApp = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const auth = useAuth();
  const [tasksCount, setTasksCount] = useState<number>(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/notifications`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setNotifications(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchNotifications();
  }, []);

  const handleDismiss = (notification: Notification) => {
    setNotifications(notifications.filter(n => n.id !== notification.id))
  }

  const refreshTasks = async () => {
    const token = auth.token();
    let url = `${window.SERVER_DATA.domain}/api/v1/tasks/incomplete`;
    const response = await fetch(url, {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setTasksCount(json.tasks);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  useEffect(() => {
    refreshTasks();
  }, []);

  
  
  return (
    <Routes>
      <Route element={<AdminLayout tasksCount={tasksCount}/>}>
        
        <Route element={<AdminContent tasksCount={tasksCount} notifications={notifications} onDismiss={handleDismiss} />}>
          <Route path="/" element={<AdminDashboard notifications={notifications} onDismiss={handleDismiss} />} />
          <Route path="/students" element={<Students />}/>
          <Route path="/students/archived" element={<Students />}/>
          <Route path="/tasks" element={<AdminTasks isAdmin={true} onChange={() => refreshTasks()} />} />
          <Route path="/documents" element={<Documents isAdmin={true} />} />
          <Route path="/documents/:id" element={<Documents isAdmin={true} />} />
          <Route path="/config/*" element={<AdminConfiguration />}/>
        </Route>

        <Route path="/user" element={<EditAdminProfile />} />

        <Route path="/students/new" element={<InviteStudent />} />
        <Route path="/students/:id/*" element={<StudentDetail />} />
        <Route path="/students/:id/edit" element={<EditStudent />} />

        <Route path="/students/:studentId/assessments/new" element={<AddAssessment />} />
        <Route path="/assessments/:id/*" element={<AssessmentDetail isAdmin={true} />} />
        <Route path="/assessments/:id/edit" element={<EditAssessment />} />

        <Route path="/institutions/new" element={<AddInstitution />} />
        <Route path="/institutions/:id/*" element={<InstitutionDetail />} />
        <Route path="/institutions/:id/edit" element={<EditInstitution />} />

        <Route path="/degrees/new" element={<AddDegree />} />
        <Route path="/degrees/:id" element={<DegreeDetail />} />
        <Route path="/degrees/:id/edit" element={<EditDegree />} />

        <Route path="/institutions/:institutionId/institution_degrees/new" element={<AddInstitutionDegree />} />
        <Route path="/institution_degrees/:id" element={<InstitutionDegreeDetail />} />
        <Route path="/institution_degrees/:id/edit" element={<EditInstitutionDegree />} />

        <Route path="/admins/new" element={<InviteAdmin />} />
        <Route path="/admins/:id" element={<AdminDetail />} />
        <Route path="/admins/:id/edit" element={<EditAdmin />} />

        <Route path="/task_templates/new" element={<AddTaskTemplate />} />
        <Route path="/task_templates/:id" element={<TaskTemplateDetail />} />
        <Route path="/task_templates/:id/edit" element={<EditTaskTemplate />} />

        <Route path="/search" element={<SearchResults />} />

        <Route path="/messages/*" element={<Messages />} />
        <Route path="/chats/:id" element={<ChatDetail />} />

        <Route path="/notifications" element={<NotificationsList notifications={notifications} onDismiss={handleDismiss} />} />
        <Route path="/announcements" element={<AnnouncementsList />} />
        <Route path="/announcements/:id" element={<AnnouncementDetail />} />

        <Route path="/collections/:id" element={<CollegeCollectionDetails />} />
      </Route>
    </Routes>
  )
}

export default AdminApp;
import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import * as React from "react";
import { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { InstitutionDegree } from "../../models";
import useAuth from "../../hooks/useAuth";

const InstitutionDegreeDropdown = ({ value, onChange, error } : { value: InstitutionDegree|null, onChange: (value: InstitutionDegree|null) => void, error: boolean }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [institutionDegrees, setInstitutionDegrees] = useState<InstitutionDegree[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    if (open) {
      const fetchInstitutionDegrees = async () => {
        const token = auth.token();
        const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees`, {
          method: 'GET',
          headers: new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
          }),
        });
        if (response.ok) {
          const json: InstitutionDegree[] = await response.json();
          console.log(json);
          setInstitutionDegrees(json);
        } else {
          const json = await response.json();
          console.error(json);
        }
      }
      fetchInstitutionDegrees();
    }
  }, [open]);

  useEffect(() => {
    const fetchInstitutionDegrees = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees?q=${inputValue}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: InstitutionDegree[] = await response.json();
        console.log(json);
        setInstitutionDegrees(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchInstitutionDegrees();
  }, [inputValue]);

  const handleChange = (event: SyntheticEvent, value: (InstitutionDegree|null), reason: AutocompleteChangeReason) => {
    onChange(value);
  }

  const sortedInstitutionDegrees = institutionDegrees.sort((a, b) => {
    const aName = `${a.institution?.name} ${a.degree?.name}`;
    const bName = `${b.institution?.name} ${b.degree?.name}`;
    return aName.localeCompare(bName);
  });
  
  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Institution Degree"
          required
          error={!value && error}
          helperText={!value && error && 'Select a college and degree'}
        />
      )} 
      value={value}
      options={sortedInstitutionDegrees}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.institution?.name} ${option.degree?.name}`}
      onChange={(event, value, reason) => handleChange(event, value as InstitutionDegree, reason)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  )
}

export default InstitutionDegreeDropdown;

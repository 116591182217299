import { Avatar, Button, Card, CardActionArea, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import { User } from './../../../../models';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { MouseEvent, useState } from "react";
import useAuth from "../../../../hooks/useAuth";

const AdminCard = ({user}: { user: User}) => {  
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const auth = useAuth();

  const handleCancelDelete = () => {
    handleCloseMenu();
    setDeleteOpen(false);
  }

  const handleConfirmDelete = () => {
    handleCloseMenu();
    setDeleteOpen(false);
    deleteAdmin();
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const deleteAdmin = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins/${user.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  if (deleted) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        avatar={<Avatar alt={`${user.first_name} ${user.last_name}`} src={user.avatar?.url} />}
        action={
          <IconButton onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
        }
        title={`${user.first_name} ${user.last_name}`}
        subheader={user.email}
      />

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem component={Link} to={`/admins/${user.id}/edit`}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setDeleteOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={deleteOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Delete this administrator?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this administrator? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default AdminCard;
import { DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface FilterData {
  unified_auditions?: string;
}

const confirmOptions = [
  ['Any', ''],
  ['Yes', 'Yes'],
  ['No', 'No'],
  ['N/A', 'N/A'],
]

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = thisYear - 5; i < thisYear + 5; i++) {
  years.push(i);
}

const FilterInstitutions = ({ title, filters, onCancel, onSave }: { title: string, filters?: FilterData, onCancel: () => void, onSave: (filters: FilterData) => void }) => {

  const [unifiedAuditions, setUnifiedAuditions] = useState(filters?.unified_auditions || '');

  const handleSave = () => {
    const filters: FilterData = {
      unified_auditions: unifiedAuditions,
    };
    onSave(filters);
  }

  const clearFilters = () => {
    setUnifiedAuditions('');
  }

  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FilterAltOutlinedIcon sx={{ pr: 1 }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>{title}</Typography>
          <SecondaryButton
            variant="outlined"
            startIcon={<CancelOutlinedIcon />}
            onClick={clearFilters}
          >
            Clear
          </SecondaryButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ py: 2 }}>

          <FormControl>
            <InputLabel id="unifiedAuditions">Unified Auditions</InputLabel>
            <Select
              labelId="unifiedAuditions"
              label="Unified Auditions"
              value={unifiedAuditions}
              onChange={(e) => setUnifiedAuditions(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{ width: 1 }} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{ width: 1 }} onClick={handleSave}>Apply</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default FilterInstitutions;
export { FilterData };
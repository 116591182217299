import { Avatar, Box, CardHeader, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useEffect, useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { User } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const AdminDetail = () => {
  const params = useParams();
  const [admin, setAdmin] = useState<User>();
  const auth = useAuth();

  useEffect(() => {
    const fetchAdmin = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAdmin(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchAdmin();
  }, []);

  if (!admin) {
    return <div/>
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to="/config/admins"
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Typography variant="subtitle2">
            Administrators
          </Typography>
        </Toolbar>
      </ResponsiveAppBar>
      
      <Box>
        <Toolbar />
        <CardHeader
          avatar={<Avatar alt={`${admin.first_name} ${admin.last_name}`} src={admin.avatar?.url} />}
          title={`${admin.first_name} ${admin.last_name}`}
          subheader={admin.email}
          action={
            <IconButton component={Link} to={`/admins/${admin.id}/edit`}>
              <EditOutlinedIcon/>
            </IconButton>
          }
        />
      </Box>
    </Box>
  );
}

export default AdminDetail;
import { Box, Card, Link, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import PrimaryButton from "../controls/PrimaryButton";
import logo from '../logo.svg';
import { UserPayload } from "../../providers/AuthProvider";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [error, setError] = useState<string|null>(null);

  const locationState: any = location.state || {};
  const from = locationState.from?.pathname || "/";
  const search = locationState.from?.search || '';
  const redirectUrl = from + search;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const password = formData.get("password") as string;

    if (email && password) {
      auth.signin(email, password, (user: UserPayload|null) => {
        if (user) {
          navigate(redirectUrl, { replace: true });
        } else {
          setError("You entered an invalid email or password. Please try again or reset your password.");
        }
      });
    }
    
  }

  return (
    <Box sx={{ 
      p: 2, 
      position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, 
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: 'grey.100',
    }}>
      <Card sx={{ width: 1, p: 2, maxWidth: 400 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{alignItems: 'center'}}>
            <img src={logo} alt="ArtsBridge College Guide" width="270" height="65" />
            <Typography variant="h5">Sign into your account</Typography>

            {error && <Typography variant="body1" color="error">{error}</Typography>}

            <TextField
              name="email"
              label="Email Address"
              type="email"
              autoComplete="email"
              fullWidth
              required
            />

            <TextField
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              fullWidth
              required
            />

            <PrimaryButton 
              variant="contained" 
              type="submit"
              fullWidth
            >
              Sign In
            </PrimaryButton>

            <Link href="/forgot_password">Forgot password?</Link>
          </Stack>
        </form>
      </Card>
    </Box>
  )
}

export default Login;
import { Avatar, Dialog, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { format, parseISO } from "date-fns";
import { DocumentFile, SharedDocument } from "../../models";
import ShareDocument from "./ShareDocument";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import useAuth from "../../hooks/useAuth";

const SharedDocumentItem = ({ sharedDocument, onChange, isAdmin }: { sharedDocument: SharedDocument, onChange: (sharedDocument: SharedDocument) => void, isAdmin: boolean }) => {  
  const [anchorElDocument, setAnchorElDocument] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const auth = useAuth();
  
  const deleteSharedDocument = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/shared_documents/${sharedDocument.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const handleOpenDocumentMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElDocument(event.currentTarget);
  };

  const handleCloseDocumentMenu = () => {
    setAnchorElDocument(null);
  };

  const handleShareDocument = (documents: DocumentFile[]) => {
    setShareOpen(false);
  }

  if (deleted) {
    return null;
  }

  const document = sharedDocument.document;

  if (!document) return null;

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1} paddingBottom={1}>
      <InsertDriveFileOutlinedIcon color="primary" />
      <Stack sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>{document.name}</Typography>
        <Typography variant="body2">
          {document.user &&
            <span>
              {`${document.user?.first_name} ${document.user?.last_name}`}
              {' '}
            </span>
          }
          {document.created_at && format(parseISO(document.created_at), 'MMM d, yyyy')}
        </Typography>
        {document.attachment?.url && (
          <Link variant="body2" href={document.attachment.url}>Download</Link>
        )}
        {document.url && (
          <Link variant="body2" href={document.url}>Link</Link>
        )}
        {document.assessment &&
          <Stack direction="row" spacing={1} sx={{ pt: 1, alignItems: 'center' }}>
            <Avatar sx={{ width: 24, height: 24 }} src={document.assessment?.institution_degree?.institution?.institution_avatar?.url} alt={`${document.assessment?.institution_degree?.institution?.name} ${document.assessment?.institution_degree?.degree?.name}`} />
            <Link variant="body2" color="inherit" underline="hover" href={`/assessments/${document.assessment.id}/documents`}>{`${document.assessment?.institution_degree?.institution?.name} ${document.assessment?.institution_degree?.degree?.name}`}</Link>
          </Stack>
        }
      </Stack>
      <IconButton onClick={handleOpenDocumentMenu} sx={{ p: 0 }}><MoreVertIcon/></IconButton>
      <Menu
        anchorEl={anchorElDocument}
        keepMounted
        open={Boolean(anchorElDocument)}
        onClose={handleCloseDocumentMenu}
      >
        {isAdmin &&
          <MenuItem onClick={() => setShareOpen(true)}>
            <ListItemIcon>
              <ContactPageOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Sharing</ListItemText>
          </MenuItem>
        }

        <MenuItem onClick={() => deleteSharedDocument()}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Unshare</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={shareOpen} fullWidth>
        <ShareDocument document={document} onCancel={() => setShareOpen(false)} onShare={handleShareDocument} isAdmin={isAdmin} />
      </Dialog>
    </Stack>
  )
}

export default SharedDocumentItem;
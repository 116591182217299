import { Card, CardContent, Container, Grid, Stack, Typography } from "@mui/material";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import * as React from "react";
import { Institution } from "../../../../models";
import EditableRichNotes from "../../../controls/EditableRichNotes";
import RichTextViewer from "../../../controls/RichTextViewer";

const InstitutionInfo = ({ institution, onChange }: { institution: Institution, onChange: (institution: Institution) => void }) => {

  const isValid = isValidPhoneNumber(institution.central_office_phone || '');
  const phoneNumber = isValid ? parsePhoneNumber(institution.central_office_phone || '') : null;
  const parsedPhoneNumber = phoneNumber ? phoneNumber.formatInternational() : institution.central_office_phone

  return (
    <Container sx={{py: 2}}>
      <Stack spacing={2}>
        <Card>
          <CardContent>
            <Grid container rowSpacing={1} columnSpacing={1}>
              {institution.updated_at &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Last Updated</Typography>
                  <Typography variant="body2">{institution.updated_at}</Typography>
                </Grid>
              }

              {institution.city &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">City</Typography>
                  <Typography variant="body2">{institution.city}</Typography>
                </Grid>
              }
              
              {institution.state &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">State</Typography>
                  <Typography variant="body2">{institution.state}</Typography>
                </Grid>
              }

              {institution.country &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Country</Typography>
                  <Typography variant="body2">{institution.country}</Typography>
                </Grid>
              }

              {parsedPhoneNumber &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Main Admissions Office Phone</Typography>
                  <Typography variant="body2">{parsedPhoneNumber}</Typography>
                </Grid>
              }

              {institution.central_office_email &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Main Admissions Office Email</Typography>
                  <Typography variant="body2">{institution.central_office_email}</Typography>
                </Grid>
              }

              {institution.application_type &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Application Type</Typography>
                  <Typography variant="body2">{institution.application_type}</Typography>
                </Grid>
              }

              {institution.application_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Application URL</Typography>
                  <Typography variant="body2">{institution.application_url}</Typography>
                </Grid>
              }

              {institution.common_app &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Common Application Institution</Typography>
                  <Typography variant="body2">{institution.common_app}</Typography>
                </Grid>
              }

              {institution.coalition_application_institution_url &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Coalition Application Website</Typography>
                  <Typography variant="body2">{institution.coalition_application_institution_url}</Typography>
                </Grid>
              }

              {institution.campus_visit_information &&
                <Grid item xs={6}>
                  <Typography variant="subtitle2">Campus Visit Information</Typography>
                  <RichTextViewer value={institution.campus_visit_information} /> 
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant="subtitle2">Comments About The College</Typography>
            <EditableRichNotes 
              endpoint={`institutions/${institution.id}`} 
              field="other" 
              value={institution.other} 
              onChange={(value) => { onChange({ ...institution, other: value }) }}
            />
          </CardContent>
        </Card>
        
        <Card>
          <CardContent>
            <Typography variant="subtitle2">Private College Notes</Typography>
            <EditableRichNotes 
              endpoint={`institutions/${institution.id}`} 
              field="private_institution_notes" 
              value={institution.private_institution_notes} 
              onChange={(value) => { onChange({ ...institution, private_institution_notes: value }) }}
            />
          </CardContent>
        </Card>
      </Stack>
    </Container>
  )
}

export default InstitutionInfo;
import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import * as React from "react";
import { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { Degree } from "../../models";
import useAuth from "../../hooks/useAuth";

const DegreeDropdown = ({ value, onChange } : { value: Degree|null, onChange: (value: Degree|null) => void }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [degrees, setDegrees] = useState<Degree[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    if (open) {
      const fetchDegrees = async () => {
        const token = auth.token();
        const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees`, {
          method: 'GET',
          headers: new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
          }),
        });
        if (response.ok) {
          const json: Degree[] = await response.json();
          console.log(json);
          setDegrees(json);
        } else {
          const json = await response.json();
          console.error(json);
        }
      }
      fetchDegrees();
    }
  }, [open]);

  useEffect(() => {
    const fetchDegrees = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees?q=${inputValue}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: Degree[] = await response.json();
        console.log(json);
        setDegrees(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchDegrees();
  }, [inputValue]);

  const handleChange = (event: SyntheticEvent, value: (Degree|null), reason: AutocompleteChangeReason) => {
    onChange(value);
  }

  const sortedDegrees = degrees.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  if (value) {
    if (!sortedDegrees.find((i) => i.id === value.id)) {
      sortedDegrees.push(value);
    }
  }
  
  return (
    <Autocomplete 
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Degree"
        />
      )} 
      value={value}
      options={sortedDegrees}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (typeof option === 'string') ? option : option.name}
      onChange={(event, value, reason) => handleChange(event, value as Degree, reason)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  )
}

export default DegreeDropdown;

import { Button, Checkbox, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Announcement, User } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import StudentsDropdown from "../filters/StudentsDropdown";
import FindStudentsDialog from "../filters/FindStudentsDialog";
import RichTextEditor from "../controls/RichTextEditor";

const AnnouncementForm = ({ announcement, onCancel, onSave }: { announcement?: Announcement, onCancel: () => void, onSave: (announcement: Announcement) => void }) => {
  
  const [title, setTitle] = useState(announcement?.title || '');
  const [message, setMessage] = useState(announcement?.message || '');
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [findStudentsOpen, setFindStudentsOpen] = useState(false);
  const [includeCollaborators, setIncludeCollaborators] = useState(false);

  const handleSave = () => {
    const announcement: Announcement = {
      title,
      message,
      user_ids: selectedUsers.map(u => u.id || 0).filter(id => id > 0),
      include_collaborators: includeCollaborators,
    }
    onSave(announcement);
  }

  const handleAddStudents = (students: User[]) => {
    setSelectedUsers(students);
    setFindStudentsOpen(false);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          <TextField 
            label="Title" 
            variant="outlined" 
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required />

          {/* <TextField 
            label="Message" 
            variant="outlined" 
            value={message}
            multiline
            rows={4}
            onChange={(e) => setMessage(e.target.value)}
            required /> */}
          
          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Message</FormLabel>
            <RichTextEditor value={message} onChange={setMessage} />
          </FormControl>

          <StudentsDropdown value={selectedUsers} onChange={setSelectedUsers} />
          <Button variant="outlined" onClick={() => setFindStudentsOpen(true)}>Find Students</Button>
          {selectedUsers.length > 0 &&
            <FormControlLabel control={<Checkbox checked={includeCollaborators} onChange={() => setIncludeCollaborators(!includeCollaborators)} />} label="Include Collaborators" />
          }
         
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
      <Dialog open={findStudentsOpen} fullWidth>
        <FindStudentsDialog students={selectedUsers} onCancel={() => setFindStudentsOpen(false)} onAdd={handleAddStudents} />
      </Dialog>
    </>
  )
}

export default AnnouncementForm;
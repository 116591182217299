import Assessment from "./Assessment";
import Collaboration from "./Collaboration";
import DocumentFile from "./DocumentFile";
import Folder from "./Folder";
import StandardizedTest from "./StandardizedTest";
import Task from "./Task";

export default interface User {
  id?: number;
  first_name: string;
  last_name: string;
  email: string;
  avatar?: {
    url: string;
  };
  biography: string;
  paid_student?: boolean;
  high_school_graduation_year?: number;
  actual_graduation_year?: number;
  notes_from?: string;
  meeting_notes?: string;
  assessments?: Assessment[];
  gap_year?: boolean;
  transfer_student?: boolean;
  transfer_deadline?: string;
  transfer_website?: string;
  transfer_notes?: string;
  high_school?: string;
  intended_college_major?: string;
  artsbridge_programs_attended?: string;
  phone?: string;
  tasks?: Task[];
  documents?: DocumentFile[];
  folders?: Folder[];
  updated_at?: string;

  collaborations?: Collaboration[];
  invitations?: Collaboration[];

  file?: File;

  standardized_tests?: StandardizedTest[];

  consultant_id?: number;
  consultant?: User;

  admin?: boolean;
  superadmin?: boolean;

  archived?: boolean;

  notify?: boolean;
}

const collegeMajors = [
  'Acting',
  'Creative Writing',
  'Dance',
  'Design and Production',
  'Fashion Design',
  'Film Studies',
  'Graphic Design',
  'Music - Comp',
  'Music - Industry/Business',
  'Music - Instrumental',
  'Music - Production',
  'Music - Songwriting',
  'Music - Voice',
  'Musical Theater',
  'Theater Arts',
  'Visual Arts',
  'Visual Arts - Animation',
  'Undecided',
];

export { collegeMajors }
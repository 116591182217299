import { Dialog, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import Folder from "../../models/Folder";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditFolder from "../admin/documents/EditFolder";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAuth from "../../hooks/useAuth";

const FolderItem = ({folder, root = '/documents'}: { folder: Folder, root?: string}) => {
  const [folderItem, setFolderItem] = useState(folder);
  const [anchorElFolder, setAnchorElFolder] = useState<null | HTMLElement>(null);
  const [editOpen, setEditOpen] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const auth = useAuth();
  
  const handleOpenFolderMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElFolder(event.currentTarget);
  };

  const handleCloseFolderMenu = () => {
    setAnchorElFolder(null);
  };

  const handleSaveEdit = async (updatedFolder: Folder) => {
    setFolderItem(updatedFolder);
    setEditOpen(false);
  };

  const deleteFolder = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/folders/${folder.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  if (deleted) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="flex-start" spacing={1} paddingBottom={1}>
      <FolderOutlinedIcon color="primary" />
      <Link href={`${root}/${folder.id}`} variant="subtitle1" sx={{ flexGrow: 1, fontWeight: 500, textDecoration: 'none' }}>{folderItem.name}</Link>
      <IconButton onClick={handleOpenFolderMenu} sx={{ p: 0 }}><MoreVertIcon/></IconButton>
      <Menu
        anchorEl={anchorElFolder}
        keepMounted
        open={Boolean(anchorElFolder)}
        onClose={handleCloseFolderMenu}
      >
        <MenuItem onClick={() => {
          setEditOpen(true)
          handleCloseFolderMenu()
        }}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteFolder()}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={editOpen} fullWidth>
        <EditFolder folder={folderItem} onCancel={() => setEditOpen(false)} onSave={handleSaveEdit} />
      </Dialog>
    </Stack>
  )
}

export default FolderItem;

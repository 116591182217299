import * as React from "react";
import { DocumentFile } from "../../../models";
import DocumentForm from "../../documents/DocumentForm";
import useAuth from "../../../hooks/useAuth";

const UploadDocument = ({ userId, onCancel, onSave, showUsers, assessmentId, folderId }: { userId: number, onCancel: () => void, onSave: (documents: DocumentFile[]) => void, showUsers: boolean, assessmentId?: number, folderId: number|null }) => {
  const auth = useAuth();
  
  const createDocument = async (document: DocumentFile) => {
    const token = auth.token();
    const formData = new FormData();
    if (document.file) {
      formData.append('attachment', document.file);
    }
    formData.append('name', document.name);
    if (document.user_ids && document.user_ids.length > 0) {
      formData.append('user_ids', document.user_ids.toString());
    } else {
      formData.append('user_id', userId.toString());
    }
    if (folderId) {
      formData.append('folder_id', folderId.toString());
    }
    if (assessmentId) {
      formData.append('assessment_id', assessmentId.toString());
    }
    if (document.url) {
      formData.append('url', document.url);
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/batch`, {
      method: 'POST',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <DocumentForm onCancel={handleCancel} onSave={createDocument} showUsers={showUsers} />
  )
}

export default UploadDocument;
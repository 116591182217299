import { Box, Breadcrumbs, IconButton, Tab, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useState } from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Link, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../../controls/ResponsiveAppBar";
import { TabContext, TabPanel } from "@mui/lab";
import RoundedBottomTabs from "../../controls/RoundedBottomTabs";
import AnnouncementsList from "../../announcements/AnnouncementsList";
import ChatsList from "./ChatsList";
import useRouteMatch from "../../../hooks/useRouteMatch";

const Messages = () => {
  const navigate = useNavigate();
  const routeMatch = useRouteMatch(['/messages/announcements', '/messages/chats']);
  const [value, setValue] = useState(routeMatch?.pattern?.path || '/messages/announcements');   

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => navigate('/')}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Messages
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <TabContext value={value}>
          <RoundedBottomTabs 
            value={value} 
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab sx={{ textTransform: 'none' }} label="Announcements" value="/messages/announcements" component={Link} to="/messages/announcements" />
            <Tab sx={{ textTransform: 'none' }} label="Chats" value="/messages/chats" component={Link} to="/messages/chats" />
          </RoundedBottomTabs>
          <TabPanel value="/messages/announcements" sx={{p: 0}}>
            <AnnouncementsList />
          </TabPanel>
          <TabPanel value="/messages/chats" sx={{p: 0}}>
            <ChatsList />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default Messages;
import * as React from 'react';
import AuthContext from '../contexts/AuthContext';
import jwt_decode from "jwt-decode";
import { ReactNode, useState } from 'react';
import { decode } from '../lib/jwt';

export interface UserPayload {
  exp: number;
  id: number;
  displayName: string;
  email: string;
  photoURL: string;
  admin: boolean;
  superadmin: boolean;
}

const getUserFromToken = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const payload = jwt_decode<UserPayload>(token);
    if (payload.exp * 1000 > Date.now()) {
      return payload;
    }
  }
  return null;
}

const devise = {
  signin: async (email: string, password: string, callback: (json: any) => void) => {
    const body = { user: { email, password } };
    const response = await fetch(`${window.SERVER_DATA.domain}/users/sign_in`, {
      method: 'POST',
      headers: new Headers({'content-type': 'application/json'}),
      body: JSON.stringify(body),
    });
    const authorization = response.headers.get('authorization');
    if (authorization) {
      const token = authorization.split(' ')[1];
      callback(token);
    } else {
      callback(null);
    }
  },
  refresh: async(callback: (json: any) => void) => {
    const token = localStorage.getItem('token');
    const body = {};
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/auth/refresh`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    const authorization = response.headers.get('authorization');
    if (authorization) {
      const token = authorization.split(' ')[1];
      callback(token);
    } else {
      callback(null);
    }
  },
  signout: async (callback: VoidFunction) => {
    const response = await fetch(`${window.SERVER_DATA.domain}/users/sign_out`, {
      method: 'DELETE',
    });
    callback();
  },
  become: async (id: string, callback: (json: any) => void) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${id}/become`, {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    const authorization = response.headers.get('authorization');
    if (authorization) {
      const token = authorization.split(' ')[1];
      callback(token);
    } else {
      callback(null);
    }
  },
  reset: async (resetPasswordToken: string, password: string, passwordConfirmation: string, callback: (json: any) => void) => {
    const body = { reset_password_token: resetPasswordToken, password: password, password_confirmation: passwordConfirmation };
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/password`, {
      method: 'PATCH',
      headers: new Headers({'content-type': 'application/json'}),
      body: JSON.stringify(body),
    });
    const authorization = response.headers.get('authorization');
    if (authorization) {
      const token = authorization.split(' ')[1];
      callback(token);
    } else {
      callback(null);
    }
  },
}

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(getUserFromToken());

  const signin = (email: string, password: string, callback: (user: UserPayload|null) => void) => {
    return devise.signin(email, password, (token) => {
      if (token) {
        localStorage.setItem('token', token);
        const user = getUserFromToken();
        setUser(user);
        callback(user);
      } else {
        localStorage.removeItem('token');
        setUser(null);
        callback(null);
      }
    });
  };

  const refresh = () => {
    return devise.refresh((token) => {
      if (token) {
        localStorage.setItem('token', token);
        const user = getUserFromToken();
        setUser(user);
      } else {
        localStorage.removeItem('token');
        setUser(null);
      }
    });
  };

  const signout = (callback: VoidFunction) => {
    return devise.signout(() => {
      localStorage.removeItem('token');
      setUser(null);
      callback();
    });
  };

  const become = (id: string, callback: VoidFunction) => {
    return devise.become(id, (token) => {
      if (token) {
        localStorage.setItem('token', token);
        const user = getUserFromToken();
        setUser(user);
      } else {
        localStorage.removeItem('token');
        setUser(null);
      }
      callback();
    });
  }

  const token = () => {
    const token = localStorage.getItem('token');
    if (!token) return null;
    const { exp } = decode(token);
    if (exp * 1000 < Date.now() + 30_000) {
      refresh();
    }
    return token;
  }

  const reset = (resetPasswordToken: string, password: string, passwordConfirmation: string, callback: (user: UserPayload|null) => void) => {
    return devise.reset(resetPasswordToken, password, passwordConfirmation, (token) => {
      if (token) {
        localStorage.setItem('token', token);
        const user = getUserFromToken();
        setUser(user);
        callback(user);
      } else {
        localStorage.removeItem('token');
        setUser(null);
        callback(null);
      }
    });
  }

  const value = { user, signin, refresh, signout, become, token, reset };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AdminForm from "./AdminForm";
import { User } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const EditAdmin = () => {
  const params = useParams();
  const [admin, setAdmin] = useState<User>();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const fetchAdmin = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setAdmin(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchAdmin();
  }, []);

  const updateAdmin = async (user: User) => {
    const token = auth.token();
    // const body = {
    //   user: {
    //     first_name: user.first_name,
    //     last_name: user.last_name,
    //     email: user.email,
    //     biography: user.biography,
    //   }
    // }
    const formData = new FormData();
    if (user.file) formData.append('avatar', user.file);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('email', user.email);
    formData.append('biography', user.biography);
    formData.append('superadmin', user.superadmin ? 'true' : 'false');
    formData.append('notify', user.notify ? 'true' : 'false');
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins/${params.id}`, {
      method: 'PATCH',
      headers: new Headers({
        // 'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      // body: JSON.stringify(body),
      body: formData,
    });
    if (response.ok) {
      navigate(`/config/admins`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/config/admins`);
  }

  if (!admin) {
    return (<div/>)
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/config/admins`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Administrators
            </Typography>
            <Typography variant="subtitle2">
              {`${admin.first_name} ${admin.last_name}`}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <AdminForm user={admin} onCancel={handleCancel} onSave={updateAdmin} />
      </Box>
    </Box>
  )
}

export default EditAdmin;
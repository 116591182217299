import { Avatar, Button, Card, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Collaboration } from "../../models";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { MouseEvent, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const CollaborationItem = ({ collaboration, isAdmin }: { collaboration: Collaboration, isAdmin: boolean }) => {  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCancelDelete = () => {
    handleCloseMenu();
    setDeleteOpen(false);
  }

  const handleConfirmDelete = () => {
    handleCloseMenu();
    setDeleteOpen(false);
    deleteCollaboration();
  }

  const deleteCollaboration = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/collaborations/${collaboration.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const signInAsCollaborator = async () => {
    if (!collaboration.collaborator_id) return;
    auth.become(collaboration.collaborator_id.toString(), () => {
      navigate('/', { replace: true });
    });
  }

  if (deleted) {
    return null;
  }

  const firstName = collaboration.collaborator ? collaboration.collaborator.first_name : collaboration.first_name ? collaboration.first_name : undefined;
  const lastName = collaboration.collaborator ? collaboration.collaborator.last_name : collaboration.last_name ? collaboration.last_name : undefined;
  const fullName = firstName && lastName ? `${firstName} ${lastName}` : firstName ? firstName : lastName ? lastName : undefined;
  const name = fullName ? fullName : collaboration.collaborator ? collaboration.collaborator.email : collaboration.email;

  return (
    <Card>
      {collaboration.collaborator ? (
        <CardHeader
          avatar={<Avatar alt={`${name}`} src={collaboration.collaborator.avatar?.url} />}
          action={
            <IconButton onClick={handleOpenMenu}><MoreVertIcon /></IconButton>
          }
          title={`${name}`}
          subheader={collaboration.collaborator.email}
        />
      ) : (
        <CardHeader
          avatar={<PersonAddIcon sx={{width: '40px'}} />}
          action={
            <IconButton onClick={handleOpenMenu}><MoreVertIcon /></IconButton>
          }
          title={`${name}`}
          subheader={
            <>
              <Typography variant="body2" color="text.secondary">{collaboration.email}</Typography>
              <Typography variant="subtitle2">{collaboration.invitation_accepted_at ? 'Accepted' : 'Waiting for response'}</Typography>
            </>
          }
        />
      )}
      
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => setDeleteOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Remove</ListItemText>
        </MenuItem>

        {isAdmin &&
          <MenuItem onClick={() => signInAsCollaborator()}>
            <ListItemIcon>
              <DeleteOutlineOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Sign In As Collaborator</ListItemText>
          </MenuItem>
        }

      </Menu>

      <Dialog
        open={deleteOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Remove this collaborator?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this collaborator? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default CollaborationItem;
import { Avatar, AvatarGroup, Box, Card, CardActionArea, CardContent, CardHeader, CardMedia, IconButton, Typography } from "@mui/material";
import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import { Institution } from "../../../../models";

const CollegeCard = ({ institution }: { institution: Institution }) => {

  const degreeList = [...new Set(institution.assessments?.map(a => a.institution_degree?.degree?.name))].join(', ');
  const users: string[] = [...new Set(institution.assessments?.map(a => `${a.user?.first_name} ${a.user?.last_name}`) || [])];
  const maxUsers = users.slice(0, 3);
  let avatarNames = maxUsers.join(', ');
  const extraUsers = users.length - maxUsers.length;
  if (extraUsers > 0) {
    avatarNames += `, and ${extraUsers} others`;
  }
  
  return (
    <Card>
      <CardActionArea component={Link} to={`/institutions/${institution.id}`}>
        <CardMedia
          component="img"
          height="140"
          image={institution.institution_avatar?.url}
          alt={institution.name}
        />

        <CardHeader
          title={institution.name}
          subheader={degreeList}
          // action={
          //   <IconButton>
          //     <MoreVertIcon />
          //   </IconButton>
          // }
        />
      </CardActionArea>

      <CardContent>
        <Box sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
          <AvatarGroup max={4} sx={{mr: 1}}>
            {institution.assessments?.map(assessment => (
              <Avatar key={assessment.id} alt={`${assessment.user?.first_name} ${assessment.user?.last_name}`} src={assessment.user?.avatar?.url} />
            ))}
          </AvatarGroup>
          <Typography variant="body2">{avatarNames}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default CollegeCard;
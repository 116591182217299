import { Alert, Avatar, Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Snackbar, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import { Task } from "../../models";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EditTask from "./EditTask";
import { formatTaskType } from "../../models/Task";
import RichTextViewer from "../controls/RichTextViewer";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import useAuth from "../../hooks/useAuth";
import { format, formatDistance, parse, parseISO } from "date-fns";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import ShareTask from "./ShareTask";

const TaskItem = ({ task, onChange, showUser = false }: { task: Task, onChange: (task: Task) => void, showUser?: boolean }) => {  
  const [completedAt, setCompletedAt] = useState<string|null>(task.completed_at || null);
  const [anchorElTask, setAnchorElTask] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [remindedAt, setRemindedAt] = useState(task.reminded_at ? parseISO(task.reminded_at) : null);
  const [shareOpen, setShareOpen] = useState(false);
  const [sendReminderOpen, setSendReminderOpen] = useState(false);
  const [includeCollaborators, setIncludeCollaborators] = useState(false);
  const [answerOpen, setAnswerOpen] = useState(false);
  const [answer, setAnswer] = useState(task.answer);

  const auth = useAuth();

  const completed = completedAt !== null;
  
  const completeTask = async () => {
    if (task.task_type === 'question') {
      setAnswerOpen(true);
      return;
    }

    const token = auth.token();

    const now = new Date().toISOString();
    const newValue = completed ? null : now;

    let body = {
      completed_at: newValue
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      setCompletedAt(newValue);
      onChange({ ...task, completed_at: newValue ? newValue : undefined });
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const handleCancelDelete = () => {
    handleCloseTaskMenu();
    setDeleteOpen(false);
  }

  const handleConfirmDelete = () => {
    handleCloseTaskMenu();
    setDeleteOpen(false);
    deleteTask();
  }

  const deleteTask = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
      onChange({ ...task, deleted_at: new Date().toISOString() });
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const handleOpenTaskMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElTask(event.currentTarget);
  };

  const handleCloseTaskMenu = () => {
    setAnchorElTask(null);
  };

  const handleSave = (updatedTask: Task) => {
    setOpen(false);
    onChange(updatedTask);
  }

  const sendReminder = async () => {
    const token = auth.token();

    const body = {
      include_collaborators: includeCollaborators
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}/remind`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      handleCloseTaskMenu();
      setOpenToast(true);
      setRemindedAt(new Date());
      setSendReminderOpen(false);
      setIncludeCollaborators(false);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCloseToast = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const handleShareTask = (tasks: Task[]) => {
    setShareOpen(false);
  }

  const handleSendReminderClose = () => {
    setSendReminderOpen(false);
  }

  const handleAnswerClose = () => {
    setAnswerOpen(false);
    setAnswer(task.answer);
  }

  const saveAnswer = async () => {
    const token = auth.token();

    const now = new Date().toISOString();

    let body = {
      answer: answer,
      completed_at: now
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/${task.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      setCompletedAt(now);
      onChange({ ...task, completed_at: now, answer: answer });
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  if (deleted) {
    return null;
  }

  let filename = (task.documents || []).length > 0 && task.documents[0].attachment_identifier;
  if (!filename) filename = task.attachment && task.attachment_identifier;
  let fileUrl = (task.documents || []).length > 0 && task.documents[0].attachment?.url;
  if (!fileUrl) fileUrl = task.attachment && task.attachment.url;

  const isAdmin = auth.user?.admin || auth.user?.superadmin || false;
  const userUrl = isAdmin ? `/admins/${task.user_id}` : `/students/${task.user_id}`;

  const isTaskOrQuestion = task.task_type === 'task' || task.task_type === 'question';
  const taskTitle = task.task_type && !isTaskOrQuestion ? formatTaskType(task.task_type) : task.title;

  const today = new Date();
  const dueDate = task.due_on ? new Date(task.due_on) : null;
  const pastDue = dueDate && (dueDate.valueOf() < today.valueOf());
  const showDueDate = task.due_on && completed;

  return (
    <Box>
      <Stack direction="row" alignItems="flex-start" spacing={1}>
        <Checkbox 
          checked={completed} 
          onClick={() => completeTask()} 
          sx={{ pt: 0 }}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
        />
        <Stack sx={{flexGrow: 1}} spacing={0}>

          <Typography variant="subtitle2" style={{fontSize: 14, fontWeight: 500}}>
            {taskTitle}
          </Typography>
          
          {task.notes && <RichTextViewer value={task.notes} /> }

          {(showUser || task.assessment) &&
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              {showUser && task.user?.id !== auth.user.id &&
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Avatar sx={{ width: 24, height: 24 }} src={task.user?.avatar?.url} alt={`${task.user?.first_name} ${task.user?.last_name}`} />
                  <Link variant="body2" color="inherit" underline="hover" href={userUrl}>{`${task.user?.first_name} ${task.user?.last_name}`}</Link>
                </Stack>
              }
              {showUser && task.user?.id == auth.user.id &&
                <Typography variant="body2">Me</Typography>
              }
              {task.assessment &&
                <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
                  <Avatar sx={{ width: 24, height: 24 }} src={task.assessment?.institution_degree?.institution?.institution_avatar?.url} alt={`${task.assessment?.institution_degree?.institution?.name} ${task.assessment?.institution_degree?.degree?.name}`} />
                  <Link variant="body2" color="inherit" underline="hover" href={`/assessments/${task.assessment.id}/tasks`}>{`${task.assessment?.institution_degree?.institution?.name} ${task.assessment?.institution_degree?.degree?.name}`}</Link>
                </Stack>
              }
            </Stack>
          }
          
          {filename &&
            <Stack spacing={1} sx={{ flexGrow: 1, alignItems: 'center' }} direction="row">
              <InsertDriveFileOutlinedIcon />
              <Typography variant="body2" component={Link} href={fileUrl}>{filename}</Typography>
            </Stack>
          }
          {task.url && (
            <Link variant="body2" href={task.url}>Link</Link>
          )}

          {task.task_type === 'question' &&
            <>
              {task.answer ?
                <Typography variant="body2" sx={{ cursor: 'pointer' }} component={Link} onClick={() => setAnswerOpen(true)}>{task.answer}</Typography>
                :
                <Typography variant="body2" sx={{ cursor: 'pointer' }} component={Link} onClick={() => setAnswerOpen(true)}>Answer Question</Typography>
              }
            </>
          }

        </Stack>

        
        {remindedAt && auth.user.admin && !completed &&
          <Chip label={`Reminded ${formatDistance(remindedAt, new Date())} ago`} variant="filled" size="small" />
        }

        {showDueDate &&
          <Typography variant="body2" color="text.secondary" noWrap={true} sx={{ flexShrink: 0 }}>
            {format(parse(task.due_on, 'yyyy-MM-dd', new Date()), "MMM d, yyyy")}
          </Typography>
        }

        {pastDue && !completed &&
          <Chip label="Past Due" variant="filled" size="small" color="error" />
        }

        <IconButton onClick={handleOpenTaskMenu} sx={{ p: 0, ml: 1 }}><MoreVertIcon/></IconButton>
        <Menu
          anchorEl={anchorElTask}
          keepMounted
          open={Boolean(anchorElTask)}
          onClose={handleCloseTaskMenu}
        >
          <MenuItem onClick={() => setOpen(true)}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => setShareOpen(true)}>
            <ListItemIcon>
              <ContactPageOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
          </MenuItem>
          {task.user?.id !== auth.user.id && isAdmin &&
            <MenuItem onClick={() => setSendReminderOpen(true)}>
              <ListItemIcon>
                <EmailOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Send Reminder</ListItemText>
            </MenuItem>
          }
          {(task.user?.id === auth.user.id || isAdmin) &&
            <MenuItem onClick={() => setDeleteOpen(true)}>
              <ListItemIcon>
                <DeleteOutlineOutlinedIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          }
        </Menu>
      </Stack>

      <Dialog open={open} fullWidth>
        <EditTask task={task} onCancel={() => setOpen(false)} onSave={handleSave} />
      </Dialog>

      <Dialog
        open={deleteOpen}
        onClose={handleCancelDelete}
      >
        <DialogTitle>Delete this task?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this task? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleConfirmDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={shareOpen} fullWidth>
        <ShareTask task={task} onCancel={() => setShareOpen(false)} onShare={handleShareTask} isAdmin={isAdmin} />
      </Dialog>

      <Dialog
        open={sendReminderOpen}
        onClose={handleSendReminderClose}
      >
        <DialogTitle>Send Reminder</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Send a reminder to complete this task?
          </DialogContentText>
          <FormControlLabel control={<Checkbox checked={includeCollaborators} onChange={() => setIncludeCollaborators(!includeCollaborators)} />} label="Include collaborators" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendReminderClose}>Cancel</Button>
          <Button type="submit" onClick={() => sendReminder()}>Send</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={answerOpen}
        onClose={handleAnswerClose}
      >
        <DialogTitle>{task.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }} />
          <TextField 
            label="Your Answer"
            variant="outlined" 
            value={answer ?? ''}
            onChange={(e) => setAnswer(e.target.value)}
            fullWidth
            sx={{ minWidth: 300 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAnswerClose}>Cancel</Button>
          <Button type="submit" onClick={() => saveAnswer()}>Submit</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success" sx={{ width: '100%' }}>
          Sent reminder!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default TaskItem;
import { Avatar, Box, Button, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tab, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useEffect, useState, MouseEvent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import InstitutionInfo from "./InstitutionInfo";
import InstitutionDegrees from "./InstitutionDegrees";
import InstitutionAssessments from "./InstitutionAssessments";
import RoundedBottomTabs from "../../../controls/RoundedBottomTabs";
import useRouteMatch from "../../../../hooks/useRouteMatch";
import { Institution } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const InstitutionDetail = () => {
  const params = useParams();
  const [institution, setInstitution] = useState<Institution>();
  const routeMatch = useRouteMatch([`/institutions/${params.id}`, `/institutions/${params.id}/degrees`, `/institutions/${params.id}/applications`]);
  const [value, setValue] = useState(routeMatch?.pattern?.path || `/institutions/${params.id}`);
  const auth = useAuth();

  const [isArchiveOpen, setArchiveOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstitution = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institutions/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setInstitution(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchInstitution();
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleConfirmArchive = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institutions/${institution.id}/archive`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      // TODO: navigate to list of colleges
      navigate('/config/colleges');
    } else {
      const json = await response.json();
      console.error(json);
    }

    setArchiveOpen(false);
  }

  if (!institution) {
    return <div/>
  }
  
  const degreeList = institution.institution_degrees?.map(i => i.degree?.name).join(', ');

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to="/config/colleges"
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Typography variant="subtitle2">
            Colleges
          </Typography>
        </Toolbar>
      </ResponsiveAppBar>
      
      <Box>
        <Toolbar />
        <CardHeader
          title={institution.name}
          subheader={degreeList}
          action={
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon/>
            </IconButton>
          }
          avatar={
            <Avatar src={institution.institution_avatar?.url} />
          }
          sx={{ backgroundColor: 'background.default' }}
        />
        <TabContext value={value}>
          <RoundedBottomTabs 
            value={value} 
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Info" value={`/institutions/${params.id}`} component={Link} to={`/institutions/${params.id}`} />
            <Tab label="Degrees" value={`/institutions/${params.id}/degrees`} component={Link} to={`/institutions/${params.id}/degrees`} />
            <Tab label="Applications" value={`/institutions/${params.id}/applications`} component={Link} to={`/institutions/${params.id}/applications`} />
          </RoundedBottomTabs>
          <TabPanel value={`/institutions/${params.id}`} sx={{p: 0}}>
            <InstitutionInfo institution={institution} onChange={(institution) => setInstitution(institution)} />
          </TabPanel>
          <TabPanel value={`/institutions/${params.id}/degrees`} sx={{p: 0}}>
            <InstitutionDegrees institution={institution} />
          </TabPanel>
          <TabPanel value={`/institutions/${params.id}/applications`} sx={{p: 0}}>
            <InstitutionAssessments institution={institution} />
          </TabPanel>
        </TabContext>
      </Box>
      
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem component={Link} to={`/institutions/${institution.id}/edit`}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setArchiveOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={isArchiveOpen}
        onClose={() => setArchiveOpen(false)}
      >
        <DialogTitle>Archive this college?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this college? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmArchive}>Archive</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default InstitutionDetail;
import * as React from "react";
import { Collaboration } from "../../models";
import CollaborationForm from "./CollaborationForm";
import useAuth from "../../hooks/useAuth";

const InviteCollaborator = ({ studentId, onCancel, onSave }: { studentId: number, onCancel: () => void, onSave: (collaboration: Collaboration) => void }) => {
  const auth = useAuth();
  
  const createCollaboration = async (collaboration: Collaboration) => {
    const token = auth.token();
    const body = {
      email: collaboration.email,
      first_name: collaboration.first_name,
      last_name: collaboration.last_name,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${studentId}/collaborations`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <CollaborationForm onCancel={handleCancel} onSave={createCollaboration} />
  )
}

export default InviteCollaborator;
import { Avatar, AvatarGroup, Box, Button, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TableCell, TableRow, Typography } from "@mui/material";
import * as React from "react";
import { Link } from "react-router-dom";
import { Institution } from "../../../../models";
import { FilterData } from "../../../filters/FilterInstitutions";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MouseEvent, useState } from "react";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useAuth from "../../../../hooks/useAuth";

const CollegeListItem = ({ institution, filters }: { institution: Institution, filters: FilterData }) => {  

  const filteredAssessments = institution.assessments?.filter(assessment => !assessment.inactive && !assessment.user?.archived) ?? []
  const degreeList = [...new Set(filteredAssessments.map(a => a.institution_degree?.degree?.name))].join(', ');
  const users: string[] = [...new Set(filteredAssessments.map(a => `${a.user?.first_name} ${a.user?.last_name}`) || [])];
  const maxUsers = users.slice(0, 3);
  let avatarNames = maxUsers.join(', ');
  const extraUsers = users.length - maxUsers.length;
  if (extraUsers > 0) {
    avatarNames += `, and ${extraUsers} others`;
  }
  const [isArchiveOpen, setArchiveOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const auth = useAuth();
  const [isArchived, setArchived] = useState(institution.archived);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleConfirmArchive = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institutions/${institution.id}/archive`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setArchived(true);
    } else {
      const json = await response.json();
      console.error(json);
    }

    setArchiveOpen(false);
  }

  if (isArchived) return null;

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <CardHeader sx={{ p: 0, textDecoration: 'none', color: 'inherit' }}
            avatar={<Avatar alt={institution.name} src={institution.institution_avatar?.url} />}
            title={institution.name}
            subheader={degreeList}
            component={Link}
            to={`/institutions/${institution.id}`}
          />
        </TableCell>
        <TableCell>
          <Box sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
            <AvatarGroup max={4} sx={{mr: 1}}>
              {filteredAssessments.map(assessment => (
                <Avatar key={assessment.id} alt={`${assessment.user?.first_name} ${assessment.user?.last_name}`} src={assessment.user?.avatar?.url} />
              ))}
            </AvatarGroup>
            <Typography variant="body2">{avatarNames}</Typography>
          </Box>
        </TableCell>    
        {filters?.unified_auditions && filters?.unified_auditions !== 'Any' && 
          <TableCell sx={{verticalAlign: 'top'}}>{filters?.unified_auditions}</TableCell>
        }
        <TableCell>
          <IconButton onClick={handleOpenMenu}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => setArchiveOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Archive</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={isArchiveOpen}
        onClose={() => setArchiveOpen(false)}
      >
        <DialogTitle>Archive this college?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to archive this college? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setArchiveOpen(false)}>Cancel</Button>
          <Button onClick={handleConfirmArchive}>Archive</Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  )
}

export default CollegeListItem;
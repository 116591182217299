import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import useAuth from "../../hooks/useAuth";
import { User } from "../../models";
import ResponsiveAppBar from "../controls/ResponsiveAppBar";
import StudentForm from "../auth/StudentForm";

const EditStudentProfile = () => {
  const auth = useAuth();
  const [student, setStudent] = useState<User>();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudent = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${auth.user.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setStudent(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchStudent();
  }, []);

  const updateStudent = async (user: User) => {
    const token = auth.token();
    // const body = {
    //   user: {
    //     first_name: user.first_name,
    //     last_name: user.last_name,
    //     email: user.email,
    //     biography: user.biography,
    //   }
    // }
    const formData = new FormData();
    if (user.file) formData.append('avatar', user.file);
    formData.append('first_name', user.first_name);
    formData.append('last_name', user.last_name);
    formData.append('email', user.email);
    formData.append('biography', user.biography);
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${auth.user.id}`, {
      method: 'PATCH',
      headers: new Headers({
        // 'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      // body: JSON.stringify(body),
      body: formData,
    });
    if (response.ok) {
      navigate(-1);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(-1);
  }

  if (!student) {
    return (<div/>)
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={handleCancel}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Edit Profile
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <StudentForm user={student} onCancel={handleCancel} onSave={updateStudent} />
      </Box>
    </Box>
  )
}

export default EditStudentProfile;
import * as React from "react";
import { Folder } from "../../../models";
import FolderForm from "../../documents/FolderForm";
import useAuth from "../../../hooks/useAuth";

const EditFolder = ({ folder, onCancel, onSave }: { folder: Folder, onCancel: () => void, onSave: (folder: Folder) => void }) => {
  const auth = useAuth();
  
  const updateFolder = async (updatedFolder: Folder) => {
    const token = auth.token();

    const body = {
      name: updatedFolder.name,
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/folders/${folder.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onSave({ ...folder, ...body });
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <FolderForm folder={folder} onCancel={handleCancel} onSave={updateFolder} />
  )
}

export default EditFolder;
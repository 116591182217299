import { Badge, Dialog, Divider, IconButton, Link as MuiLink, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useEffect, useState } from "react";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import AnnouncementMenuItem from "./AnnouncementMenuItem";
import { Announcement, Notification } from "../../models";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import NewAnnouncement from "./NewAnnouncement";
import { Link } from "react-router-dom";
import NotificationMenuItem from "./NotificationMenuItem";
import { adminNotificationUrl, studentNotificationUrl } from "../../models/Notification";
import useAuth from "../../hooks/useAuth";
import { SendOutlined } from "@mui/icons-material";

const AnnouncementsMenu = ({ isAdmin, notifications, onDismiss }: { isAdmin: boolean, notifications: Notification[], onDismiss: (notification: Notification) => void }) => {
  const [anchorElAnnounements, setAnchorElAnnouncements] = useState<null | HTMLElement>(null);
  // const [notifications, setNotifications] = useState<Notification[]>([]);
  const [open, setOpen] = useState(false);
  const auth = useAuth();
  
  const handleOpenAnnouncementsMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElAnnouncements(event.currentTarget);
  };

  const handleCloseAnnouncementsMenu = () => {
    setAnchorElAnnouncements(null);
  };

  const handleSave = (announcement: Announcement) => {    
    setOpen(false);
  }

  const handleDismiss = (notification: Notification) => {
    onDismiss(notification);
  }

  const handleClick = async (notification: Notification) => {
    const url = notificationUrl(notification);
    const token = auth.token();
    const body = {
      dismissed: true,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/notifications/${notification.id}/deliveries`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onDismiss(notification);
      if (url) {
        document.location = url;
      }
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const notificationUrl = isAdmin ? adminNotificationUrl : studentNotificationUrl;

  return (
    <>
      <IconButton sx={{ mr: 1 }} onClick={handleOpenAnnouncementsMenu}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsOutlinedIcon />
        </Badge>
      </IconButton>
      <Menu
        sx={{ mt: '45px' }}
        anchorEl={anchorElAnnounements}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElAnnounements)}
        onClose={handleCloseAnnouncementsMenu}
      >
        <MenuItem color="primary.main" sx={{ minWidth: '200px', maxWidth: '400px', color: 'primary.main' }} component={Link} to="/notifications">
          <Typography variant="body2">View All</Typography>
        </MenuItem>
        <Divider/>

        {notifications.length > 0 && notifications.slice(0, 10).map(notification => (
          <div key={notification.id}>
            <MenuItem key={notification.id} sx={{ minWidth: '200px', maxWidth: '400px' }} onClick={() => handleClick(notification)}>
              <NotificationMenuItem notification={notification} onDismiss={handleDismiss} />
            </MenuItem>
            {/*notificationUrl(notification) && notificationUrl(notification) !== '' ?
              Array.from(notificationUrl(notification)!)[0] !== '/' ?
                (<MenuItem key={notification.id} sx={{ minWidth: '200px', maxWidth: '400px' }} onClick={() => handleClick(notification)} component={MuiLink} href={notificationUrl(notification)!}>
                  <NotificationMenuItem notification={notification} onDismiss={handleDismiss} />
                </MenuItem>)
              :
                (<MenuItem key={notification.id} sx={{ minWidth: '200px', maxWidth: '400px' }} component={Link} to={notificationUrl(notification)!}>
                  <NotificationMenuItem notification={notification} onDismiss={handleDismiss} />
                </MenuItem>)
            :
              <MenuItem key={notification.id} sx={{ minWidth: '200px', maxWidth: '400px' }}>
                <NotificationMenuItem notification={notification} onDismiss={handleDismiss} />
              </MenuItem>
            */}
          </div>
        ))}

        {notifications.length === 0 && (
          <MenuItem sx={{ minWidth: '200px', maxWidth: '400px' }} onClick={() => setAnchorElAnnouncements(null)}>
            <Typography variant="body2">No notifications</Typography>
          </MenuItem>
        )}

      </Menu>
      <Dialog open={open} fullWidth>
        <NewAnnouncement onCancel={() => setOpen(false)} onSave={handleSave} />
      </Dialog>
    </>
  )
}

export default AnnouncementsMenu;
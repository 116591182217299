import { DialogActions, DialogContent, FormControl, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { StandardizedTest } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import RichTextEditor from "../controls/RichTextEditor";
import SecondaryButton from "../controls/SecondaryButton";
import PaperDropzone from "../controls/PaperDropzone/PaperDropzone";

const testTypes = [
  ['PSAT'], ['SAT'], ['Pre-ACT'], ['ACT'], ['AP'], ['TOEFL'], ['IELTS'], ['Other']
]

const StandardizedTestForm = ({ standardizedTest, onCancel, onSave }: { standardizedTest?: StandardizedTest, onCancel: () => void, onSave: (standardizedTest: StandardizedTest) => void }) => {
  const [testType, setTestType] = useState<string>(standardizedTest?.test_type || '');
  const [date, setDate] = useState<string>(standardizedTest?.date || '');
  const [totalScore, setTotalScore] = useState<number | undefined>(standardizedTest?.total_score);
  const [notes, setNotes] = useState<string>(standardizedTest?.notes || '');
  const [file, setFile] = useState<File>();

  const handleSave = () => {
    const standardizedTest: StandardizedTest = {
      test_type: testType,
      date,
      total_score: totalScore,
      notes,
      file,
    }
    onSave(standardizedTest);
  }

  const onFileChange = (file: File) => {
    setFile(file);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          <FormControl>
            <InputLabel id="testType">Test Type</InputLabel>
            <Select
              labelId="testType"
              label="Test Type"
              value={testType}
              onChange={(e) => setTestType(e.target.value)}
            >
              {testTypes.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            label="Test Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />

          <TextField
            label="Total Score"
            variant="outlined"
            value={totalScore || ''}
            onChange={(e) => setTotalScore(+e.target.value)}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Notes</FormLabel>
            <RichTextEditor value={notes} onChange={setNotes} />
          </FormControl>

          <PaperDropzone onChange={onFileChange} />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{ width: 1 }} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{ width: 1 }} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default StandardizedTestForm;
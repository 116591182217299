import { styled, Tabs } from "@mui/material";

const RoundedTopTabs = styled(Tabs)(({ theme }) => `
  & .MuiTab-root {
    text-transform: none;
    color: ${theme.palette.primary.main};
    border-radius: 20px 20px 0 0;

    &.Mui-selected {
      color: ${theme.palette.text.primary};
      background-color: ${theme.palette.background.default};

      &:before {
        content: '';
        display: block;
        background-color: ${theme.palette.primary.main};
        height: 4px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: auto;
        top: 0;
      }
    }
  }

  & .MuiTabs-indicator {
    opacity: 0;
  }
`);

export default RoundedTopTabs;
import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AssessmentForm from "./AssessmentForm";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Assessment, User } from '../../../../models';
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const AddAssessment = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [student, setStudent] = useState<User>();
  const auth = useAuth();
  const [error, setError] = useState<string|null>(null);
  
  useEffect(() => {
    const fetchStudent = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${params.studentId}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setStudent(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchStudent();
  }, []);

  const createAssessment = async (assessment: Assessment) => {
    setError(null);
    const token = auth.token();
    const body = {
      institution_degree_id: assessment.institution_degree_id,
      academic_confidence: assessment.academic_confidence,
      artistic_confidence: assessment.artistic_confidence,
      scholarship: assessment.scholarship,
      application_status: assessment.application_status,
      applying: assessment.applying,
      prescreen_deadline: assessment.prescreen_deadline,
      admission_decision: assessment.admission_decision,
      prescreen_artistic_upload_status: assessment.prescreen_artistic_upload_status,
      supplemental_application_status: assessment.supplemental_application_status,
      test_optional: assessment.test_optional,
      test_optional_other: assessment.test_optional_other,
      inactive: assessment.inactive,
      inactive_explanation: assessment.inactive_explanation,
      notes: assessment.notes,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${params.studentId}/assessments`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      navigate(`/students/${params.studentId}/colleges`);
    } else {
      const json = await response.json();
      console.error(json);

      setError(`We could not add that school to the college list for ${student?.first_name} ${student?.last_name}. Check to make sure it hasn't already been added to Active or Inactive colleges.`)

    }
  }

  const handleCancel = () => {
    navigate(`/students/${params.studentId}/colleges`);
  }

  if (!student) {
    return (<div/>);
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/students/${params.studentId}/colleges`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Students
            </Typography>
            <Typography variant="subtitle2">
              {`${student.first_name} ${student.last_name}`}
            </Typography>
            <Typography variant="subtitle2">
              Add a College
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <AssessmentForm user={student} onCancel={handleCancel} onSave={createAssessment} error={error} />
      </Box>
    </Box>
  )
}

export default AddAssessment;
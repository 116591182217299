import { Avatar, Card, CardActionArea, CardContent, CardHeader, IconButton, Typography } from "@mui/material";
import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import { Chat } from "../../../models";

const ChatListItem = ({ chat, to }: { chat: Chat, to: string }) => {  

  const messages = chat.chat_messages || [];
  const sortedMessages = messages.sort((a, b) => {
    if (a.created_at < b.created_at) return -1;
    if (a.created_at > b.created_at) return 1;
    return 0;
  });
  const lastMessage = (sortedMessages.length > 0) ? sortedMessages[sortedMessages.length - 1] : null
  
  return (
    <Card>
      <CardActionArea component={Link} to={to}>
        <CardHeader
          avatar={<Avatar alt={`${chat.user.first_name} ${chat.user.last_name}`} src={chat.user.avatar?.url} />}
          title={`${chat.user.first_name} ${chat.user.last_name}`}
          subheader={lastMessage?.body}
        />
      </CardActionArea>
    </Card>
  )
}

export default ChatListItem;
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useRouteMatch from "../../../hooks/useRouteMatch";
import RoundedBottomTabs from "../../controls/RoundedBottomTabs";
import Administrators from "./administrators/Administrators";
import AdminColleges from "./colleges/AdminColleges";
import AdminDegrees from "./degrees/AdminDegrees";
import AdminTasks from "./tasks/AdminTasks";
import AdminCollegeCollections from "./collections/AdminCollegeCollections";

const AdminConfiguration = () => {
  const auth = useAuth();
  const routeMatch = useRouteMatch(['/config/colleges', '/config/degrees', '/config/collections', '/config/tasks', '/config/admins']);
  const [value, setValue] = useState(routeMatch?.pattern?.path || '/config/colleges');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  
  return (
    <Box>
      <TabContext value={value}>
        <RoundedBottomTabs 
          value={value} 
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab sx={{ textTransform: 'none' }} label="Colleges" value="/config/colleges" component={Link} to="/config/colleges" />
          <Tab sx={{ textTransform: 'none' }} label="Degrees" value="/config/degrees" component={Link} to="/config/degrees" />
          <Tab sx={{ textTransform: 'none' }} label="College Collections" value="/config/collections" component={Link} to="/config/collections" />
          <Tab sx={{ textTransform: 'none' }} label="Tasks/Events" value="/config/tasks" component={Link} to="/config/tasks" />
          {auth.user.superadmin &&
            <Tab sx={{ textTransform: 'none' }} label="Administrators" value="/config/admins" component={Link} to="/config/admins" />
          }
        </RoundedBottomTabs>
        <TabPanel value="/config/colleges" sx={{p: 0}}>
          <AdminColleges />
        </TabPanel>
        <TabPanel value="/config/degrees" sx={{p: 0}}>
          <AdminDegrees />
        </TabPanel>
        <TabPanel value="/config/collections" sx={{p: 0}}>
          <AdminCollegeCollections />
        </TabPanel>
        <TabPanel value="/config/tasks" sx={{p: 0}}>
          <AdminTasks />
        </TabPanel>
        {auth.user.superadmin &&
          <TabPanel value="/config/admins" sx={{p: 0}}>
            <Administrators />
          </TabPanel>
        }
      </TabContext>
    </Box>
  )
}

export default AdminConfiguration;
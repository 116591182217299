import * as React from "react";
import { InstitutionDegree } from "../../../../../../models";
import EditDegreeForm from "./EditDegreeForm";
import useAuth from "../../../../../../hooks/useAuth";

const EditDegreeInfo = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  const auth = useAuth();
  
  const updateInstitutionDegree = async (institutionDegree: InstitutionDegree) => {
    const token = auth.token();

    const body = {
      website_url: institutionDegree.website_url,
      supplemental_application_required: institutionDegree.supplemental_application_required,
      supplemental_application_deadline: institutionDegree.supplemental_application_deadline,
      supplemental_application_url: institutionDegree.supplemental_application_url,
      supplemental_application_requirements: institutionDegree.supplemental_application_requirements,
      score_optional_enabled: institutionDegree.score_optional_enabled,
      score_optional: institutionDegree.score_optional,
      writing_requirements: institutionDegree.writing_requirements,
      has_department_visit_information: institutionDegree.has_department_visit_information,
      department_visit_information: institutionDegree.department_visit_information,
      admissions_office_phone: institutionDegree.admissions_office_phone,
      admissions_office_email: institutionDegree.admissions_office_email,
    }
    
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${institutionDegree.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onSave({...institutionDegree, ...body});
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <EditDegreeForm institutionDegree={institutionDegree} onCancel={handleCancel} onSave={updateInstitutionDegree} />
  )
}

export default EditDegreeInfo;
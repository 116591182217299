import * as React from "react";
import { useEffect, useState } from "react";
import { InstitutionDegree, Task, User } from "../../../models";
import TaskForm from "../../tasks/TaskForm";
import useAuth from "../../../hooks/useAuth";

const AddTask = ({ showUsers, onCancel, onSave, initialUsers }: { showUsers: boolean, onCancel: () => void, onSave: (tasks: Task[]) => void, initialUsers?: User[] }) => {  
  const auth = useAuth();
  
  const createTask = async (task: Task) => {
    const token = auth.token();

    const formData = new FormData();
    if (task.file) formData.append('attachment', task.file);
    formData.append('title', task.title);
    formData.append('notes', task.notes);
    formData.append('task_type', task.task_type);
    formData.append('due_on', task.due_on || '');
    formData.append('user_ids', (task.user_ids || []).toString());
    formData.append('include_collaborators', task.include_collaborators ? 'true' : 'false');
    formData.append('institution_degree_ids', (task.institution_degree_ids || []).toString());
    if (task.url) {
      formData.append('url', task.url);
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/tasks/batch`, {
      method: 'POST',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <TaskForm onCancel={handleCancel} onSave={createTask} showUsers={showUsers} initialUsers={initialUsers} />
  )
}

export default AddTask;
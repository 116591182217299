import { DialogActions, DialogContent } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { DocumentFile, Folder } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import SelectableFolder from "./SelectableFolder";
import useAuth from "../../hooks/useAuth";

const MoveDocument = ({document, onCancel, onMove}: { document: DocumentFile, onCancel: () => void, onMove: (folder?: Folder) => void }) => {  
  const [isLoading, setLoading] = useState(false);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<Folder|undefined>(undefined);
  const auth = useAuth();
  
  useEffect(() => {
    const params: string[] = [];

    const fetchDocumentFolders = async () => {
      setLoading(true);
      const token = auth.token();
      const url = `${window.SERVER_DATA.domain}/api/v1/documents/${document.id}/folders`;
      // const url = `${window.SERVER_DATA.domain}/api/v1/folders`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      setLoading(false);
      if (response.ok) {
        const folders: Folder[] = await response.json();
        // const { folder_id, name, folders, documents }: { folder_id: number|undefined, name: string|undefined, folders: Folder[], documents: DocumentFile[] } = await response.json();
        setFolders(folders);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchDocumentFolders();
  }, []);

  const handleMove = () => {
    onMove(selectedFolder);
  }

  return (
    <>
      <DialogContent>
        {folders.map(folder => (
          <SelectableFolder key={folder.id} folder={folder} selectedId={selectedFolder?.id} onCheckboxClick={setSelectedFolder} />
        ))}
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleMove}>Move</PrimaryButton>
      </DialogActions>
    </>
  )
};

export default MoveDocument;

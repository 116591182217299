import * as React from "react";
import Folder from "../../../models/Folder";
import FolderForm from "../../documents/FolderForm";
import useAuth from "../../../hooks/useAuth";

const NewFolder = ({ userId, assessmentId, onCancel, onSave, folderId }: { userId?: number, assessmentId?: number, onCancel: () => void, onSave: (folder: Folder) => void, folderId: number|null }) => {
  const auth = useAuth();
  
  const createFolder = async (folder: Folder) => {
    const token = auth.token();
    let body = {
      name: folder.name,
      user_id: userId,
      assessment_id: assessmentId,
      folder_id: folderId,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/folders`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <FolderForm onCancel={handleCancel} onSave={createFolder} />
  )
}

export default NewFolder;
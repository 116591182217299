import User from "./User";
import Task from "./Task";

export default interface SharedTask {
  id?: number;
  task_id?: number;
  user_id?: number;

  task?: Task;
  user?: User;
}

const sharedTaskRecipientName = (sharedTask: SharedTask): string|undefined => {
  const user = sharedTask.user as User;
  return `${user.first_name} ${user.last_name}`;
}

export { sharedTaskRecipientName }
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import useAuth from "../../../hooks/useAuth";

const StatusListItem = ({ id, field, label, value, options, isEditable = true, onChange }: { id: number, field: string, label: string, value: string, options: string[][], isEditable?: boolean, onChange?: ((value: string) => void) }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState(value);
  const auth = useAuth();
  
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    // event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    return false;
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuItemClick = async (value: string) => {
    const token = auth.token();
    const body = {
      [field]: value
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/assessments/${id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      setSelectedValue(value);
      if (onChange) onChange(value);
    } else {
      const json = await response.json();
      console.error(json);
    }
    handleClose();
  };

  const selectedOption = options.find((o) => (o.length > 1 ? o[1] : o[0]) === selectedValue)
  
  return (
    <Stack direction="row" spacing={1}>
      {label &&
        <Typography variant="subtitle2" noWrap>{label}</Typography>
      }
      {isEditable ? (
        <>
          <Typography 
            variant="body2" 
            color="inherit" 
            onClick={handleClick}
            noWrap
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            {selectedOption ? selectedOption[0] : 'Please Select'}
          </Typography>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {options.map(([label, value]) => (
              <MenuItem 
                key={value || label} 
                selected={(value || label) === selectedValue}
                onClick={() => handleMenuItemClick(value || label)}
              >{label}</MenuItem>
            ))}
          </Menu>
        </>
      ) : (
        <Typography 
          variant="body2" 
          color="inherit" 
          noWrap
        >
          {selectedOption ? (selectedOption[0] === 'Please Select' ? 'Not Selected' : selectedOption[0]) : 'Not Selected'}
        </Typography>
      )}
      
    </Stack>
  )
}

export default StatusListItem;
import { DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SortOptions from './SortOptions';
import { TaskTemplate } from "../../models";
import useAuth from "../../hooks/useAuth";

const SortStudents = ({ title, options, onCancel, onSave }: { title: string, options?: SortOptions, onCancel: () => void, onSave: (options: SortOptions) => void }) => {
  const [column, setColumn] = useState(options?.column || 'lower(users.last_name),lower(users.first_name)');
  const [direction, setDirection] = useState(options?.direction || 'asc');
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>();
  const auth = useAuth();
  
  useEffect(() => {
    const fetchTaskTemplates = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: TaskTemplate[] = await response.json();
        console.log(json);
        setTaskTemplates(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchTaskTemplates();
  }, []);

  const handleSave = () => {
    const options: SortOptions = {
      column,
      direction,
    };
    onSave(options);
  }

  if (!taskTemplates) return null;
  
  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <ImportExportOutlinedIcon sx={{pr: 1}} />
          <Typography variant="h6" sx={{flexGrow: 1}}>{title}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{py: 2}}>

          <FormControl>
            <InputLabel id="column">Sort</InputLabel>
            <Select
              labelId="column"
              label="Sort"
              value={column}
              onChange={(e) => setColumn(e.target.value)}
            >
              <MenuItem value="lower(users.last_name),lower(users.first_name)">Last Name, First Name</MenuItem>
              <MenuItem value="lower(users.email">Email</MenuItem>
              {taskTemplates.map((taskTemplate) => (
                <MenuItem key={taskTemplate.id} value={`task_templates.${taskTemplate.id}`}>{taskTemplate.title}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="direction">Direction</InputLabel>
            <Select
              labelId="direction"
              label="Direction"
              value={direction}
              onChange={(e) => setDirection(e.target.value)}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Apply</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default SortStudents;

import { Box, Card, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Collaboration } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import logo from '../logo.svg';
import useAuth from "../../hooks/useAuth";

const AcceptInvitation = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [collaboration, setCollaboration] = useState<Collaboration>();
  const [password, setPassword] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const invitationToken = searchParams.get('invitation_token');

  useEffect(() => {
    const fetchCollaboration = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/collaborations/${invitationToken}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setCollaboration(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchCollaboration();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !password || !confirmPassword) {
      return;
    }
    if (password !== confirmPassword) {
      return;
    }
    const body = {
      first_name: firstName,
      last_name: lastName,
      password: password,
    }
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/collaborations/${invitationToken}/accept`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`,
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      navigate('/');
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  if (!collaboration) return null;

  return (
    <Box sx={{
      p: 2,
      position: 'fixed', top: 0, bottom: 0, left: 0, right: 0,
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: 'grey.100',
    }}>
      <Card sx={{ width: 1, p: 2, maxWidth: 400 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ alignItems: 'center' }}>
            <img src={logo} alt="ArtsBridge College Guide" width="250" height="84" />
            <Typography variant="body1" align="center">{collaboration.user?.first_name} {collaboration.user?.last_name} wants to collaborate with you on the ArtsBridge College Guide.</Typography>

            <TextField
              name="email"
              label="Email Address"
              type="email"
              autoComplete="email"
              fullWidth
              value={collaboration?.email}
              disabled
            />

            <TextField
              name="first_name"
              label="First Name"
              type="text"
              fullWidth
              value={firstName || ''}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />

            <TextField
              name="last_name"
              label="Last Name"
              type="text"
              fullWidth
              value={lastName || ''}
              onChange={(e) => setLastName(e.target.value)}
              required
            />

            <TextField
              name="password"
              label="Create a password"
              type="password"
              autoComplete="new-password"
              fullWidth
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <TextField
              name="confirmPassword"
              label="Confirm password"
              type="password"
              autoComplete="new-password"
              fullWidth
              value={confirmPassword || ''}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />

            <PrimaryButton
              variant="contained"
              type="submit"
              fullWidth
            >
              Accept Invitation
            </PrimaryButton>
          </Stack>
        </form>
      </Card>
    </Box>
  )
}

export default AcceptInvitation;
import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Link } from "react-router-dom";
import StatusList from "../controls/StatusList/StatusList";
import { format, parseISO } from "date-fns";
import { Assessment } from "../../models";
import ConfidenceList from "../controls/StatusList/ConfidenceList";

const AssessmentCard = ({ assessment, onChange }: { assessment: Assessment, onChange: (assessment: Assessment) => void }) => {

  const collegeInstitutionDegree = assessment.institution_degree;
  if (!collegeInstitutionDegree) return null;

  return (
    <Card>
      <CardActionArea component={Link} to={`/assessments/${assessment.id}`}>
        <CardMedia
          component="img"
          height="140"
          image={assessment.institution_degree?.institution?.institution_avatar?.url}
          alt={`${assessment.institution_degree?.institution?.name} ${assessment.institution_degree?.degree?.name}`}
        />

        <CardHeader
          title={assessment.institution_degree?.institution?.name}
          subheader={assessment.institution_degree?.degree?.name}
          // action={
          //   <IconButton>
          //     <MoreVertIcon />
          //   </IconButton>
          // }
          sx={{
            pb: 1,
          }}
        />
      </CardActionArea>

      <CardContent sx={{ pt: 1 }}>
        <StatusList assessment={assessment} onChange={onChange} />
        <ConfidenceList assessment={assessment} isAdmin={false} />
        <Divider sx={{ my: 2 }} /> 

        <Grid container spacing={2}>

          {assessment.applying === 'Regular Decision' && collegeInstitutionDegree.regular_deadline && 
            <Grid item xs={12}>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Regular Admission Deadline:</Typography>
                <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.regular_deadline), 'MMM d, yyyy')}</Typography>
              </Stack>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.pre_screening_deadline &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Regular Decision Prescreen Deadline:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.pre_screening_deadline), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </Grid>
          }

          {assessment.applying === 'Early Action' && collegeInstitutionDegree.early_action === 'Yes' &&
            <Grid item xs={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Early Action Date:</Typography>
                <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.early_action_date), 'MMM d, yyyy')}</Typography>
              </Stack>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_2 &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Early Action Prescreen Deadline:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_2), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </Grid>
          }

          {assessment.applying === 'Early Decision' && collegeInstitutionDegree.early_decision === 'Yes' &&
            <Grid item xs={6}>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Early Decision Date:</Typography>
                <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.early_decision_date), 'MMM d, yyyy')}</Typography>
              </Stack>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_3 &&
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Early Decision Prescreen Deadline:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_3), 'MMM d, yyyy')}</Typography>
                </Stack>
              }
            </Grid>
          }

          {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision === 'Yes' &&
            <Grid item xs={6}>
              <>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Other Decision Details:</Typography>
                  <Typography variant="body2">{collegeInstitutionDegree.other_decision_details}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Other Decision Date:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.other_decision_date), 'MMM d, yyyy')}</Typography>
                </Stack>
                {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_4 &&
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Other Decision Prescreen Deadline:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_4), 'MMM d, yyyy')}</Typography>
                  </Stack>
                }
              </>
            </Grid>
          }

          {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision_two === 'Yes' &&
            <Grid item xs={6}>
              <>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Other Decision Details 2:</Typography>
                  <Typography variant="body2">{collegeInstitutionDegree.other_decision_details_two}</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2">Other Decision Date 2:</Typography>
                  <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.other_decision_date_two), 'MMM d, yyyy')}</Typography>
                </Stack>
                {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_5 &&
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Other Decision 2 Prescreen Deadline:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_5), 'MMM d, yyyy')}</Typography>
                  </Stack>
                }
              </>
            </Grid>
          }

        </Grid>

      </CardContent>
    </Card>
  );
}

export default AssessmentCard;
import { Box, Card, CardContent, Container, Dialog, Grid, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import DocumentItem from "../../../documents/DocumentItem";
import UploadDocument from "../../documents/UploadDocument";
import SecondaryButton from "../../../controls/SecondaryButton";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import { DocumentFile, Folder, SharedDocument, User } from "../../../../models";
import NewFolder from "../../documents/NewFolder";
import { Link, useParams } from "react-router-dom";
import FolderItem from "../../../documents/FolderItem";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import SharedDocumentItem from "../../../documents/SharedDocumentItem";
import useAuth from "../../../../hooks/useAuth";

const perPage = 20;

const StudentDocuments = ({ student, onChange }: { student: User, onChange: (student: User) => void }) => {
  const [open, setOpen] = useState(false);
  const [openFolder, setOpenFolder] = useState(false);

  const [parentId, setParentId] = useState<number|undefined>(undefined);
  const [folderName, setFolderName] = useState<string|undefined>(undefined);
  const [folders, setFolders] = useState<Folder[]>([]);
  const [documents, setDocuments] = useState<DocumentFile[]>([]);
  const [sharedDocuments, setSharedDocuments] = useState<SharedDocument[]>([]);
  const auth = useAuth();
  
  const routeParams = useParams();
  const tempFolderId = routeParams['*']?.split('/')[1];
  const folderId = tempFolderId ? parseInt(tempFolderId) : null;

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const params: string[] = [];
    const filterParams = params.join('&');

    const fetchFolder = async () => {
      setLoading(true);
      const token = auth.token();
      const baseRoute = folderId ? `/folders/${folderId}` : '/folders';
      const url = filterParams ? `${window.SERVER_DATA.domain}/api/v1${baseRoute}?user_id=${student.id}&page=${page}&per_page=${perPage}&${filterParams}` : `${window.SERVER_DATA.domain}/api/v1${baseRoute}?user_id=${student.id}&page=${page}&per_page=${perPage}`;
      const response = await fetch(url, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      setLoading(false);
      if (response.ok) {
        const { folder_id, name, folders, documents }: { folder_id: number|undefined, name: string|undefined, folders: Folder[], documents: DocumentFile[] } = await response.json();

        if (page === 1) {
          setParentId(folder_id);
          setFolderName(name);
          setFolders(folders);
          setDocuments(documents);
        } else {
          setFolders(prev => {
            const filteredPrev = prev.filter(a => !folders.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...folders];
          });
          setDocuments(prev => {
            const filteredPrev = prev.filter(a => !documents.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...documents];
          });
        }

        if (folders.length < perPage && documents.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchFolder();

    const fetchSharedDocuments = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/shared_documents?user_id=${student.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        setSharedDocuments(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchSharedDocuments();
  }, [routeParams]);

  const handleSave = (newDocuments: DocumentFile[]) => {
    const filteredDocuments = newDocuments.filter(d => d.folder_id === folderId);
    setDocuments(documents.concat(filteredDocuments));
    setOpen(false);
  }

  const handleSaveFolder = (newFolder: Folder) => {
    setFolders([...folders, newFolder]);
    setOpenFolder(false);
    // onChange({...student, folders: [...folders, newFolder]});
  }

  const handleChangeDocument = (document: DocumentFile) => {
    setDocuments(documents.map(d => d.id === document.id ? document : d));
  }

  const handleChangeSharedDocument = (sharedDocument: SharedDocument) => {
    // TODO: implement editing shared documents
  }

  const filteredDocuments = documents.filter(d => d.folder_id === folderId);

  const sortedDocuments = filteredDocuments.sort((a, b) => {
    return (a.created_at || new Date()) > (b.created_at || new Date()) ? -1 : 1;
  });

  const backLink = parentId ? `/students/${student.id}/documents/${parentId}` : `/students/${student.id}/documents`;

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<FileUploadOutlinedIcon/>} 
              sx={{width: 1, maxWidth: 300}}
              onClick={() => setOpen(true)}
            >
              Upload
            </SecondaryButton>
            <SecondaryButton 
              variant="outlined" 
              startIcon={<CreateNewFolderOutlinedIcon/>} 
              sx={{width: 1, maxWidth: 300}}
              onClick={() => setOpenFolder(true)}
            >
              New Folder
            </SecondaryButton>
        </Stack>

        <Stack spacing={2}>
          <Box>
            <Typography variant="overline">{student.first_name} {student.last_name}'s DOCUMENTS</Typography>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  {folderName &&
                    <Stack direction="row" alignItems="center">
                      <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        sx={{ mr: 2 }}
                        component={Link}
                        to={backLink}
                      >
                        <ArrowBackOutlinedIcon />
                      </IconButton>
                      <Typography variant="h6">{folderName}</Typography>
                    </Stack>
                  }
                  {folders.map(folder => (
                    <FolderItem key={folder.id} folder={folder} root={`/students/${student.id}/documents`} />
                  ))}
                  {sortedDocuments.map(document => (
                    <DocumentItem key={document.id} document={document} onChange={handleChangeDocument} isAdmin={false} />
                  ))}
                  {folders.length === 0 && sortedDocuments.length === 0 && 
                    <Typography variant="body2" pt={1}>No documents</Typography>
                  }
                </Stack>
              </CardContent>
            </Card>
          </Box>

          <Box>
            <Typography variant="overline">SHARED WITH {student.first_name} {student.last_name}</Typography>
            <Card>
              <CardContent>
                {sharedDocuments.map(sharedDocument => (
                  <SharedDocumentItem key={sharedDocument.id} sharedDocument={sharedDocument} onChange={handleChangeSharedDocument} isAdmin={false} />
                ))}
                {sharedDocuments.length === 0 &&
                  <Typography variant="body2" pt={1}>No shared documents</Typography>
                }
              </CardContent>
            </Card>
          </Box>
        </Stack>

        {!reachedEnd &&
          <SecondaryButton variant="outlined" onClick={loadNextPage}>
            {isLoading ? 'Loading...' : 'Load More'}
          </SecondaryButton>
        }
        
        {student.id &&
          <>
            <Dialog open={open} fullWidth>
              <UploadDocument userId={student.id} onCancel={() => setOpen(false)} onSave={handleSave} showUsers={false} folderId={folderId} />
            </Dialog>

            <Dialog open={openFolder} fullWidth>
              <NewFolder userId={student.id} onCancel={() => setOpenFolder(false)} onSave={handleSaveFolder} folderId={folderId} />
            </Dialog>
          </>
        }
      </Stack>
    </Container>
  )
}

export default StudentDocuments;
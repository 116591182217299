import { SxProps, Theme } from "@mui/material";
import * as React from "react";
import { useRef, useState } from "react";
import { debounce } from 'lodash';
import RichTextEditor from "./RichTextEditor";
import useAuth from "../../hooks/useAuth";

const EditableRichNotes = ({ 
  sx = [], 
  endpoint, 
  field, 
  value, 
  wrap, 
  rows = undefined,
  onChange,
}: { 
  sx?: SxProps<Theme>, 
  endpoint: string, 
  field: string, 
  value: string, 
  wrap?: string, 
  rows?: number|undefined,
  onChange?: (value: string) => void,
}) => {
  const [currentValue, setCurrentValue] = useState(value || '');
  const auth = useAuth();
  
  const handleChange = async (value: string) => {
    const token = auth.token();

    let body: any;
    if (wrap) {
      body = {
        [wrap]: {
          [field]: value
        }
      }
    } else {
      body = {
        [field]: value
      }
    }

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/${endpoint}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      console.log(body);
      onChange && onChange(value);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const debouncedChange = useRef(debounce(handleChange, 300));

  const onEditorChange = (value: string) => {
    setCurrentValue(value);
    debouncedChange.current(value);
  }

  return (
    <RichTextEditor value={currentValue} onChange={onEditorChange} placeholder="Add notes" />
  )
}

export default EditableRichNotes;
import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import * as React from "react";
import { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { InstitutionDegree, User } from "../../models";
import useAuth from "../../hooks/useAuth";

const StudentsDropdown = ({ label = 'Assign people', value, onChange, required = false, error = false } : { label?: string, value: User[], onChange: (value: User[]) => void, required?: boolean, error?: boolean }) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [students, setStudents] = useState<User[]>([]);
  const auth = useAuth();
  
  useEffect(() => {
    if (open) {
      const fetchStudents = async () => {
        const token = auth.token();
        const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?student=1`, {
          method: 'GET',
          headers: new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
          }),
        });
        if (response.ok) {
          const json: User[] = await response.json();
          console.log(json);
          setStudents(json);
        } else {
          const json = await response.json();
          console.error(json);
        }
      }
      fetchStudents();
    }
  }, [open]);

  useEffect(() => {
    const fetchStudents = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?student=1?q=${inputValue}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: User[] = await response.json();
        console.log(json);
        setStudents(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    fetchStudents();
  }, [inputValue]);

  const handleChange = (event: SyntheticEvent, value: (User[]), reason: AutocompleteChangeReason) => {
    onChange(value);
  }

  const sortedStudents = students.sort((a, b) => {
    const aName = `${a.last_name.toLowerCase()}, ${a.first_name.toLowerCase()}`;
    const bName = `${b.last_name.toLowerCase()}, ${b.first_name.toLowerCase()}`;
    return aName.localeCompare(bName);
  });
  
  return (
    <Autocomplete 
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label={label}
          required={required}
          error={value.length === 0 && error}
          helperText={value.length === 0 && error && 'Select at least one student'}
        />
      )} 
      value={value}
      options={sortedStudents}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.first_name} ${option.last_name}`}
      onChange={(event, value, reason) => handleChange(event, value as User[], reason)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  )
}

export default StudentsDropdown;

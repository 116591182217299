import * as React from "react";
import { DocumentFile } from "../../models";
import DocumentForm from "./DocumentForm";
import useAuth from "../../hooks/useAuth";

const EditDocument = ({ document, onCancel, onSave, showUsers }: { document: DocumentFile, onCancel: () => void, onSave: (document: DocumentFile) => void, showUsers: boolean }) => {
  const auth = useAuth();
  
  const updateDocument = async (newDocument: DocumentFile) => {
    const token = auth.token();
    const formData = new FormData();
    if (newDocument.file) {
      formData.append('attachment', newDocument.file);
    }
    formData.append('name', newDocument.name);
    if (newDocument.user_ids) {
      formData.append('user_ids', newDocument.user_ids.toString());
    }
    if (newDocument.url) {
      formData.append('url', newDocument.url);
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/${document.id}`, {
      method: 'PATCH',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      await fetchDocument();
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const fetchDocument = async () => {
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/documents/${document.id}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <DocumentForm document={document} onCancel={handleCancel} onSave={updateDocument} showUsers={false} />
  )
}

export default EditDocument;
import { Box, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Degree, Institution } from "../../models";
import { admissionDecisionOptions, allApplyingOptions, applicationStatusOptions, statusOptions, testOptionalOptions } from "../../models/Assessment";
import CollegeDropdown from "../filters/CollegeDropdown";
import DegreeDropdown from "../filters/DegreeDropdown";

interface FilterData {
  prescreens?: string;
  auditions_portfolio_required?: string;
  early_decision?: string;
  early_action?: string;
  score_optional_enabled?: string;
  common_app?: string;
  application_status?: string;
  audition_date_start?: string;
  audition_date_end?: string;
  graduation_year?: string;
  college?: Institution;
  degree?: Degree;
  applying?: string;
  test_optional?: string;
  academic_confidence?: string;
  artistic_confidence?: string;
  unified_auditions?: string;
  supplemental_application_status?: string;
  admission_decision?: string;
}

const confirmOptions = [
  ['Any', ''],
  ['Yes', 'Yes'],
  ['No', 'No']
]

const confirmOptionsNA = [
  ['Any', ''],
  ['Yes', 'Yes'],
  ['No', 'No'],
  ['N/A', 'N/A'],
]

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = thisYear - 5; i < thisYear + 5; i++) {
  years.push(i);
}

const FilterAssessments = ({ title, filters, onCancel, onSave }: { title: string, filters?: FilterData, onCancel: () => void, onSave: (filters: FilterData) => void }) => {

  const [prescreens, setPrescreens] = useState(filters?.prescreens || '');
  const [auditionsPortfolioRequired, setAuditionsPortfolioRequired] = useState(filters?.auditions_portfolio_required || '');
  const [earlyDecision, setEarlyDecision] = useState(filters?.early_decision || '');
  const [earlyAction, setEarlyAction] = useState(filters?.early_action || '');
  const [scoreOptionalEnabled, setScoreOptionalEnabled] = useState(filters?.score_optional_enabled || '');
  const [commonApp, setCommonApp] = useState(filters?.common_app || '');
  const [applicationStatus, setApplicationStatus] = useState(filters?.application_status || '');
  const [auditionDateStart, setAuditionDateStart] = useState(filters?.audition_date_start || '');
  const [auditionDateEnd, setAuditionDateEnd] = useState(filters?.audition_date_end || '');
  const [graduationYear, setGraduationYear] = useState(filters?.graduation_year || '');
  const [college, setCollege] = useState(filters?.college || null);
  const [degree, setDegree] = useState(filters?.degree || null);
  const [applying, setApplying] = useState(filters?.applying || '');
  const [testOptional, setTestOptional] = useState(filters?.test_optional || '');
  const [academicConfidence, setAcademicConfidence] = useState(filters?.academic_confidence || '');
  const [artisticConfidence, setArtisticConfidence] = useState(filters?.artistic_confidence || '');
  const [unifiedAuditions, setUnifiedAuditions] = useState(filters?.unified_auditions || '');
  const [supplementalApplicationStatus, setSupplementalApplicationStatus] = useState(filters?.supplemental_application_status || '');
  const [admissionDecision, setAdmissionDecision] = useState(filters?.admission_decision || '');

  const handleSave = () => {
    const filters: FilterData = {
      prescreens,
      auditions_portfolio_required: auditionsPortfolioRequired,
      early_decision: earlyDecision,
      early_action: earlyAction,
      score_optional_enabled: scoreOptionalEnabled,
      common_app: commonApp,
      application_status: applicationStatus,
      audition_date_start: auditionDateStart,
      audition_date_end: auditionDateEnd,
      graduation_year: graduationYear,
      college: college || undefined,
      degree: degree || undefined,
      applying,
      test_optional: testOptional,
      academic_confidence: academicConfidence,
      artistic_confidence: artisticConfidence,
      unified_auditions: unifiedAuditions,
      supplemental_application_status: supplementalApplicationStatus,
      admission_decision: admissionDecision,
    };
    onSave(filters);
  }

  const clearFilters = () => {
    setPrescreens('');
    setAuditionsPortfolioRequired('');
    setEarlyDecision('');
    setEarlyAction('');
    setScoreOptionalEnabled('');
    setCommonApp('');
    setApplicationStatus('');
    setAuditionDateStart('');
    setAuditionDateEnd('');
    setGraduationYear('');
    setCollege(null);
    setDegree(null);
    setApplying('');
    setTestOptional('');
    setAcademicConfidence('');
    setArtisticConfidence('');
    setUnifiedAuditions('');
    setSupplementalApplicationStatus('');
    setAdmissionDecision('');
  }

  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FilterAltOutlinedIcon sx={{ pr: 1 }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>{title}</Typography>
          <SecondaryButton
            variant="outlined"
            startIcon={<CancelOutlinedIcon />}
            onClick={clearFilters}
          >
            Clear
          </SecondaryButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ py: 2 }}>

          <FormControl>
            <InputLabel id="prescreens">Prescreens</InputLabel>
            <Select
              labelId="prescreens"
              label="Prescreens"
              value={prescreens}
              onChange={(e) => setPrescreens(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="auditionsPortfolioRequired">Auditions/Portfolio Required</InputLabel>
            <Select
              labelId="auditionsPortfolioRequired"
              label="Auditions/Portfolio Required"
              value={auditionsPortfolioRequired}
              onChange={(e) => setAuditionsPortfolioRequired(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="earlyDecision">Early Decision</InputLabel>
            <Select
              labelId="earlyDecision"
              label="Early Decision"
              value={earlyDecision}
              onChange={(e) => setEarlyDecision(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="earlyAction">Early Action</InputLabel>
            <Select
              labelId="earlyAction"
              label="Early Action"
              value={earlyAction}
              onChange={(e) => setEarlyAction(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="scoreOptional">Score Optional</InputLabel>
            <Select
              labelId="scoreOptional"
              label="Score Optional"
              value={scoreOptionalEnabled}
              onChange={(e) => setScoreOptionalEnabled(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="commonApp">Common App</InputLabel>
            <Select
              labelId="commonApp"
              label="Common App"
              value={commonApp}
              onChange={(e) => setCommonApp(e.target.value)}
            >
              {confirmOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="applicationStatus">Application Status</InputLabel>
            <Select
              labelId="applicationStatus"
              label="Application Status"
              value={applicationStatus}
              onChange={(e) => setApplicationStatus(e.target.value)}
            >
              <MenuItem value="">Any</MenuItem>
              {applicationStatusOptions().map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
              <MenuItem value="Not Selected">Not Selected</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="supplementalApplicationStatus">Supplemental Application/Portfolio Status</InputLabel>
            <Select
              labelId="supplementalApplicationStatus"
              label="Supplemental Application/Portfolio Status"
              value={supplementalApplicationStatus}
              onChange={(e) => setSupplementalApplicationStatus(e.target.value)}
            >
              <MenuItem value="">Any</MenuItem>
              {statusOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
              <MenuItem value="Not Selected">Not Selected</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="admissionDecision">Prescreen Status</InputLabel>
            <Select
              labelId="admissionDecision"
              label="Prescreen Status"
              value={admissionDecision}
              onChange={(e) => setAdmissionDecision(e.target.value)}
            >
              <MenuItem value="">Any</MenuItem>
              {admissionDecisionOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
              <MenuItem value="Not Selected">Not Selected</MenuItem>
            </Select>
          </FormControl>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Audition Date After"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={auditionDateStart}
                  onChange={(e) => setAuditionDateStart(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Audition Date Before"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={auditionDateEnd}
                  onChange={(e) => setAuditionDateEnd(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>

          <FormControl>
            <InputLabel id="high_school_graduation_year">Year of Intended Enrollment</InputLabel>
            <Select
              labelId="high_school_graduation_year"
              label="Year of Intended Enrollment"
              value={graduationYear}
              onChange={(e) => setGraduationYear(e.target.value)}
            >
              <MenuItem value="Any">Any</MenuItem>
              {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <CollegeDropdown value={college} onChange={setCollege} />

          <DegreeDropdown value={degree} onChange={setDegree} />

          <FormControl>
            <InputLabel id="applying">Applying</InputLabel>
            <Select
              labelId="applying"
              label="Applying"
              value={applying}
              onChange={(e) => setApplying(e.target.value)}
            >
              <MenuItem value="Any">Any</MenuItem>
              {allApplyingOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  selected={(value || label) === applying}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="testOptional">Test Optional Recommendation</InputLabel>
            <Select
              labelId="testOptional"
              label="Test Optional Recommendation"
              value={testOptional}
              onChange={(e) => setTestOptional(e.target.value)}
            >
              <MenuItem value="Any">Any</MenuItem>
              {testOptionalOptions.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  selected={(value || label) === testOptional}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="academic_confidence">Academic Confidence</InputLabel>
            <Select
              labelId="academic_confidence"
              label="Academic Confidence"
              value={academicConfidence}
              onChange={(e) => setAcademicConfidence(e.target.value)}
            >
              <MenuItem value="Any">Any</MenuItem>
              <MenuItem value="Likely">Likely</MenuItem>
              <MenuItem value="Likely/Target">Likely/Target</MenuItem>
              <MenuItem value="Target">Target</MenuItem>
              <MenuItem value="Target/Stretch">Target/Stretch</MenuItem>
              <MenuItem value="Stretch">Stretch</MenuItem>
              <MenuItem value="High Stretch">High Stretch</MenuItem>
              <MenuItem value="Not Applicable">Not Applicable</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="artistic_confidence">Artistic Confidence</InputLabel>
            <Select
              labelId="artistic_confidence"
              label="Artistic Confidence"
              value={artisticConfidence}
              onChange={(e) => setArtisticConfidence(e.target.value)}
            >
              <MenuItem value="Any">Any</MenuItem>
              <MenuItem value="Likely">Likely</MenuItem>
              <MenuItem value="Likely/Target">Likely/Target</MenuItem>
              <MenuItem value="Target">Target</MenuItem>
              <MenuItem value="Target/Stretch">Target/Stretch</MenuItem>
              <MenuItem value="Stretch">Stretch</MenuItem>
              <MenuItem value="High Stretch">High Stretch</MenuItem>
              <MenuItem value="Unknown">Unknown</MenuItem>
              <MenuItem value="Need Add'l Info/Student">Need Add'l Info/Student</MenuItem>
              <MenuItem value="Not Applicable">Not Applicable</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="unifiedAuditions">Unified Auditions</InputLabel>
            <Select
              labelId="unifiedAuditions"
              label="Unified Auditions"
              value={unifiedAuditions}
              onChange={(e) => setUnifiedAuditions(e.target.value)}
            >
              {confirmOptionsNA.map(([label, value]) => (
                <MenuItem
                  key={value || label}
                  value={value || label}
                >{label}</MenuItem>
              ))}
            </Select>
          </FormControl>

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{ width: 1 }} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{ width: 1 }} onClick={handleSave}>Apply</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default FilterAssessments;
export { FilterData };
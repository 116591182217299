import { Box, Breadcrumbs, Card, CardContent, Container, IconButton, Stack, Toolbar, Typography } from "@mui/material";
import * as React from "react"
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Assessment, DocumentFile, Institution, Task, User } from "../models";
import StudentCard from "./admin/students/StudentCard/StudentCard";
import SearchField from "./controls/SearchField";
import DocumentItem from "./documents/DocumentItem";
import TaskItem from "./tasks/TaskItem";
import AssessmentCard from "./assessments/AssessmentCard";
import ResponsiveAppBar from "./controls/ResponsiveAppBar";
import CollegeCard from "./admin/configuration/colleges/CollegeCard";
import useAuth from "../hooks/useAuth";

interface SearchResult {
  institutions: Institution[];
  students: User[];
  assessments: Assessment[];
  documents: DocumentFile[];
  tasks: Task[];
}

const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get("q");
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState<SearchResult>();
  const auth = useAuth();

  useEffect(() => {
    const abortController = new AbortController();

    const fetchSearchResults = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/search?q=${q}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setSearchResults(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchSearchResults();

    return () => {
      abortController.abort();
    };
  }, [q]);

  const handleSearch = (query) => {
    setSearchParams({ q: query });
  }

  const updateTask = (task: Task) => {
    if (!searchResults) return;
    const newTasks = searchResults?.tasks.map(t => t.id === task.id ? task : t) || [];
    setSearchResults({ ...searchResults, tasks: newTasks });
  }

  const updateDocument = (document: DocumentFile) => {
    if (!searchResults) return;
    const newDocuments = searchResults?.documents.map(d => d.id === document.id ? document : d) || [];
    setSearchResults({ ...searchResults, documents: newDocuments });
  }

  if (!searchResults) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Search
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <Container sx={{ py: 2 }}>

          <Stack spacing={2}>

            <SearchField onSearch={handleSearch} value={q} />

            {searchResults?.students.length > 0 &&
              <Box>
                <Typography variant="overline">Students</Typography>
                <Stack spacing={2}>
                  {searchResults?.students.map(student => (
                    <StudentCard student={student} key={student.id} showAssessments={false} to={`/students/${student.id}`} />
                  ))}
                </Stack>
              </Box>
            }

            {searchResults?.institutions.length > 0 &&
              <Box>
                <Typography variant="overline">Colleges</Typography>
                <Stack spacing={2}>
                  {searchResults?.institutions.map(institution => (
                    <CollegeCard institution={institution} key={institution.id} />
                  ))}
                </Stack>
              </Box>
            }

            {searchResults?.assessments.length > 0 &&
              <Box>
                <Typography variant="overline">College Applications</Typography>
                <Stack spacing={2}>
                  {searchResults?.assessments.map(assessment => (
                    <AssessmentCard assessment={assessment} key={assessment.id} onChange={() => {}} />
                  ))}
                </Stack>
              </Box>
            }

            {searchResults?.tasks.length > 0 &&
              <Box>
                <Typography variant="overline">Tasks/Events</Typography>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      {searchResults?.tasks.map(task => (
                        <TaskItem task={task} key={task.id} onChange={updateTask} />
                      ))}
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            }

            {searchResults?.documents.length > 0 &&
              <Box>
                <Typography variant="overline">Documents</Typography>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      {searchResults?.documents.map(document => (
                        <DocumentItem document={document} key={document.id} onChange={updateDocument} isAdmin={false} />
                      ))}
                    </Stack>
                  </CardContent>
                </Card>
              </Box>
            }

          </Stack>

        </Container>
      </Box>
    </Box>
  )
}

export default SearchResults;
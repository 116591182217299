import * as React from "react";
import { InstitutionDegree } from "../../../../../../models";
import AuditionForm from "./AuditionForm";
import useAuth from "../../../../../../hooks/useAuth";

const EditAudition = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  const auth = useAuth();
  
  const updateInstitutionDegree = async (institutionDegree: InstitutionDegree) => {
    const token = auth.token();

    const body = {
      required_audition_portfolio: institutionDegree.required_audition_portfolio,
      scheduling_url: institutionDegree.scheduling_url,
      scheduling_instructions: institutionDegree.scheduling_instructions,
      unified_auditions: institutionDegree.unified_auditions,
      audition_dates: institutionDegree.audition_dates,
      in_person_audition_requirements: institutionDegree.in_person_audition_requirements,
    }
    
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${institutionDegree.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onSave({...institutionDegree, ...body});
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <AuditionForm institutionDegree={institutionDegree} onCancel={handleCancel} onSave={updateInstitutionDegree} />
  )
}

export default EditAudition;
import * as React from 'react'
import { Routes, Route } from "react-router-dom";
import Login from '../auth/Login';
import Home from '../Home';
import StudentLayout from '../student/StudentLayout';
import RequireAuth from '../auth/RequireAuth';
import AuthProvider from '../../providers/AuthProvider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import AcceptInvitation from '../invitations/AcceptInvitation';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2D9CDB',
    },
  },
  typography: {
    fontSize: 12,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          } />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/users/password/edit" element={<ResetPassword />} />
          <Route path="/invitation" element={<AcceptInvitation />} />
          <Route element={<StudentLayout tasksCount={0} />} />
        </Routes>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useRouteMatch from "../../../hooks/useRouteMatch";
import useUser from "../../../hooks/useUser";
import { User } from "../../../models";
import StudentCollaborators from "../../collaborators/StudentCollaborators";
import RoundedBottomTabs from "../../controls/RoundedBottomTabs";
import StudentStandardizedTests from "../../standardized_tests/StudentStandardizedTests";

const Settings = () => {
  const auth = useAuth();
  const routeMatch = useRouteMatch(['/settings', '/settings/tests']);
  const [value, setValue] = useState(routeMatch?.pattern?.path || '/settings');
  const [user, setUser] = useState<User>();
  const currentUser = useUser();

  useEffect(() => {
    const userId = currentUser.userId || auth.user.id;
    // const userId = auth.user.id;
    const fetchUser = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users/${userId}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setUser(json);
    }

    fetchUser();
  }, [auth, currentUser]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!user) return null;

  return (
    <Box>
      <TabContext value={value}>
        <RoundedBottomTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {auth.user.id === user.id &&
            <Tab sx={{ textTransform: 'none' }} label="Collaborators" value="/settings" component={Link} to="/settings" />
          }
          <Tab sx={{ textTransform: 'none' }} label="Standardized Tests" value="/settings/tests" component={Link} to="/settings/tests" />
        </RoundedBottomTabs>
        <TabPanel value="/settings" sx={{ p: 0 }}>
          <StudentCollaborators student={user} onChange={() => {}} isAdmin={false} />
        </TabPanel>
        <TabPanel value="/settings/tests" sx={{ p: 0 }}>
          <StudentStandardizedTests student={user} onChange={() => {}} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default Settings;
import { Box, Card, CardContent, Container, Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import SecondaryButton from "../../../controls/SecondaryButton";
import TaskTemplateItem from "./TaskTemplateItem";
import { TaskTemplate } from "../../../../models";
import useAuth from "../../../../hooks/useAuth";

const perPage = 20;

const AdminTasks = () => {
  const [taskTemplates, setTaskTemplates] = useState<TaskTemplate[]>([]);

  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);
  const auth = useAuth();

  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const fetchTaskTemplates = async () => {
      setLoading(true);
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates?page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      setLoading(false);
      if (response.ok) {
        const json: TaskTemplate[] = await response.json();
        console.log(json);
        setTaskTemplates(prev => {
          const filteredPrev = prev.filter(a => !json.map(b => b.id).includes(a.id));
          return [...filteredPrev, ...json];
        });
        if (json.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchTaskTemplates();
  }, []);
  
  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          component={Link}
          to={`/task_templates/new`}
        >
          Add a Task
        </SecondaryButton>

        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Box>
                {taskTemplates.map(taskTemplate => (
                  <TaskTemplateItem taskTemplate={taskTemplate} key={taskTemplate.id} />
                ))}
              </Box>

              {!reachedEnd &&
                <SecondaryButton variant="outlined" onClick={loadNextPage}>
                  {isLoading ? 'Loading...' : 'Load More' }
                </SecondaryButton>
              }
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  )
}

export default AdminTasks;
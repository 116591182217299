import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TaskTemplateForm from "./TaskTemplateForm";
import { TaskTemplate } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const EditTaskTemplate = () => {
  const params = useParams();
  const [taskTemplate, setTaskTemplate] = useState<TaskTemplate>();
  const navigate = useNavigate();
  const auth = useAuth();
  
  useEffect(() => {
    const fetchTaskTemplate = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setTaskTemplate(json);
    }

    fetchTaskTemplate();
  }, []);

  const updateTaskTemplate = async (taskTemplate: TaskTemplate) => {
    const token = auth.token();
    const body = {
      title: taskTemplate.title,
      notes: taskTemplate.notes,
      task_type: taskTemplate.task_type,
      institution_id: taskTemplate.institution_id,
      due_on: taskTemplate.due_on,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates/${params.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      navigate(`/task_templates/${params.id}`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/task_templates/${params.id}`);
  }

  if (!taskTemplate) {
    return (<div/>)
  }

  return (
    <>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/task_templates/${params.id}`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Tasks
            </Typography>
            <Typography variant="subtitle2">
              {taskTemplate.title}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <TaskTemplateForm taskTemplate={taskTemplate} onCancel={handleCancel} onSave={updateTaskTemplate} />
      </Box>
    </>
  )
}

export default EditTaskTemplate;
import * as React from "react";
import { Institution } from "../../../../../../models";
import EditCollegeForm from "./EditCollegeForm";
import useAuth from "../../../../../../hooks/useAuth";

const EditCollegeInfo = ({ institution, onCancel, onSave }: { institution: Institution, onCancel: () => void, onSave: (institution: Institution) => void }) => {
  const auth = useAuth();
  
  const updateCollege = async (institution: Institution) => {
    const token = auth.token();

    const body = {
      city: institution.city,
      state: institution.state,
      country: institution.country,
      central_office_phone: institution.central_office_phone,
      central_office_email: institution.central_office_email,
    }
    
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institutions/${institution.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      onSave({...institution, ...body});
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <EditCollegeForm institution={institution} onCancel={handleCancel} onSave={updateCollege} />
  )
}

export default EditCollegeInfo;
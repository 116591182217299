import { DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Institution, User } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import { collegeMajors } from "../../models/User";
import useAuth from "../../hooks/useAuth";
import CollegeDropdown from "./CollegeDropdown";

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = thisYear - 5; i < thisYear + 5; i++) {
  years.push(i);
}

const FindStudentsDialog = ({students, onCancel, onAdd}: { students: User[], onCancel: () => void, onAdd: (students: User[]) => void }) => {  
  const [intendedCollegeMajor, setIntendedCollegeMajor] = useState('');
  const [graduationYear, setGraduationYear] = useState('');
  const [college, setCollege] = useState<Institution>();
  const auth = useAuth();
  
  const handleAdd = async () => {
    const token = auth.token();

    let params = '';
    if (intendedCollegeMajor) params += `&intended_college_major=${intendedCollegeMajor}`;
    if (graduationYear) params += `&graduation_year=${graduationYear}`;
    if (college) params += `&college=${college.id}`;

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users?student=1${params}`, {
      method: 'GET',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      })
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onAdd(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          <FormControl>
            <InputLabel id="high_school_graduation_year">Year of Intended Enrollment</InputLabel>
            <Select
              labelId="high_school_graduation_year"
              label="Year of Intended Enrollment"
              value={graduationYear}
              onChange={(e) => setGraduationYear(e.target.value)}
            >
              {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="intendedCollegeMajor">Intended College Major</InputLabel>
            <Select
              labelId="intendedCollegeMajor"
              label="Intended College Major"
              value={intendedCollegeMajor || ''}
              onChange={(e) => setIntendedCollegeMajor(e.target.value)}
            >
              {collegeMajors.map(major => (
                <MenuItem key={major} value={major}>{major}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <CollegeDropdown value={college ?? null} onChange={setCollege} />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleAdd}>Find</PrimaryButton>
      </DialogActions>
    </>
  )
};

export default FindStudentsDialog;

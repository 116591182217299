import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import InstitutionDegreeForm from "./InstitutionDegreeForm";
import { InstitutionDegree } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const EditInstitutionDegree = () => {
  const params = useParams();
  const [institutionDegree, setInstitutionDegree] = useState<InstitutionDegree>();
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const fetchInstitutionDegree = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setInstitutionDegree(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchInstitutionDegree();
  }, []);

  const updateInstitutionDegree = async (institutionDegree: InstitutionDegree) => {
    const token = auth.token();
    const body = {
      degree_id: institutionDegree.degree_id,
      website_url: institutionDegree.website_url,
      supplemental_application_required: institutionDegree.supplemental_application_required,
      supplemental_application_deadline: institutionDegree.supplemental_application_deadline,
      supplemental_application_url: institutionDegree.supplemental_application_url,
      supplemental_application_requirements: institutionDegree.supplemental_application_requirements,
      pre_screening: institutionDegree.pre_screening,
      pre_screening_deadline: institutionDegree.pre_screening_deadline,
      multiple_prescreens: institutionDegree.multiple_prescreens,
      prescreen_deadline_2: institutionDegree.prescreen_deadline_2,
      prescreen_deadline_3: institutionDegree.prescreen_deadline_3,
      prescreen_deadline_4: institutionDegree.prescreen_deadline_4,
      prescreen_deadline_5: institutionDegree.prescreen_deadline_5,
      score_optional_enabled: institutionDegree.score_optional_enabled,
      score_optional: institutionDegree.score_optional,
      writing_requirements: institutionDegree.writing_requirements,
      pre_screening_requirements_url: institutionDegree.pre_screening_requirements_url,
      prescreen_artistic_upload_url: institutionDegree.prescreen_artistic_upload_url,
      required_audition_portfolio: institutionDegree.required_audition_portfolio,
      scheduling_url: institutionDegree.scheduling_url,
      scheduling_instructions: institutionDegree.scheduling_instructions,
      unified_auditions: institutionDegree.unified_auditions,
      audition_dates: institutionDegree.audition_dates,
      in_person_audition_requirements: institutionDegree.in_person_audition_requirements,
      comments_about_degree: institutionDegree.comments_about_degree,
      has_department_visit_information: institutionDegree.has_department_visit_information,
      department_visit_information: institutionDegree.department_visit_information,
      admissions_office_phone: institutionDegree.admissions_office_phone,
      admissions_office_email: institutionDegree.admissions_office_email,
      early_action: institutionDegree.early_action,
      early_action_date: institutionDegree.early_action_date,
      early_decision:institutionDegree.early_decision,
      early_decision_date: institutionDegree.early_decision_date,
      other_decision: institutionDegree.other_decision,
      other_decision_details: institutionDegree.other_decision_details,
      other_decision_date: institutionDegree.other_decision_date,
      other_decision_two: institutionDegree.other_decision_two,
      other_decision_details_two: institutionDegree.other_decision_details_two,
      other_decision_date_two: institutionDegree.other_decision_date_two,
      regular_deadline: institutionDegree.regular_deadline,
      artsbridge_recommended_deadline: institutionDegree.artsbridge_recommended_deadline,
      optional_arts_supplement: institutionDegree.optional_arts_supplement,
      optional_arts_supplement_url: institutionDegree.optional_arts_supplement_url,
      optional_arts_supplement_deadline: institutionDegree.optional_arts_supplement_deadline,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${params.id}`, {
      method: 'PATCH',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      navigate(`/institution_degrees/${params.id}`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/institution_degrees/${params.id}`);
  }

  if (!institutionDegree) {
    return (<div/>)
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/institution_degrees/${params.id}`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Colleges
            </Typography>
            <Typography variant="subtitle2">
              {institutionDegree.institution?.name}
            </Typography>
            <Typography variant="subtitle2">
              {institutionDegree.degree?.name}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <InstitutionDegreeForm institutionId={institutionDegree.institution_id} institutionDegree={institutionDegree} onCancel={handleCancel} onSave={updateInstitutionDegree} />
      </Box>
    </Box>
  )
}

export default EditInstitutionDegree;
import { Autocomplete, AutocompleteChangeReason, Box, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from "react";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { User } from "../../models";
import StudentsDropdown from "./StudentsDropdown";

interface FilterData {
  upload_start: string;
  upload_end: string;
  users: User[];
  shared_users: User[];
}

const confirmOptions = [
  ['Any', ''],
  ['Yes', 'Yes'],
  ['No', 'No']
]

const FilterDocuments = ({ title, filters, onCancel, onSave }: { title: string, filters?: FilterData, onCancel: () => void, onSave: (filters: FilterData) => void }) => {
  const [uploadStart, setUploadStart] = useState(filters?.upload_start || '');
  const [uploadEnd, setUploadEnd] = useState(filters?.upload_end || '');

  const [selectedUsers, setSelectedUsers] = useState<User[]>(filters?.users || []);
  const [selectedSharedUsers, setSelectedSharedUsers] = useState<User[]>(filters?.shared_users || []);


  const handleSave = () => {
    const filters: FilterData = {
      upload_start: uploadStart,
      upload_end: uploadEnd,
      users: selectedUsers,
      shared_users: selectedSharedUsers,
    };
    onSave(filters);
  }

  const clearFilters = () => {
    setUploadStart('');
    setUploadEnd('');
  }

  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <FilterAltOutlinedIcon sx={{ pr: 1 }} />
          <Typography variant="h6" sx={{ flexGrow: 1 }}>{title}</Typography>
          <SecondaryButton
            variant="outlined"
            startIcon={<CancelOutlinedIcon />}
            onClick={clearFilters}
          >
            Clear
          </SecondaryButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ py: 2 }}>

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Uploaded After"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={uploadStart}
                  onChange={(e) => setUploadStart(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Uploaded Before"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={uploadEnd}
                  onChange={(e) => setUploadEnd(e.target.value)}
                />
              </Grid>
            </Grid>
          </Box>

          <StudentsDropdown label="Shared by" value={selectedUsers} onChange={setSelectedUsers} />
          <StudentsDropdown label="Shared with" value={selectedSharedUsers} onChange={setSelectedSharedUsers} />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{ width: 1 }} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{ width: 1 }} onClick={handleSave}>Apply</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default FilterDocuments;
export { FilterData };
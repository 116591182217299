import { Avatar, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack } from "@mui/material";
import * as React from "react";
import { MouseEvent, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import EditableField from "../../../controls/EditableField";
import EditableNotes from "../../../controls/EditableNotes";
import { TaskTemplate } from "../../../../models";
import useAuth from "../../../../hooks/useAuth";
import RichTextViewer from "../../../controls/RichTextViewer";

const TaskTemplateItem = ({taskTemplate}: { taskTemplate: TaskTemplate}) => {  
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState(taskTemplate.title);
  const [anchorElTask, setAnchorElTask] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const auth = useAuth();

  const deleteTaskTemplate = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates/${taskTemplate.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const handleOpenTaskMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElTask(event.currentTarget);
  };

  const handleCloseTaskMenu = () => {
    setAnchorElTask(null);
  };

  if (deleted) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="flex-start" sx={{ p: 0 }}>
      <RadioButtonUncheckedOutlinedIcon sx={{ px: 1 }} />
      <Stack sx={{flexGrow: 1}}>
        <EditableField endpoint={`task_templates/${taskTemplate.id}`} field="title" value={taskTemplate.title} fontWeight="500" />
        <RichTextViewer value={taskTemplate.notes} /> 
        {taskTemplate.institution &&
          <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
            <Avatar sx={{ width: 24, height: 24 }} src={taskTemplate.institution.institution_avatar?.url} alt={taskTemplate.institution.name} />
            <Link variant="body2" color="inherit" underline="hover" href={`/institutions/${taskTemplate.institution.id}`}>{taskTemplate.institution.name}</Link>
          </Stack>
        }
      </Stack>
      <IconButton onClick={handleOpenTaskMenu} sx={{ p: 0 }}><MoreVertIcon/></IconButton>
      <Menu
        anchorEl={anchorElTask}
        keepMounted
        open={Boolean(anchorElTask)}
        onClose={handleCloseTaskMenu}
      >
        <MenuItem onClick={() => deleteTaskTemplate()}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </Stack>
  )
}

export default TaskTemplateItem;
import { Avatar, Box, Button, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tab, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { MouseEvent, SyntheticEvent, useEffect, useState } from "react";
import StudentInfo from "./info/StudentInfo";
import { TabContext, TabPanel } from '@mui/lab';
import StudentColleges from "./colleges/StudentColleges";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import StudentTasks from "./tasks/StudentTasks";
import useRouteMatch from "../../../hooks/useRouteMatch";
import StudentDocuments from "./documents/StudentDocuments";
import SecondaryButton from "../../controls/SecondaryButton";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import StudentAddTask from "./tasks/StudentAddTask";
import UploadDocument from "../documents/UploadDocument";
import RoundedBottomTabs from "../../controls/RoundedBottomTabs";
import StudentCollaborators from "../../collaborators/StudentCollaborators";
import { DocumentFile, Task, User } from "../../../models";
import ResponsiveAppBar from "../../controls/ResponsiveAppBar";
import StudentStandardizedTests from "../../standardized_tests/StudentStandardizedTests";
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import useAuth from "../../../hooks/useAuth";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const StudentDetail = () => {
  const params = useParams();
  const [student, setStudent] = useState<User>();
  const routeMatch = useRouteMatch([`/students/${params.id}`, `/students/${params.id}/colleges`, `/students/${params.id}/tasks`, `/students/${params.id}/documents/*`, `/students/${params.id}/collaborators`, `/students/${params.id}/tests`]);
  const [value, setValue] = useState(routeMatch?.pattern?.path || `/students/${params.id}`);
  const [openTask, setOpenTask] = useState(false);
  const [openDocument, setOpenDocument] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();

  const [archiveOpen, setArchiveOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const fetchStudent = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/students/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setStudent(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchStudent();
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSaveTask = (newTasks: Task[]) => {
    if (!student) return;
    const filteredTasks = newTasks.filter(t => t.user_id === student.id);
    setStudent({ ...student, tasks: student.tasks?.concat(filteredTasks) });
    setOpenTask(false);
    navigate(`/students/${params.id}/tasks`);
    setValue(`/students/${params.id}/tasks`);
  }

  const handleSaveDocument = (newDocuments: DocumentFile[]) => {
    if (!student) return;
    const filteredDocuments = newDocuments.filter(d => d.user_id === student.id);
    setStudent({ ...student, documents: (student.documents || []).concat(filteredDocuments) });
    setOpenDocument(false);
    navigate(`/students/${params.id}/documents`);
    setValue(`/students/${params.id}/documents`);
  }

  const signInAsStudent = async () => {
    if (!params.id) return;
    auth.become(params.id, () => {
      navigate('/', { replace: true });
    });
  }

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCancelArchive = () => {
    handleCloseMenu();
    setArchiveOpen(false);
  }

  const handleConfirmArchive = () => {
    handleCloseMenu();
    setArchiveOpen(false);
    archiveStudent();
  }

  const handleConfirmUnarchive = () => {
    handleCloseMenu();
    setArchiveOpen(false);
    unarchiveStudent();
  }

  const archiveStudent = async () => {
    if (!student) return;

    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users/${student.id}/trash`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      navigate('/students/archived');
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const unarchiveStudent = async () => {
    if (!student) return;

    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/users/${student.id}/untrash`, {
      method: 'PUT',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      navigate('/students');
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  const dialogTitle = student?.archived ? 'Unarchive this student?' : 'Archive this student?';
  const dialogMessage = student?.archived ? 'Are you sure you want to unarchive this student? You can find unarchived students in the Active Students tab.' : 'Are you sure you want to archive this student? You can find archived students in the Archived Students tab.';

  if (!student) {
    return <div />
  }

  return (
    <Box sx={{ flexGrow: 1, width: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={student.archived ? '/students/archived' : '/students'}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Typography variant="subtitle2">
            Students
          </Typography>
        </Toolbar>
      </ResponsiveAppBar>

      <Box>
        <Toolbar />
        <CardHeader
          avatar={<Avatar alt={`${student.first_name} ${student.last_name}`} src={student.avatar?.url} />}
          title={`${student.first_name} ${student.last_name}`}
          subheader={student.email}
          action={
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon />
            </IconButton>
          }
          sx={{ backgroundColor: 'background.default' }}
        />

        <Box
          sx={{
            backgroundColor: 'background.default',
            pb: 2,
            px: 2,
          }}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <SecondaryButton
                variant="outlined"
                startIcon={<CheckCircleOutlinedIcon />}
                sx={{ width: 1, maxWidth: 300 }}
                onClick={() => setOpenTask(true)}
              >
                New Task/Event
              </SecondaryButton>
            </Grid>
            <Grid item xs={6} lg={3}>
              <SecondaryButton
                variant="outlined"
                startIcon={<FileUploadOutlinedIcon />}
                sx={{ width: 1, maxWidth: 300 }}
                onClick={() => setOpenDocument(true)}
              >
                Upload Document
              </SecondaryButton>
            </Grid>
            <Grid item xs={6} lg={3}>
              <SecondaryButton
                variant="outlined"
                startIcon={<AssignmentIndOutlinedIcon />}
                sx={{ width: 1, maxWidth: 300 }}
                onClick={() => signInAsStudent()}
              >
                Sign In As Student
              </SecondaryButton>
            </Grid>
            <Grid item xs={6} lg={3}>
              <SecondaryButton
                variant="outlined"
                startIcon={<EmailOutlinedIcon />}
                sx={{ width: 1, maxWidth: 300 }}
                component={Link}
                to={`/chats/${params.id}`}
              >
                Contact Student
              </SecondaryButton>
            </Grid>
          </Grid>
        </Box>

        <TabContext value={value}>
          <RoundedBottomTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Info" value={`/students/${params.id}`} component={Link} to={`/students/${params.id}`} />
            <Tab label="Colleges" value={`/students/${params.id}/colleges`} component={Link} to={`/students/${params.id}/colleges`} />
            <Tab label="Tasks/Events" value={`/students/${params.id}/tasks`} component={Link} to={`/students/${params.id}/tasks`} />
            <Tab label="Documents" value={`/students/${params.id}/documents/*`} component={Link} to={`/students/${params.id}/documents`} />
            <Tab label="Collaborators" value={`/students/${params.id}/collaborators`} component={Link} to={`/students/${params.id}/collaborators`} />
            <Tab label="Standardized Tests" value={`/students/${params.id}/tests`} component={Link} to={`/students/${params.id}/tests`} />
          </RoundedBottomTabs>
          <TabPanel value={`/students/${params.id}`} sx={{ p: 0 }}>
            <StudentInfo student={student} onChange={setStudent} />
          </TabPanel>
          <TabPanel value={`/students/${params.id}/colleges`} sx={{ p: 0 }}>
            <StudentColleges student={student} isAdmin={true} onChange={setStudent} />
          </TabPanel>
          <TabPanel value={`/students/${params.id}/tasks`} sx={{ p: 0 }}>
            <StudentTasks student={student} onChange={setStudent} />
          </TabPanel>
          <TabPanel value={`/students/${params.id}/documents/*`} sx={{ p: 0 }}>
            <StudentDocuments student={student} onChange={setStudent} />
          </TabPanel>
          <TabPanel value={`/students/${params.id}/collaborators`} sx={{ p: 0 }}>
            <StudentCollaborators student={student} onChange={setStudent} isAdmin={true} />
          </TabPanel>
          <TabPanel value={`/students/${params.id}/tests`} sx={{ p: 0 }}>
            <StudentStandardizedTests student={student} onChange={setStudent} />
          </TabPanel>
        </TabContext>
      </Box>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem component={Link} to={`/students/${student.id}/edit`}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setArchiveOpen(true)}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>{student.archived ? 'Unarchive' : 'Archive'}</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={archiveOpen}
        onClose={handleCancelArchive}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelArchive}>Cancel</Button>
          <Button onClick={student.archived ? handleConfirmUnarchive : handleConfirmArchive}>{student.archived ? 'Unarchive' : 'Archive'}</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTask} fullWidth>
        <StudentAddTask user={student} onCancel={() => setOpenTask(false)} onSave={handleSaveTask} />
      </Dialog>

      {student.id &&
        <Dialog open={openDocument} fullWidth>
          <UploadDocument userId={student.id} onCancel={() => setOpenDocument(false)} onSave={handleSaveDocument} showUsers={false} folderId={null} />
        </Dialog>
      }

    </Box>
  );
}

export default StudentDetail;
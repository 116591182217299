import { Button, ButtonProps, styled } from "@mui/material";
import { ForwardRefExoticComponent, RefAttributes } from "react";
import { LinkProps } from "react-router-dom";

interface SecondaryButtonProps extends ButtonProps {
  component?: ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement>>;
  to?: string;
}

const SecondaryButton = styled(Button)<SecondaryButtonProps>(({ theme }) => ({
  boxShadow: 'none',
  textTransform: 'none',
  // fontSize: 12,
  backgroundColor: theme.palette.background.default,
}));

export default SecondaryButton;
import { Box, Breadcrumbs, CardHeader, IconButton, Tab, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import InstitutionDegreeInfo from "./InstitutionDegreeInfo";
import RoundedBottomTabs from "../../../controls/RoundedBottomTabs";
import Background from "../../../controls/Background";
import useRouteMatch from "../../../../hooks/useRouteMatch";
import { InstitutionDegree } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const InstitutionDegreeDetail = () => {
  const params = useParams();
  const [institutionDegree, setInstitutionDegree] = useState<InstitutionDegree>();
  const routeMatch = useRouteMatch([`/institution_degrees/${params.id}`]);
  const [value, setValue] = useState(routeMatch?.pattern?.path || `/institution_degrees/${params.id}`);
  const auth = useAuth();

  useEffect(() => {
    const fetchInstitutionDegree = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setInstitutionDegree(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchInstitutionDegree();
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!institutionDegree) {
    return <div/>
  }
  
  return (
    <Background sx={{width: 1}}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/institutions/${institutionDegree.institution?.id}/degrees`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Colleges
            </Typography>
            <Typography variant="subtitle2">
              {institutionDegree.institution?.name}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      
      <Box>
        <Toolbar />
        <CardHeader
          title={institutionDegree.degree?.name}
          action={
            <IconButton component={Link} to={`/institution_degrees/${institutionDegree.id}/edit`}>
              <EditOutlinedIcon/>
            </IconButton>
          }
          sx={{ backgroundColor: 'background.default' }}
        />
        <TabContext value={value}>
          <RoundedBottomTabs 
            value={value} 
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Info" value={`/institution_degrees/${params.id}`} component={Link} to={`/institution_degrees/${params.id}`} />
          </RoundedBottomTabs>
          <TabPanel value={`/institution_degrees/${params.id}`} sx={{p: 0}}>
            <InstitutionDegreeInfo institutionDegree={institutionDegree} />
          </TabPanel>
        </TabContext>
      </Box>
    </Background>
  )
}

export default InstitutionDegreeDetail;
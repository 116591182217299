import Assessment from "./Assessment";
import DocumentFile from "./DocumentFile";
import SharedTask from "./SharedTask";
import TaskTemplate from "./TaskTemplate";
import User from "./User";

export default interface Task {
  id?: number;
  user_id?: number;
  assessment_id?: number;
  title: string;
  notes: string;
  due_on?: string;
  order?: number;
  task_type: string;
  completed_at?: string;
  attachment?: {
    url: string;
  };
  assessment?: Assessment;
  user_ids?: number[];
  include_collaborators?: boolean;
  institution_degree_ids?: number[];
  file?: File;
  file_name?: string;
  attachment_identifier?: string;
  task_template_id?: number;
  task_template?: TaskTemplate;
  user?: User;
  documents?: DocumentFile[];
  reminded_at?: string;
  shared_tasks?: SharedTask[];
  deleted_at?: string;
  answer?: string;
  url?: string;
}

export const taskTypes = [
  ['Task', 'task'],
  ['Audition', 'audition'],
  ['Interview', 'interview'],
  ['Portfolio Review', 'portfolio_review'],
  ['Question', 'question'],
];

export const formatTaskType = (taskType: string) => {
  const type = taskTypes.find(([label, value]) => value === taskType);
  return type ? type[0] : undefined;
}
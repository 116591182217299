import { DialogActions, DialogContent, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Collaboration } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";

const CollaborationForm = ({ collaboration, onCancel, onSave }: { collaboration?: Collaboration, onCancel: () => void, onSave: (collaboration: Collaboration) => void }) => {
  const [email, setEmail] = useState<string>(collaboration?.email || '');
  const [firstName, setFirstName] = useState<string>(collaboration?.first_name || '');
  const [lastName, setLastName] = useState<string>(collaboration?.last_name || '');

  const handleSave = () => {
    const collaboration: Collaboration = {
      email,
      first_name: firstName,
      last_name: lastName,
    }
    onSave(collaboration);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          <TextField 
            label="Email" 
            variant="outlined" 
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required />

          <TextField 
            label="First Name" 
            variant="outlined" 
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required />
          
          <TextField 
            label="Last Name" 
            variant="outlined" 
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required />
          
        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default CollaborationForm;
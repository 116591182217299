import { Box, CardHeader, IconButton, Tab, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { SyntheticEvent, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { TabContext, TabPanel } from "@mui/lab";
import DegreeActiveAssessments from "./DegreeActiveAssessments";
import DegreeInactiveAssessments from "./DegreeInactiveAssessments";
import { Degree } from "../../../../models";
import RoundedBottomTabs from '../../../controls/RoundedBottomTabs';
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const DegreeDetail = () => {
  const params = useParams();
  const [degree, setDegree] = useState<Degree>();
  const [value, setValue] = useState('1');
  const auth = useAuth();

  useEffect(() => {
    const fetchDegree = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setDegree(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchDegree();
  }, []);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!degree) {
    return <div/>
  }
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to="/config/degrees"
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Typography variant="subtitle2">
            Degrees
          </Typography>
        </Toolbar>
      </ResponsiveAppBar>
      
      <Box>
        <Toolbar />
        <CardHeader
          title={degree.name}
          action={
            <IconButton component={Link} to={`/degrees/${degree.id}/edit`}>
              <EditOutlinedIcon/>
            </IconButton>
          }
          sx={{ backgroundColor: 'background.default' }}
        />
        <TabContext value={value}>
          <RoundedBottomTabs 
            value={value} 
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Active Applications" value="1" />
            <Tab label="Inactive Applications" value="2" />
          </RoundedBottomTabs>
          <TabPanel value="1" sx={{p: 0}}>
            <DegreeActiveAssessments degree={degree}/>
          </TabPanel>
          <TabPanel value="2" sx={{p: 0}}>
            <DegreeInactiveAssessments degree={degree}/>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  )
}

export default DegreeDetail;
import { AppBar, useMediaQuery, useTheme } from "@mui/material"
import React, { ReactNode } from "react"

const ResponsiveAppBar = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  return (
    <AppBar 
      position="fixed" 
      color="inherit" 
      elevation={0}
      sx={{ width: `calc(100% - ${drawerWidth}px)` }}
    >
      {children}
    </AppBar>
  )
}

export default ResponsiveAppBar;
import { Box, Breadcrumbs, IconButton, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import TaskTemplateForm from "./TaskTemplateForm";
import { TaskTemplate } from "../../../../models";
import ResponsiveAppBar from "../../../controls/ResponsiveAppBar";
import useAuth from "../../../../hooks/useAuth";

const AddTaskTemplate = () => {
  const navigate = useNavigate();  
  const auth = useAuth();

  const createTaskTemplate = async (taskTemplate: TaskTemplate) => {
    const token = auth.token();
    const body = {
      title: taskTemplate.title,
      notes: taskTemplate.notes,
      type_type: taskTemplate.task_type,
      institution_id: taskTemplate.institution_id,
      due_on: taskTemplate.due_on,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/task_templates`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      navigate(`/config/tasks`);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    navigate(`/config/tasks`);
  }

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: 'background.default' }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            component={Link}
            to={`/config/tasks`}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Tasks
            </Typography>
            <Typography variant="subtitle2">
              Add a Task
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <TaskTemplateForm onCancel={handleCancel} onSave={createTaskTemplate} />
      </Box>
    </Box>
  )
}

export default AddTaskTemplate;
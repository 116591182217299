import { createContext } from "react";
import { UserPayload } from "../providers/AuthProvider";

interface AuthContextType {
  user: any;
  signin: (email: string, password: string, callback: (user: UserPayload|null) => void) => void;
  refresh: () => void;
  signout: (callback: VoidFunction) => void;
  become: (id: string, callback: VoidFunction) => void;
  token: () => string|null;
  reset: (reset_password_token: string, password: string, password_confirmation: string, callback: (user: UserPayload|null) => void) => void;
}

const AuthContext = createContext<AuthContextType>(null!);

export default AuthContext;
import { Card, CardContent, Container, IconButton, List, ListItem, ListItemText, Stack } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { Link } from "react-router-dom";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SecondaryButton from "../../../controls/SecondaryButton";
import { Degree } from "../../../../models";
import SearchField from "../../../controls/SearchField";
import useAuth from "../../../../hooks/useAuth";

const perPage = 20;

const AdminDegrees = () => {
  const [degrees, setDegrees] = useState<Degree[]>([]);
  
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [reachedEnd, setReachedEnd] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const auth = useAuth();

  // reset page when search, filters, or sort options change
  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const loadNextPage = () => {
    setPage(page => page + 1);
  }

  useEffect(() => {
    const abortController = new AbortController();

    const fetchDegrees = async () => {
      setLoading(true);
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/degrees?q=${searchQuery}&page=${page}&per_page=${perPage}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
        signal: abortController.signal,
      });
      setLoading(false);
      if (response.ok) {
        const json: Degree[] = await response.json();
        console.log(json);
        if (page === 1) {
          setDegrees(json);
        } else {
          setDegrees(prev => {
            const filteredPrev = prev.filter(a => !json.map(b => b.id).includes(a.id));
            return [...filteredPrev, ...json];
          });
        }
        if (json.length < perPage) {
          setReachedEnd(true);
        }
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchDegrees();

    return () => {
      abortController.abort();
    };
  }, [page, searchQuery]);
  
  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          component={Link}
          to={`/degrees/new`}
        >
          Add a Degree
        </SecondaryButton>

        <Card>
          <CardContent>
            <SearchField label="Search Degrees" onSearch={setSearchQuery} realTime={true} />
          </CardContent>
          <List>
            {degrees.map(degree => (
              <ListItem 
                key={degree.id}
                secondaryAction={
                  <IconButton edge="end">
                    <MoreVertIcon />
                  </IconButton>
                }
                button
                component={Link} 
                to={`/degrees/${degree.id}`}
              >
                <ListItemText primary={degree.name} />
              </ListItem>
            ))}
          </List>
        </Card>

        {!reachedEnd &&
          <SecondaryButton variant="outlined" onClick={loadNextPage}>
            {isLoading ? 'Loading...' : 'Load More' }
          </SecondaryButton>
        }
      </Stack>
    </Container>
  )
}

export default AdminDegrees;
import { LinearProgress, linearProgressClasses, Link, Stack, styled, Typography } from "@mui/material"
import React from "react"

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    // backgroundColor: customColor,
  },
}));

const ProgressItem = ({ title, count, total, color, url }: { title: string, count: number, total: number, color: string, url: string }) => {

  const pct = total > 0 ? (count / total) * 100 : 0;
  return (
    <Stack sx={{ mb: 2 }}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h6" sx={{ flexGrow: 1 }}>{title} ({count})</Typography>
        <Link href={url} variant="body1" underline="hover">View</Link>
      </Stack>
      <Stack>
        <BorderLinearProgress variant="determinate" value={pct} sx={{ 
          '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: color,
          }}}  />
      </Stack>
    </Stack>
  )
}

export default ProgressItem
import { DialogActions, DialogContent, FormControl, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { InstitutionDegree } from "../../../../../../models";
import PrimaryButton from "../../../../../controls/PrimaryButton";
import SecondaryButton from "../../../../../controls/SecondaryButton";
import RichTextEditor from "../../../../../controls/RichTextEditor";

const AuditionForm = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  
  const [requiredAuditionPortfolio, setRequiredAuditionPortfolio] = useState(institutionDegree?.required_audition_portfolio || '');
  const [schedulingUrl, setSchedulingUrl] = useState(institutionDegree?.scheduling_url || '');
  const [schedulingInstructions, setSchedulingInstructions] = useState(institutionDegree?.scheduling_instructions || '');
  const [unifiedAuditions, setUnifiedAuditions] = useState(institutionDegree?.unified_auditions || '');
  const [auditionDates, setAuditionDates] = useState(institutionDegree?.audition_dates || '');
  const [inPersonAuditionRequirements, setInPersonAuditionRequirements] = useState(institutionDegree?.in_person_audition_requirements || '');

  const handleSave = () => {
    const updatedInstitutionDegree: InstitutionDegree = {...institutionDegree,
      required_audition_portfolio: requiredAuditionPortfolio,
      scheduling_url: schedulingUrl,
      scheduling_instructions: schedulingInstructions,
      unified_auditions: unifiedAuditions,
      audition_dates: auditionDates,
      in_person_audition_requirements: inPersonAuditionRequirements,
    }

    onSave(updatedInstitutionDegree);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>

          <FormControl>
            <InputLabel id="requiredAuditionPortfolio">Audition/Interview Required</InputLabel>
            <Select
              labelId="requiredAuditionPortfolio"
              label="Audition/Interview Required"
              value={requiredAuditionPortfolio || ''}
              onChange={(e) => setRequiredAuditionPortfolio(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="Optional">Optional</MenuItem>
            </Select>
          </FormControl>

          {requiredAuditionPortfolio === 'Yes' &&
            <>
              <TextField 
                label="Scheduling Website" 
                variant="outlined" 
                value={schedulingUrl}
                onChange={(e) => setSchedulingUrl(e.target.value)} />

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Scheduling Instructions</FormLabel>
                <RichTextEditor value={schedulingInstructions} onChange={setSchedulingInstructions} />
              </FormControl>

              <FormControl>
                <InputLabel id="unifiedAuditions">Unified Auditions</InputLabel>
                <Select
                  labelId="unifiedAuditions"
                  label="Unified Auditions"
                  value={unifiedAuditions || ''}
                  onChange={(e) => setUnifiedAuditions(e.target.value)}
                >
                  <MenuItem value="N/A">N/A</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                  <MenuItem value="Yes">Yes</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Audition/Interview Dates</FormLabel>
                <RichTextEditor value={auditionDates} onChange={setAuditionDates} />
              </FormControl>

              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Audition/Interview Requirements</FormLabel>
                <RichTextEditor value={inPersonAuditionRequirements} onChange={setInPersonAuditionRequirements} />
              </FormControl>

            </>
          }

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default AuditionForm;
import { DialogActions, DialogContent, FormControl, FormLabel, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { InstitutionDegree } from "../../../../../../models";
import PrimaryButton from "../../../../../controls/PrimaryButton";
import SecondaryButton from "../../../../../controls/SecondaryButton";
import { format, parseISO } from "date-fns";
import RichTextEditor from "../../../../../controls/RichTextEditor";
import { MuiTelInput } from "mui-tel-input";

const EditDegreeForm = ({ institutionDegree, onCancel, onSave }: { institutionDegree: InstitutionDegree, onCancel: () => void, onSave: (institutionDegree: InstitutionDegree) => void }) => {
  
  let institutionDegreeSupplementalApplicationDeadline = '';
  if (institutionDegree?.supplemental_application_deadline) {
    const date = parseISO(institutionDegree.supplemental_application_deadline);
    institutionDegreeSupplementalApplicationDeadline = format(date, 'yyyy-MM-dd');
  }
  
  const [websiteUrl, setWebsiteUrl] = useState(institutionDegree?.website_url || '');
  const [supplementalApplicationRequired, setSupplementalApplicationRequired] = useState(institutionDegree?.supplemental_application_required || '');
  const [supplementalApplicationDeadline, setSupplementalApplicationDeadline] = useState(institutionDegreeSupplementalApplicationDeadline);
  const [supplementalApplicationUrl, setSupplementalApplicationUrl] = useState(institutionDegree?.supplemental_application_url || '');
  const [supplementalApplicationRequirements, setSupplementalApplicationRequirements] = useState(institutionDegree?.supplemental_application_requirements || '');
  const [scoreOptionalEnabled, setScoreOptionalEnabled] = useState(institutionDegree?.score_optional_enabled || '');
  const [scoreOptional, setScoreOptional] = useState(institutionDegree?.score_optional || '');
  const [writingRequirements, setWritingRequirements] = useState(institutionDegree?.writing_requirements || '');
  const [hasDepartmentVisitInformation, setHasDepartmentVisitInformation] = useState(institutionDegree?.has_department_visit_information || '');
  const [departmentVisitInformation, setDepartmentVisitInformation] = useState(institutionDegree?.department_visit_information || '');
  const [admissionsOfficePhone, setAdmissionsOfficePhone] = useState(institutionDegree?.admissions_office_phone || '');
  const [admissionsOfficeEmail, setAdmissionsOfficeEmail] = useState(institutionDegree?.admissions_office_email || '');

  const handleSave = () => {
    const updatedInstitutionDegree: InstitutionDegree = {...institutionDegree,
      website_url: websiteUrl,
      supplemental_application_required: supplementalApplicationRequired,
      supplemental_application_deadline: supplementalApplicationDeadline,
      supplemental_application_url: supplementalApplicationUrl,
      supplemental_application_requirements: supplementalApplicationRequirements,
      score_optional_enabled: scoreOptionalEnabled,
      score_optional: scoreOptional,
      writing_requirements: writingRequirements,
      has_department_visit_information: hasDepartmentVisitInformation,
      department_visit_information: departmentVisitInformation,
      admissions_office_phone: admissionsOfficePhone,
      admissions_office_email: admissionsOfficeEmail,
    }

    onSave(updatedInstitutionDegree);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2} sx={{ pt: 1 }}>

        <TextField 
            label="Degree Program Description Website" 
            variant="outlined" 
            value={websiteUrl}
            onChange={(e) => setWebsiteUrl(e.target.value)} />

          <FormControl>
            <InputLabel id="supplementalApplicationRequired">Supplemental Application/Portfolio Required</InputLabel>
            <Select
              labelId="supplementalApplicationRequired"
              label="Supplemental Application/Portfolio Required"
              value={supplementalApplicationRequired || ''}
              onChange={(e) => setSupplementalApplicationRequired(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {supplementalApplicationRequired === 'Yes' && 
            <>
              <TextField
                label="Supplemental Application/Portfolio Deadline"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={supplementalApplicationDeadline}
                onChange={(e) => setSupplementalApplicationDeadline(e.target.value)}
              />
            
              <TextField 
                label="Supplemental Application/Portfolio Website" 
                variant="outlined" 
                value={supplementalApplicationUrl}
                onChange={(e) => setSupplementalApplicationUrl(e.target.value)} />
            
              <FormControl sx={{ fontSize: 14, mb: 1 }}>
                <FormLabel sx={{ fontSize: 12, pb: 1 }}>Supplemental Application/Portfolio Requirements</FormLabel>
                <RichTextEditor value={supplementalApplicationRequirements} onChange={setSupplementalApplicationRequirements} />
              </FormControl>

            </>
          }

          <FormControl>
            <InputLabel id="scoreOptionalEnabled">Score Optional</InputLabel>
            <Select
              labelId="scoreOptionalEnabled"
              label="Score Optional"
              value={scoreOptionalEnabled || ''}
              onChange={(e) => setScoreOptionalEnabled(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Score Optional Policy</FormLabel>
            <RichTextEditor value={scoreOptional} onChange={setScoreOptional} />
          </FormControl>

          {/*<FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Essay Requirements</FormLabel>
            <RichTextEditor value={writingRequirements} onChange={setWritingRequirements} />
          </FormControl>*/}

          <FormControl>
            <InputLabel id="hasDepartmentVisitInformation">Has Department Visit Information</InputLabel>
            <Select
              labelId="hasDepartmentVisitInformation"
              label="Has Department Visit Information"
              value={hasDepartmentVisitInformation || ''}
              onChange={(e) => setHasDepartmentVisitInformation(e.target.value)}
            >
              <MenuItem value="No">No</MenuItem>
              <MenuItem value="Yes">Yes</MenuItem>
            </Select>
          </FormControl>

          {hasDepartmentVisitInformation === 'Yes' &&
            <FormControl sx={{ fontSize: 14, mb: 1 }}>
              <FormLabel sx={{ fontSize: 12, pb: 1 }}>Department Visit Information</FormLabel>
              <RichTextEditor value={departmentVisitInformation} onChange={setDepartmentVisitInformation} />
            </FormControl>
          }

          <MuiTelInput 
            label="Admissions Office Phone" 
            size="small" 
            forceCallingCode={true} 
            defaultCountry="US" 
            // disableDropdown 
            value={admissionsOfficePhone} 
            onChange={setAdmissionsOfficePhone} />

          <TextField 
            label="Admissions Office Email" 
            variant="outlined"
            value={admissionsOfficeEmail}
            onChange={(e) => setAdmissionsOfficeEmail(e.target.value)} />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default EditDegreeForm;
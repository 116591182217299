import { Box, Button, Card, CardContent, Container, Dialog, Stack, Typography } from "@mui/material";
import * as React from "react";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import TaskItem from "../../../tasks/TaskItem";
import StudentAddTask from "./StudentAddTask";
import { useState } from "react";
import SecondaryButton from "../../../controls/SecondaryButton";
import { Task, User } from "../../../../models";
import { format, parse } from "date-fns";
import TemplatedTaskItem from "../../../tasks/TemplatedTaskItem";

const StudentTasks = ({ student, onChange }: { student: User, onChange: (student: User) => void }) => {
  const [open, setOpen] = useState(false);
  const tasks = student.tasks || [];
  // const [tasks, setTasks] = useState<Task[]>(student.tasks || []);

  const handleSave = (newTasks: Task[]) => {
    const filteredTasks = newTasks.filter(t => t.user_id === student.id);
    setOpen(false);
    onChange({...student, tasks: (student.tasks || []).concat(filteredTasks)});
  }

  const updateTask = (task: Task) => {
    const newTasks = tasks.map(t => t.id === task.id ? task : t);
    onChange({...student, tasks: newTasks});
  }

  const completedTasks = tasks.filter(task => task.completed_at)
  const incompleteTasks = tasks.filter(task => !task.completed_at)

  const generalTasks = incompleteTasks.filter(task => !task.due_on).sort((a, b) => (a.order || 0) - (b.order || 0));

  const dueTasks: {[key: string]: Task[]} = {};
  for (const task of incompleteTasks) {
    if (task.due_on) {
      (dueTasks[task.due_on] ||= []).push(task);
    }
  }

  const sortableDueTasks = Object.entries(dueTasks);
  const sortedDueTasksArray = sortableDueTasks.sort(([a,], [b,]) => a.localeCompare(b));
  const sortedDueTasks = Object.fromEntries(sortedDueTasksArray);

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          onClick={() => setOpen(true)}
        >
          New Task/Event
        </SecondaryButton>

        {Object.keys(sortedDueTasks).map((key, index) => (
          <Box key={key}>
            <Typography variant="overline">{format(parse(key, 'yyyy-MM-dd', new Date()),  "MMM d, yyyy")}</Typography>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  {sortedDueTasks[key].map(task => (
                    (task.task_template_id ? (
                      <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} />
                    ): (
                      <TaskItem key={task.id} task={task} onChange={updateTask} />
                    ))
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Box>
        ))}
        
        <Box>
          <Typography variant="overline">GENERAL TASKS/EVENTS</Typography>
          <Card>
            <CardContent>
              <Stack spacing={2}>
                {generalTasks.map(task => (
                  (task.task_template_id ? (
                    <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} />
                  ) : (
                    <TaskItem key={task.id} task={task} onChange={updateTask} />
                  ))
                ))}
                {generalTasks.length === 0 && (
                  <Typography variant="body2" sx={{ pt: 1 }}>
                    You're all caught up.
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Box>

        

        {completedTasks.length > 0 &&
          <Box>
            <Typography variant="overline">COMPLETED TASKS/EVENTS</Typography>
            <Card>
              <CardContent>
                {completedTasks.map(task => (
                  (task.task_template_id ? (
                    <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} />
                  ): (
                    <TaskItem key={task.id} task={task} onChange={updateTask} />
                  ))
                ))}
              </CardContent>
            </Card>
          </Box>
        }
        
        <Dialog open={open} fullWidth>
          <StudentAddTask user={student} onCancel={() => setOpen(false)} onSave={handleSave} />
        </Dialog>
      </Stack>
    </Container>
  )
}

export default StudentTasks;
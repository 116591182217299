import { Avatar, Box, Checkbox, Container, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputLabel, MenuItem, Paper, Select, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { useEffect, useState } from "react";
import { User } from "../../../models";
import PaperDropzone from "../../controls/PaperDropzone/PaperDropzone";
import PrimaryButton from "../../controls/PrimaryButton";
import SecondaryButton from "../../controls/SecondaryButton";
import RichTextEditor from "../../controls/RichTextEditor";
import { MuiTelInput } from 'mui-tel-input'
import useAuth from "../../../hooks/useAuth";
import { collegeMajors } from "../../../models/User";

const thisYear = new Date().getFullYear();
const years: number[] = [];
for (let i = thisYear - 5; i < thisYear + 5; i++) {
  years.push(i);
}

const StudentForm = ({ student, onCancel, onSave }: { student?: User, onCancel: () => void, onSave: (student: User) => void }) => {
  let studentTransferDeadline = '';
  if (student?.transfer_deadline) {
    const date = parseISO(student.transfer_deadline);
    studentTransferDeadline = format(date, 'yyyy-MM-dd');
  }
  
  const [firstName, setFirstName] = useState(student?.first_name || '');
  const [lastName, setLastName] = useState(student?.last_name || '');
  const [email, setEmail] = useState(student?.email || '');
  const [phone, setPhone] = useState(student?.phone || '');
  const [biography, setBiography] = useState(student?.biography || '');
  const [highSchoolGraduationYear, setHighSchoolGraduationYear] = useState(student?.high_school_graduation_year);
  const [actualGraduationYear, setActualGraduationYear] = useState(student?.actual_graduation_year);
  const [paidStudent, setPaidStudent] = useState(student?.paid_student || false);
  const [transferStudent, setTransferStudent] = useState(student?.transfer_student || false);
  const [gapYear, setGapYear] = useState(student?.gap_year || false);
  const [transferDeadline, setTransferDeadline] = useState(studentTransferDeadline);
  const [transferWebsite, setTransferWebsite] = useState(student?.transfer_website || '');
  const [transferNotes, setTransferNotes] = useState(student?.transfer_notes || '');
  const [notesFrom, setNotesFrom] = useState(student?.notes_from || '');
  const [file, setFile] = useState<File>();
  const [highSchool, setHighSchool] = useState(student?.high_school || '');
  const [intendedCollegeMajor, setIntendedCollegeMajor] = useState(student?.intended_college_major || '');
  const [artsbridgeProgramsAttended, setArtsbridgeProgramsAttended] = useState(student?.artsbridge_programs_attended || '');
  const [consultantId, setConsultantId] = useState(student?.consultant_id);
  const [admins, setAdmins] = useState<User[]>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [highSchoolGraduationYearError, setHighSchoolGraduationYearError] = useState(false);
  const [intendedCollegeMajorError, setIntendedCollegeMajorError] = useState(false);
  const [consultantError, setConsultantError] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    const fetchAdmins = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/admins`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      const json = await response.json();
      console.log(json);
      setAdmins(json);
    }

    fetchAdmins();
  }, []);

  const handleSave = () => {
    setFirstNameError(!firstName);
    setLastNameError(!lastName);
    setEmailError(!email);
    setHighSchoolGraduationYearError(!highSchoolGraduationYear);
    setIntendedCollegeMajorError(!intendedCollegeMajor);
    setConsultantError(!consultantId);

    if (!firstName) {
      return;
    }

    if (!lastName) {
      return;
    }

    if (!email) {
      return;
    }

    if (!highSchoolGraduationYear) {
      return;
    }

    if (!intendedCollegeMajor) {
      return;
    }

    if (!consultantId) {
      return;
    }

    const student: User = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone,
      biography,
      high_school_graduation_year: highSchoolGraduationYear,
      actual_graduation_year: actualGraduationYear,
      paid_student: paidStudent,
      transfer_student: transferStudent,
      gap_year: gapYear,
      transfer_deadline: transferDeadline,
      transfer_website: transferWebsite,
      transfer_notes: transferNotes,
      notes_from: notesFrom,
      high_school: highSchool,
      intended_college_major: intendedCollegeMajor,
      artsbridge_programs_attended: artsbridgeProgramsAttended,
      consultant_id: consultantId,
      file,
    }
    onSave(student);
  }

  const onFileChange = (file: File) => {
    setFile(file);
  }
  
  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            <Avatar src={student?.avatar?.url} alt={`${student?.first_name} ${student?.last_name}`} />
            <PaperDropzone onChange={onFileChange} sx={{ flexGrow: 1 }} />
          </Stack>
          
          <TextField 
            label="First Name" 
            variant="outlined" 
            value={firstName}
            // inputProps={{ style: { fontSize: '0.875rem' } }}
            // InputLabelProps={{ style: { fontSize: '0.875rem' } }}
            size="small"
            onChange={(e) => setFirstName(e.target.value)} 
            required
            error={firstNameError}
            helperText={firstNameError && 'First name is required'}
          />
          
          <TextField 
            label="Last Name" 
            variant="outlined" 
            value={lastName}
            // inputProps={{ style: { fontSize: '0.875rem' } }}
            // InputLabelProps={{ style: { fontSize: '0.875rem' } }}
            size="small"
            onChange={(e) => setLastName(e.target.value)} 
            required
            error={lastNameError}
            helperText={lastNameError && 'Last name is required'}
          />

          <TextField 
            label="Email" 
            variant="outlined" 
            value={email}
            // inputProps={{ style: { fontSize: '0.875rem' } }}
            // InputLabelProps={{ style: { fontSize: '0.875rem' } }}
            size="small"
            onChange={(e) => setEmail(e.target.value)} 
            required
            error={emailError}
            helperText={emailError && 'Email is required'}
          />

          <MuiTelInput 
            label="Phone Number" 
            size="small" 
            forceCallingCode={true} 
            defaultCountry="US" 
            // disableDropdown 
            value={phone} 
            onChange={setPhone} />

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Biography</FormLabel>
            <RichTextEditor value={biography} onChange={setBiography} />
          </FormControl>

          <FormControl error={highSchoolGraduationYearError} required>
            <InputLabel id="high_school_graduation_year">Year of Intended Enrollment</InputLabel>
            <Select
              labelId="high_school_graduation_year"
              label="Year of Intended Enrollment"
              value={highSchoolGraduationYear || ''}
              onChange={(e) => setHighSchoolGraduationYear(+e.target.value)}
              required
            >
              {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{highSchoolGraduationYearError && 'Please select a year'}</FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id="actual_graduation_year">High School Graduation Year</InputLabel>
            <Select
              labelId="actual_graduation_year"
              label="High School Graduation Year"
              value={actualGraduationYear || ''}
              onChange={(e) => setActualGraduationYear(+e.target.value)}
              required
            >
              {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControlLabel 
            control={
              <Checkbox 
                checked={paidStudent}
                onChange={() => setPaidStudent(!paidStudent)} 
                size="small"
              />
            } 
            label="College Client"
            sx={{ fontSize: '0.875rem' }}
          />
          <FormControlLabel control={<Checkbox checked={transferStudent} onChange={() => setTransferStudent(!transferStudent)} />} label="Transfer Student" />
          <FormControlLabel control={<Checkbox checked={gapYear} onChange={() => setGapYear(!gapYear)} />} label="Gap Year" />

          {/*
          <TextField
            label="Transfer Deadline"
            type="date"
            InputLabelProps={{ 
              shrink: true,
            }}
            size="small"
            value={transferDeadline}
            onChange={(e) => setTransferDeadline(e.target.value)}
          />
          */}

          {/*
          <TextField 
            label="Transfer Website" 
            variant="outlined" 
            value={transferWebsite}
            size="small"
            onChange={(e) => setTransferWebsite(e.target.value)} 
          />*/}

          {/*<FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Transfer Notes</FormLabel>
            <RichTextEditor value={transferNotes} onChange={setTransferNotes} />
          </FormControl>*/}

          <TextField 
            label="High School" 
            variant="outlined" 
            value={highSchool}
            size="small"
            onChange={(e) => setHighSchool(e.target.value)} />

          <FormControl error={intendedCollegeMajorError} required>
            <InputLabel id="intendedCollegeMajor">Intended College Major</InputLabel>
            <Select
              labelId="intendedCollegeMajor"
              label="Intended College Major"
              value={intendedCollegeMajor || ''}
              onChange={(e) => setIntendedCollegeMajor(e.target.value)}
              required
            >
              {collegeMajors.map(major => (
                <MenuItem key={major} value={major}>{major}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{intendedCollegeMajorError && 'Please select an intended college major'}</FormHelperText>
          </FormControl>

          <TextField 
            label="ArtsBridge Programs" 
            variant="outlined" 
            value={artsbridgeProgramsAttended}
            size="small"
            onChange={(e) => setArtsbridgeProgramsAttended(e.target.value)} />

          {auth.user.superadmin && admins.length > 0 &&
            <FormControl error={consultantError} required>
              <InputLabel id="consultantId">Consultant</InputLabel>
              <Select
                labelId="consultantId"
                label="Consultant"
                value={consultantId || ''}
                onChange={(e) => setConsultantId(+e.target.value)}
                required
              >
                {admins.map(user => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                  >{`${user.first_name} ${user.last_name}`}</MenuItem>
                ))}
              </Select>
              <FormHelperText>{intendedCollegeMajorError && 'Please select a consultant'}</FormHelperText>
            </FormControl>
          }

          <FormControl sx={{ fontSize: 14, mb: 1 }}>
            <FormLabel sx={{ fontSize: 12, pb: 1 }}>Private ArtsBridge Notes</FormLabel>
            <RichTextEditor value={notesFrom} onChange={setNotesFrom} />
          </FormControl>
        </Stack>
      </Container>
      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default StudentForm;
import { Autocomplete, AutocompleteChangeReason, TextField } from "@mui/material";
import * as React from "react";
import { SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { InstitutionDegree } from "../../models";
import useAuth from "../../hooks/useAuth";

const CollegesDropdown = ({ 
  value, 
  filter, 
  onChange, 
  required = false, 
  error = false,
  disabled = false,
} : { 
  value: InstitutionDegree[], 
  filter?: InstitutionDegree[], 
  onChange: (value: InstitutionDegree[]) => void, 
  required?: boolean, 
  error?: boolean,
  disabled?: boolean,
}) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [institutionDegrees, setInstitutionDegrees] = useState<InstitutionDegree[]>(filter ?? []);
  const auth = useAuth();
  
  useEffect(() => {
    if (open && (filter ?? []).length === 0) {
      const fetchInstitutionDegrees = async () => {
        const token = auth.token();
        const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees`, {
          method: 'GET',
          headers: new Headers({
            'content-type': 'application/json',
            authorization: `Bearer ${token}`
          }),
        });
        if (response.ok) {
          const json: InstitutionDegree[] = await response.json();
          console.log(json);
          setInstitutionDegrees(json);
        } else {
          const json = await response.json();
          console.error(json);
        }
      }
      fetchInstitutionDegrees();
    } else if (open && (filter ?? []).length > 0) {
      setInstitutionDegrees(filter);
    }
  }, [open, filter]);

  useEffect(() => {
    const fetchInstitutionDegrees = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/institution_degrees?q=${inputValue}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json: InstitutionDegree[] = await response.json();
        console.log(json);
        setInstitutionDegrees(json);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }
    if ((filter ?? []).length === 0) {
      fetchInstitutionDegrees();
    } else {
      const query = (filter ?? []).filter(d => {
        if (inputValue.length === 0) { return true }
        const name = `${d.institution?.name} ${d.degree?.name}`;
        return name.toLowerCase().includes(inputValue.toLowerCase());
      })
      setInstitutionDegrees(query);
    }
  }, [inputValue, filter]);

  const handleChange = (event: SyntheticEvent, value: (InstitutionDegree[]), reason: AutocompleteChangeReason) => {
    onChange(value);
  }

  const filteredInstitutionDegrees = institutionDegrees.filter((institutionDegree) => {
    if (filter) {
      return filter.find((f) => f.id === institutionDegree.id);
    }
    return true;
  });

  const sortedInstitutionDegrees = filteredInstitutionDegrees.sort((a, b) => {
    const aName = `${a.institution?.name} ${a.degree?.name}`;
    const bName = `${b.institution?.name} ${b.degree?.name}`;
    return aName.localeCompare(bName);
  });
  
  return (
    <Autocomplete 
      multiple
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Apply to specific colleges"
          required={required}
          error={value.length === 0 && error}
          helperText={value.length === 0 && error && 'Select at least one college'}
        />
      )} 
      value={value}
      options={sortedInstitutionDegrees}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (typeof option === 'string') ? option : `${option.institution?.name} ${option.degree?.name}`}
      onChange={(event, value, reason) => handleChange(event, value as InstitutionDegree[], reason)}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      disabled={disabled}
    />
  )
}

export default CollegesDropdown;

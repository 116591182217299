import { DialogActions, DialogContent, Stack, TextField } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Folder } from "../../models";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";

const FolderForm = ({ folder, onCancel, onSave }: { folder?: Folder, onCancel: () => void, onSave: (folder: Folder) => void }) => {
  
  const [name, setName] = useState(folder?.name || '');

  const handleSave = () => {
    const folder: Folder = {
      name,
    }
    onSave(folder);
  }

  return (
    <>
      <DialogContent>
        <Stack spacing={2}>

          <TextField 
            label="Enter folder name" 
            variant="outlined" 
            value={name}
            onChange={(e) => setName(e.target.value)} />

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default FolderForm;
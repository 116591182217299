import { Dialog, Divider, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import * as React from "react";
import { MouseEvent, useState } from "react";
import { StandardizedTest } from "../../models";
import RichTextViewer from "../controls/RichTextViewer";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditStandardizedTest from "./EditStandardizedTest";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useAuth from "../../hooks/useAuth";

const StandardizedTestItem = ({ standardizedTest }: { standardizedTest: StandardizedTest }) => {
  const [test, setTest] = useState(standardizedTest);
  const [anchorElTest, setAnchorElTest] = useState<null | HTMLElement>(null);
  const [deleted, setDeleted] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const auth = useAuth();
  
  const handleOpenTestMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElTest(event.currentTarget);
  };

  const handleCloseTestMenu = () => {
    setAnchorElTest(null);
  };

  const handleSaveEdit = async (updatedTest: StandardizedTest) => {
    setTest(updatedTest);
    setEditOpen(false);
  };

  const deleteTest = async () => {
    const token = auth.token();

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/standardized_tests/${test.id}`, {
      method: 'DELETE',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      setDeleted(true);
    } else {
      const json = await response.json();
      console.error(json);
    }
  };

  if (deleted) {
    return null;
  }

  return (
    <Stack direction="row">
      <Stack sx={{ flexGrow: 1 }}>
        <Stack direction="row">
          <Typography sx={{flexGrow: 1}} variant="subtitle2">{test.date && format(parseISO(test.date), 'MMM d, yyyy')}</Typography>
          <IconButton onClick={handleOpenTestMenu} sx={{ p: 0 }}><MoreVertIcon /></IconButton>
        </Stack>
        <Typography variant="subtitle2">Total Score: {test.total_score}</Typography>
        {test.notes &&
          <>
            <Typography variant="subtitle2">Notes</Typography>
            <RichTextViewer value={test.notes} />
          </>
        }
        {test.attachment?.url && (
          <Link variant="body2" href={test.attachment.url}>Download</Link>
        )}
        <Divider sx={{mt: 1}} />
      </Stack>
      <Menu
        anchorEl={anchorElTest}
        keepMounted
        open={Boolean(anchorElTest)}
        onClose={handleCloseTestMenu}
      >
        <MenuItem onClick={() => setEditOpen(true)}>
          <ListItemIcon>
            <EditOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => deleteTest()}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog open={editOpen} fullWidth>
        <EditStandardizedTest standardizedTest={test} onCancel={() => setEditOpen(false)} onSave={handleSaveEdit} />
      </Dialog>
    </Stack>
  );
}

export default StandardizedTestItem;
import * as React from "react";
import { StandardizedTest } from "../../models";
import StandardizedTestForm from "./StandardizedTestForm";
import useAuth from "../../hooks/useAuth";

const EditStandardizedTest = ({ standardizedTest, onCancel, onSave }: { standardizedTest: StandardizedTest, onCancel: () => void, onSave: (standardizedTest: StandardizedTest) => void }) => {
  const auth = useAuth();
  
  const updateStandardizedTest = async (updatedStandardizedTest: StandardizedTest) => {
    const token = auth.token();

    const formData = new FormData();
    if (updatedStandardizedTest.file) {
      formData.append('attachment', updatedStandardizedTest.file);
    }
    formData.append('test_type', updatedStandardizedTest.test_type);
    formData.append('date', updatedStandardizedTest.date);
    formData.append('total_score', updatedStandardizedTest.total_score?.toString() || '');
    formData.append('notes', updatedStandardizedTest.notes);

    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/standardized_tests/${standardizedTest.id}`, {
      method: 'PATCH',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
      body: formData,
    });
    if (response.ok) {
      fetchStandardizedTest();
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const fetchStandardizedTest = async () => {
    const token = auth.token();
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/standardized_tests/${standardizedTest.id}`, {
      method: 'GET',
      headers: new Headers({
        authorization: `Bearer ${token}`
      }),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      onSave(json);
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <StandardizedTestForm standardizedTest={standardizedTest} onCancel={handleCancel} onSave={updateStandardizedTest} />
  )
}

export default EditStandardizedTest;
import { Drawer, styled } from "@mui/material";

const StyledDrawer = styled(Drawer)(({ theme }) => `
  & .MuiListItem-root {
    padding: 4px 8px;

    & .MuiListItemButton-root {
      border-radius: 10px;

      &.Mui-selected {
        color: ${theme.palette.primary.main};
  
        & .MuiListItemIcon-root {
          color: inherit;
        }
      }
    }
  }
`);

export default StyledDrawer
import { DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import PrimaryButton from "../controls/PrimaryButton";
import SecondaryButton from "../controls/SecondaryButton";
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import SortOptions from './SortOptions';

const SortAssessments = ({ title, options, onCancel, onSave }: { title: string, options?: SortOptions, onCancel: () => void, onSave: (options: SortOptions) => void }) => {
  const [column, setColumn] = useState(options?.column || 'lower(institutions.name)');
  const [direction, setDirection] = useState(options?.direction || 'asc');

  const handleSave = () => {
    const options: SortOptions = {
      column,
      direction,
    };
    onSave(options);
  }
  
  return (
    <>
      <DialogTitle>
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <ImportExportOutlinedIcon sx={{pr: 1}} />
          <Typography variant="h6" sx={{flexGrow: 1}}>{title}</Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{py: 2}}>

          <FormControl>
            <InputLabel id="column">Sort</InputLabel>
            <Select
              labelId="column"
              label="Sort"
              value={column}
              onChange={(e) => setColumn(e.target.value)}
            >
              <MenuItem value="lower(institutions.name)">Name</MenuItem>
              <MenuItem value="lower(degrees.name)">Degree</MenuItem>
              <MenuItem value="calc_application_deadline">Application Deadline</MenuItem>
              <MenuItem value="calc_prescreen_deadline">Prescreen Deadline</MenuItem>
              <MenuItem value="artistic_confidence">Artistic Confidence</MenuItem>
              <MenuItem value="academic_confidence">Academic Confidence</MenuItem>
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id="direction">Direction</InputLabel>
            <Select
              labelId="direction"
              label="Direction"
              value={direction}
              onChange={(e) => setDirection(e.target.value)}
            >
              <MenuItem value="asc">Ascending</MenuItem>
              <MenuItem value="desc">Descending</MenuItem>
            </Select>
          </FormControl>

        </Stack>
      </DialogContent>
      <DialogActions>
        <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Apply</PrimaryButton>
      </DialogActions>
    </>
  )
}

export default SortAssessments;

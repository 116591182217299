import { Box, Card, CardContent, CardHeader, Container, Dialog, Divider, Stack, Typography } from "@mui/material";
import * as React from "react";
import { StandardizedTest, User } from "../../models";
import { useState } from "react";
import SecondaryButton from "../controls/SecondaryButton";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import StandardizedTestItem from "./StandardizedTestItem";
import AddStandardizedTest from "./AddStandardizedTest";

const StudentStandardizedTests = ({ student, onChange }: { student: User, onChange: (student: User) => void }) => {
  const [newOpen, setNewOpen] = useState(false);
  // const [standardizedTests, setStandardizedTests] = useState(student.standardized_tests || []);
  const standardizedTests = student.standardized_tests || [];

  const handleSaveNew = (newStandardizedTest: StandardizedTest) => {
    // setStandardizedTests([...standardizedTests, newStandardizedTest]);
    setNewOpen(false);
    onChange({...student, standardized_tests: [...standardizedTests, newStandardizedTest]});
  }

  const testTypes: { [key: string]: StandardizedTest[] } = {};
  for (const test of standardizedTests) {
    (testTypes[test.test_type] ||= []).push(test);
    testTypes[test.test_type].sort((a, b) => {
      if (b.date && a.date) return b.date.localeCompare(a.date);
      else if (a.date) return -1;
      else if (b.date) return 1;
      else return 0;
    });
  }
  const sortableTestTypes = Object.entries(testTypes);
  const sortedTestTypesArray = sortableTestTypes.sort(([a,], [b,]) => a.localeCompare(b));
  const sortedTestTypes = Object.fromEntries(sortedTestTypesArray);

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton
          variant="outlined"
          startIcon={<ControlPointOutlinedIcon />}
          sx={{ width: 1, maxWidth: 300 }}
          onClick={() => setNewOpen(true)}
        >
          Add Standardized Test
        </SecondaryButton>

        {Object.keys(sortedTestTypes).map((key, index) => (
          <Box key={key}>
            <Typography variant="overline">{key}</Typography>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  {sortedTestTypes[key].map(standardizedTest => (
                    <StandardizedTestItem key={standardizedTest.id} standardizedTest={standardizedTest} />
                  ))}
                </Stack>
              </CardContent>
            </Card>
          </Box>
        ))}
        {standardizedTests.length === 0 &&
          <Card>
            <CardHeader
              subheader="No standardized tests for this student"
            />
          </Card>
        }

        {student.id &&
          <Dialog open={newOpen} fullWidth>
            <AddStandardizedTest studentId={student.id} onCancel={() => setNewOpen(false)} onSave={handleSaveNew} />
          </Dialog>
        }
      </Stack>
    </Container>
  )
}

export default StudentStandardizedTests;
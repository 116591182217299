import { Box, Card, CardContent, Container, Dialog, Stack, Typography } from "@mui/material";
import * as React from "react";
import TaskItem from "../../../tasks/TaskItem";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useState } from "react";
import AssessmentAddTask from "./AssessmentAddTask";
import SecondaryButton from "../../../controls/SecondaryButton";
import TertiaryButton from "../../../controls/TertiaryButton";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import { Assessment, Task } from "../../../../models";
import { format, parse } from "date-fns";
import TemplatedTaskItem from "../../../tasks/TemplatedTaskItem";

const AssessmentTasks = ({ assessment, onChange }: { assessment: Assessment, onChange: (assessment: Assessment) => void }) => {
  const [open, setOpen] = useState(false);
  const tasks = assessment.tasks || [];
  // const [tasks, setTasks] = useState(assessment.tasks || []);

  const handleSave = (newTasks: Task[]) => {
    const filteredTasks = newTasks.filter(t => t.assessment_id === assessment.id);
    // setTasks(tasks.concat(filteredTasks));
    setOpen(false);
    onChange({...assessment, tasks: tasks.concat(filteredTasks)});
  }

  const updateTask = (task: Task) => {
    const newTasks = tasks.map(t => t.id === task.id ? task : t);
    onChange({...assessment, tasks: newTasks});
  }

  const completedTasks = tasks.filter(task => task.completed_at)
  const incompleteTasks = tasks.filter(task => !task.completed_at)

  const generalTasks = incompleteTasks.filter(task => !task.due_on).sort((a, b) => (a.order || 0) - (b.order || 0));

  const dueTasks: {[key: string]: Task[]} = {};
  for (const task of incompleteTasks) {
    if (task.due_on) {
      (dueTasks[task.due_on] ||= []).push(task);
    }
  }
  
  return (
    <Stack spacing={2}>
      <Container sx={{ 
        py: 2, 
        backgroundColor: 'background.default', 
        borderBottomLeftRadius: 20, 
        borderBottomRightRadius: 20,
      }}>
        <Stack spacing={2} direction="row">
          <SecondaryButton 
            variant="outlined" 
            startIcon={<ControlPointOutlinedIcon/>} 
            sx={{width: 1, maxWidth: 300}}
            onClick={() => setOpen(true)}
          >
            Add a Task
          </SecondaryButton>
        </Stack>
      </Container>

      <Box>
        <Typography variant="overline">GENERAL TASKS/EVENTS</Typography>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              {generalTasks.map(task => (
                (task.task_template_id ? (
                  <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} />
                ): (
                  <TaskItem key={task.id} task={task} onChange={updateTask} />
                ))
              ))}
              {generalTasks.length === 0 && (
                <Typography variant="body2" sx={{ pt: 1 }}>
                  You're all caught up.
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Card>
      </Box>

      {Object.keys(dueTasks).map((key, index) => (
        <Box key={key}>
          <Typography variant="overline">{format(parse(key, 'yyyy-MM-dd', new Date()),  "MMM d, yyyy")}</Typography>
          <Card>
            <CardContent>
              {dueTasks[key].map(task => (
                (task.task_template_id ? (
                  <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} />
                ): (
                  <TaskItem key={task.id} task={task} onChange={updateTask} />
                ))
              ))}
            </CardContent>
          </Card>
        </Box>
      ))}

      {completedTasks.length > 0 &&
        <Box>
          <Typography variant="overline">COMPLETED TASKS/EVENTS</Typography>
          <Card>
            <CardContent>
              {completedTasks.map(task => (
                (task.task_template_id ? (
                  <TemplatedTaskItem key={task.id} task={task} onChange={updateTask} />
                ): (
                  <TaskItem key={task.id} task={task} onChange={updateTask} />
                ))
              ))}
            </CardContent>
          </Card>
        </Box>
      }

      <Dialog open={open} fullWidth>
        <AssessmentAddTask assessment={assessment} onCancel={() => setOpen(false)} onSave={handleSave} />
      </Dialog>
    </Stack>
  )
}

export default AssessmentTasks;
import { Box, Button, Stack, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { convertFromHTML, convertToHTML } from "draft-convert";
import * as React from 'react';
import { useRef, useState } from "react";
import { CompositeDecorator, Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import RichTextLink from './RichTextLink';
import './editorStyle.css';

const BLOCK_TYPES = [
  // { label: 'H1', style: 'header-one', icon: null },
  // { label: 'H2', style: 'header-two', icon: null },
  // { label: 'H3', style: 'header-three', icon: null },
  // { label: 'H4', style: 'header-four', icon: null },
  // { label: 'H5', style: 'header-five', icon: null },
  // { label: 'H6', style: 'header-six', icon: null },
  { label: 'Blockquote', style: 'blockquote', icon: <FormatQuoteIcon /> },
  { label: 'UL', style: 'unordered-list-item', icon: <FormatListBulletedIcon /> },
  { label: 'OL', style: 'ordered-list-item', icon: <FormatListNumberedIcon /> },
  { label: 'Code Block', style: 'code-block', icon: <CodeIcon /> },
];

const BlockStyleControls = ({ editorState, onToggle }: { editorState: EditorState, onToggle: (style: string) => void }) => {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <ToggleButtonGroup size="small" sx={{ backgroundColor: 'background.default' }}>
      {BLOCK_TYPES.map((type) =>
        <ToggleButton
          key={type.label}
          value={type.label}
          selected={type.style === blockType}
          onClick={() => onToggle(type.style)}
        >{type.icon || type.label}</ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: <FormatBoldIcon /> },
  { label: 'Italic', style: 'ITALIC', icon: <FormatItalicIcon /> },
  { label: 'Underline', style: 'UNDERLINE', icon: <FormatUnderlinedIcon /> },
  // { label: 'Monospace', style: 'CODE', icon: null },
];

const InlineStyleControls = ({ editorState, onToggle }: { editorState: EditorState, onToggle: (style: string) => void }) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  
  return (
    <ToggleButtonGroup size="small" sx={{ backgroundColor: 'background.default' }}>
      {INLINE_STYLES.map((type) =>
        <ToggleButton
          key={type.label}
          value={type.label}
          selected={currentStyle.has(type.style)}
          onClick={() => onToggle(type.style)}
        >{type.icon || type.label}</ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};

const LinkControls = ({ editorState, onPromptForLink, onRemoveLink }: { editorState: EditorState, onPromptForLink: () => void, onRemoveLink: () => void }) => {
  return (
    <ToggleButtonGroup size="small" sx={{ backgroundColor: 'background.default' }}>
      <ToggleButton
        value="Add Link"
        onClick={onPromptForLink}
      ><AddLinkIcon/></ToggleButton>
      <ToggleButton
        value="Remove Link"
        onClick={onRemoveLink}
      ><LinkOffIcon/></ToggleButton>
    </ToggleButtonGroup>
  );
}

const RichTextEditor = ({ value, onChange, placeholder = '' }: { value: string, onChange: (value: string) => void, placeholder?: string }) => {
  const editor = useRef<Editor>(null);

  const focusEditor = () => {
    editor.current?.focus();
  }

  const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  }

  const decorator = new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: RichTextLink,
    },
  ]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromHTML({
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === 'a') {
            return createEntity(
                'LINK',
                'MUTABLE',
                {url: node.href}
            )
        }
      },
    })(value ?? ''), decorator)
  );

  const onDraftChange = (editorState: EditorState) => {
    setEditorState(editorState);
    const html = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'LINK') {
          return <a href={entity.data.url}>{originalText}</a>;
        }
        return originalText;
      }
    })(editorState.getCurrentContent());
    onChange(html.toString());
  }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  const toggleBlockType = (blockType: string) => {
    setEditorState(
      RichUtils.toggleBlockType(
        editorState, 
        blockType
      )
    );
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    setEditorState(
      RichUtils.toggleInlineStyle(
        editorState,
        inlineStyle
      )
    );
  };

  const urlTextField = useRef<HTMLInputElement>(null);
  const [showURLInput, setShowURLInput] = useState(false);
  const [urlValue, setUrlValue] = useState('');

  const promptForLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      setShowURLInput(true);
      setUrlValue(url);
      setTimeout(() => urlTextField.current?.focus(), 0);
    }
  }
  
  const removeLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState(
        RichUtils.toggleLink(editorState, selection, null)
      );
    }
  }

  const onLinkInputKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      confirmLink();
    }
  }

  const confirmLink = () => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {url: urlValue}
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const selectionState = editorState.getSelection();
    const contentStateWithLink = Modifier.applyEntity(contentStateWithEntity, selectionState, entityKey);
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithLink,
    });    
    setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    onDraftChange(newEditorState);

    setShowURLInput(false);
    setUrlValue('');
    setTimeout(() => urlTextField.current?.focus(), 0);
  }
  
  return (
    <Stack sx={{ border: 1, borderRadius: 1, borderColor: 'grey.400' }}>
      <Stack direction="row" spacing={1} sx={{ p: 1, backgroundColor: 'grey.50' }}>
        <BlockStyleControls
          editorState={editorState}
          onToggle={toggleBlockType}
        />
        <InlineStyleControls
          editorState={editorState}
          onToggle={toggleInlineStyle}
        />
        <LinkControls 
          editorState={editorState}
          onPromptForLink={promptForLink}
          onRemoveLink={removeLink}
        />
      </Stack>
      {showURLInput &&
        <Stack direction="row" spacing={1}>
          <TextField
            ref={urlTextField}
            value={urlValue}
            onChange={(e) => setUrlValue(e.target.value)}
            onKeyDown={onLinkInputKeyDown}
          />
          <Button onClick={confirmLink}>Confirm</Button>
        </Stack>
      }
      <Box sx={{ p: 1, fontSize: 12, lineHeight: '18.84px' }} onClick={focusEditor}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={onDraftChange}
          placeholder={placeholder}
          handleKeyCommand={handleKeyCommand}
        />
      </Box>
    </Stack>
  )
}

export default RichTextEditor;
import { Box, Card, Link, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import { FormEvent, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrimaryButton from "../controls/PrimaryButton";
import logo from '../logo.svg';
import useAuth from "../../hooks/useAuth";
import { UserPayload } from "../../providers/AuthProvider";

const ResetPassword = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [error, setError] = useState<string|undefined>();

  const resetPasswordToken = searchParams.get('reset_password_token');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password && passwordConfirmation) {
      auth.reset(resetPasswordToken, password, passwordConfirmation, (user: UserPayload|null) => {
        if (user) {
          navigate(`/`);
        } else {
          setError("We were unable to reset your password. Please try again or request another reset password link.");
        }
      });
    }

    // const token = auth.token();
    // const body = {
    //   reset_password_token: resetPasswordToken,
    //   password,
    //   password_confirmation: passwordConfirmation,
    // }
    // const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/password`, {
    //   method: 'PATCH',
    //   headers: new Headers({
    //     'content-type': 'application/json',
    //     authorization: `Bearer ${token}`
    //   }),
    //   body: JSON.stringify(body),
    // });
    // if (response.ok) {
    //   const json = await response.json();
    //   console.log(json);
    //   navigate(`/`);
    // } else {
    //   const json = await response.json();
    //   console.error(json);
    //   setError(json.errors[0].detail);
    // }
  }

  return (
    <Box sx={{ 
      p: 2, 
      position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, 
      display: 'flex', alignItems: 'center', justifyContent: 'center',
      backgroundColor: 'grey.100',
    }}>
      <Card sx={{ width: 1, p: 2, maxWidth: 400 }}>
        <form onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{alignItems: 'center'}}>
            <img src={logo} alt="ArtsBridge College Guide" width="270" height="65" />
            <Typography variant="h5">Create a new password</Typography>

            {error && <Typography variant="body1" color="error">{error}</Typography>}

            <TextField
              name="password"
              label="New Password"
              type="password"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />

            <TextField
              name="password_confirmation"
              label="Confirm New Password"
              type="password"
              fullWidth
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />

            <PrimaryButton 
              variant="contained" 
              type="submit"
              fullWidth
            >
              Save my password
            </PrimaryButton>

            <Link href="/login">Back to sign in</Link>
          </Stack>
        </form>
      </Card>
    </Box>
  )
}

export default ResetPassword;
import { Card, CardHeader, Container, Dialog, Stack } from "@mui/material";
import * as React from "react";
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import { useState } from "react";
import SecondaryButton from "../controls/SecondaryButton";
import CollaborationItem from "./CollaborationItem";
import InviteCollaborator from "./InviteCollaborator";
import { Collaboration, User } from "../../models";

const StudentCollaborators = ({ student, isAdmin, onChange }: { student: User, isAdmin: boolean, onChange: (student: User) => void }) => {
  const [open, setOpen] = useState(false);
  // const [collaborations, setCollaborations] = useState(student.collaborations || []);
  const collaborations = student.collaborations || [];

  const handleSave = (newCollaboration: Collaboration) => {
    // setCollaborations([...collaborations, newCollaboration]);
    setOpen(false);
    onChange({...student, collaborations: [...collaborations, newCollaboration]});
  }

  return (
    <Container sx={{ py: 2 }}>
      <Stack spacing={2}>
        <SecondaryButton 
          variant="outlined" 
          startIcon={<ControlPointOutlinedIcon/>} 
          sx={{width: 1, maxWidth: 300}}
          onClick={() => setOpen(true)}
        >
          Invite a Collaborator
        </SecondaryButton>

        <Stack spacing={2}>
          {collaborations.map(collaboration => (
            <CollaborationItem key={collaboration.id} collaboration={collaboration} isAdmin={isAdmin} />
          ))}
          {collaborations.length === 0 &&
            <Card>
              <CardHeader
                subheader="No collaborators for this student"
              />
            </Card>
          }
        </Stack>
        
        {student.id &&
          <Dialog open={open} fullWidth>
            <InviteCollaborator studentId={student.id} onCancel={() => setOpen(false)} onSave={handleSave} />
          </Dialog>
        }
      </Stack>
    </Container>
  )
}

export default StudentCollaborators;
import { Avatar, Box, Breadcrumbs, Button, Container, IconButton, Paper, Stack, TextField, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { Chat, ChatMessage } from "../../models";
import SendIcon from '@mui/icons-material/Send';
import useAuth from "../../hooks/useAuth";
import ResponsiveAppBar from "../controls/ResponsiveAppBar";

const ChatDetail = () => {
  const auth = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [chat, setChat] = useState<Chat>();
  const [message, setMessage] = useState<string>();
  const [messages, setMessages] = useState<ChatMessage[]>(chat?.chat_messages || []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;
  
  useEffect(() => {
    const fetchChat = async () => {
      const token = auth.token();
      const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/chats/${params.id}`, {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
          authorization: `Bearer ${token}`
        }),
      });
      if (response.ok) {
        const json = await response.json();
        console.log(json);
        setChat(json);
        setMessages(json.chat_messages);
      } else {
        const json = await response.json();
        console.error(json);
      }
    }

    fetchChat();
  }, []);

  const sendMessage = async () => {
    const token = auth.token();
    const body = {
      body: message,
    }
    const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/chats/${params.id}/chat_messages`, {
      method: 'POST',
      headers: new Headers({
        'content-type': 'application/json',
        authorization: `Bearer ${token}`
      }),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const json = await response.json();
      console.log(json);
      setMessages([...messages, json]);
      setMessage('');
    } else {
      const json = await response.json();
      console.error(json);
    }
  }

  if (!chat) return null;
  if (!auth.user) return null;

  const sortedMessages = messages.sort((a, b) => {
    if (a.created_at < b.created_at) return -1;
    if (a.created_at > b.created_at) return 1;
    return 0;
  });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ResponsiveAppBar>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>

          <Breadcrumbs color="inherit">
            <Typography variant="subtitle2">
              Messages
            </Typography>
            <Typography variant="subtitle2">
              {`${chat?.user?.first_name} ${chat?.user?.last_name}`}
            </Typography>
          </Breadcrumbs>
        </Toolbar>
      </ResponsiveAppBar>
      <Box>
        <Toolbar/>
        <Container sx={{ pt: 2, pb: 8 }}>
          <Stack spacing={2}>
            {sortedMessages.map(chatMessage => (
              <Box key={chatMessage.id}>
                {chatMessage.user?.id === auth.user.id ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Box sx={{ backgroundColor: 'primary.main', p: 1, borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomLeftRadius: 5 }}>
                      <Typography variant="body1" color="common.white">
                        {chatMessage.body}
                      </Typography>
                    </Box>
                    <Avatar sx={{ml: 2}} alt={`${chatMessage.user?.first_name} ${chatMessage.user?.last_name}`} src={chatMessage.user?.avatar?.url} />
                  </Box>
                ) : (
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Avatar sx={{mr: 2}} alt={`${chatMessage.user?.first_name} ${chatMessage.user?.last_name}`} src={chatMessage.user?.avatar?.url} />
                    <Box sx={{ backgroundColor: 'grey.500', p: 1, borderTopLeftRadius: 5, borderTopRightRadius: 5, borderBottomRightRadius: 5 }}>
                      <Typography variant="body1" color="text.primary">
                        {chatMessage.body}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        </Container>
      </Box>
      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Stack direction="row" spacing={1}>
          <TextField 
            variant="outlined" 
            placeholder="Type a message..."
            value={message}
            multiline
            rows={undefined}
            size="small"
            onChange={(e) => setMessage(e.target.value)} 
            sx={{ flexGrow: 1 }}
          />
          <Button variant="contained" sx={{ flexGrow: 0 }} onClick={sendMessage}>
            <SendIcon />
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
}

export default ChatDetail;
import { Avatar, Card, CardActionArea, CardHeader, IconButton, Stack, Typography } from "@mui/material";
import * as React from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import './StudentCard.css';
import { User } from "../../../../models";
import { FilterData } from "../../../filters/FilterAssessments";

const filterOptions = ['Yes', 'No'];
const applicationStatusOptions = [
  'In Progress',
  'Ready for ArtsBridge Review',
  'Reviewed by ArtsBridge',
  'Submitted',
  'Withdrawn',
  'Accepted',
  'Accepted (Only Academically)',
  'Wait Listed',
  'Denied',
];

const StudentCard = ({ student, showAssessments, filters, to }: { student: User, showAssessments: boolean, filters?: FilterData, to: string }) => {  
  
  const filteredAssessments = student.assessments?.filter(assessment => {
    let include = true;

    if (!assessment.institution_degree) return false;

    if (filters) {

      if (filterOptions.includes(filters.prescreens)) {
        if (assessment.institution_degree?.pre_screening !== filters.prescreens) {
          include = false;
        }
      }

      if (filterOptions.includes(filters.auditions_portfolio_required)) {
        if (assessment.institution_degree?.required_audition_portfolio !== filters.auditions_portfolio_required) {
          include = false;
        }
      }

      // if (filters.entered_audition_dates === 'Yes') {
      //   if (assessment.institution_degree?.audition_dates === '') {
      //     include = false;
      //   }
      // } else if (filters.entered_audition_dates === 'No') {
      //   if (assessment.institution_degree?.audition_dates !== '') {
      //     include = false;
      //   }
      // }

      if (filterOptions.includes(filters.early_decision)) {
        if (assessment.institution_degree?.early_decision !== filters.early_decision) {
          include = false;
        }
      }

      if (filterOptions.includes(filters.early_action)) {
        if (assessment.institution_degree?.early_action !== filters.early_action) {
          include = false;
        }
      }

      if (filterOptions.includes(filters.score_optional_enabled)) {
        if (assessment.institution_degree?.score_optional_enabled !== filters.score_optional_enabled) {
          include = false;
        }
      }

      if (filterOptions.includes(filters.common_app)) {
        if (assessment.institution_degree?.institution?.common_app !== filters.common_app) {
          include = false;
        }
      }

      if (applicationStatusOptions.includes(filters.application_status)) {
        if (assessment.application_status !== filters.application_status) {
          include = false;
        }
      }

    }
    return include;
  });
  
  return (
    <Card>
      <CardActionArea component={Link} to={to}>
        <CardHeader
          avatar={<Avatar alt={`${student.first_name} ${student.last_name}`} src={student.avatar?.url} />}
          action={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          title={`${student.first_name} ${student.last_name}`}
          subheader={student.email}
        />
      </CardActionArea>
      {showAssessments && filteredAssessments?.map(assessment => 
        <CardActionArea key={assessment.id} component={Link} to={`/assessments/${assessment.id}`}>
          <Stack direction="row" spacing={2} sx={{ pl: 9, py: 2 }}>
            <img 
              src={assessment.institution_degree?.institution?.institution_avatar?.url} 
              alt={`${assessment.institution_degree?.institution?.name} ${assessment.institution_degree?.degree?.name}`} 
              className="student-card__assessment-image"
            />
            <Stack>
              <Typography variant="subtitle2">{assessment.institution_degree?.institution?.name}</Typography>
              <Typography variant="body2">{assessment.institution_degree?.degree?.name}</Typography>
            </Stack>
          </Stack>
        </CardActionArea>
      )}
    </Card>
  )
}

export default StudentCard;
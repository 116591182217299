import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Checkbox, Divider, Grid, Stack, TableCell, TableRow, Typography } from "@mui/material";
import * as React from "react";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { Link } from "react-router-dom";
import StatusList from "../controls/StatusList/StatusList";
import { format, parseISO } from "date-fns";
import { Assessment } from "../../models";
import { FilterData } from "../filters/FilterAssessments";
import RichTextViewer from "../controls/RichTextViewer";
import StatusListItem from "../controls/StatusList/StatusListItem";
import { applicationStatusOptions, applyingOptions, statusOptions } from "../../models/Assessment";
import ConfidenceList from "../controls/StatusList/ConfidenceList";

const confidenceOptions = [
  ["Please Select", ""],
  ["Likely", "Likely"],
  ["Likely/Target", "Likely/Target"],
  ["Target", "Target"],
  ["Target/Stretch", "Target/Stretch"],
  ["Stretch", "Stretch"],
  ["High Stretch", "High Stretch"],
  ["Not Applicable", "Not Applicable"],
];

const AssessmentListItem = ({ assessment, filters, isAdmin, onChange, isSelectable = false, isItemSelected = false, onCheckboxClick }: { assessment: Assessment, filters: FilterData|undefined, isAdmin: boolean, onChange: (assessment: Assessment) => void, isSelectable?: boolean, isItemSelected?: boolean, onCheckboxClick?: (assessment: Assessment) => void }) => {

  const collegeInstitutionDegree = assessment.institution_degree;
  if (!collegeInstitutionDegree) return null;
  
  return (
    <TableRow>
      {isSelectable &&
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={isItemSelected}
            onClick={() => onCheckboxClick(assessment)}
          />
        </TableCell>
      }
      <TableCell sx={{verticalAlign: 'top'}}>
        <Stack direction="row" spacing={2} sx={{ color: 'inherit', textDecoration: 'none' }} component={Link} to={`/assessments/${assessment.id}`}>
          <img 
            src={assessment.institution_degree?.institution?.institution_avatar?.url} 
            alt={`${assessment.institution_degree?.institution?.name} ${assessment.institution_degree?.degree?.name}`} 
            className="student-list-item__assessment-image"
          />
          <Stack>
            <Typography variant="subtitle2">{assessment.institution_degree?.institution?.name}</Typography>
            <Typography variant="body2">{assessment.institution_degree?.degree?.name}</Typography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell sx={{verticalAlign: 'top'}}>
        <StatusList assessment={assessment} onChange={onChange} />
      </TableCell>

      <TableCell sx={{verticalAlign: 'top'}}>
        <ConfidenceList assessment={assessment} isAdmin={isAdmin} onChange={onChange} />
      </TableCell>

      <TableCell sx={{verticalAlign: 'top'}}>
        <ul className="status-list">
          {assessment.applying === 'Regular Decision' && collegeInstitutionDegree.regular_deadline && 
            <>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Regular Admission Deadline:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.regular_deadline), 'MMM d, yyyy')}</Typography>
                </Stack>
              </li>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.pre_screening_deadline &&
                <li>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" noWrap>Regular Decision Prescreen Deadline:</Typography>
                    <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.pre_screening_deadline), 'MMM d, yyyy')}</Typography>
                  </Stack>
                </li>
              }
            </>
          }

          {assessment.applying === 'Early Action' && collegeInstitutionDegree.early_action === 'Yes' &&
            <>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Early Action Date:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.early_action_date), 'MMM d, yyyy')}</Typography>
                </Stack>
              </li>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_2 &&
                <li>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" noWrap>Early Action Prescreen Deadline:</Typography>
                    <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_2), 'MMM d, yyyy')}</Typography>
                  </Stack>
                </li>
              }
            </>
          }

          {assessment.applying === 'Early Decision' && collegeInstitutionDegree.early_decision === 'Yes' &&
            <>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Early Decision Date:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.early_decision_date), 'MMM d, yyyy')}</Typography>
                </Stack>
              </li>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_3 &&
                <li>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2">Early Decision Prescreen Deadline:</Typography>
                    <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.prescreen_deadline_3), 'MMM d, yyyy')}</Typography>
                  </Stack>
                </li>
              }
            </>
          }

          {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision === 'Yes' &&
            <>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Other Decision Details:</Typography>
                  <Typography variant="body2" noWrap>{collegeInstitutionDegree.other_decision_details}</Typography>
                </Stack>
              </li>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Other Decision Date:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.other_decision_date), 'MMM d, yyyy')}</Typography>
                </Stack>
              </li>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_4 &&
                <li>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" noWrap>Other Decision Prescreen Deadline:</Typography>
                    <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_4), 'MMM d, yyyy')}</Typography>
                  </Stack>
                </li>
              }
            </>
          }

          {assessment.applying === 'Other Decision' && collegeInstitutionDegree.other_decision_two === 'Yes' &&
            <>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Other Decision Details 2:</Typography>
                  <Typography variant="body2" noWrap>{collegeInstitutionDegree.other_decision_details_two}</Typography>
                </Stack>
              </li>
              <li>
                <Stack direction="row" spacing={1}>
                  <Typography variant="subtitle2" noWrap>Other Decision Date 2:</Typography>
                  <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.other_decision_date_two), 'MMM d, yyyy')}</Typography>
                </Stack>
              </li>
              {collegeInstitutionDegree.pre_screening === 'Yes' && collegeInstitutionDegree.prescreen_deadline_5 &&
                <li>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" noWrap>Other Decision 2 Prescreen Deadline:</Typography>
                    <Typography variant="body2" noWrap>{format(parseISO(collegeInstitutionDegree.prescreen_deadline_5), 'MMM d, yyyy')}</Typography>
                  </Stack>
                </li>
              }
            </>
          }

          {collegeInstitutionDegree.supplemental_application_required === 'Yes' && collegeInstitutionDegree.supplemental_application_deadline &&
            <li>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Supplemental Application/Portfolio Deadline:</Typography>
                <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.supplemental_application_deadline), 'MMM d, yyyy')}</Typography>
              </Stack>
            </li>
          }

          {collegeInstitutionDegree.optional_arts_supplement === 'Yes' && collegeInstitutionDegree.optional_arts_supplement_deadline &&
            <li>
              <Stack direction="row" spacing={1}>
                <Typography variant="subtitle2">Optional Arts Supplement Deadline:</Typography>
                <Typography variant="body2">{format(parseISO(collegeInstitutionDegree.optional_arts_supplement_deadline), 'MMM d, yyyy')}</Typography>
              </Stack>
            </li>
          }
        </ul>
      </TableCell>

      {filters?.prescreens && filters?.prescreens !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{collegeInstitutionDegree.pre_screening}</TableCell>
      }
      {filters?.auditions_portfolio_required && filters?.auditions_portfolio_required !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{collegeInstitutionDegree.required_audition_portfolio}</TableCell>
      }
      {filters?.early_decision && filters?.early_decision !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{collegeInstitutionDegree.early_decision}</TableCell>
      }
      {filters?.early_action && filters?.early_action !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{collegeInstitutionDegree.early_action}</TableCell>
      }
      {filters?.score_optional_enabled && filters?.score_optional_enabled !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{collegeInstitutionDegree.score_optional_enabled}</TableCell>
      }
      {filters?.common_app && filters?.common_app !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{collegeInstitutionDegree.institution?.common_app}</TableCell>
      }
      {(filters?.audition_date_start || filters?.audition_date_end) && 
        <TableCell sx={{verticalAlign: 'top'}}><RichTextViewer value={collegeInstitutionDegree.audition_dates} /></TableCell>
      }
      {filters?.graduation_year && filters?.graduation_year !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{assessment.user?.high_school_graduation_year}</TableCell>
      }

      {filters?.test_optional && filters?.test_optional !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>
          {assessment.test_optional === 'Other' && assessment.test_optional_other ?
            <Typography variant="body2">{assessment.test_optional_other}</Typography>
          :
            <Typography variant="body2">{assessment.test_optional}</Typography>
          }
        </TableCell>
      }
      {filters?.unified_auditions && filters?.unified_auditions !== 'Any' && 
        <TableCell sx={{verticalAlign: 'top'}}>{assessment.institution_degree?.unified_auditions}</TableCell>
      }
    </TableRow>
  );
}

export default AssessmentListItem;
import { Box, Container, Grid, Paper, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { Degree } from "../../../../models";
import PrimaryButton from "../../../controls/PrimaryButton";
import SecondaryButton from "../../../controls/SecondaryButton";

const DegreeForm = ({ degree, onCancel, onSave }: { degree?: Degree, onCancel: () => void, onSave: (degree: Degree) => void }) => {
  const [name, setName] = useState(degree?.name || '');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const drawerWidth = isMobile ? 0 : 300;

  const handleSave = () => {
    const degree: Degree = {
      name: name,
    }
    onSave(degree);
  }

  return (
    <Box sx={{ pb: 7 }}>
      <Container sx={{ py: 2 }}>
        <Stack spacing={2}>

          <TextField 
            label="Name" 
            variant="outlined" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            required />

        </Stack>
      </Container>
      <Paper sx={{ position: 'fixed', bottom: 0, left: drawerWidth, right: 0, p: 1, zIndex: 1100 }} elevation={3}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SecondaryButton variant="outlined" sx={{width: 1}} onClick={onCancel}>Cancel</SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <PrimaryButton variant="contained" sx={{width: 1}} onClick={handleSave}>Save</PrimaryButton>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default DegreeForm;
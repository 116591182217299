import * as React from 'react';
import UserContext from '../contexts/UserContext';
import { ReactNode, useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import { Collaboration } from '../models';

const getUserFromToken = async () => {
  // fetch all the current user's invitations
  const token = localStorage.getItem('token');
  const response = await fetch(`${window.SERVER_DATA.domain}/api/v1/invitations`, {
    method: 'GET',
    headers: new Headers({
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    }),
  });
  const json = await response.json() as Collaboration[];
  console.log(json);
  
  const userId = localStorage.getItem('user');
  if (userId) {
    // use the saved student
    const userIdNumber = parseInt(userId);
    if (json.filter(c => c.user_id === userIdNumber).length > 0) {
      return userIdNumber;
    }
  } else if (json.length > 0) {
    // default to the first student
    const userIdNumber = json[0].user_id;
    if (userIdNumber) {
      localStorage.setItem('user', userIdNumber.toString());
      return userIdNumber;
    }
  }
  
  return null;
}

const UserProvider = ({ children }: { children: ReactNode }) => {
  const auth = useAuth();
  
  const [userId, setUserId] = useState<number>();

  useEffect(() => {
    const fetchUser = async () => {
      const userId = undefined;//await getUserFromToken();
      setUserId(userId || auth.user.id);
    }
    fetchUser();
  }, []);

  const switchUser = (userId: number) => {
    localStorage.setItem('user', userId.toString());
    setUserId(userId);
  };

  const value = { userId, switchUser };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export default UserProvider;
import { Box, Button, Toolbar, Typography } from "@mui/material";
import * as React from "react";
import { alpha } from '@mui/material/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const EnhancedTableToolbar = ({ numSelected, onNewTask }: { numSelected: number, onNewTask: () => void }) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Students
        </Typography>
      )}
      {numSelected > 0 ? (
        <Button variant="outlined" startIcon={<CheckCircleOutlinedIcon />} sx={{ flexShrink: 0 }} onClick={onNewTask}>
          New Task/Event
        </Button>
      ) : (
        <Box/>
      )}
    </Toolbar>
  )
}

export default EnhancedTableToolbar;